// @flow
import React, { useState } from 'react'
import Popover from '@material-ui/core/Popover'
import WarningIcon from '@material-ui/icons/Warning'
import RenderIf from '../renderIf'

import PopoverContent from './fragments/popoverContent'

import type { TeamTooltipProps } from './TeamTooltipTypes'

import { StyledTeamTooltip } from './TeamTooltipStyles'

const TeamTooltip = ({ teamData }: TeamTooltipProps) => {
  const { full_name } = teamData || {}
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = e => {
    e.stopPropagation()
    full_name && setAnchorEl(e.currentTarget)
  }

  const handleClose = e => {
    e.stopPropagation()
    setAnchorEl(null)
  }

  const handleIconCLick = e => {
    e.stopPropagation()
    handleClick(e)
  }

  const open = Boolean(anchorEl)
  const popoverId = open ? 'team-popover' : undefined
  return (
    <RenderIf condition={teamData}>
      <StyledTeamTooltip full_name={full_name}>
        <>
          <WarningIcon onClick={handleIconCLick} />
          <Popover
            id={popoverId}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            {/* $FlowFixMe */}
            <PopoverContent {...teamData} />
          </Popover>
        </>
      </StyledTeamTooltip>
    </RenderIf>
  )
}
export default TeamTooltip
