// @flow
import { call, fork, put, take } from 'redux-saga/effects'
import { GET_PAYMENT_METHODS, getPaymentMethods } from 'src/redux/actions/payments/payments'

import http from 'src/services/http'
// types
import type { IOEffect } from 'redux-saga/effects'

export const shapePaymentMethods = (data: any) => {
  return data.reduce((acc, cur) => {
    if (cur.cardType) {
      return {
        ...acc,
        cards: [...acc.cards, cur],
      }
    } else {
      return {
        ...acc,
        ACH: [...acc.ACH, cur],
      }
    }
  }, { cards: [], ACH: [] })
}

function * watchGetPaymentMethods(): Generator<IOEffect, void, any> {
  while (true) {
    yield take(GET_PAYMENT_METHODS.REQUEST)
    try {
      const { data } = yield call(http, 'api/payment/ach/list', {
        method: 'GET',
      })

      const shapedData = shapePaymentMethods(data)

      yield put(getPaymentMethods.success(shapedData))
    } catch (e) {
      yield put(getPaymentMethods.error(e.message))
    }
  }
}

export default [fork(watchGetPaymentMethods)]
