// @flow
import React from 'react'
import { Link } from 'react-router-dom'
import { Modal, RenderIf } from 'src/common/components'

import type { ConfirmData } from 'src/redux/reducers/ui'
import type { MyLeaguesDataItem } from 'src/redux/reducers/myLeagues'

// style
import { StyledConfirm } from './LeagueWarningStyles'

const Subtitle = ({ data }: {data: MyLeaguesDataItem[]}) => {
  return (
    <StyledConfirm>
      <div>You must enter a valid website for your leagues to appear in the NFL FLAG League Finder:</div>
      <ul>
        {data.map(({ id, name }, index) => {
          return (
            <li key={id}>
              <Link
                to={`/my-leagues/${id}/edit`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {`${index + 1}. ${name}`}
              </Link>
            </li>
          )
        })}
      </ul>
    </StyledConfirm>
  )
}

const LeagueWarning = ({
  confirmData,
  toggleConfirm,
}: {
    confirmData?: ConfirmData,
    toggleConfirm: () => void
  }) => {
  return (
    <>
      <RenderIf condition={confirmData?.show}>
        <Modal
          title={'Required'}
          //  $FlowFixMe
          subtitle={<Subtitle data={confirmData?.data} />}
          showCancelBtn={false}
          onConfirm={() => toggleConfirm()}
          confirmBtnText={'OK'}
        />
      </RenderIf>
    </>
  )
}

export default LeagueWarning
