// @flow
const { REACT_APP_MAIN_SITE = '' } = process.env

export const getMainAppOriginUrl = () => (new URL(REACT_APP_MAIN_SITE)).origin

export const wrappLinksIntoHTMLTag = (str: string) => {
  return str.replace(/https?:\/\/\S+/, letter => {
    return `<a href="${letter}" rel="noopener noreferrer" target="_blank">${letter}</a>`
  })
}
