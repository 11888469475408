// @flow
import * as React from 'react'
import { connect } from 'react-redux'

import {
  getFeatures,
  getFeature,
} from 'src/redux/actions/featureManagement/featureManagement'

const withFeatureManagement = <P>(WrappedComponent: React.ComponentType<P>) => {
  class WithFeatureManagementComponent extends React.Component<P> {
    render() {
      return <WrappedComponent {...this.props} />
    }
  }
  const mapStateToProps = ({ featureManagement }) => ({ featureManagement })

  const mapDispatchToProps = dispatch => ({
    getFeatures: data => dispatch(getFeatures.request(data)),
    getFeature: data => dispatch(getFeature.request(data)),
  })

  return connect(
    mapStateToProps,
    mapDispatchToProps,
  )(WithFeatureManagementComponent)
}

export default withFeatureManagement

