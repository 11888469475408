// @flow

import * as React from 'react'
import { connect } from 'react-redux'

import { showToast, toggleConfirm } from 'src/redux/actions/ui/ui'

const withUi = <P>(WrappedComponent: React.ComponentType<P>) => {
  class withUiComponent extends React.Component<P> {
    render() {
      return <WrappedComponent {...this.props} />
    }
  }
  const mapStateToProps = ({ ui }) => ({ ui })

  const mapDispatchToProps = dispatch => ({
    showToastSuccess: value => dispatch(showToast.success({ ...value })),
    toggleConfirm: value => dispatch(toggleConfirm.success(value)),
  })

  return connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withUiComponent)
}

export default withUi
