// @flow
import React, { lazy } from 'react'
import { PrivateRoute } from 'src/common/components'
const ThankYouPage = lazy(() => import('src/modules/payment/thankYouPage'))
const PendingOrderCardPayment = lazy(() => import('src/modules/payment/pendingOrderCardPayment'))
const OrderPayment = lazy(() => import('src/modules/payment/orderPayment'))

export default [
  <PrivateRoute exact={true} path="/my-leagues/:leagueId/thank-you" component={ThankYouPage} key="payment-thank-you"/>,
  <PrivateRoute exact={true} path="/my-leagues/:leagueId/orders/:orderId/payment" component={PendingOrderCardPayment} key="/my-leagues/leagueId/order/payment"/>,
  <PrivateRoute exact={true} path="/order/league/:leagueId/season/:seasonId/order/:orderId/only/payment" component={OrderPayment} key="/rder/league/leagueId/season/seasonId/order/orderId/only/payment"/>,
]

// Turned VirtualTerminal off. See NFLFLAG-515
// const VirtualTerminal = lazy(() => import("src/modules/payment/virtualTerminal"))
// <PrivateRoute exact={true} path="/my-leagues/:leagueId/vt" component={VirtualTerminal} key="virtual-terminal"/>,
