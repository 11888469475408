// @flow

import { requestTypeCreator } from 'src/helpers'

// types
import type {
  DefaultRedux,
} from './makeOrderTypes'

export const MAKE_ORDER_TEAMS_CHANGE = requestTypeCreator('22--MAKE_ORDER_TEAMS_CHANGE')
export const CHECKOUT_LATEST_ITEMS_QTY = requestTypeCreator('22--CHECKOUT_LATEST_ITEMS_QTY')
export const SUBMIT_MAIN_MAKE_ORDER = requestTypeCreator('22--SUBMIT_MAIN_MAKE_ORDER', { ADJUSTED: true })
export const MAKE_ORDER_UPDATE_ORDER = requestTypeCreator('22--MAKE_ORDER_UPDATE_ORDER')
export const REMOVE_TEAM_FROM_MAKE_ORDER = requestTypeCreator('22--REMOVE_TEAM_FROM_MAKE_ORDER')
export const RESET_MAKE_ORDER = '22--RESET_MAKE_ORDER'

export const RESET_CONFIRM_WARNING = requestTypeCreator('RESET_CONFIRM_WARNING')

export const UPDATE_ORDER_FORM_STATE = {
  PAID: 'UPDATE_ORDER_FORM_STATE__PAID',
  FREE: 'UPDATE_ORDER_FORM_STATE__FREE',
}
export const RESTORE_ORDER_PROGRESS = requestTypeCreator('22--RESTORE_ORDER_PROGRESS')
export const SET_AVAILABLE_SHIPPING_ADDRESSES = requestTypeCreator('22--SET_AVAILABLE_SHIPPING_ADDRESSES')
export const SEND_SHIPPING_ADDRESS = requestTypeCreator('22--SEND_SHIPPING_ADDRESS')
export const SHIPPING_ADDRESS_FIELDS_CHANGE = 'SHIPPING_ADDRESS_FIELDS_CHANGE'

export const GET_SHIPPING_METHODS = requestTypeCreator('22--GET_SHIPPING_METHODS')
export const SEND_SHIPPING_METHOD = requestTypeCreator('22--SEND_SHIPPING_METHOD')

export const VERIFICATION_CHECK_BEFORE_PAYMENT = requestTypeCreator('22--VERIFICATION_CHECK_BEFORE_PAYMENT')

export const SUBMIT_ORDER_CHECK_PAYMENT = requestTypeCreator('SUBMIT_ORDER_CHECK_PAYMENT')

export const SET_DISCOUNT = requestTypeCreator('SET_DISCOUNT')

// LINE

export const SEND_MAKE_ORDER_FILE = requestTypeCreator('22--SEND_MAKE_ORDER_FILE')

export const UPDATE_DISCOUNT = requestTypeCreator('UPDATE_DISCOUNT')
export const MAKE_DISCOUNT_PAYMENT = requestTypeCreator('MAKE_DISCOUNT_PAYMENT')
export const UPDATE_NOTE = requestTypeCreator('UPDATE_NOTE')

export const SET_MAKE_ORDER_ADJUSTED = '22--SET_MAKE_ORDER_ADJUSTED'
export const SET_FOOTBALLS_STATE = 'SET_FOOTBALLS_STATE'
export const UPDATE_CREDIT_AMOUNT = requestTypeCreator('UPDATE_CREDIT_AMOUNT', { START: 'START' })

export const makeOrderTeamsChange = {
  success: (payload: any): DefaultRedux => ({ type: MAKE_ORDER_TEAMS_CHANGE.SUCCESS, payload }),
  error: (payload: any): DefaultRedux => ({ type: MAKE_ORDER_TEAMS_CHANGE.FAILURE, payload }),
  request: (payload: any): DefaultRedux => ({ type: MAKE_ORDER_TEAMS_CHANGE.REQUEST, payload }),
}

export const checkoutLatestItemsQty = {
  success: (payload: any): DefaultRedux => ({ type: CHECKOUT_LATEST_ITEMS_QTY.SUCCESS, payload }),
  error: (payload: any): DefaultRedux => ({ type: CHECKOUT_LATEST_ITEMS_QTY.FAILURE, payload }),
  request: (payload: any): DefaultRedux => ({ type: CHECKOUT_LATEST_ITEMS_QTY.REQUEST, payload }),
}

export const updateMakeOrder = {
  success: (payload: any): DefaultRedux => ({ type: MAKE_ORDER_UPDATE_ORDER.SUCCESS, payload }),
}

export const submitMainMakeOrder = {
  success: (payload: any): DefaultRedux => ({ type: SUBMIT_MAIN_MAKE_ORDER.SUCCESS, payload }),
  error: (payload: any): DefaultRedux => ({ type: SUBMIT_MAIN_MAKE_ORDER.FAILURE, payload }),
  request: (payload: any): DefaultRedux => ({ type: SUBMIT_MAIN_MAKE_ORDER.REQUEST, payload }),
  adjusted: (payload: any): DefaultRedux => ({ type: SUBMIT_MAIN_MAKE_ORDER.ADJUSTED, payload }),
}

export const removeTeamFromMakeOrder = {
  success: (payload: any): DefaultRedux => ({ type: REMOVE_TEAM_FROM_MAKE_ORDER.SUCCESS, payload }),
}

export const updateOrderFormState = {
  paid: (payload: any): DefaultRedux => ({ type: UPDATE_ORDER_FORM_STATE.PAID, payload }),
  free: (payload: any): DefaultRedux => ({ type: UPDATE_ORDER_FORM_STATE.FREE, payload }),
}

export const resetMakeOrder = {
  success: (payload: any): DefaultRedux => ({ type: RESET_MAKE_ORDER, payload }),
}

export const resetConfirmWarning = {
  success: (payload: any): DefaultRedux => ({ type: RESET_CONFIRM_WARNING.SUCCESS, payload }),
}

export const restoreOrderProgress = {
  success: (payload: any): DefaultRedux => ({ type: RESTORE_ORDER_PROGRESS.SUCCESS, payload }),
  request: (payload: any): DefaultRedux => ({ type: RESTORE_ORDER_PROGRESS.REQUEST, payload }),
  error: (payload: any): DefaultRedux => ({ type: RESTORE_ORDER_PROGRESS.FAILURE, payload }),
}

export const sendOrderShippingAddress = {
  success: (payload: any): DefaultRedux => ({ type: SEND_SHIPPING_ADDRESS.SUCCESS, payload }),
  error: (payload: any): DefaultRedux => ({ type: SEND_SHIPPING_ADDRESS.FAILURE, payload }),
  request: (payload: any): DefaultRedux => ({ type: SEND_SHIPPING_ADDRESS.REQUEST, payload }),
}

export const changeShippingAddressFields = (payload: any): DefaultRedux => ({ type: SHIPPING_ADDRESS_FIELDS_CHANGE, payload })

export const getShippingMethods = {
  error: (payload: any): DefaultRedux => ({ type: GET_SHIPPING_METHODS.FAILURE, payload }),
  request: (payload: any): DefaultRedux => ({ type: GET_SHIPPING_METHODS.REQUEST, payload }),
  success: (payload: any): DefaultRedux => ({ type: GET_SHIPPING_METHODS.SUCCESS, payload }),
}

export const sendShippingMethod = {
  success: (payload: any): DefaultRedux => ({ type: SEND_SHIPPING_METHOD.SUCCESS, payload }),
  error: (payload: any): DefaultRedux => ({ type: SEND_SHIPPING_METHOD.FAILURE, payload }),
  request: (payload: any): DefaultRedux => ({ type: SEND_SHIPPING_METHOD.REQUEST, payload }),
}

export const setAvailableAddresses = {
  success: (payload: any): DefaultRedux => ({ type: SET_AVAILABLE_SHIPPING_ADDRESSES.SUCCESS, payload }),
}
export const verificationCheckBeforePayment = {
  request: (payload: any): DefaultRedux => ({ type: VERIFICATION_CHECK_BEFORE_PAYMENT.REQUEST, payload }),
  success: (payload: any): DefaultRedux => ({ type: VERIFICATION_CHECK_BEFORE_PAYMENT.SUCCESS, payload }),
}

export const submitOrderCheckPayment = {
  request: (payload: any): DefaultRedux => ({ type: SUBMIT_ORDER_CHECK_PAYMENT.REQUEST, payload }),
  error: (payload: any): DefaultRedux => ({ type: SUBMIT_ORDER_CHECK_PAYMENT.FAILURE, payload }),
  success: (payload: any): DefaultRedux => ({ type: SUBMIT_ORDER_CHECK_PAYMENT.SUCCESS, payload }),
}

// LINE

export const sendMakeOrderFile = {
  success: (payload: any): DefaultRedux => ({ type: SEND_MAKE_ORDER_FILE.SUCCESS, payload }),
  error: (payload: any): DefaultRedux => ({ type: SEND_MAKE_ORDER_FILE.FAILURE, payload }),
  request: (payload: any): DefaultRedux => ({ type: SEND_MAKE_ORDER_FILE.REQUEST, payload }),
}

export const setMakeOrderAdjusted = {
  success: (payload: any): DefaultRedux => ({ type: SET_MAKE_ORDER_ADJUSTED, payload }),
}

export const makeDiscountPayment = {
  success: (payload: any): DefaultRedux => ({ type: MAKE_DISCOUNT_PAYMENT.SUCCESS, payload }),
  error: (payload: any): DefaultRedux => ({ type: MAKE_DISCOUNT_PAYMENT.FAILURE, payload }),
  request: (payload: any): DefaultRedux => ({ type: MAKE_DISCOUNT_PAYMENT.REQUEST, payload }),
}

export const updateDiscount = {
  success: (payload: any): DefaultRedux => ({ type: UPDATE_DISCOUNT.SUCCESS, payload }),
  error: (payload: any): DefaultRedux => ({ type: UPDATE_DISCOUNT.FAILURE, payload }),
  request: (payload: any): DefaultRedux => ({ type: UPDATE_DISCOUNT.REQUEST, payload }),
}

export const updateNote = {
  success: (payload: any): DefaultRedux => ({ type: UPDATE_NOTE.SUCCESS, payload }),
  error: (payload: any): DefaultRedux => ({ type: UPDATE_NOTE.FAILURE, payload }),
  request: (payload: any): DefaultRedux => ({ type: UPDATE_NOTE.REQUEST, payload }),
}

export const setDiscount = {
  request: (payload: any): DefaultRedux => ({ type: SET_DISCOUNT.REQUEST, payload }),
}
export const setFootballsState = {
  success: (payload: any): DefaultRedux => ({ type: SET_FOOTBALLS_STATE, payload }),
}

export const updateCreditAmount = {
  success: (payload: any): DefaultRedux => ({ type: UPDATE_CREDIT_AMOUNT.SUCCESS, payload }),
  error: (payload: any): DefaultRedux => ({ type: UPDATE_CREDIT_AMOUNT.FAILURE, payload }),
  request: (payload: any): DefaultRedux => ({ type: UPDATE_CREDIT_AMOUNT.REQUEST, payload }),
  start: (payload: any): DefaultRedux => ({ type: UPDATE_CREDIT_AMOUNT.START, payload }),
}
