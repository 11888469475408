// @flow
import React from 'react'
import { StyledCheckbox, StyledError } from '../checkbox/checkboxStyles'

import type { CheckboxGroupPropsType } from './CheckboxGroupTypes'

class CheckboxGroup extends React.Component<CheckboxGroupPropsType> {
  render() {
    let errorBlock = null
    const {
      options,
      input,
      meta: { touched, error, dirty },
    } = this.props

    if ((dirty || touched) && error) {
      errorBlock = (
        <div>
          <StyledError className="error-message">{error}</StyledError>
        </div>
      )
    }

    return (
      <div>
        {options.map(option => {
          return (
            <StyledCheckbox key={option.value}>
              <label className="checkbox-group checkbox-container">
                {option.name}
                <input
                  onChange={event => {
                    const newValue = [...input.value]
                    if (event.target.checked) {
                      newValue.push(option.value)
                    } else {
                      newValue.splice(newValue.indexOf(option.value), 1)
                    }
                    return input.onChange(newValue)
                  }}
                  checked={input.value.indexOf(option.value) !== -1}
                  type="checkbox"
                />
                <span className="checkmark" />
              </label>
            </StyledCheckbox>
          )
        })}
        {errorBlock}
      </div>
    )
  }
}

export default CheckboxGroup
