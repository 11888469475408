// @flow
import * as React from 'react'
import { StyledShippingInformation } from '../../orderSummaryStyles'
// types
import type { RefundInformationPropsType } from './RefundInformationType'
import REFUNDS from 'src/config/refunds'
import { formatFullDate } from 'src/helpers/dateHelpers'
import { compose } from 'redux'
import { withUser, withPayments } from 'src/decorators'
import { getPriceString } from 'src/helpers/moneyHelpers'

const RefundInformation = ({
  refunds = [],
  user: { userData },
  payments: { currencyRate },
  order = {},
}: RefundInformationPropsType) => {
  const { currency } = userData || {}
  const onlySuccessRefunds = refunds.filter(refund => REFUNDS.SUCCESS_STATUSES.includes(refund.status))

  return onlySuccessRefunds.map<React.Node>((refund, index) => {
    let refundType: string

    if (refund?.type === REFUNDS.TYPES.BRAINTREE) refundType = 'Card'
    if (refund?.type === REFUNDS.TYPES.CHECK) refundType = 'Check'
    const formatMoney = price => getPriceString({ price, currency, currencyRate: order?.currency_cad_rate || currencyRate })
    return (
      <StyledShippingInformation key={index}>
        <div className="col">
          {Boolean(refund.status) && (
            <div className="shipping-row">
              <span className="label"><strong>Payment Status</strong></span>
              <span className="value">refunded</span>
            </div>
          )}
          {Boolean(refund.type) && (
            <div className="shipping-row">
              <span className="label">Payment Type</span>
              <span className="value">{refundType}</span>
            </div>
          )}
          {Boolean(refund.amount) && (
            <div className="shipping-row">
              <span className="label">Amount</span>
              <span className="value">{formatMoney(refund.amount)}</span>
            </div>
          )}
        </div>
        <div className="col">
          {Boolean(refund.card_type) && (
            <div className="shipping-row">
              <span className="label">Card Type</span>
              <span className="value">{refund.card_type}</span>
            </div>
          )}
          {Boolean(refund.card_number) && (
            <div className="shipping-row">
              <span className="label">Credit Card</span>
              <span className="value">*{refund.card_number}</span>
            </div>
          )}
          {refund && refund.created_at && (
            <div className="shipping-row">
              <span className="label">Submitted at</span>
              <span className="value">{ formatFullDate(refund.created_at) }</span>
            </div>
          )}
        </div>
      </StyledShippingInformation>
    )
  })
}

export default compose(
  withUser,
  withPayments,
)(RefundInformation)
