// @flow
import * as React from 'react'
import { connect } from 'react-redux'

import {
  getResources,
  getResourcePage,
  getRegisterAgreement,
} from 'src/redux/actions/resources/resources'

import type { TypeOfRegisterAgreement } from 'src/common/constants.types'

const withResources = <P>(WrappedComponent: React.ComponentType<P>) => {
  class WithResourcesComponent extends React.Component<P> {
    render() {
      return <WrappedComponent {...this.props} />
    }
  }
  const mapStateToProps = ({ resources }) => ({ resources })

  const mapDispatchToProps = (dispatch, ownProps) => ({
    getResources: () => dispatch(getResources.request()),
    getResourcePage: () => dispatch(getResourcePage.request(ownProps.match.params.resourceId)),
    getRegisterAgreement: (type: TypeOfRegisterAgreement) => dispatch(getRegisterAgreement.request(type)),
  })

  return connect(
    mapStateToProps,
    mapDispatchToProps,
  )(WithResourcesComponent)
}

export default withResources
