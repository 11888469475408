// @flow

import React from 'react'

const SquareCheckIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 148.961 148.961" width="14" height="14">
      <g>
        <path
          d="M146.764,17.379c-2.93-2.93-7.679-2.929-10.606,0.001L68.852,84.697L37.847,53.691c-2.93-2.929-7.679-2.93-10.606-0.001   c-2.93,2.929-2.93,7.678-0.001,10.606l36.309,36.311c1.407,1.407,3.314,2.197,5.304,2.197c1.989,0,3.897-0.79,5.304-2.197   l72.609-72.622C149.693,25.057,149.693,20.308,146.764,17.379z"
          fill="#45BB7E"/>
        <path
          d="M130.57,65.445c-4.142,0-7.5,3.357-7.5,7.5v55.57H15V20.445h85.57c4.143,0,7.5-3.357,7.5-7.5c0-4.142-3.357-7.5-7.5-7.5   H7.5c-4.142,0-7.5,3.357-7.5,7.5v123.07c0,4.143,3.358,7.5,7.5,7.5h123.07c4.143,0,7.5-3.357,7.5-7.5v-63.07   C138.07,68.803,134.713,65.445,130.57,65.445z"
          fill="#45BB7E"/>
      </g>
    </svg>
  )
}

export default SquareCheckIcon
