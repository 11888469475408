// @flow

import { requestTypeCreator } from 'src/helpers'

// types
import type {
  BasicRedux,
} from './bulkOrderTypes'

export const GET_ORDER = requestTypeCreator('GET_ORDER')
export const GET_ORDER_TRACKING_NUMBER = requestTypeCreator('GET_ORDER_TRACKING_NUMBER')
export const UPDATE_ORDER = requestTypeCreator('UPDATE_ORDER')
export const DOWNLOAD_ORDER_PDF = requestTypeCreator('DOWNLOAD_ORDER_PDF')
export const CANCEL_PENDING_ORDER = requestTypeCreator('CANCEL_PENDING_ORDER')

export const RESET_BULK_ORDER = 'RESET_BULK_ORDER'

export const DOWNLOAD_ORDER_INVOICE_PDF = requestTypeCreator('DOWNLOAD_ORDER_INVOICE_PDF')

export const resetBulkOrder = {
  success: (payload: any) => ({ type: RESET_BULK_ORDER, payload }),
}

export const getOrder = {
  error: (payload: any): BasicRedux => ({ type: GET_ORDER.FAILURE, payload }),
  request: (payload: any): BasicRedux => ({ type: GET_ORDER.REQUEST, payload }),
  success: (payload: any): BasicRedux => ({ type: GET_ORDER.SUCCESS, payload }),
}

export const getOrderTrackingNumber = {
  error: (payload: any): BasicRedux => ({ type: GET_ORDER_TRACKING_NUMBER.FAILURE, payload }),
  request: (payload: any): BasicRedux => ({ type: GET_ORDER_TRACKING_NUMBER.REQUEST, payload }),
  success: (payload: any): BasicRedux => ({ type: GET_ORDER_TRACKING_NUMBER.SUCCESS, payload }),
}

export const updateOrder = {
  success: (payload: any): BasicRedux => ({ type: UPDATE_ORDER.SUCCESS, payload }),
}

export const downloadOrderPDF = {
  request: (payload: any): BasicRedux => ({ type: DOWNLOAD_ORDER_PDF.REQUEST, payload }),
  success: (payload: any): BasicRedux => ({ type: DOWNLOAD_ORDER_PDF.SUCCESS, payload }),
  error: (payload: any): BasicRedux => ({ type: DOWNLOAD_ORDER_PDF.FAILURE, payload }),
}

export const cancelPendingOrder = {
  error: (payload: any): BasicRedux => ({ type: CANCEL_PENDING_ORDER.FAILURE, payload }),
  request: (payload: any): BasicRedux => ({ type: CANCEL_PENDING_ORDER.REQUEST, payload }),
  success: (payload: any): BasicRedux => ({ type: CANCEL_PENDING_ORDER.SUCCESS, payload }),
}

export const downloadOrderInvoicePDF = {
  request: (payload: any): BasicRedux => ({ type: DOWNLOAD_ORDER_INVOICE_PDF.REQUEST, payload }),
  success: (payload: any): BasicRedux => ({ type: DOWNLOAD_ORDER_INVOICE_PDF.SUCCESS, payload }),
  error: (payload: any): BasicRedux => ({ type: DOWNLOAD_ORDER_INVOICE_PDF.FAILURE, payload }),
}
