import styled from 'styled-components'

export const StyledLoader = styled.div`
    align-items: center;
    background-color: rgba(255, 255, 255, 0.5);
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 999;
    
    .loader {
        animation: load8 1.1s infinite linear;
        border-bottom: 0.8em solid rgba(212, 53, 64, 0.2);
        border-left: 1em solid ${props => props.theme.main.colors.secondaryColor};
        border-right: 0.8em solid rgba(212, 53, 64, 0.2);
        border-top: 0.8em solid rgba(212, 53, 64, 0.2);
        border-radius: 50%;
        font-size: 10px;
        height: 7em;
        position: relative;
        text-indent: -9999em;
        transform: translateZ(0);
        width: 7em;
        z-index: 9999;
        
        :after {
            border-radius: 50%;
            height: 10em;
            width: 10em;
        }
    }

    .message {
        margin-top: 90px;
    }

    @keyframes load8 {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`
