// @flow
import React, { lazy } from 'react'
import { PublicRoute, PrivateRoute } from 'src/common/components'

const Register = lazy(() => import('src/modules/register/registerForm'))
const RegisterLeagueOwner = lazy(() => import('src/modules/register/registerLeagueOwnerForm'))
const EmailConfirmation = lazy(() => import('src/modules/register/emailConfirmation'))
const PasswordActivation = lazy(() => import('src/modules/register/passwordActivation'))
const ForgotPassword = lazy(() => import('src/modules/register/forgotPassword'))
const ResetPassword = lazy(() => import('src/modules/register/resetPassword'))
const ThankYouPage = lazy(() => import('src/modules/register/thankYouPage'))
const EditPassword = lazy(() => import('src/modules/register/editPassword'))
const ThankYouEditPasswordPage = lazy(() => import('src/modules/register/thankYouEditPasswordPage'))

export default [
  <PublicRoute exact={true} path="/register" component={Register} key="register-form"/>,
  <PublicRoute exact={true} path="/register-lo" component={RegisterLeagueOwner} key="register-lo-form"/>,
  <PublicRoute exact={true} path="/register/email-confirmation" component={EmailConfirmation} key="email-confirmation"/>,
  <PublicRoute exact={true} path="/register/password-activation/:token?" no_redirects component={PasswordActivation} key="password-activation"/>,
  <PublicRoute exact={true} path="/forgot-password" component={ForgotPassword} key="forgot-password"/>,
  <PublicRoute exact={true} path="/reset-password/:token" no_redirects component={ResetPassword} key="reset-password"/>,
  <PublicRoute exact={true} path="/register/thank-you" component={ThankYouPage} key="thank-you" />,
  <PrivateRoute exact={true} path="/edit-password/:token" no_redirects component={EditPassword} key="reset-password"/>,
  <PrivateRoute exact={true} path="/password/thank-you" component={ThankYouEditPasswordPage} key="password-thank-you"/>,
]
