// @flow
export default {
  all: {
    mobile: {},
  },
  main: {
    colors: {
      accentColor: '#fac83d',
      backgroundLight: '#ebedef',
      baseFontColor: '#424242',
      blockHeaderBackground: '#e0e2e4',
      borderColor: '#ebedef',
      buttonPrimaryColor: '#fac83d',
      disabledInputBgColor: '#fafafa',
      errorColor: '#f44336',
      warningColor: '#f3af5f',
      inputBackgroundColor: '#fff',
      inputBorderColor: '#ccc',
      inputColor: '#000',
      inverseColor: '#fff',
      primaryColor: '#1f2e51',
      secondaryColor: '#d43540',
      greenColor: '#008000',
      adminMessageBackground: '#f7e184',
      linkColor: '#3751a2',
      greenCheckboxColor: '#219653',
    },
    fonts: {
      baseFontFamily: 'Lato, Arial, sans-serif',
      secondaryFontFamily: 'Bebas Neue',
      headerFontFamily: 'Futura PT',
    },
    secondary: {
      blockBoxShadow: '3px 6px 40px 0 rgba(14, 28, 69, 0.1)',
    },
  },
}
