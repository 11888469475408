import React from 'react'
import ReactDOM from 'react-dom'
import './css/index.css'
import App from './App'
import { store } from './redux/store'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import { updateServiceworker } from 'src/redux/actions/serviceWorkers'
const onUpdate = registration => {
  store.dispatch(updateServiceworker())
  if (registration.waiting) {
    registration.waiting.postMessage({ type: 'SKIP_WAITING' })
  }
}

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
const { REACT_APP_ENABLE_SERVICE_WORKERS = '' } = process.env

if (REACT_APP_ENABLE_SERVICE_WORKERS === 'true') {
  serviceWorkerRegistration.register({ onUpdate })
} else {
  serviceWorkerRegistration.unregister()
}

