import styled from 'styled-components'

export const StyledBreadcrumbs = styled.ul`
    align-items: center;
    display: flex;
    font-size: 14px;
    list-style: none;
    padding-left: 0;
    margin-bottom: 10px;
    
    a {
        color: ${props => props.theme.main.colors.baseFontColor};
        font-weight: 300;
    }
    
    li {
        align-items: center;
        display: flex;
        padding: 0 10px;
        position: relative;
        
        :first-child {
            padding-left: 0;
            
            :before {
                display: none;
            }
        }
        
        :before {
            display: inline-flex;
            content: '/';
            left: -2px;
            position: absolute;
        }
    }
`
