// @flow

import type { GetOrdersActionTypes } from 'src/redux/actions/orders/ordersTypes'
import { GET_ORDERS } from 'src/redux/actions/orders/orders'

export type OrderItem = {
    id: number,
    season_id: number,
    date_created: string,
    date_paid: string,
    total_amount: string,
    total: string,
    order_mode: ?string,
    date_created: string,
    date_paid: ?string,
    shipping_status: ?string,
    discount_amount: string,
    payment_status: string
};

export type OrdersData = OrderItem[];

export type OrdersState = {
  isLoading: boolean,
  ordersData?: OrdersData,
  ordersErrors?: ?{ [key: string]: any } | ?string
};

const initialState = {
  isLoading: false,
  ordersData: [],
  ordersErrors: null,
}

const orders = (state: OrdersState = initialState, action: GetOrdersActionTypes): OrdersState => {
  switch (action.type) {
    case GET_ORDERS.REQUEST:
    {
      return {
        ...state,
        isLoading: true,
      }
    }

    case GET_ORDERS.SUCCESS:
    {
      return {
        ...state,
        isLoading: false,
        ordersData: [...action.payload],
      }
    }

    case GET_ORDERS.FAILURE:
    {
      return {
        ...state,
        isLoading: false,
        ordersErrors: action.payload,
      }
    }

    default: {
      return state
    }
  }
}

export default orders
