// @flow

import { call, fork, put, take, select } from 'redux-saga/effects'
import { GET_REGISTER_AGREEMENT, getRegisterAgreement } from 'src/redux/actions/resources/resources'
import http from 'src/services/http'

// types
import type { IOEffect } from 'redux-saga/effects'
import type { TypeOfRegisterAgreement } from 'src/common/constants.types'

function * watchGetRegisterAgreement(): Generator<IOEffect, void, any> {
  while (true) {
    const action = yield take(GET_REGISTER_AGREEMENT.REQUEST)
    const agreementName: TypeOfRegisterAgreement = action.payload

    const { user: { userData } } = yield select()

    if (userData?.connectedByAdmin) {
      yield put(getRegisterAgreement.success())
      continue
    }

    try {
      const { data } = yield call(http, `public-site/agreements/${agreementName}`, {
        method: 'GET',
      })

      yield put(getRegisterAgreement.success(data))
    } catch (e) {
      yield put(getRegisterAgreement.error(e.message))
    }
  }
}

export default [fork(watchGetRegisterAgreement)]
