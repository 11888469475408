// @flow

import moment from 'moment-timezone'
import LENGTH_RULES from 'src/config/lengthRules'
import { gradeMapper } from 'src/config/gradeValues'
import { COUNTRY_CODES, ALLOWED_FILE_TYPES } from 'src/common/constants'
const { REACT_APP_MAX_FILE_SIZE = 32 } = process.env

export const required = (value: any) => {
  if (Array.isArray(value)) {
    return value.length ? undefined : 'Required'
  }
  return value ? undefined : 'Required'
}

export const email = (value: string): boolean | string =>
  value &&
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) &&
    'Please use a valid email address.'

export const minLength = (min: number) => (value: string): boolean | string =>
  value && value.length < min && `Must be at least ${min} characters long.`

export const maxLength = (max: number) => (value: any) =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined

export const number = (value: any) => value && isNaN(Number(value)) ? 'Must be a number' : undefined

export const fieldMatch = (value: string, allValues: any) =>
  allValues.email && value !== allValues.email
    ? 'Emails don\'t match'
    : allValues.password !== allValues.confirm_password
      ? 'Passwords don\'t match'
      : undefined

export const password = (value: string): boolean | ?string =>
  value && !/^(?=.*\d)/.test(value)
    ? 'Your password should contain at least one digit'
    : !/^(?=.*[a-z])/.test(value)
      ? 'Your password should contain at least one lower case'
      : !/^(?=.*[A-Z])/.test(value)
        ? 'Your password should contain at least one upper case'
        : !/(?=^.{8,}$)/.test(value) ? 'Your password should contain at least 8 from the mentioned characters' : undefined

export const siteStartUrl = (value: string): boolean | ?string =>
  (value && !(/^http:\/\/|^https:\/\//.test(value)))
    ? 'Valid Website URL should start with http:// or https://'
    : undefined

export const siteUrl = (value: string): boolean | ?string => {
  const urlPattern = new RegExp('^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', 'i')

  return value && !urlPattern.test(String(value).toLowerCase())
    ? 'Please use a valid website'
    : undefined
}

type validateDateDiffOptsType = {
    msg: string,
    nonEqual?: boolean
};
export const validateDateDiff = (firstParam: string, secondParam: string, { msg, nonEqual }: validateDateDiffOptsType) => (value: string, allValues: any) => {
  if (!allValues[firstParam] || !allValues[secondParam]) return undefined
  if (nonEqual && moment(allValues[firstParam]).format() === moment(allValues[secondParam]).format()) return msg

  return moment(allValues[firstParam]) > moment(allValues[secondParam])
    ? msg
    : undefined
}

export const minGrade = (value: string, allValues: any) => {
  if (!allValues.max) return undefined
  return +value > +allValues.max
    ? `Must not be greater than ${gradeMapper[allValues.max] ? gradeMapper[allValues.max] : allValues.max}`
    : undefined
}

export const maxGrade = (value: string, allValues: any) =>
  +value < +allValues.min
    ? `Must not be less than ${gradeMapper[allValues.min] ? gradeMapper[allValues.min] : allValues.min}`
    : undefined

export const maxValue = (max: number) => (value: number) =>
  value && value > max ? `Must not be greater than ${max}` : undefined

export const validateLength = (key: string = '') => (value: string) => {
  if (!value) return undefined
  if (!LENGTH_RULES[key]) return `Internal error, ${key} is not exist`

  const rule = LENGTH_RULES[key]

  value = value.trim()

  return value.length < rule.min
    ? `${rule.name} should not be less than ${rule.min} symbols`
    : value.length > rule.max
      ? `${rule.name} should not exceed ${rule.max} symbols`
      : undefined
}

export const minSize = (value: any) => value && value.find(item => +item.quantity > 0)
  ? undefined
  : 'Please add items for all chosen teams or remove extra teams from the order'

export const maxSize = (value: any) =>
  value && value.find(item => +item.quantity && +item.max_quantity && (+item.quantity > +item.max_quantity))
    ? 'Incorrect number of jerseys for some teams'
    : undefined

export const maxOrderValue = (max: number) => (value: number) =>
  value && +value > +max ? `Cannot exceed ${max}` : undefined

export const validateFootballsMax = (max: number) => (value: number, allValues: any) =>
  (+allValues.footballs_blue + +allValues.footballs_brown) > +max ? `You can order no more than ${max} free footballs.` : undefined

export const validateBelts = (max: number) => (value: number, allValues: any) => {
  return (
    (allValues && +allValues.belts) > +max ? `You can order no more than ${max} additional flag belts.` : undefined
  )
}

export const validatePaidBelts = (max: number) => (value: number, allValues: any) => {
  return Object.values(allValues).reduce((acc, cur) => acc + +cur, 0) > +max ? `You can order no more than ${max} additional flag belts.` : undefined
}

export const validateFootballsMin = (min: number) => (value: number, allValues: any) =>
  ((+allValues.footballs_blue || 0) + (+allValues.footballs_brown || 0)) < +min ? `Your order includes ${min} footballs for free. Please choose the number of each kind you would like to get.` : undefined

export const validateAdditionalFootballsMax = (max: number, used: number, color: string) => (value: number) => {
  const current = max < 500 ? max - used : max
  return (+value) > current
    ? `You can order no more than ${current > 0 ? current : 0} ${String(color).toLowerCase()} footballs.`
    : undefined
}

export const validateDescriptor = (value: string): boolean | ?string =>
  value && !(/^[A-Za-z0-9.+\-\s]*$/).test(value)
    ? 'Statement descriptor can contain letters (A-Z, a-z), digits (0-9), space, \'+\', \'-\', and \'.\''
    : value.length > 13
      ? 'Statement descriptor must be 1 to 13 characters in length'
      : undefined

export const insuranceDates = (value: string, allValues: any) => moment(allValues.start_date) >= moment(allValues.end_date)
  ? 'The insurance can\'t start after it expires'
  : undefined

export const validateZipUS = (value: string): string =>
  value && !/((^)([0-9]{5}|[0-9]{5}[-]{1}[0-9]{4}))+$/.test(value) ? 'Wrong Zip Code. Correct US Zip code format: XXXXX or XXXXX-XXXX' : ''

export const validateZipCA = (value: string): string =>
  value && !/^([a-zA-Z]\d[a-zA-Z]) ?(\d[a-zA-Z]\d)$/.test(value)
    ? 'Wrong Zip Code. Correct CA Zip code format: A1A 1A1'
    : ''

export const zipCodeFn = (country: string) => (value: string): string => {
  switch (country) {
    case COUNTRY_CODES.CA:
      return validateZipCA(value)
    default:
      return validateZipUS(value)
  }
}

export const validateTotalSize = (value: []): ?string => {
  const totalFileSize = value.reduce((acc, cur) => {
    return acc + cur.size
  }, 0)

  const availableSize = +REACT_APP_MAX_FILE_SIZE - 3

  return (totalFileSize) > (availableSize * 1024 * 1024)
    ? `File size exceeds the maximum limit. Maximum size for all files: ${availableSize} MB`
    : undefined
}

export const validateFileType = (value: ?File) => {
  return ALLOWED_FILE_TYPES.includes(value?.type)
    ? undefined
    : 'Invalid file format. Only files with following extensions are allowed: PDF, JPEG, TIFF, or PNG'
}
