// @flow

import React from 'react'
import { StyledFormInput, StyledError, StyledFormTextarea } from './formInputStyles'
import type { FormInputPropsType } from './FormInputTypes'

const FormInput = ({
  input,
  meta: { touched, error },
  placeholder,
  validationErrors,
  id, // form field id equals to validationErrors.errors[key]
  type,
  hideInput,
  asyncErrorCheck,
  ...rest
}: FormInputPropsType) => {
  let errorBlock = null

  if ((touched || asyncErrorCheck) && (error || (validationErrors && validationErrors.errors[id]))) {
    errorBlock = (
      <div>
        <StyledError className="error-message">{error || validationErrors.message}</StyledError>
      </div>
    )
  }

  return (
    <>
      {!hideInput && (
        <>
          {type === 'textarea'
            ? (
              <StyledFormTextarea {...rest} {...input} value={typeof (input.value) === 'string' ? input.value.trimLeft() : input.value} placeholder={placeholder} />
            )
            : (
              <StyledFormInput {...rest} {...input} type={type} value={typeof (input.value) === 'string' ? input.value.trimLeft() : input.value} placeholder={placeholder} />
            )}
          {errorBlock}
        </>
      )}

    </>
  )
}

export default FormInput
