// @flow

import React from 'react'
import { SearchIcon } from 'src/assets/icons'

// styles
import { StyledSearch } from './quickSearchListStyles'

// types
import type { QuickSearchListPropsType } from './QuickSearchListTypes'

const QuickSearchList = ({ onSearchChange, onSubmitSearch }: QuickSearchListPropsType) => {
  return (
    <StyledSearch>
      <div className="form-body">
        <div className="form-row">
          <input type="text" placeholder="Quick Search" onChange={onSearchChange}/>
          <button type="submit" className="btn-search" onClick={onSubmitSearch}>
            <SearchIcon />
          </button>
        </div>
      </div>
    </StyledSearch>
  )
}

export default QuickSearchList
