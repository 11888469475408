// @flow

import React from 'react'
// types
import type { ErrorBlockPropsType } from './ErrorBlockTypes'

// styles
import { StyledErrorBlock } from './errorBlockStyles'

const ErrorBlock = ({ error }: ErrorBlockPropsType) => {
  return (
    <>
      {error && <StyledErrorBlock>{error}</StyledErrorBlock>}
    </>
  )
}

export default ErrorBlock
