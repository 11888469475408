// @flow
import React from 'react'
import { StyledFormWrapper } from './formWrapperStyles'

export type FormWrapperPropsType = {
    children: React$Element<any>
};

const FormWrapper = ({ children }: FormWrapperPropsType) => <StyledFormWrapper>{children}</StyledFormWrapper>

export default FormWrapper
