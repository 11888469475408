// @flow
import type { UserActionTypes } from 'src/redux/actions/user/userTypes'
import { CURRENCIES } from 'src/common/constants'
import {
  SET_USER,
  GET_USER,
  REGISTER_USER,
  SEND_ACTIVATION_EMAIL,
  RESET_USER,
  EDIT_USER,
  SET_USER_PASSWORD,
  SUBMIT_USER_AGREEMENT,
  EDIT_USER_PASSWORD,
  EDIT_USER_EMAIL,
  EDIT_USER_GEOLOCATION_ZIP,
} from 'src/redux/actions/user/user'

export type UserData = {
    id: number,
    connectedByAdmin: ?boolean,
    first_name: ?string,
    last_name: ?string,
    email: string,
    zip: string,
    country: string,
    state: string,
    deleted_at: ?string,
    agreement: boolean,
    is_activated: boolean,
    geoLocationZip: ?string,
    roles: string[],
    currency: string
}; // TODO add type

export type UserState = {
    isLoading: boolean,
    userData?: ?UserData,
    userErrors?: ?{ [key: string]: any } | ?string,
    emailUnconfirmed?: string,
    loginErrors: ?{ [key: string]: any } | ?string,
    passwordErrors: ?{ [key: string]: any } | ?string,
    editUserEmailSuccess: boolean,
    currency: string
};

const initialState = {
  isLoading: false,
  userData: null,
  userErrors: null,
  emailUnconfirmed: undefined,
  loginErrors: null,
  passwordErrors: null,
  editUserEmailSuccess: false,
  currency: CURRENCIES.USD,
}

const user = (state: UserState = initialState, action: UserActionTypes): UserState => {
  switch (action.type) {
    case SET_USER.REQUEST:
    case GET_USER.REQUEST:
    case SET_USER_PASSWORD.REQUEST:
    case EDIT_USER_PASSWORD:
    case REGISTER_USER.REQUEST:
    case SEND_ACTIVATION_EMAIL.REQUEST:
    case EDIT_USER.REQUEST:
    case SUBMIT_USER_AGREEMENT.REQUEST:
    case EDIT_USER_EMAIL.REQUEST:
    {
      return {
        ...state,
        isLoading: true,
        userErrors: null,
        loginErrors: null,
        editUserEmailSuccess: false,
      }
    }
    case SET_USER.SUCCESS:
    case SET_USER_PASSWORD.SUCCESS:
    case EDIT_USER_PASSWORD.SUCCESS:
    case EDIT_USER.SUCCESS:
    case EDIT_USER_GEOLOCATION_ZIP:
    {
      const userData = action.payload
      if (window.TrackJS) {
        userData.id && window.TrackJS.addMetadata('user_id', userData.id)
        userData.email && window.TrackJS.addMetadata('user_email', userData.email)
      }
      return {
        ...state,
        isLoading: false,
        userData: { ...state.userData, ...userData },
        loginErrors: null,
        userErrors: null,
        passwordErrors: null,
      }
    }
    case REGISTER_USER.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        userErrors: null,
        loginErrors: null,
        emailUnconfirmed: action.payload,
      }
    }
    case SUBMIT_USER_AGREEMENT.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        userErrors: null,
        userData: { ...action.payload },
        loginErrors: null,
      }
    }

    case EDIT_USER_EMAIL.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        editUserEmailSuccess: action.payload,
      }
    }

    case REGISTER_USER.FAILURE:
    case EDIT_USER.FAILURE:
    {
      return { ...state, isLoading: false, userErrors: action.payload }
    }

    case SET_USER.FAILURE:
    case EDIT_USER_PASSWORD.FAILURE:
    case EDIT_USER_EMAIL.FAILURE:
    case SET_USER_PASSWORD.FAILURE: {
      return { ...state, isLoading: false, loginErrors: action.payload }
    }
    case SUBMIT_USER_AGREEMENT.FAILURE: {
      return { ...state, isLoading: false, userErrors: action.payload }
    }
    case GET_USER.SUCCESS:
    case SEND_ACTIVATION_EMAIL.SUCCESS: {
      return { ...state, isLoading: false }
    }
    case SEND_ACTIVATION_EMAIL.FAILURE: {
      return { ...state, isLoading: false }
    }
    case RESET_USER: {
      if (window.TrackJS) {
        window.TrackJS.addMetadata('user_id', null)
        window.TrackJS.addMetadata('user_email', null)
      }
      return { ...initialState }
    }

    case 'persist/REHYDRATE': {
      if (action.payload && action.payload.userData && window.TrackJS) {
        const { userData } = action.payload
        userData.id && window.TrackJS.addMetadata('user_id', userData.id)
        userData.email && window.TrackJS.addMetadata('user_email', userData.email)
      }
      return state
    }

    default: {
      return state
    }
  }
}

export default user
