// @flow

import { compose } from 'redux'
import ImportSizesForm from './ImportSizesForm'
import { reduxForm } from 'redux-form'
import config from 'src/config'
import { withNFLTeams, withMakeOrder } from 'src/decorators'

const Form = reduxForm({
  destroyOnUnmount: false,
  enableReinitialize: true,
  form: config.importSizesForm.id,
})(ImportSizesForm)

export default compose(
  withNFLTeams,
  withMakeOrder,
)(Form)
