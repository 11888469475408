import styled from 'styled-components'

export const StyledError = styled.span`
    font-size: 13px;
    color: ${props => props.theme.main.colors.errorColor};
`

export const StyledSelect = styled.select`
    appearance: none;
    background: ${props => props.theme.main.colors.inputBackgroundColor};
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0'%3F%3E%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' id='Capa_1' x='0px' y='0px' viewBox='0 0 59.414 59.414' style='enable-background:new 0 0 59.414 59.414;' xml:space='preserve' width='512px' height='512px' class=''%3E%3Cg%3E%3Cg%3E%3Cpolygon points='29.707,45.268 0,15.561 1.414,14.146 29.707,42.439 58,14.146 59.414,15.561 ' data-original='%2348A0DC' class='active-path' data-old_color='%2348A0DC' fill='%23424242'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
    background-position: calc(100% - 10px) center;
    background-repeat: no-repeat;
    background-size: 11px;
    border: 1px solid ${props => props.theme.main.colors.inputBorderColor};
    border-radius: 0;
    color: ${props => props.theme.main.colors.inputColor};
    margin: 6px 0 4px;
    font-size: 14px;
    padding: 12px;
    
    :disabled {
        background-image: none;
        border: 0;
        font-weight: 500;
        padding: 12px 0;
    }
    
    :-moz-focusring {
        color:transparent;
        text-shadow:0 0 0 ${props => props.theme.main.colors.inputColor};
        
        * {
            color: ${props => props.theme.main.colors.inputColor}
            text-shadow:none;
        }
    }
`
