// @flow

// import { stopSubmit } from "redux-form"
import { call, fork, put, take } from 'redux-saga/effects'
import { stopSubmit, stopAsyncValidation } from 'redux-form'
import { GET_PUBLIC_LEAGUES, getPublicLeagues } from 'src/redux/actions/publicLeagues/publicLeagues'
import http from 'src/services/http'
import config from 'src/config'
// types
import type { IOEffect } from 'redux-saga/effects'

function * watchRegister(): Generator<IOEffect, void, any> {
  while (true) {
    const {
      payload: { ...values },

    } = yield take(GET_PUBLIC_LEAGUES.REQUEST)
    const formId = config.leaguesFinderForm.id
    try {
      delete values.country

      const { data } = yield call(http, 'api/league/search', {
        body: JSON.stringify({
          ...values,
        }),
        method: 'POST',
      }, { disableToastr: true })

      yield put(getPublicLeagues.success(data))
    } catch (e) {
      yield put(getPublicLeagues.error(e.message))
      yield put(stopSubmit(formId, e.errors ? e.errors : {}))
      yield put(stopAsyncValidation(formId, e.errors ? e.errors : {}))
    }
  }
}

export default [fork(watchRegister)]
