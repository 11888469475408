import React from 'react'
import { DeleteIcon } from 'src/assets/icons'
import { InsertDriveFileOutlined } from '@material-ui/icons'
import ErrorBlock from '../errorBlock'

import { StyledPrimaryButton, StyledPrimaryButtonContainer, StyledPrimaryButtonText } from '../formFileInput/formInputStyles'
import { StyledFile, StyledFileWrapper } from './fileMultiUploadStyles'

const FileMultiUpload = props => {
  const { meta: { touched, error }, buttonText = 'Choose Files to Upload', size, input: { name }, ...rest } = props

  let errorBlock = null

  if (touched && error) {
    errorBlock = (
      <div>
        <ErrorBlock error={error} />
      </div>
    )
  }

  const handleChange = e => {
    const { input: { onChange } } = props
    const temp = [...Array.from(props?.input?.value), ...e.target.files]
    const uniqueArray = temp.filter((v, i, a) => a.findIndex(t => ((t.size === v.size) && (t.name === v.name))) === i)
    onChange(uniqueArray)
    // hack for avoiding default browser behaviour after delete file and trying to upload the same file
    e.target.value = ''
  }

  const handleDelete = file => {
    const newVal = Array.from(props?.input?.value).filter(item => ((item.size !== file.size) || (item.name !== file.name)))
    props.change(name, newVal)
  }

  return (
    <>
      <StyledPrimaryButtonContainer {...rest}>
        <StyledPrimaryButtonText>{buttonText}</StyledPrimaryButtonText>
        <StyledPrimaryButton
          buttonText={buttonText}
          size={size}
          type="file"
          onChange={handleChange}
          multiple
        />

      </StyledPrimaryButtonContainer>
      <StyledFileWrapper>
        {Array.from(props?.input?.value)?.map(file => {
          const { size, name } = file
          return (
            <div key={`${name}-${size}`}>
              <StyledFile className="d-flex">
                <span className="file-icon"><InsertDriveFileOutlined /></span>
                {name}
                <span className="delete-icon" onClick={() => handleDelete(file)}><DeleteIcon /></span>
              </StyledFile>
            </div>
          )
        })}
      </StyledFileWrapper>
      <StyledFileWrapper>
        {errorBlock}
      </StyledFileWrapper>
    </>
  )
}

export default FileMultiUpload
