// @flow
import {
  CHECK_FILE_INSURANCE,
  GET_MY_INSURANCES,
} from 'src/redux/actions/myInsurances/myInsurances'
import { INSURANCE_PAYMENT_STATUS } from 'src/common/constants'
import type { GetMyInsurancesActionTypes } from 'src/redux/actions/myInsurances/myInsurancesTypes'
import type { FileType } from 'src/common/components/cardInsurance/CardInsuranceTypes'

export type MyInsurancesDataItem = {
  id: number,
  title: string,
  league_country: string,
  type_insurance: string,
  status: string,
  expiration_status: string,
  start_date: string,
  end_date: string,
  approve_date: string,
  policy_number: string,
  bound_at: string,
  payment_status: INSURANCE_PAYMENT_STATUS,
  file_name: string,
  file: string,
  vendor: string,
  order_field: number,
  status_reason: string,
  files: FileType[],
  meta?: {
    coverage: string
  },
  players?: {
    minAge: number,
    maxAge: number,
    numberOfPlayers: number
  }[]
};

export type MyInsurancesData = MyInsurancesDataItem[];

export type MyInsurancesState = {
  isLoading: boolean,
  fileOpen: string,
  myInsurancesData?: MyInsurancesData,
  myInsurancesErrors?: ?{ [key: string]: any } | ?string
};

const initialState = {
  isLoading: false,
  fileOpen: '',
  myInsurancesData: [],
  myInsurancesErrors: null,
}

const myInsurances = (state: MyInsurancesState = initialState, action: GetMyInsurancesActionTypes): MyInsurancesState => {
  switch (action.type) {
    case GET_MY_INSURANCES.REQUEST: {
      return {
        ...state,
        isLoading: true,
        myInsurancesErrors: null,
      }
    }
    case CHECK_FILE_INSURANCE.REQUEST: {
      return {
        ...state,
        isLoading: true,
        fileOpen: '',
        myInsurancesErrors: null,
      }
    }
    case GET_MY_INSURANCES.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        myInsurancesData: [...action.payload],
      }
    }
    case CHECK_FILE_INSURANCE.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        fileOpen: action.payload,
      }
    }
    case CHECK_FILE_INSURANCE.FAILURE: {
      return {
        ...state,
        isLoading: false,
        myInsurancesErrors: action.payload,
      }
    }
    case GET_MY_INSURANCES.FAILURE: {
      return {
        ...state,
        isLoading: false,
        myInsurancesErrors: action.payload,
      }
    }
    default: {
      return state
    }
  }
}

export default myInsurances
