// @flow

import { fork, put, take, call, select } from 'redux-saga/effects'
import {
  SUBMIT_ACH_MICRO_TRANSFER,
  submitACHMicroTransfer,
} from 'src/redux/actions/payments/payments'
import { stopSubmit } from 'redux-form'
import config from 'src/config'
import http from 'src/services/http'
import { history } from 'src/services/history'
// types
import type { IOEffect } from 'redux-saga/effects'

function * watchSubmitACHMicroTransfer(): Generator<IOEffect, void, any> {
  while (true) {
    const {
      payload,
    } = yield take(SUBMIT_ACH_MICRO_TRANSFER.REQUEST)
    const formId = config.microTransferForm.id
    try {
      const { payments: { verificationId } } = yield select()
      const data = { values: payload, verificationId }
      yield http('api/payment/ach/verify', {
        body: JSON.stringify(data),
        method: 'POST',
      })

      yield put(submitACHMicroTransfer.success())
      yield call(history.push, '/payment-settings')
      yield
    } catch (e) {
      yield put(submitACHMicroTransfer.error(e.message))
      yield put(stopSubmit(formId, e.errors ? e.errors : {}))
    }
  }
}

export default [fork(watchSubmitACHMicroTransfer)]
