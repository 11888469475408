// @flow

import { call, fork, put, take } from 'redux-saga/effects'
import {
  CHECK_FILE_INSURANCE,
  checkFileInsurance,
  GET_MY_INSURANCES,
  getMyInsurances,
} from 'src/redux/actions/myInsurances/myInsurances'
import http from 'src/services/http'
// types
import type { IOEffect } from 'redux-saga/effects'
import { showToast } from 'src/redux/actions/ui/ui'

function * watchGetMyInsurances(): Generator<IOEffect, void, any> {
  while (true) {
    yield take(GET_MY_INSURANCES.REQUEST)

    try {
      const { data } = yield call(http, 'api/insurance', {
        method: 'GET',
      })

      yield put(getMyInsurances.success(data))
    } catch (e) {
      yield put(getMyInsurances.error(e.message))
    }
  }
}

function * watchCheckFileInsurance(): Generator<IOEffect, void, any> {
  while (true) {
    const data = yield take(CHECK_FILE_INSURANCE.REQUEST)
    const { payload: { insuranceId, fileId, name } } = data
    try {
      const { data } = yield call(http, `api/insurance/${insuranceId}/file/${fileId}`, {
        method: 'GET',
      })

      const storeData = {
        ...data,
        name,
      }

      yield put(checkFileInsurance.success(storeData))
    } catch (e) {
      yield put(checkFileInsurance.error(e.message))
      yield put(showToast.success({ title: e.message, messageType: 'error' }))
    }
  }
}

export default [
  fork(watchGetMyInsurances),
  fork(watchCheckFileInsurance),
]
