export const STEPS = {
  COVERAGE_DETAILS: 'coverage-details',
  PLAYER_COUNT: 'player-count',
  COST: 'cost',
  ADDITIONAL_INSURED: 'additional-insured',
  PAYMENT: 'payment',
}

export const AGE_CATEGORIES = [
  { index: 1, name: '12 & under' },
  { index: 2, name: '13-15' },
  { index: 3, name: '16-18' },
]

export const STEP_VALUE = {
  [STEPS.COVERAGE_DETAILS]: 1,
  [STEPS.PLAYER_COUNT]: 2,
  [STEPS.COST]: 3,
  [STEPS.ADDITIONAL_INSURED]: 4,
  [STEPS.PAYMENT]: 5,
}

const STEP_PATH = '/my-insurances/purchase/step'

export function linkGenerator(data) {
  const { step, params: { leagueId } } = data
  return `${STEP_PATH}/${step.stepName}/${leagueId || ''}`
}

export function linkGeneratorByName(stepName, leagueId) {
  return `${STEP_PATH}/${stepName}/${leagueId || ''}`
}

export function isZipCodeValid(value) {
  return /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value)
}

