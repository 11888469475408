// @flow

export const trimObjectStringValues: any = (obj: {}) => {
  Object.keys(obj).forEach(key => {
    if (typeof obj[key] === 'string') {
      obj[key] = obj[key].trim()
    }
  })
}

