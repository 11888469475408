// @flow
import styled from 'styled-components'
import { device } from 'src/common/theme/mediaQueries'
export const StyledItemInfo = styled.div`
    .mobile-label {
        display: none;
    }

    .item-info-table {
        display: table;
        width: 100%;
    }

    .table-row {
        display: table-row;
    }

    .table-cell {
        border-bottom: 1px solid ${props => props.theme.main.colors.borderColor};
        display: table-cell;
        padding: 12px;
    }

    .table-heading {
        display: table;
        font-weight: bold;
        width: 100%;
    }

    .table-body {
        display: table;
        width: 100%;
    }

    .sku {
        width:25%;
    }

    .quantity {
        text-align: right;
        width: 145px;
    }

    @media ${device.mobileL} {
        .mobile-label {
            display: inline-flex;
            opacity: 0.6;
            padding-right: 6px;
        }

        .table-heading {
            display: none;
        }

        .table-cell {
            align-items: center;
            border-bottom: 0;
            display: flex;
            justify-content: center;
            text-align: center;
            width: 100%;

            &:last-child {
                border-bottom: 1px solid ${props => props.theme.main.colors.borderColor};
            }

            &.description {
                padding-bottom: 0;
                padding-top: 0;
            }
        }
    }
`
