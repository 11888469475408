// @flow

export const getExistingPropsAndValues = (data: Object) => {
  const props = []
  const values = []
  // eslint-disable-next-line flowtype/require-parameter-type, array-callback-return
  Object.entries(data).filter(([name, value]) => {
    if (value) {
      props.push(name)
      values.push(value)
    }
  })
  return {
    props,
    values,
  }
}

export const bTreeTraveller = (depth: string[]) => {
  const ROOT = new Map<any, any>()
  const RESULT_KEY = Symbol('result')
  return {
    add(data: Object) {
      let map = ROOT
      for (const keyName of depth) {
        const keyVal = data[keyName]
        let child = map.get(keyVal)
        if (!child) {
          child = new Map<any, any>()
          map.set(keyVal, child)
        }
        map = child
      }

      const arr = map.get(RESULT_KEY) || []
      arr.push(data)
      map.set(RESULT_KEY, arr)
    },
    get(search: Object) {
      let map = ROOT
      for (const keyName of depth) {
        const keyVal = search[keyName]
        map = map.get(keyVal) || new Map<any, any>()
      }
      return map.get(RESULT_KEY) || []
    },
  }
}

export const getValuesSum = (obj: any) => Object.values(obj).reduce((acc, cur) => acc + +cur, 0)
