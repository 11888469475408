// @flow
import React, { useState, useRef } from 'react'

import { Braintree, HostedField } from 'react-braintree-fields'

import PaymentCard from 'src/assets/icons/PaymentCard'
import VisaIcon from 'src/assets/icons/VisaIcon'
import MasterCard from 'src/assets/icons/MasterCard'
import AmexIcon from 'src/assets/icons/AmexIcon'
import JCBIcon from 'src/assets/icons/JCBIcon'
import DiscoverIcon from 'src/assets/icons/DiscoverIcon'
import { ErrorBlock, ButtonPrimary } from 'src/common/components'

// types
import type { CardFormPropsType } from './CardFormTypes'

// styles
import { StyledCardForm, StyledButton } from './CardFormStyles'

const FIELDS = {
  number: {
    name: 'number',
    error: 'This card number is not valid.',
  },
  expirationDate: {
    name: 'expirationDate',
    error: 'This expiration date is not valid.',
  },
  cvv: {
    name: 'cvv',
    error: 'This security code is not valid.',
  },
  postalCode: {
    name: 'postalCode',
    error: 'This postal code is not valid.',
  },
}

const CardForm = ({
  onSubmit,
  buttonText,
  paymentToken,
  showSaveCard = true,
}: CardFormPropsType) => {
  const [tokenize, setTokenizeFunc] = useState()
  const [storeInVaultOnSuccess, setStoreInVaultOnSuccess] = useState(false)
  const [error, setError] = useState(null)
  const [fieldError, setFieldError] = useState(null)
  const numberField = useRef()
  const cvvField = useRef()

  const onAuthorizationSuccess = () => {
    numberField.current && numberField.current.focus()
  }

  const handleCheckbox = () => {
    setStoreInVaultOnSuccess(!storeInVaultOnSuccess)
  }

  const handleError = newError => {
    // $FlowFixMe
    const errorObj = newError?.details?.invalidFieldKeys?.reduce((acc, name) => {
      return {
        ...acc,
        [name]: FIELDS[name].error,
      }
    }, {})
    setFieldError({
      ...errorObj,
    })
    setError('Please check your information and try again.')
  }

  const getToken = () => {
    if (typeof tokenize === 'function') {
      tokenize()
        .then(data => {
          onSubmit({ ...data, storeInVaultOnSuccess })
        })
        .catch(handleError)
    }
  }

  const onValidityChange = (data, event) => {
    const name = event.emittedBy
    if (data.isValid) {
      setFieldError(prev => ({
        ...prev,
        [name]: null,
      }))
    } else {
      setFieldError(prev => ({
        ...prev,
        [name]: FIELDS[name].error,
      }))
    }
  }

  return (
    <>
      <StyledCardForm>
        {paymentToken && (
          <Braintree
            className="demo"
            authorization={paymentToken}
            onAuthorizationSuccess={onAuthorizationSuccess}
            onError={handleError}
            getTokenRef={ref => setTokenizeFunc(() => ref)}
            styles={{
              input: {
                'font-size': 'inherit',
              },
            }}
          >
            <div className="top-text-wrapper">
              <div className="title-wrapper"> <PaymentCard /> <span className="title">Card details</span></div>
              <div>
                <VisaIcon />
                <MasterCard />
                <AmexIcon />
                <JCBIcon />
                <DiscoverIcon />
              </div>
            </div>
            <div className="wrapper">
              <div className="top-wrapper">
                <div className="form-input">
                  <p className="text-label">Card Number:</p>
                  <HostedField
                    type="number"
                    ref={numberField}
                    onValidityChange={onValidityChange}
                    placeholder="•••• •••• •••• ••••"
                    className={fieldError?.[FIELDS.number.name] ? 'braintree-hosted-fields-invalid' : ''}
                  />
                  <div className="error-wrapper">
                    <ErrorBlock error={fieldError?.[FIELDS.number.name]} />
                  </div>
                </div>
              </div>
              <div className="bottom-container">
                <div className="form-input">
                  <p className="text-label">Expiration Date:</p>
                  <HostedField
                    type="expirationDate"
                    onValidityChange={onValidityChange}
                    placeholder="MM/YY"
                    className={fieldError?.[FIELDS.expirationDate.name] ? 'braintree-hosted-fields-invalid' : ''}
                  />
                  <div className="error-wrapper">
                    <ErrorBlock error={fieldError?.[FIELDS.expirationDate.name]} />
                  </div>
                </div>

                <div className="form-input">
                  <p className="text-label">CVV:</p>
                  <HostedField
                    type="cvv"
                    placeholder="•••"
                    ref={cvvField}
                    onValidityChange={onValidityChange}
                    className={fieldError?.[FIELDS.cvv.name] ? 'braintree-hosted-fields-invalid' : ''}
                  />
                  <div className="error-wrapper">
                    <ErrorBlock error={fieldError?.[FIELDS.cvv.name]} />
                  </div>
                </div>
                <div className="form-input">
                  <p className="text-label">Postal Code:</p>
                  <HostedField
                    className={fieldError?.[FIELDS.postalCode.name] ? 'braintree-hosted-fields-invalid' : ''}
                    type="postalCode"
                    onValidityChange={onValidityChange} />
                  <div className="error-wrapper">
                    <ErrorBlock error={fieldError?.[FIELDS.postalCode.name]} />
                  </div>
                </div>

              </div>
              {showSaveCard && (
                <div className="bottom-container">
                  <div className="checkbox-wrapper">
                    <input
                      onChange={handleCheckbox}
                      checked={storeInVaultOnSuccess}
                      className="checkbox"
                      type="checkbox"
                    />
                    <p className="text-label">Save this payment method to my profile for future payments</p>
                  </div>
                </div>
              )}
              <div className="bottom-wrapper">
                <ErrorBlock error={error} />
              </div>
            </div>

          </Braintree>)}
      </StyledCardForm>
      <StyledButton className="d-flex justify-content-center">
        <ButtonPrimary size="medium" buttonText={buttonText} action={getToken}/>
      </StyledButton>
    </>
  )
}

export default CardForm
