// @flow
import * as React from 'react'
import { connect } from 'react-redux'

import {
  getOrders,
} from 'src/redux/actions/orders/orders'

const withOrders = <P>(WrappedComponent: React.ComponentType<P>) => {
  class WithOrdersComponent extends React.Component<P> {
    render() {
      return <WrappedComponent {...this.props} />
    }
  }
  const mapStateToProps = ({ orders }) => ({ orders })

  const mapDispatchToProps = (dispatch, ownProps) => ({
    getOrders: () => dispatch(getOrders.request({ leagueId: +ownProps.match.params.leagueId })),
  })

  return connect(
    mapStateToProps,
    mapDispatchToProps,
  )(WithOrdersComponent)
}

export default withOrders
