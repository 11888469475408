// @flow
import * as actions from 'src/redux/actions/serviceWorkers'
import type { ServerWorkersActionTypes } from 'src/redux/actions/serviceWorkers/serviceWorkersTypes'

export type ServiceWorkersState = {
    serviceWorkerUpdated: boolean
};

const initialState = {
  serviceWorkerUpdated: false,
}

const serviceWorkers = (state: ServiceWorkersState = initialState, action: ServerWorkersActionTypes) => {
  switch (action.type) {
    case actions.UPDATE_SERVICEWORKER: {
      return {
        ...state,
        serviceWorkerUpdated: true,
      }
    }

    default:
      return state
  }
}

export default serviceWorkers
