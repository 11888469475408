// @flow
import React, { lazy } from 'react'
import { PrivateRoute } from 'src/common/components'
const PaymentMethods = lazy(() => import('src/modules/paymentSettings/paymentMethods'))
const ACHPayment = lazy(() => import('src/modules/paymentSettings/ACHPayment'))
const ThankYouACH = lazy(() => import('src/modules/paymentSettings/thankYouACH'))

export default [
  <PrivateRoute exact={true} path="/payment-settings" component={PaymentMethods} key="payment-settings-payment-methods" />,
  <PrivateRoute exact={true} path="/payment-settings/ach" component={ACHPayment} key="payment-settings-ach"/>,
  <PrivateRoute exact={true} path="/payment-settings/ach/:token" component={ACHPayment} key="payment-settings-ach-token"/>,
  <PrivateRoute exact={true} path="/payment-settings/thank-you" component={ThankYouACH} key="payment-settings-ach-thank-you"/>,
]
