// @flow

import React, { useEffect, useState } from 'react'
import { StyledEmptyBlock, StyledInfoBlock } from './serviceWorkerNotifierStyles'

export type ServiceWorkerNotifierType = {
    serviceWorkerUpdated: boolean
};

const ServiceWorkerNotifier = (
  { serviceWorkerUpdated }: ServiceWorkerNotifierType,
) => {
  const [udate, setUpdate] = useState(false)
  useEffect(() => {
    if (serviceWorkerUpdated) {
      setUpdate(true)
    }
  }, [serviceWorkerUpdated])
  return udate
    ? (
      <StyledInfoBlock>
        <StyledEmptyBlock className="empty-insurance">
          {/* eslint-disable  */}
                <h4 className="text-center title">There is a newer version of NFL FLAG. Please close other NFL FLAG tabs and <a
                        href="javascript:window.location.reload(true)"
                    >
                     reload
                    </a> the page to get the latest version.
                </h4>
                {/* eslint-enable */}
        </StyledEmptyBlock>
      </StyledInfoBlock>
    )
    : null
}

export default ServiceWorkerNotifier
