// @flow
import styled from 'styled-components'
import { MESSAGE_TYPES } from 'src/common/constants'

const handleMessageBg = (messageType: string) => {
  switch (messageType) {
    case 'success':
      return '#45bb7e'
    case 'error':
      return '#db7478'
    case 'warning':
      return '#f3af5f'
    case 'info':
      return '#81c8e1'
    default:
      return '#81c8e1'
  }
}

export const StyledMessage = styled.div`
    align-items: center;
    animation-delay: ${({ messageType }) => messageType === MESSAGE_TYPES.ERROR ? '4.5s' : '2.3s'};
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
    animation-name: fadeInOpacity;
    animation-timing-function: ease-in-out;
    background-color: ${({ messageType }) => handleMessageBg(messageType)};
    color: #fff;
    display: flex;
    justify-content: center;
    right: 20px;
    padding: 22px 24px;
    position: fixed;
    top: 20px;
    width: auto;
    opacity: 1;
    z-index: 999;
    
    @keyframes fadeInOpacity {
        0% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }
    
    .text-wrapper {
        display: flex;
        flex-direction: column;
    }
    
    .title {
        font-size: 21px;
        font-weight: bold;
        margin-bottom: 6px;
    }

    .icon-wrapper {
        display: flex;
        margin-bottom: 6px;
        margin-right: 12px;

        svg {
            fill: #fff;
            height: 20px;
            width: 20px;
            
            path {
                fill: #fff;
            }
        }
    }

    polygon {
        fill: #fff;
    }
`
