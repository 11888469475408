// @flow

import { connect } from 'react-redux'
import ServiceWorkerNotifier from './ServiceWorkerNotifier'

const mapStateToProps = state => {
  return {
    serviceWorkerUpdated: state.serviceWorkers.serviceWorkerUpdated,
  }
}

export default connect(mapStateToProps)(ServiceWorkerNotifier)
