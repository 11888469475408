// @flow

import { call, fork, put, take } from 'redux-saga/effects'
import { GET_RESOURCES, getResources } from 'src/redux/actions/resources/resources'
import http from 'src/services/http'
// types
import type { IOEffect } from 'redux-saga/effects'

function * watchGetResources(): Generator<IOEffect, void, any> {
  while (true) {
    yield take(GET_RESOURCES.REQUEST)

    try {
      const { data } = yield call(http, 'api/public-site/resources', {
        method: 'GET',
      })

      yield put(getResources.success(data.results))
    } catch (e) {
      yield put(getResources.error(e.message))
    }
  }
}

export default [fork(watchGetResources)]
