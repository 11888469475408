// @flow

import React from 'react'

const ArrowDown = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 309.156 309.156" width="12" height="12">
      <g>
        <polygon
          points="288.461,64.929 154.589,202.766 20.723,64.94 0,85.07 154.589,244.228 309.156,85.07   "
          fill="#424242"/>
      </g>
    </svg>
  )
}

export default ArrowDown
