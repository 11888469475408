// @flow
import React from 'react'
// styles
import { StyledContent, StyledWrapper } from './authBLockStyles'

// types
import type { AuthBlockTypes } from './AuthBlockTypes'

const AuthBLock = (props: AuthBlockTypes) => (
  <StyledWrapper>
    <StyledContent>
      {props.children}
    </StyledContent>
  </StyledWrapper>
)

export default AuthBLock
