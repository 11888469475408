// @flow
import { call, put, takeLatest, fork } from 'redux-saga/effects'
import { getCurrencyRate, GET_CURRENCY_RATE } from 'src/redux/actions/payments/payments'
import { fetchCurrencyRate } from 'src/services/payments'
import { formatMoney } from 'src/helpers/moneyHelpers'

// types
import type { IOEffect } from 'redux-saga/effects'

function * watchGetCurrencyRateStart(): Generator<IOEffect, void, any> {
  yield takeLatest(
    GET_CURRENCY_RATE.REQUEST,
    getCurrencyRateRequest,
  )
}

function * getCurrencyRateRequest(): Generator<IOEffect, void, any> {
  try {
    const { rate } = yield call(
      fetchCurrencyRate,
    )
    yield put(getCurrencyRate.success(+formatMoney(rate)))
  } catch (e) {
    yield put(getCurrencyRate.error(e.message))
  }
}

export default [fork(watchGetCurrencyRateStart)]
