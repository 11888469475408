// @flow

import { call, fork, put, take } from 'redux-saga/effects'
import { REMOVE_DIVISION, removeDivision } from '../actions/divisions/divisions'
import { getLeague } from 'src/redux/actions/myLeagues/myLeagues'
import http from 'src/services/http'

// types
import type { IOEffect } from 'redux-saga/effects'

function * watchRemoveDivision(): Generator<IOEffect, void, any> {
  while (true) {
    const {
      payload: { division_id, leagueId },
    } = yield take(REMOVE_DIVISION.REQUEST)
    try {
      yield call(http, `api/division/${division_id}`, {
        method: 'DELETE',
      })

      yield put(removeDivision.success())
      yield put(getLeague.request({ leagueId }))
    } catch (e) {
      yield put(removeDivision.error(e.message))
    }
  }
}

export default [fork(watchRemoveDivision)]
