// @flow
import React from 'react'
// styles
import { StyledWrapper } from './loaderWrapperStyles'

// types
import type { LoaderWrapperTypes } from './LoaderWrapperTypes'

const Layout = (props: LoaderWrapperTypes) => {
  return (
    <StyledWrapper fullHeight={props.fullHeight}>
      {props.children}
    </StyledWrapper>
  )
}

export default Layout
