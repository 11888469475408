// @flow

import { fork, put, take, call } from 'redux-saga/effects'
import { SAVE_ACH_ACCOUNT_DETAILS, saveACHAccountDetails } from 'src/redux/actions/payments/payments'
import http from 'src/services/http'
import { stopSubmit } from 'redux-form'
import config from 'src/config'
import { history } from 'src/services/history'
// types
import type { IOEffect } from 'redux-saga/effects'

function * watchSaveACHAccountDetails(): Generator<IOEffect, void, any> {
  while (true) {
    const {
      payload,
    } = yield take(SAVE_ACH_ACCOUNT_DETAILS.REQUEST)
    const formId = config.ACHPaymentForm.id
    try {
      const {
        tokenizedPayload: { nonce },
      } = payload
      const { data: { verification: { id } } } = yield http('api/payment/ach/create', {
        body: JSON.stringify({
          nonce,
        }),
        method: 'POST',
      })

      yield put(saveACHAccountDetails.success(id))
      yield call(history.push, '/payment-settings/thank-you')
    } catch (e) {
      yield put(saveACHAccountDetails.error(e.message))
      yield put(stopSubmit(formId, e.errors ? e.errors : {}))
    }
  }
}

export default [fork(watchSaveACHAccountDetails)]
