// @flow

import React from 'react'

const StartLeagueIcon = () => {
  return (
    <svg x="0px" y="0px" fill="#fff" width="20px" height="20px" viewBox="0 0 1792 1792">
      <g>
        <g>
          <path d="M1357.583,1628.183l-61.01-252.018c-34.481-21.244-156.528-90.206-379.349-90.206 c-13.266,0-23.886-5.312-31.84-13.265c-18.575-18.575-37.137-58.367-31.839-214.869v-15.933l172.433-167.122 c15.933-55.712,103.472-368.741,0-527.911c-2.641-5.311-74.272-103.458-291.811-103.458 c-217.524,0-291.811,103.458-291.811,103.458c-106.113,159.169-18.562,469.542,0,527.911l172.434,167.122v15.933 c5.312,156.502-13.264,196.294-31.826,214.869c-7.966,7.953-21.229,13.265-31.839,13.265c-222.834,0-344.867,66.32-379.349,90.206 l-61.024,252.018l-76.929-18.573l66.321-281.217l10.607-7.952c5.311-5.311,137.952-108.756,416.5-114.066 c2.655-15.908,7.954-53.058,7.954-129.998L373.393,917.205l-2.656-7.953c-5.311-15.908-129.985-400.566,7.955-604.839 c2.655-5.312,95.504-140.595,358.132-140.595c262.627,0,355.476,135.283,358.145,140.595 c137.926,206.916,13.264,588.932,7.953,604.839l-2.668,7.953l-161.812,159.171c-2.643,76.94,2.642,114.09,7.953,129.998 c278.548,5.311,411.188,108.756,416.5,114.066l10.62,7.952l66.321,281.217L1357.583,1628.183z"></path>
          <rect id="Rectangle-path" x="1280.665" y="906.609" width="477.511" height="79.584"></rect>
          <rect id="Rectangle-path_1_" x="1479.629" y="707.647" width="79.586" height="477.509"></rect>
        </g>
      </g>
    </svg>
  )
}

export default StartLeagueIcon
