import styled from 'styled-components'
import { device } from 'src/common/theme/mediaQueries'

export const StyledCard = styled.div`
    border: 1px solid ${props => props.theme.main.colors.borderColor};
    border-left: 5px solid ${props => props.theme.main.colors.secondaryColor};
    border-radius: 3px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    cursor: ${props => props.pointer ? 'pointer' : 'auto'}
    
    :hover {
        background-color: rgba(31,46,81,0.03);
    }
    
    .card-left,
    .card-center,
    .card-right {
        padding: 10px 15px;
    }
    
    .card-left {
        padding-right: 20px;
        flex: 3;
    }
    
    .card-center {
        flex: 2;
        display: flex;
        justify-content: space-between;
    }
    
    .card-right {
        align-items: center;
        display: flex;
        justify-content: flex-end;
        min-width: 200px;
        flex: 1;
    }
    
    .contact {
        align-items: center;
        display: flex;
        line-height: 1.2;
    }
    
    .phone {
      a {
        :hover {
          text-decoration: none;
        }
      }
    }
    
    .card-title {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        text-transform: uppercase;
    }

    .logo {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 10px;

        img {
            width: 125px;
        }
    }
    
    .distance {
      margin-top: 10px;
      display: block;
    }
    
    .card-status {
        display: flex;
        align-items: center;
        align-content: center;
        font-size: 14px;
        font-family: ${props => props.theme.main.fonts};
        text-transform: none;
        color: #d43540;
        margin-top: 5px;
        
        svg {
          margin-right: 5px;
          path {
            fill: red;
            }
          }
       }
    
    .card-subtitle {
        display: block;
        margin-bottom: 10px;
    }
    
    .list-contacts {
        flex-wrap: wrap;
        
        .list-item {
            border-right: 1px solid ${props => props.theme.main.colors.borderColor};
            padding-left: 8px;
            padding-right: 20px;
            
            :first-child {
                padding-left: 0;
            }
            
            :last-child {
                border-right: 0;
            }

            a {
                align-items: center;
                display: flex;
                line-height: 1.2;
            }
        }
    }
    
    .contact {
        margin-bottom: 8px;
        
        .icon-wrapper {
            min-width: 20px;
            margin-right: 4px;
        }
    }
    
    @media ${device.tablet} {
        .card-left {
            padding-bottom: 20px;
            flex-basis: 100%;
        }

        .card-center {
            flex-basis: 100%;
        }
        
        .card-right {
            justify-content: center;
            padding-bottom: 20px;
            margin: 0 auto;
            max-width: 240px;
            flex-basis: 100%;
        }
        
        .list-contacts {
        
            .list-item {
                border-right: 0;
                padding-left: 0;
            }
        }
    }
    
    @media ${device.mobileL} {
        .card-title {
            font-size: 14px;
        }
        .logo {
            width: 100%;
        }
    }
`
