// @flow
import type { MembersActionTypes } from 'src/redux/actions/members/membersTypes'
import {
  GET_MEMBERS_BY_LEAGUE_ID,
  INVITE_MEMBER,
  REMOVE_MEMBER,
} from 'src/redux/actions/members/members'

export type MembersDataItem = any;

export type LeagueOwnerType = {
    email: string,
    first_name: ?string,
    last_name: ?string,
    role: string
};

export type MembersData = {
    leagueOwner: LeagueOwnerType,
    members: LeagueOwnerType[]
};

export type MembersState = {
    isLoading: boolean,
    membersData: ?MembersData,
    membersErrors?: ?{ [key: string]: any } | ?string
};

const initialState = {
  isLoading: false,
  membersData: null,
  membersErrors: null,
  currentMemberData: null,
}

const members = (state: MembersState = initialState, action: MembersActionTypes): MembersState => {
  switch (action.type) {
    case GET_MEMBERS_BY_LEAGUE_ID.REQUEST:
    case INVITE_MEMBER.REQUEST:
    case REMOVE_MEMBER.REQUEST:
    {
      return {
        ...state,
        isLoading: true,
      }
    }

    case GET_MEMBERS_BY_LEAGUE_ID.SUCCESS:
    {
      return ({
        ...state,
        isLoading: false,
        membersData: { ...action.payload },
        membersErrors: null,
      })
    }

    case REMOVE_MEMBER.SUCCESS:
    {
      return ({
        ...state,
        isLoading: false,
        membersErrors: null,
      })
    }

    case INVITE_MEMBER.SUCCESS:
    {
      return ({
        ...state,
        isLoading: false,
        membersErrors: null,
      })
    }

    case GET_MEMBERS_BY_LEAGUE_ID.FAILURE:
    case INVITE_MEMBER.FAILURE:
    case REMOVE_MEMBER.FAILURE:
    {
      return { ...state, isLoading: false, membersErrors: action.payload }
    }
    default: {
      return state
    }
  }
}

export default members
