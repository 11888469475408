// @flow
import * as React from 'react'
import { connect } from 'react-redux'

import {
  getPaymentToken,
  setPaymentPayload,
  showPaymnentLoader,
  submitVirtualPayment,
  submitInsurancePayment,
  submitPendingOrderCardPayment,
  submitACHAccountDetails,
  getACHInstance,
  saveACHAccountDetails,
  submitACHMicroTransfer,
  getPaymentMethods,
  getPaymentMethodByToken,
  deletePaymentMethod,
  createPaymentMethod,
  updatePaymentMethod,
  getCurrencyRate,
} from 'src/redux/actions/payments/payments'

const withPayments = <P>(WrappedComponent: React.ComponentType<P>) => {
  class WithPaymentsComponent extends React.Component<P> {
    render() {
      return <WrappedComponent {...this.props} />
    }
  }
  const mapStateToProps = ({ payments }) => ({ payments })

  const mapDispatchToProps = (dispatch, ownProps) => ({
    getPaymentToken: () => dispatch(getPaymentToken.request()),
    setPaymentPayload: data => dispatch(setPaymentPayload.request(data)),
    setPaymentPaylodError: error => dispatch(setPaymentPayload.error(error)),
    showPaymnentLoader: value => dispatch(showPaymnentLoader.success(value)),
    submitVirtualPayment: data => dispatch(submitVirtualPayment.request(data)),
    submitInsurancePayment: data => dispatch(submitInsurancePayment.request(data)),
    submitPendingOrderCardPayment: data => dispatch(submitPendingOrderCardPayment.request(data)),
    submitACHAccountDetails: data => dispatch(submitACHAccountDetails.request(data)),
    saveACHAccountDetails: data => dispatch(saveACHAccountDetails.request(data)),
    getACHInstance: data => dispatch(getACHInstance.request(data)),
    submitACHMicroTransfer: data => dispatch(submitACHMicroTransfer.request(data)),
    getPaymentMethods: data => dispatch(getPaymentMethods.request(data)),
    getPaymentMethodByToken: data => dispatch(getPaymentMethodByToken.request(data)),
    deletePaymentMethod: data => dispatch(deletePaymentMethod.request(data)),
    createPaymentMethod: data => dispatch(createPaymentMethod.request(data)),
    updatePaymentMethod: data => dispatch(updatePaymentMethod.request(data)),
    getCurrencyRate: data => dispatch(getCurrencyRate.request(data)),
  })

  return connect(
    mapStateToProps,
    mapDispatchToProps,
  )(WithPaymentsComponent)
}

export default withPayments

