import styled from 'styled-components'

export const StyledFormInput = styled.input`
    color: ${props => props.theme.main.colors.inputColor};
    font-size: 14px;
    margin-right: 4px;
    padding: 12px;
    width: auto !important;
    
   
`

export const StyledRadioInput = styled.label`
    align-items: center;
    display: flex;
    padding-right: 20px;
`

export const StyledError = styled.span`
    color: ${props => props.theme.main.colors.errorColor};
    font-size: 13px;
`
