// @flow

import React from 'react'
import DatePicker from 'react-datepicker'
import moment from 'moment-timezone'
import { StyledDatePicker, StyledError } from './datePickerStyles'

import type { DatePickerPropsType } from './CustomDatePickerTypes'

import 'react-datepicker/dist/react-datepicker.css'

class CustomDatePicker extends React.Component<DatePickerPropsType, any> {
  state = {
    startDate: this.props.input.value ? moment(this.props.input.value, 'MM/DD/YYYY').toDate() : moment().toDate(),
  }

  handleChange = (date: any) => {
    date
      ? this.props.input.onChange(moment(date).format('MM/DD/YYYY'))
      : this.props.input.value ? moment(this.props.input.value, 'MM/DD/YYYY').toDate() : moment().toDate()
  }

  render() {
    const {
      minDate,
      maxDate,
      input,
      placeholder,
      meta: { touched, error },
      validationErrors,
      id,
      asyncErrorCheck,
      disabled,
    } = this.props
    let errorBlock = null
    if ((touched || asyncErrorCheck) && (error || (validationErrors && validationErrors.errors[id]))) {
      errorBlock = (
        <div>
          <StyledError className="error-message">{error || validationErrors.message}</StyledError>
        </div>
      )
    }
    return (
      <StyledDatePicker disabled={disabled}>
        <DatePicker
          placeholder={placeholder}
          name={input.name}
          value={input.value}
          dateFormat="MM/DD/YYYY"
          autoComplete="off"
          onChange={this.handleChange}
          dateForm="MM/DD/YYYY"
          showYearDropdown
          dropdownMode="select"
          selected={input.value ? moment(input.value, 'MM/DD/YYYY').toDate() : this.state.startDate}
          minDate={minDate ? moment(minDate).toDate() : null}
          maxDate={maxDate ? moment(maxDate).toDate() : null}
          disabled={disabled}
        />
        {errorBlock}
      </StyledDatePicker>
    )
  }
}

export default CustomDatePicker
