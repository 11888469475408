// @flow

import submitExemptionCertificate from './submitExemptionCertificate'
import getExposureZones from './getExposureZones'
import getExemptionCertificateAttachment from './getExemptionCertificateAttachment'
import getExemptionCertificates from './getExemptionCertificates'

export default [
  ...submitExemptionCertificate,
  ...getExposureZones,
  ...getExemptionCertificateAttachment,
  ...getExemptionCertificates,
]
