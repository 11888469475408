// @flow
import React, { lazy } from 'react'
import { PrivateRoute } from 'src/common/components'
const CreateDivision = lazy(() => import('src/modules/divisions/createDivision'))
const EditDivision = lazy(() => import('src/modules/divisions/editDivision'))

export default [
  <PrivateRoute exact={true} path="/my-leagues/:leagueId/:seasonId/divisions/create" component={CreateDivision} key="create-division"/>,
  <PrivateRoute exact={true} path="/my-leagues/:leagueId/divisions/:divisionId/edit" component={EditDivision} key="edit-division"/>,
]
