import styled from 'styled-components'

export const StyledWarningBlock = styled.div`
    .title {
        margin-bottom: 8px;
    }
    
    .subtitle {
        margin: 0 auto;
        max-width: 500px;
    }
`
