// @flow
import React from 'react'

const PaymentCard = () => (
  <svg width="40" height="20" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.4688 0H1.5C0.65625 0 0 0.6875 0 1.5V12.5C0 13.3438 0.65625 14 1.5 14H16.4688C17.3125 14 18 13.3438 18 12.5V1.5C18 0.6875 17.3125 0 16.4688 0ZM16.2812 12.5H1.6875C1.5625 12.5 1.5 12.4375 1.5 12.3125V1.6875C1.5 1.59375 1.5625 1.5 1.6875 1.5H16.2812C16.4062 1.5 16.4688 1.59375 16.4688 1.6875V12.3125C16.4688 12.4375 16.4062 12.5 16.2812 12.5ZM6 10.375C6 10.1875 5.8125 10 5.625 10H3.375C3.15625 10 3 10.1875 3 10.375V10.625C3 10.8438 3.15625 11 3.375 11H5.625C5.8125 11 6 10.8438 6 10.625V10.375ZM12 10.375C12 10.1875 11.8125 10 11.625 10H7.375C7.15625 10 7 10.1875 7 10.375V10.625C7 10.8438 7.15625 11 7.375 11H11.625C11.8125 11 12 10.8438 12 10.625V10.375ZM8.125 9C8.3125 9 8.5 8.84375 8.5 8.625V7.375C8.5 7.1875 8.3125 7 8.125 7H6.375C6.15625 7 6 7.1875 6 7.375V8.625C6 8.84375 6.15625 9 6.375 9H8.125ZM9 8.625C9 8.84375 9.15625 9 9.375 9H11.125C11.3125 9 11.5 8.84375 11.5 8.625V7.375C11.5 7.1875 11.3125 7 11.125 7H9.375C9.15625 7 9 7.1875 9 7.375V8.625ZM3 8.625C3 8.84375 3.15625 9 3.375 9H5.125C5.3125 9 5.5 8.84375 5.5 8.625V7.375C5.5 7.1875 5.3125 7 5.125 7H3.375C3.15625 7 3 7.1875 3 7.375V8.625ZM15 7.375C15 7.1875 14.8125 7 14.625 7H12.375C12.1562 7 12 7.1875 12 7.375V8.625C12 8.84375 12.1562 9 12.375 9H14.625C14.8125 9 15 8.84375 15 8.625V7.375ZM15 3.25C15 2.84375 14.6562 2.5 14.25 2.5H11.75C11.3125 2.5 11 2.84375 11 3.25V4.75C11 5.1875 11.3125 5.5 11.75 5.5H14.25C14.6562 5.5 15 5.1875 15 4.75V3.25Z" fill="#323232"></path>
  </svg>
)

export default PaymentCard
