// @flow

import { stopSubmit, reset } from 'redux-form'
import moment from 'moment-timezone'
import { call, fork, put, take } from 'redux-saga/effects'
import config from 'src/config'
import { UPLOAD_INSURANCE, uploadInsurance } from 'src/redux/actions/fileActions/fileActions'
import { getLeague, getLeagueInsurance } from 'src/redux/actions/myLeagues/myLeagues'
import { showToast } from 'src/redux/actions/ui/ui'
import http from 'src/services/http'
// types
import type { IOEffect } from 'redux-saga/effects'

function * watchUploadInsurance(): Generator<IOEffect, void, any> {
  while (true) {
    const {
      payload: { ...values },
    } = yield take(UPLOAD_INSURANCE.REQUEST)
    const formId = config.leagueInsuranceForm.id

    try {
      const { file, leagueId, start_date, end_date } = values
      const data = new FormData()
      for (const f of file) {
        data.append('files', f)
      }
      data.append('league_id', leagueId)
      data.append('start_date', moment.utc(start_date).startOf('day').format())
      data.append('end_date', moment.utc(end_date).endOf('day').format())
      yield call(http, 'api/league/media', {
        body: data,
        method: 'POST',
        headers: {
          'Content-Disposition': `attachment; filename=${file.name}`,
          'Content-Length': file.length,
        },
        withoutContentType: true,
      })

      yield put(uploadInsurance.success())
      yield put(getLeagueInsurance.request({ leagueId: +leagueId }))
      yield put(getLeague.request({ leagueId: +leagueId }))
      yield put(reset(formId))
    } catch (e) {
      yield put(showToast.success({ title: 'File name can contain english letters, numbers and allowed symbols', messageType: 'error' }))
      yield put(uploadInsurance.error(e.message))
      yield put(stopSubmit(formId))
    }
  }
}

export default [fork(watchUploadInsurance)]
