// @flow
import * as React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { uploadInsurance } from 'src/redux/actions/fileActions/fileActions'

const withFileActions = <P>(WrappedComponent: React.ComponentType<P>) => {
  class WithFileActionsComponent extends React.Component<P> {
    render() {
      return <WrappedComponent {...this.props} />
    }
  }
  const mapStateToProps = ({ filesState }) => ({ filesState })

  const mapDispatchToProps = (dispatch, ownProps) => ({
    uploadInsurance: values => dispatch(uploadInsurance.request({ ...values })),
  })

  return connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withRouter(WithFileActionsComponent))
}

export default withFileActions

