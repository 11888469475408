// @flow

import React from 'react'
import WarningIcon from '@material-ui/icons/Warning'
// types
import type { ErrorFieldPropsType } from './ErrorFieldTypes'

// styles
import { StyledErrorBlock } from './errorFieldStyles'

const ErrorField = ({ meta: { touched, error, warning } }: ErrorFieldPropsType) => {
  return (
    <>
      { touched &&
      ((error && <StyledErrorBlock>{ error }</StyledErrorBlock>) ||
        (warning && <StyledErrorBlock isWarning={true}>
          <WarningIcon style={{ fontSize: '12px' }} />{ warning }
        </StyledErrorBlock>)) }
    </>
  )
}

export default ErrorField
