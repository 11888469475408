import styled from 'styled-components'
// import { device } from "src/common/theme/mediaQueries"

export const SeasonRowStyle = styled.div`
    margin-top: 8px;
    line-height: 1.4;

    & + & {
        margin-top: 15px;
    }
`
