// @flow
import React from 'react'
import Pagination from '../pagination'
import { withRouter } from 'react-router-dom'
import queryString from 'query-string'

class WithPaginationComponent extends React.Component<any> {
  emitChange({ limit = 10, page = 1 }) {
    const offset = (page - 1) * limit
    this.props.onPaginationChange && this.props.onPaginationChange({
      limit,
      offset,
      page,
    })
  }

  componentDidMount() {
    const { history, location } = this.props
    const values = queryString.parse(location.search)

    let { limit, page } = values

    limit = +values.limit || +this.props.config.limit || 10
    page = +values.page || 1

    const offset = (page - 1) * limit

    if (!+values.page || !+values.limit) {
      values.page = page
      values.limit = limit

      const search = queryString.stringify(values)

      const newLocation = Object.assign({}, location)
      newLocation.search = search
      history.replace(newLocation)
    }

    // emit at Mount, change to flag
    this.emitChange({
      limit,
      offset,
      page,
    })
  }

  onChangePage(page: number) {
    const { paginationSource } = this.props
    this.updateRoute(page, paginationSource.limit)
  }

  onChangeSize(size: number) {
    this.updateRoute(1, size)
  }

  updateRoute(page: number = 1, limit: number = 10) {
    const { history, location } = this.props
    const offset = ((page || 1) - 1) * limit
    const values = queryString.parse(this.props.location.search)

    const newLocation = Object.assign({}, location)
    newLocation.search = queryString.stringify({
      ...values,
      limit,
      page,
    })

    newLocation.state = {
      ...location.state,
      offset,
      page,
      limit,
    }

    history.push(newLocation)

    this.emitChange({ limit, page })
  }

  render() {
    const values = queryString.parse(this.props.location.search)
    const {
      paginationSource,
      children,
    } = this.props

    return (
      <>
        {children}
        <Pagination
          onChangePage={this.onChangePage.bind(this)}
          onChangeSize={this.onChangeSize.bind(this)}
          page={(paginationSource && +paginationSource.page) || +values.page || 1}
          total={paginationSource && paginationSource.total}
          limit={paginationSource && paginationSource.limit}
        />
      </>
    )
  }
}

export default withRouter(WithPaginationComponent)
