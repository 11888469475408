// @flow
import React from 'react'
import { Field } from 'redux-form'
import { get } from 'lodash'
import { ButtonPrimary, Checkbox } from 'src/common/components'
import { CloseIcon } from 'src/assets/icons'
import { required } from 'src/helpers/formValidation'
// styles
import { StyledWrapper, StyledModal, StyledCloseButton } from './agreementModalStyles'

// types
import type { AgreementModalTypes } from './AgreementModalTypes'
const AgreementModal = (props: AgreementModalTypes) => {
  const agreementData = get(props, 'agreementData.rendered.fragment')
  const {
    withoutForm = false,
  } = props
  return (
    <StyledWrapper>
      <StyledModal>
        <div className="d-flex justify-content-end">
          {withoutForm && <StyledCloseButton onClick={withoutForm ? props.handleSubmit(props.submitForm) : () => {}}>
            {

              <CloseIcon />
            }
          </StyledCloseButton>
          }
        </div>
        <div className="modal-title">{props.title}</div>
        <div className="modal-body">{props.children}
          <div dangerouslySetInnerHTML={{ __html: agreementData }} />
        </div>
        {!withoutForm && (
          <form>
            <div className="modal-action">
              <div className="flex-column">
                <Field
                  name="agreement"
                  type="checkbox"
                  component={Checkbox}
                  labelText="I read and agree"
                  validate={[required]}
                />
              </div>
              <ButtonPrimary buttonText="Submit" size="small" action={props.handleSubmit(props.submitForm)}/>
            </div>
          </form>
        )}
      </StyledModal>
    </StyledWrapper>
  )
}

export default AgreementModal
