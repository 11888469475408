// @flow
import type { MyLeaguesActionTypes } from 'src/redux/actions/myLeagues/myLeaguesTypes'
import {
  GET_MY_LEAGUES,
  CREATE_LEAGUE,
  GET_LEAGUES_ORGANIZATIONS,
  GET_LEAGUE,
  EDIT_LEAGUE,
  SET_LEAGUE,
  RESET_MY_LEAGUES,
  GET_SURVEY_QUESTIONS,
  GET_LEAGUE_INSURANCE,
  VALIDATE_ADDRESS,
} from 'src/redux/actions/myLeagues/myLeagues'

import type { VendorType } from 'src/common/constants.types'

export type LeagueActiveSeasonType = {
    id: number,
    end_date: string,
    start_date: string,
    registration_start_date: string,
    registration_end_date: string
};

export type LeagueInsuranceType = {
    active: string,
    status: string,
    start_date: string,
    end_date: string
};

export type MyLeaguesDataItem = {
    has_boys: boolean,
    has_coed: boolean,
    has_girls: boolean,
    has_insurance: string,
    id: number,
    latitude: string,
    longitude: string,
    max_age: number,
    min_age: number,
    min_grade: number,
    max_grade: number,
    name: string,
    default_vendor: VendorType,
    roles: string,
    website: ?string,
    phone: ?string,
    distance: string,
    league_zip: string,
    league_city: string,
    league_state: string,
    league_address_1: string,
    league_county: string,
    league_country: string,
    billing_address_1: string,
    billing_city: string,
    billing_state: string,
    billing_zip: string,
    billing_county: string,
    billing_country: string,
    shipping_address_1: string,
    shipping_city: string,
    shipping_state: string,
    shipping_zip: string,
    shipping_county: string,
    shipping_country: string,
    practice_address_id: number,
    insurance_status: string,
    approved: string,
    updated_at: string,
    active: ?boolean,
    participants: number | 'unknown',
    insurance: LeagueInsuranceType,
    active_seasons: LeagueActiveSeasonType[],
    twitter?: string,
    facebook?: string,
    instagram?: string,
    email?: string,
    program_logo?: string
};

export type MyLeaguesData = MyLeaguesDataItem[];
export type LeagueInsuranceData = {
    insurance_status: ?string,
    next_insurance_start_date: ?string,
    last_insurance_expiration_date: ?string,
    current_insurance_expiration_date: ?string,
    next_insurance_approval_status: ?string,
    insurance_expiration_status: ?string
};

type LeagueSurveyOptionsType = {
  value: string,
  name: string
};

export type LeagueSurveyQuestionType = {
  id: number,
  question: string,
  type: string,
  is_followup_question: boolean,
  no_answer_needed: boolean,
  placeholder: ?string,
  rules: {
    [key: string]: any
  },
  options: ?LeagueSurveyOptionsType[]
};

export type MyLeaguesState = {
    isLoading: boolean,
    isLoadingLeague: boolean,
    myLeaguesData: MyLeaguesData,
    myLeaguesSortByActiveSeasons?: MyLeaguesData,
    leagueData: any, // todo add type
    myLeaguesErrors?: ?{ [key: string]: any } | ?string,
    errors?: ?{ [key: string]: any } | ?string,
    cuurentLeagueData: any, // todo add type
    organizationsData: any, // todo add type
    surveyQuestions: LeagueSurveyQuestionType[],
    leagueInsuranceData: ?LeagueInsuranceData,
    addressValidationData: null | {[key: string]: any}
};

const initialState = {
  isLoading: false,
  isLoadingLeague: false,
  leagueData: {},
  myLeaguesData: [],
  myLeaguesSortByActiveSeasons: [],
  myLeaguesErrors: null,
  errors: null,
  cuurentLeagueData: null,
  organizationsData: [],
  surveyQuestions: [],
  leagueInsuranceData: null,
  addressValidationData: null,
}

const myLeagues = (state: MyLeaguesState = initialState, action: MyLeaguesActionTypes): MyLeaguesState => {
  switch (action.type) {
    case SET_LEAGUE.REQUEST:
    case GET_MY_LEAGUES.REQUEST:
    case CREATE_LEAGUE.REQUEST:
    case GET_LEAGUES_ORGANIZATIONS.REQUEST:
    case GET_SURVEY_QUESTIONS.REQUEST:
    case GET_LEAGUE_INSURANCE.REQUEST:
    case VALIDATE_ADDRESS.REQUEST:
    {
      return {
        ...state,
        isLoading: true,
      }
    }
    case GET_MY_LEAGUES.SUCCESS:
    {
      return {
        ...state,
        isLoading: false,
        myLeaguesData: [...action.payload.leagues],
        myLeaguesSortByActiveSeasons: [...action.payload.leaguesSortByActiveSeasons],
      }
    }
    case GET_LEAGUE_INSURANCE.SUCCESS:
    {
      return {
        ...state,
        isLoading: false,
        leagueInsuranceData: { ...action.payload },

      }
    }
    case SET_LEAGUE.SUCCESS:
    {
      return {
        ...state,
        isLoading: false,
        leagueData: { ...action.payload },

      }
    }
    case GET_SURVEY_QUESTIONS.SUCCESS:
    {
      return {
        ...state,
        isLoading: false,
        surveyQuestions: [...action.payload],
      }
    }

    case GET_LEAGUES_ORGANIZATIONS.SUCCESS:
    {
      return {
        ...state,
        isLoading: false,
        organizationsData: [...action.payload],

      }
    }
    case CREATE_LEAGUE.SUCCESS:
      return {
        ...state,
        isLoading: false,
      }

    case EDIT_LEAGUE.SUCCESS:
    {
      return {
        ...state,
        isLoadingLeague: false,
        addressValidationData: null,
      }
    }

    case GET_MY_LEAGUES.FAILURE:
    case CREATE_LEAGUE.FAILURE:
    {
      return { ...state, isLoading: false, myLeaguesErrors: action.payload }
    }

    case GET_LEAGUE.REQUEST:
    case EDIT_LEAGUE.REQUEST:

    {
      return {
        ...state,
        isLoadingLeague: true,
      }
    }
    case GET_LEAGUE.SUCCESS:
    {
      return {
        ...state,
        isLoadingLeague: false,
        leagueData: action.payload,

      }
    }

    case VALIDATE_ADDRESS.SUCCESS:
    {
      return {
        ...state,
        isLoading: false,
        addressValidationData: action.payload,
      }
    }

    case GET_LEAGUE.FAILURE:
    case EDIT_LEAGUE.FAILURE:
    case SET_LEAGUE.FAILURE:
    case GET_SURVEY_QUESTIONS.FAILURE:
    case GET_LEAGUE_INSURANCE.FAILURE:
    {
      return { ...state, isLoadingLeague: false, leagueErrors: action.payload }
    }

    case VALIDATE_ADDRESS.FAILURE:
    {
      return { ...state, isLoading: false, leagueErrors: action.payload }
    }

    case RESET_MY_LEAGUES: {
      return { ...initialState }
    }

    case 'persist/REHYDRATE': {
      if (action.key === 'myLeagues') {
        return {
          ...state,
          ...action.payload,
          surveyQuestions: [],
        }
      }
      return state
    }

    default: {
      return state
    }
  }
}

export default myLeagues
