// @flow

import { compose } from 'redux'
import { withUser, withBulkOrder, withMyLeagues, withNFLTeams } from 'src/decorators'
import BulkOrderSummary from './BulkOrderSummary'

export default compose(
  withUser,
  withBulkOrder,
  withMyLeagues,
  withNFLTeams,
)(BulkOrderSummary)
