// @flow

import React from 'react'

const EmailIcon = ({ width = 20, height = 20 }: {width: number, height: number}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={width} viewBox="0 0 128 128" width={height}>
      <g>
        <path
          d="m123.25 24.192c0-.018 0-.034-.005-.052s-.007-.063-.009-.094a1.734 1.734 0 0 0 -.083-.408c-.006-.018 0-.037-.011-.055s-.01-.015-.013-.023a1.734 1.734 0 0 0 -.227-.407c-.021-.028-.043-.053-.066-.08a1.755 1.755 0 0 0 -.31-.294c-.012-.009-.022-.02-.034-.028a1.744 1.744 0 0 0 -.414-.2c-.034-.012-.068-.022-.1-.032a1.733 1.733 0 0 0 -.474-.073h-115.004a1.733 1.733 0 0 0 -.474.073c-.035.01-.068.02-.1.032a1.744 1.744 0 0 0 -.414.2c-.012.008-.022.019-.034.028a1.755 1.755 0 0 0 -.31.294c-.022.027-.045.052-.066.08a1.734 1.734 0 0 0 -.227.407c0 .008-.01.015-.013.023s-.005.037-.011.055a1.734 1.734 0 0 0 -.083.408c0 .032-.009.063-.009.094s-.005.034-.005.052v79.615c0 .023.006.045.007.068a1.737 1.737 0 0 0 .019.188c.008.051.015.1.027.152a1.74 1.74 0 0 0 .056.179c.017.047.033.094.054.139a1.729 1.729 0 0 0 .093.172c.024.04.048.081.075.119a1.743 1.743 0 0 0 .125.152c.033.036.066.072.1.106.021.019.037.042.059.061s.036.017.052.03a1.736 1.736 0 0 0 .452.263c.035.014.071.022.107.033a1.732 1.732 0 0 0 .488.085c.012 0 .023.006.035.006h.023 114.952.023c.012 0 .023-.006.034-.006a1.732 1.732 0 0 0 .489-.085c.035-.011.07-.019.1-.033a1.736 1.736 0 0 0 .453-.263c.016-.013.036-.017.052-.03s.038-.042.059-.061c.036-.034.069-.069.1-.106a1.743 1.743 0 0 0 .125-.152c.027-.038.051-.078.075-.119a1.729 1.729 0 0 0 .093-.172c.021-.045.037-.092.054-.139a1.74 1.74 0 0 0 .056-.179c.012-.05.019-.1.027-.152a1.737 1.737 0 0 0 .019-.188c0-.023.007-.045.007-.068zm-77.45 36.124 17.058 14.677a1.751 1.751 0 0 0 2.283 0l17.059-14.677 35.512 41.741h-107.423zm-37.55 38.736v-71.045l34.9 30.026zm76.6-41.019 34.9-30.026v71.045zm31.931-32.091-35.505 30.551c-.006.005-.014.008-.02.014s-.012.014-.019.02l-17.237 14.831-17.237-14.831c-.007-.006-.012-.014-.019-.02l-.02-.014-35.507-30.551z"
          fill="#424242"/>
      </g>
    </svg>
  )
}

export default EmailIcon
