// @flow

import { fork, put, take, call } from 'redux-saga/effects'
import { stopSubmit } from 'redux-form'
import { SEND_ACTIVATION_EMAIL, sendActivationEmail } from 'src/redux/actions/user/user'
import http from 'src/services/http'
import config from 'src/config'
// types
import type { IOEffect } from 'redux-saga/effects'

function * watchSendActivationEmail(): Generator<IOEffect, void, any> {
  while (true) {
    const {
      payload: { email, forgotPassword, redirectCb },
    } = yield take(SEND_ACTIVATION_EMAIL.REQUEST)
    const url = forgotPassword
      ? 'forgot-password'
      : 'send-activation-email'
    const formId = config.forgotPasswordForm.id
    try {
      yield http(url, {
        body: JSON.stringify({
          email,
        }),
        method: 'POST',
      })

      yield put(sendActivationEmail.success())
      yield call(redirectCb, '/register/thank-you')
    } catch (e) {
      yield put(sendActivationEmail.error())
      yield put(stopSubmit(formId, e.errors ? e.errors : { email: e.message }))
    }
  }
}

export default [fork(watchSendActivationEmail)]
