// @flow

import { requestTypeCreator } from 'src/helpers'

// types
import type {
  GetMyLeaguesActionTypes,
  CreateLeagueActionTypes,
  GetLeaguesOrganizationsActionTypes,
  GetLeagueActionTypes,
  SetLeagueError,
  SetLeagueActionTypes,
  SetLeagueRequest,
  SetLeagueResponse,
  GetSurveyQuestionsActionTypes,
  SetSurveyAnswersActionTypes,
  GetLeagueInsuranceActionTypes,
  BasicRedux,
} from './myLeaguesTypes'

export const GET_MY_LEAGUES = requestTypeCreator('GET_MY_LEAGUES')
export const CREATE_LEAGUE = requestTypeCreator('CREATE_LEAGUE')
export const GET_LEAGUES_ORGANIZATIONS = requestTypeCreator('GET_LEAGUES_ORGANIZATIONS')

export const GET_LEAGUE = requestTypeCreator('GET_LEAGUE')
export const EDIT_LEAGUE = requestTypeCreator('EDIT_LEAGUE')
export const SET_LEAGUE = requestTypeCreator('SET_LEAGUE')
export const RESET_MY_LEAGUES = 'RESET_MY_LEAGUES'

export const GET_SURVEY_QUESTIONS = requestTypeCreator('GET_SURVEY_QUESTIONS')
export const SET_SURVEY_ANSWERS = requestTypeCreator('SET_SURVEY_ANSWERS')
export const GET_LEAGUE_INSURANCE = requestTypeCreator('GET_LEAGUE_INSURANCE')
export const VALIDATE_ADDRESS = requestTypeCreator('VALIDATE_ADDRESS')

export const setLeague = {
  error: (payload: SetLeagueError): SetLeagueActionTypes => ({ type: SET_LEAGUE.FAILURE, payload }),
  request: (payload: SetLeagueRequest): SetLeagueActionTypes => ({ type: SET_LEAGUE.REQUEST, payload }),
  success: (payload: SetLeagueResponse): SetLeagueActionTypes => ({ type: SET_LEAGUE.SUCCESS, payload }),
}

export const getMyLeagues = {
  error: (payload: any): GetMyLeaguesActionTypes => ({ type: GET_MY_LEAGUES.FAILURE, payload }),
  request: (payload: any): GetMyLeaguesActionTypes => ({ type: GET_MY_LEAGUES.REQUEST, payload }),
  success: (payload: any): GetMyLeaguesActionTypes => ({ type: GET_MY_LEAGUES.SUCCESS, payload }),
}

export const getLeague = {
  error: (payload: any): GetLeagueActionTypes => ({ type: GET_LEAGUE.FAILURE, payload }),
  request: (payload: any): GetLeagueActionTypes => ({ type: GET_LEAGUE.REQUEST, payload }),
  success: (payload: any): GetLeagueActionTypes => ({ type: GET_LEAGUE.SUCCESS, payload }),
}

export const createLeague = {
  error: (payload: any): CreateLeagueActionTypes => ({ type: CREATE_LEAGUE.FAILURE, payload }),
  request: (payload: any): CreateLeagueActionTypes => ({ type: CREATE_LEAGUE.REQUEST, payload }),
  success: (payload: any): CreateLeagueActionTypes => ({ type: CREATE_LEAGUE.SUCCESS, payload }),
}

export const getLeaguesOrganizations = {
  error: (payload: any): GetLeaguesOrganizationsActionTypes => ({ type: GET_LEAGUES_ORGANIZATIONS.FAILURE, payload }),
  request: (payload: any): GetLeaguesOrganizationsActionTypes => ({ type: GET_LEAGUES_ORGANIZATIONS.REQUEST, payload }),
  success: (payload: any): GetLeaguesOrganizationsActionTypes => ({ type: GET_LEAGUES_ORGANIZATIONS.SUCCESS, payload }),
}

export const editLeague = {
  error: (payload: any): CreateLeagueActionTypes => ({ type: EDIT_LEAGUE.FAILURE, payload }),
  request: (payload: any): CreateLeagueActionTypes => ({ type: EDIT_LEAGUE.REQUEST, payload }),
  success: (payload: any): CreateLeagueActionTypes => ({ type: EDIT_LEAGUE.SUCCESS, payload }),
}

export const resetMyLeagues = {
  success: () => ({ type: RESET_MY_LEAGUES }),
}

export const getSurveyQuestions = {
  error: (payload: any): GetSurveyQuestionsActionTypes => ({ type: GET_SURVEY_QUESTIONS.FAILURE, payload }),
  request: (payload: any): GetSurveyQuestionsActionTypes => ({ type: GET_SURVEY_QUESTIONS.REQUEST, payload }),
  success: (payload: any): GetSurveyQuestionsActionTypes => ({ type: GET_SURVEY_QUESTIONS.SUCCESS, payload }),
}

export const setSurveyAnswers = {
  error: (payload: any): SetSurveyAnswersActionTypes => ({ type: SET_SURVEY_ANSWERS.FAILURE, payload }),
  request: (payload: any): SetSurveyAnswersActionTypes => ({ type: SET_SURVEY_ANSWERS.REQUEST, payload }),
  success: (payload: any): SetSurveyAnswersActionTypes => ({ type: SET_SURVEY_ANSWERS.SUCCESS, payload }),
}

export const getLeagueInsurance = {
  error: (payload: any): GetLeagueInsuranceActionTypes => ({ type: GET_LEAGUE_INSURANCE.FAILURE, payload }),
  request: (payload: any): GetLeagueInsuranceActionTypes => ({ type: GET_LEAGUE_INSURANCE.REQUEST, payload }),
  success: (payload: any): GetLeagueInsuranceActionTypes => ({ type: GET_LEAGUE_INSURANCE.SUCCESS, payload }),
}

export const validateAddress = {
  error: (payload: any): BasicRedux => ({ type: VALIDATE_ADDRESS.FAILURE, payload }),
  request: (payload: any): BasicRedux => ({ type: VALIDATE_ADDRESS.REQUEST, payload }),
  success: (payload: any): BasicRedux => ({ type: VALIDATE_ADDRESS.SUCCESS, payload }),
}
