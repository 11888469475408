// @flow
import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import type { SurveyTypes } from './SurveyTypes'
import { FormInput, Select, CheckboxGroup } from '../index'
import { Field } from 'redux-form'
import { required, minLength, number } from 'src/helpers/formValidation'

import { wrappLinksIntoHTMLTag } from 'src/helpers/urlHelpers'

import { SurveyItemStyle } from './surveyStyles'

const createValidators = rules => {
  const validators = []
  if (!rules) {
    return validators
  }
  if (rules.required) {
    validators.push(required)
  }
  if (rules.minLength) {
    validators.push(minLength(rules.minLength))
  }
  if (rules.number) {
    validators.push(number)
  }
  return validators
}

const getFormField = type => {
  switch (type) {
    case 'TEXTAREA':
      return 'textarea'
    default:
      return 'text'
  }
}

const getFormComponent = type => {
  switch (type) {
    case 'SELECT':
      return Select
    case 'CHECKBOXGROUP':
      return CheckboxGroup
    default:
      return FormInput
  }
}

const SurveyField = props => {
  const {
    question: {
      id,
      question,
      type,
      is_followup_question,
      no_answer_needed,
      placeholder,
      rules,
      options,
    },
  } = props

  const [validators, updateValidator] = useState([])

  useEffect(() => {
    updateValidator(createValidators(rules))
  }, [rules])

  return (
    <SurveyItemStyle
      className={classNames({
        'form-row': true,
        'questions-block': !is_followup_question,
        'extraQuestions-block': is_followup_question,
      })}>
      <div className="form-group">
        <label
          className={classNames({
            required: rules && rules.required,
          })}
          dangerouslySetInnerHTML={{ __html: wrappLinksIntoHTMLTag(question) }}
        />
        <div>
          <Field
            name={`id-${id}`}
            component={getFormComponent(type)}
            type={getFormField(type)}
            hideInput={no_answer_needed}
            placeholder={placeholder}
            selectData={options}
            options={options}
            validate={validators}
          />
        </div>
      </div>
    </SurveyItemStyle>
  )
}

const Survey = ({ questions }: SurveyTypes) => {
  return (
    <div>
      {questions.map(question => <SurveyField key={question.id} question={question} />)}
    </div>
  )
}

export default Survey
