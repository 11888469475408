import styled from 'styled-components'

export const StyledBlock = styled.div`
    box-shadow: ${props => props.theme.main.secondary.blockBoxShadow};
    margin-bottom: 30px;

    .block-body {
        padding: 40px;
        text-align: center;
        
        .title {
            margin-bottom: 0;
            margin-top: 10px;
        }
    }
    
    .subtitle {
        font-weight: 300;
        margin: 25px auto;
        max-width: 700px;
    }
`
