// @flow
import type { DivissionActionTypes } from 'src/redux/actions/divisions/divisionsTypes'
import { CREATE_DIVISION, GET_ADIVISIONS, EDIT_DIVISION, REMOVE_DIVISION } from 'src/redux/actions/divisions/divisions'

export type DivisionsDataItem = {
    address_id: number,
    division_address_1: string,
    division_address_2: ?string,
    division_city: string,
    division_state: string,
    division_zip: string,
    has_boys: ?boolean,
    has_coed: ?boolean,
    has_girls: ?boolean,
    id: string,
    league_max_age: ?number,
    league_max_grade: ?number,
    max_age: ?number,
    max_grade: ?number,
    name: string,
    season_id: number
};
export type DivisionsData = DivisionsDataItem[];

export type DivisionsState = {
    isLoading: boolean,
    divisionsData: DivisionsData,
    divisionsErrors?: ?{ [key: string]: any } | ?string,
    curentDivisionData: ?DivisionsDataItem
};

const initialState = {
  isLoading: false,
  divisionsData: [],
  divisionsErrors: null,
  curentDivisionData: null,
}

const divisions = (state: DivisionsState = initialState, action: DivissionActionTypes): DivisionsState => {
  switch (action.type) {
    case CREATE_DIVISION.REQUEST:
    case GET_ADIVISIONS.REQUEST:
    case EDIT_DIVISION.REQUEST:
    case REMOVE_DIVISION.REQUEST:
    {
      return {
        ...state,
        isLoading: true,
      }
    }

    case CREATE_DIVISION.SUCCESS:
    case EDIT_DIVISION.SUCCESS:
    {
      return ({
        ...state,
        isLoading: false,
        cuurentDivisionData: { ...action.payload },
        divisionsErrors: null,
      })
    }

    case REMOVE_DIVISION.SUCCESS:
    {
      return ({
        ...state,
        isLoading: false,
        divisionsErrors: null,
      })
    }

    case GET_ADIVISIONS.SUCCESS:
    {
      return ({
        ...state,
        isLoading: false,
        divisionsData: [...action.payload],
        divisionsErrors: null,
      })
    }

    case CREATE_DIVISION.FAILURE:
    case GET_ADIVISIONS.FAILURE:
    case EDIT_DIVISION.FAILURE:
    case REMOVE_DIVISION.FAILURE:
    {
      return { ...state, isLoading: false, divisionsErrors: action.payload }
    }

    default: {
      return state
    }
  }
}

export default divisions
