// @flow
import React, { lazy } from 'react'
import { PublicRoute } from 'src/common/components'

const ThankYouPage = lazy(() => import('src/modules/public/thankYouPage'))
const ErrorPage = lazy(() => import('src/modules/public/errorPage'))

export default [
  <PublicRoute exact={true} path="/public/thank-you" no_redirects component={ThankYouPage} key="public-thank-you" />,
  <PublicRoute exact={true} path="/public/error" no_redirects component={ErrorPage} key="public-error" />,
]
