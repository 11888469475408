// @flow

import { requestTypeCreator } from 'src/helpers'

// types
import type {
  GetResourcesActionTypes,
  GetResourcePageActionTypes,
  GetRegisterAgreementTypes,
} from './resourcesTypes'

export const GET_RESOURCES = requestTypeCreator('GET_RESOURCES')
export const GET_RESOURCE_PAGE = requestTypeCreator('GET_RESOURCE_PAGE')
export const GET_REGISTER_AGREEMENT = requestTypeCreator('GET_REGISTER_AGREEMENT')

export const TOGGLE_AGREEMENT_FORM = 'TOGGLE_AGREEMENT_FORM'

export const getResources = {
  error: (payload: any): GetResourcesActionTypes => ({ type: GET_RESOURCES.FAILURE, payload }),
  request: (payload: any): GetResourcesActionTypes => ({ type: GET_RESOURCES.REQUEST, payload }),
  success: (payload: any): GetResourcesActionTypes => ({ type: GET_RESOURCES.SUCCESS, payload }),
}

export const getResourcePage = {
  error: (payload: any): GetResourcePageActionTypes => ({ type: GET_RESOURCE_PAGE.FAILURE, payload }),
  request: (payload: any): GetResourcePageActionTypes => ({ type: GET_RESOURCE_PAGE.REQUEST, payload }),
  success: (payload: any): GetResourcePageActionTypes => ({ type: GET_RESOURCE_PAGE.SUCCESS, payload }),
}

export const getRegisterAgreement = {
  error: (payload: any): GetRegisterAgreementTypes => ({ type: GET_REGISTER_AGREEMENT.FAILURE, payload }),
  request: (payload: any): GetRegisterAgreementTypes => ({ type: GET_REGISTER_AGREEMENT.REQUEST, payload }),
  success: (payload: any): GetRegisterAgreementTypes => ({ type: GET_REGISTER_AGREEMENT.SUCCESS, payload }),
}

export const toggleUserForm = {
  success: (payload: any): any => ({ type: TOGGLE_AGREEMENT_FORM, payload }),
}
