// @flow
import React from 'react'
import { StyledWarningBlock } from './warningBlockStyles'
import StyledBanner, { BANNER_TYPES } from 'src/common/components/bannerBlockStyle'

import type { WarningBlockPropTypes } from './WarningBlockTypes'

const WarningBlock = ({ title, subtitle, children }: WarningBlockPropTypes) => {
  return (
    <StyledBanner type={BANNER_TYPES.ERROR} mb={20}>
      <StyledWarningBlock>
        <h4 className="text-center title">{title}</h4>
        <p className="text-center subtitle">{subtitle}</p>
        <div className="text-center">{children}</div>
      </StyledWarningBlock>
    </StyledBanner>
  )
}

export default WarningBlock
