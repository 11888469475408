import styled from 'styled-components'

export const StyledFilesList = styled.ul`
    display: inline-flex;
    flex-direction: column;
    padding: 0;
    .file-card {
        display: flex;
        align-items: center;
        text-decoration: underline;
        cursor: pointer;
    }

`
