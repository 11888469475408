// @flow
import { cloneDeep } from 'lodash'
import roles from 'src/config/roles'

export type ValuesType = {
    [key: string]: any
};

export const shapeRolesOnFormSubmit = (values: ValuesType) => {
  const formValues = cloneDeep(values)

  const formRoles = []
  const valueNames = Object.keys(values)
  roles.forEach(role => {
    if (role.name === valueNames.find(name => name === role.name)) {
      formValues[role.name] && formRoles.push(role.name)
      delete formValues[role.name]
    }
  })
  formValues.roles = [...formRoles]
  return formValues
}
