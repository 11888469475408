// @flow

import { requestTypeCreator } from 'src/helpers'

// types
import type { UploadInsuranceActionTypes } from './fileActionsTypes'

export const UPLOAD_INSURANCE = requestTypeCreator('UPLOAD_INSURANCE')

export const uploadInsurance = {
  error: (payload: any): UploadInsuranceActionTypes => ({ type: UPLOAD_INSURANCE.FAILURE, payload }),
  request: (payload: any): UploadInsuranceActionTypes => ({ type: UPLOAD_INSURANCE.REQUEST, payload }),
  success: (payload: any): UploadInsuranceActionTypes => ({ type: UPLOAD_INSURANCE.SUCCESS, payload }),
}
