// @flow
import React from 'react'
import { CheckIcon } from 'src/assets/icons'
import RenderIf from '../renderIf'
// styles
import { StyledBlock } from './thankYouBlockStyles'

// types
import type { ThankYouBlockTypes } from './ThankYouBlockTypes'

const ThankYouBlock = (props: ThankYouBlockTypes) => {
  return (
    <StyledBlock>
      <div className="block-body">
        <CheckIcon />
        <h2 className="title">Thank you!</h2>
        <RenderIf condition={props.message}>
          <h4 className="subtitle">{props.message}</h4>
        </RenderIf>
        {props.children}
      </div>
    </StyledBlock>
  )
}

export default ThankYouBlock
