// @flow
import * as React from 'react'
import { connect } from 'react-redux'

import { getPublicLeagues, updatePublicLeague } from 'src/redux/actions/publicLeagues/publicLeagues'

const withPublicLeagues = <P>(WrappedComponent: React.ComponentType<P>) => {
  class WithPublicLeaguesComponent extends React.Component<P> {
    render() {
      return <WrappedComponent {...this.props} />
    }
  }
  const mapStateToProps = ({ publicLeagues }) => ({ publicLeagues })

  const mapDispatchToProps = (dispatch, ownProps) => ({
    getPublicLeagues: payload => dispatch(getPublicLeagues.request(payload)),
    updatePublicLeague: payload => dispatch(updatePublicLeague.success(payload)),
  })

  return connect(
    mapStateToProps,
    mapDispatchToProps,
  )(WithPublicLeaguesComponent)
}

export default withPublicLeagues

