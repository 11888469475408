// @flow
import * as React from 'react'
import { connect } from 'react-redux'

import { createDivision, editDivision, removeDivision } from 'src/redux/actions/divisions/divisions'

const withDivisions = <P>(WrappedComponent: React.ComponentType<P>) => {
  class WithDivisionsComponent extends React.Component<P> {
    render() {
      return <WrappedComponent {...this.props} />
    }
  }
  const mapStateToProps = ({ divisions }) => ({ divisions })

  const mapDispatchToProps = (dispatch, ownProps) => ({
    createDivision: values => dispatch(createDivision.request({ ...values, league_id: ownProps.match.params.leagueId })),
    editDivision: values => dispatch(editDivision.request({ ...values, league_id: ownProps.match.params.leagueId, division_id: ownProps.match.params.divisionId })),
    removeDivision: value => dispatch(removeDivision.request({ leagueId: ownProps.match.params.leagueId, division_id: value })),
  })

  return connect(
    mapStateToProps,
    mapDispatchToProps,
  )(WithDivisionsComponent)
}

export default withDivisions

