// @flow

import { get } from 'lodash'
import { logoutUser } from '../user'
import { showToast } from 'src/redux/actions/ui/ui'
import { store } from 'src/redux/store'

import 'url-search-params-polyfill'

type Options = {
    [key: string]: any
};

type GlobalOptions = {
    headers: {
        [key: string]: any
    },
    mode: string
};

type ClientOptions = {
    externalApi: ?string,
    disableToastr: ?boolean,
    responseIsFile: ?boolean
};

/**
 * default headers
 * enabled cors
 */
const globalOptions = (): GlobalOptions => {
  return {
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
    credentials: 'include',
  }
}

const getResponseMessage = message => {
  return (message && message !== '' && message) || null
}

const getFileName = contentDisposition => {
  return (contentDisposition && contentDisposition.split('filename=')[1]) || 'file'
}

const isSuccessfulResponse = response => {
  return response.status >= 200 && response.status < 300
}

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 * @return {object|undefined} Returns either the response, or throws an error
 */

const checkStatus = async(response: Response, method: string, clientOpts: ?ClientOptions): Promise<any> => {
  const {
    disableToastr = false,
    responseIsFile = false,
  } = clientOpts || {}

  if (responseIsFile && isSuccessfulResponse(response)) {
    try {
      const file = await response.blob()
      const filename = getFileName(response.headers.get('content-disposition'))

      return {
        file,
        filename,
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('BlOB err', err)
      throw err
    }
  }

  const json = await response.json()

  if (isSuccessfulResponse(response)) {
    const message = getResponseMessage(json.message)
    message && method !== 'GET' &&
        !disableToastr && store.dispatch(showToast.success({ title: message, messageType: 'success' }))

    return json
  }

  if (response.status === 401 || response.status === 403 || response.status === 500 || response.status === 422 || response.status === 412) {
    const message = getResponseMessage(json.message)
    !disableToastr && store.dispatch(showToast.success({ title: message, messageType: 'error' }))
  }

  if (response.status === 401) {
    logoutUser()
  }

  if (response.status === 404 && method === 'GET') {
    const message = getResponseMessage(json.message)
    !disableToastr && store.dispatch(showToast.success({ title: message, messageType: 'error' }))
  }

  if (response.status === 428 && method === 'POST') {
    const error = {
      response,
      json,
    }

    throw error
  }

  throw json
}

/**
 * @param url string
 * @param options object
 * @param externalApi string
 * @returns {Promise<T>}
 */
const http = async <T>(url: string, options: Options, clientOpts: ?ClientOptions): Promise<T> => {
  const { REACT_APP_API_URL = '' } = process.env
  const {
    externalApi = null,
  } = clientOpts || {}
  // $FlowFixMe
  const newOptions = { ...globalOptions(), ...options }
  if (options.headers && !options.withoutContentType) {
    newOptions.headers = {
      ...globalOptions().headers,
      ...options.headers,

    }
  }
  if (options.headers && options.withoutContentType) {
    newOptions.headers = {
      ...globalOptions().headers,
      ...options.headers,

    }
    delete newOptions.headers['Content-Type']
  }
  const urlParams = new URLSearchParams(get(options, 'params', {}))
  const newUrl = externalApi
    ? `${externalApi}/${url}${
      urlParams.toString() ? '?' : ''
    }${urlParams.toString()}`
    : `${REACT_APP_API_URL}/${url}${
      urlParams.toString() ? '?' : ''
    }${urlParams.toString()}`
  let response
  try {
    response = await fetch(newUrl, newOptions)
  } catch (e) {
    if (navigator.onLine) {
      response = 'OOPS! Something went wrong'
    } else {
      response = 'Connection was lost'
    }

    store.dispatch(showToast.success({ title: response, messageType: 'error' }))
    throw response
  }

  const { method } = newOptions
  return checkStatus(response, method, clientOpts)
}

export default http

// todo: refactor
