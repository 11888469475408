// @flow
import React, { memo, Suspense } from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { ThemeProvider } from 'styled-components'
import { persistor, store } from './redux/store'
import { Loader, LoaderWrapper } from './common/components'
import { theme } from './common/theme'
import Router from './router'
import 'src/translations/i18n'

const App = () => {
  return (
    <Suspense fallback={<LoaderWrapper fullHeight><Loader show={true} /></LoaderWrapper>}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ThemeProvider theme={theme}>
            <Router />
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </Suspense>
  )
}

export default memo<{}>(App)
