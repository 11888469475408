// @flow
type OptionalObject = {
    [key: string]: any
};

const requestTypeCreator: any = (base: string, optional?: OptionalObject) => {
  if (optional) {
    for (const index in optional) {
      optional[index] = `${base}_${index}`
    }
  }

  return {
    ...optional,
    FAILURE: `${base}_FAILURE`,
    REQUEST: `${base}_REQUEST`,
    SUCCESS: `${base}_SUCCESS`,
  }
}

export default requestTypeCreator
