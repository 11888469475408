// @flow
import React from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { withStyles } from '@material-ui/core/styles'
import InputBase from '@material-ui/core/InputBase'
import { PAYMENT_TYPES } from 'src/config/ACHConfig'

// types
import type { PaymentSelectPropsType } from './PaymentSelectTypes'

import { StyledSelect } from './PaymentSelectStyles'

const BootstrapInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase)

const PaymentSelect = ({
  value = 'Card',
  handleChange,
  label,
  selectOptions,
  can_pay_by_check,
}: PaymentSelectPropsType) => {
  const renderOptions = selectOptions => {
    return selectOptions.map(({ name, value }) => {
      if (can_pay_by_check === false && value === PAYMENT_TYPES.CHECK) {
        return null
      }
      return <MenuItem key={value} value={value}>{name}</MenuItem>
    })
  }
  return (
    <StyledSelect>
      <FormControl >
        <div className="label">{label}</div>
        <Select
          labelId="demo-customized-select-label"
          id="demo-customized-select"
          value={value}
          onChange={handleChange}
          input={<BootstrapInput />}
        >
          {renderOptions(selectOptions)}
        </Select>
      </FormControl>
    </StyledSelect>
  )
}

export default PaymentSelect
