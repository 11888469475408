// @flow
import styled from 'styled-components'

const handleMessageBg = (alertType: string) => {
  switch (alertType) {
    case 'success':
      return '#cdefe4'
    case 'warning':
      return '#ffe4bf'
    case 'info':
      return '#c1e3ef'
    case 'error':
      return '#f1cecf'
    default:
      return '#c1e3ef'
  }
}

const handleMessageBorder = (alertType: string) => {
  switch (alertType) {
    case 'success':
      return '#20b575'
    case 'warning':
      return '#f3a02f'
    case 'info':
      return '#47b3d7'
    case 'error':
      return '#d03a3b'
    default:
      return '#47b3d7'
  }
}

export const StyledAlert = styled.div`
    background-color: ${({ alertType }) => handleMessageBg(alertType)};
    border-top: 5px solid ${({ alertType }) => handleMessageBorder(alertType)};
    display: ${props => props.showAlert ? 'block' : 'none'};
    margin-bottom: 30px;
    padding: 20px 20px 30px;
    .btn-close {
        appearance: none;
        background-color: transparent;
        border: 0;
        
        :hover {
            filter: brightness(1.5)
        }
    }
    
    .alert-header {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;
    }
    
    .alert-body {
        line-height: 1.2;
    }
    
    .title {
        margin-bottom: 0;
        padding-right: 30px;
    }
`
