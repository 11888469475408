import styled from 'styled-components'
import { device } from 'src/common/theme/mediaQueries'

export const StyledSidebar = styled.div`
    background-color: ${props => props.theme.main.colors.primaryColor};
    color: ${props => props.theme.main.colors.inverseColor};
    display: flex;
    flex-direction: column;
    height: 100%;
    max-width: 300px;
    min-height: 100vh;
    min-width: 200px;
    width: 100%;

    .link-menu {
      padding: 40px 0px;
    }

    .link-wrapper {
        button {
            align-items: center;
            background-color: ${props => props.theme.main.colors.buttonPrimaryColor};
            border: 1px solid ${props => props.theme.main.colors.buttonPrimaryColor};
            color: ${props => props.theme.main.colors.secondaryColor};
            border-radius: 3px;
            cursor: pointer;
            display: inline-flex;
            font-family: ${props => props.theme.main.fonts.secondaryFontFamily};
            font-size: 16px;
            height: 40px;
            justify-content: center;
            letter-spacing: 0.6px;
            max-width: 150px;
            padding: 12px 24px;
            text-decoration: none;
            width: 150px;
        
            &:hover {
                opacity: 0.8;
                text-decoration: none;
            }
            
            &:focus {
                outline: none;
            }
        }
    }

    .matrix-button {
        margin-top: 40px;
    }
    
    .user-name {
        font-size: 17px;
        font-weight: 300;
        padding: 10px;
        text-align: center;
        display: block;
        color: #fff;

        .empty {
           text-decoration: underline;
        }
    }

    h4 {
        font-weight: 400;
        margin: 30px 0 20px;
        padding: 0 24px;
    }

    @media ${device.tablet} {
        min-width: 300px;
        padding-bottom: 50px;
        padding-top: 40px;
        position: relative;
        top: 0;
        transition: transform 0.5s ease-in-out;
        transform: translateX(${props => props.showMenu ? '0' : '-320px'});
        width: 300px;
        z-index: 999;
    }

    svg {
        width: 24px;
        path {
            fill: ${props => props.theme.main.colors.inverseColor};
        }
    }
`

export const StyledHeader = styled.div`
    display: flex;
    justify-content: center;
    padding: 15px;

    img {
        max-width: 150px;
        object-fit: contain;
    }
`
