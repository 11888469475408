// @flow

import React from 'react'

const ParentIcon = () => {
  return (
    <svg x="0px" y="0px" fill="#fff" width="20px" height="20px" viewBox="0 0 1792 1792">
      <g>
        <g>
          <path d="M1325.595,1673.907l-58.053-240.916c-34.838-23.215-150.944-84.188-359.941-84.188 c-14.513,0-26.12-5.782-34.824-14.514c-20.324-17.404-37.742-58.053-34.838-211.889v-17.403l165.444-162.566 c17.434-58.039,98.702-354.116,0-505.046c-2.892-2.906-72.566-98.701-278.644-98.701c-206.092,0-275.752,95.795-278.658,98.701 c-89.986,133.511-31.933,400.561,0,505.046l165.457,162.566v17.403c5.797,153.836-14.514,194.484-34.838,211.889 c-8.703,8.731-23.215,14.514-34.822,14.514c-208.998,0-325.104,63.864-359.942,84.188l-58.053,240.916l-84.175-20.324 l66.77-272.832l11.608-8.703c5.797-5.811,133.525-104.514,403.466-113.216c2.906-17.404,8.703-52.241,5.812-118.998 L370.608,985.972l-2.891-8.704c-5.812-14.512-127.729-388.952,8.702-589.233c2.905-5.811,92.891-136.43,351.225-136.43 c258.333,0,348.305,133.524,351.225,139.336c133.51,200.28,11.594,574.735,8.702,589.249l-2.892,8.701l-156.754,153.836 c0,66.755,2.891,101.592,5.811,118.998c269.941,5.81,397.67,107.403,403.452,113.214l11.622,8.703l66.756,272.861 L1325.595,1673.907z"></path >
          <path d="M1665.212,1459.128l-55.162-226.43c-31.918-20.298-142.24-78.35-336.696-78.35 c-11.623,0-23.244-5.811-34.838-14.515c-17.433-17.433-37.729-55.161-31.946-200.293v-17.406l156.755-153.849 c17.404-55.147,92.891-330.9,0-470.236c-2.893-2.906-66.756-92.877-258.348-92.877c-191.564,0-255.43,87.08-258.32,92.877 l-72.567-49.338c2.892-5.811,89.972-130.619,330.888-130.619c240.943,0,328.022,124.809,330.915,130.619 c124.808,188.674,11.62,539.884,8.701,554.397l-2.892,8.717l-145.133,142.228c0,58.052,2.892,89.97,5.812,107.403 c252.508,5.812,371.535,101.594,377.346,104.485l11.594,8.701l60.973,255.457L1665.212,1459.128z"></path>
        </g>
      </g>
    </svg>
  )
}

export default ParentIcon
