// @flow
import type { BasicRedux } from 'src/redux/actions/featureManagement/featureManagementTypes'
import {
  GET_FEATURES,
  GET_FEATURE,
} from 'src/redux/actions/featureManagement/featureManagement'

export type Feature = {
  id: number,
  featureName: string,
  status: boolean
};

export type FeatureManagementState = {
  isLoading: boolean,
  featureList: Feature[],
  featureErrors?: ?{ [key: string]: any } | ?string,
  features: { [key: string]: any }
};

const initialState = {
  isLoading: false,
  featureList: [],
  featureErrors: null,
  features: {},
}

const featureManagement = (state: FeatureManagementState = initialState, action: BasicRedux): FeatureManagementState => {
  switch (action.type) {
    case GET_FEATURES.REQUEST:
    case GET_FEATURE.REQUEST:
    {
      return {
        ...state,
        isLoading: true,
        featureErrors: null,
      }
    }

    case GET_FEATURES.FAILURE:
    case GET_FEATURE.FAILURE:
    {
      return {
        ...state,
        isLoading: false,
        featureErrors: action.payload,
      }
    }

    case GET_FEATURES.SUCCESS:
    {
      return {
        ...state,
        isLoading: false,
        featureList: action.payload,
        featureErrors: null,
      }
    }

    case GET_FEATURE.SUCCESS:
    {
      return {
        ...state,
        isLoading: false,
        featureErrors: null,
        features: {
          ...state.features,
          [action?.payload?.name]: action?.payload,
        },
      }
    }

    default: {
      return state
    }
  }
}

export default featureManagement
