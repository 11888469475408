// @flow
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { ButtonPrimary } from 'src/common/components'
import { StyledHeader } from './headerStyles'
import { withUser } from 'src/decorators'
import { logoutUser } from 'src/services/user'
// types
import type { HeaderStateTypes, HeaderPropsTypes } from './HeaderTypes'

class Header extends Component<HeaderPropsTypes, HeaderStateTypes> {
  state = {
    showMenu: false,
  }

  toggleMenuHandler = () => {
    this.setState({
      showMenu: !this.state.showMenu,
    })
  }

  render() {
    return (
      <StyledHeader showMenu={this.state.showMenu}>
        <div className="toggle-nav" onClick={this.toggleMenuHandler}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div className="header-menu">
          <div className="menu-item">
            <Link to="/league-finder" className="header-link">
              <span className="link-text">Find League</span>
            </Link>
          </div>
          <div className="menu-item">
            <a
              href={process.env.REACT_APP_MAIN_SITE}
              className="header-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="link-text">Main Site</span>
            </a>
          </div>
          <div className="menu-item">
            <Link to="/edit-profile" className="header-link">
              <span className="link-text">Profile</span>
            </Link>
          </div>
          <div className="menu-item">
            <ButtonPrimary size="small" buttonText="Logout" action={() => logoutUser(this.props.resetUserState)}/>
          </div>
        </div>
      </StyledHeader>
    )
  }
}

export default withUser(Header)
