// @flow

import { requestTypeCreator } from 'src/helpers'

// types
import type { GetPaymentTokenActionTypes, SetPaymentPayloadActionTypes, BasicRedux } from './paymentsTypes'

export const GET_PAYMENT_TOKEN = requestTypeCreator('GET_PAYMENT_TOKEN')
export const SET_PAYMENT_PAYLOAD = requestTypeCreator('SET_PAYMENT_PAYLOAD')
export const SUBMIT_VIRTUAL_PAYMENT = requestTypeCreator('SUBMIT_VIRTUAL_PAYMENT')
export const SUBMIT_INSURANCE_PAYMENT = requestTypeCreator('SUBMIT_INSURANCE_PAYMENT')
export const SUBMIT_PENDING_ORDER_CARD_PAYMENT = requestTypeCreator('SUBMIT_PENDING_ORDER_CARD_PAYMENT')
export const SUBMIT_ACH_ACCOUNT_DETAILS = requestTypeCreator('SUBMIT_ACH_ACCOUNT_DETAILS')
export const GET_ACH_INSTANCE = requestTypeCreator('GET_ACH_INSTANCE')
export const SAVE_ACH_ACCOUNT_DETAILS = requestTypeCreator('SAVE_ACH_ACCOUNT_DETAILS')
export const SUBMIT_ACH_MICRO_TRANSFER = requestTypeCreator('SUBMIT_ACH_MICRO_TRANSFER')
export const GET_PAYMENT_METHODS = requestTypeCreator('GET_PAYMENT_METHODS')
export const GET_PAYMENT_METHOD_BY_TOKEN = requestTypeCreator('GET_PAYMENT_METHOD_BY_TOKEN')
export const DELETE_PAYMENT_METHOD = requestTypeCreator('DELETE_PAYMENT_METHOD')
export const CREATE_PAYMENT_METHOD = requestTypeCreator('CREATE_PAYMENT_METHOD')
export const UPDATE_PAYMENT_METHOD = requestTypeCreator('UPDATE_PAYMENT_METHOD')
export const GET_CURRENCY_RATE = requestTypeCreator('GET_CURRENCY_RATE')

export const SHOW_PAYMENT_LOADER = 'SHOW_PAYMENT_LOADER'
export const RESET_PAYMENTS_ERROR = 'RESET_PAYMENTS_ERROR'

export const getPaymentToken = {
  error: (payload: any): GetPaymentTokenActionTypes => ({ type: GET_PAYMENT_TOKEN.FAILURE, payload }),
  request: (payload: any): GetPaymentTokenActionTypes => ({ type: GET_PAYMENT_TOKEN.REQUEST, payload }),
  success: (payload: any): GetPaymentTokenActionTypes => ({ type: GET_PAYMENT_TOKEN.SUCCESS, payload }),
}

export const setPaymentPayload = {
  error: (payload: any): SetPaymentPayloadActionTypes => ({ type: SET_PAYMENT_PAYLOAD.FAILURE, payload }),
  request: (payload: any): SetPaymentPayloadActionTypes => ({ type: SET_PAYMENT_PAYLOAD.REQUEST, payload }),
  success: (payload: any): SetPaymentPayloadActionTypes => ({ type: SET_PAYMENT_PAYLOAD.SUCCESS, payload }),
}

export const showPaymnentLoader = {
  success: (payload: any) => ({ type: SHOW_PAYMENT_LOADER, payload }),
}

export const submitVirtualPayment = {
  error: (payload: any): SetPaymentPayloadActionTypes => ({ type: SUBMIT_VIRTUAL_PAYMENT.FAILURE, payload }),
  request: (payload: any): SetPaymentPayloadActionTypes => ({ type: SUBMIT_VIRTUAL_PAYMENT.REQUEST, payload }),
  success: (payload: any): SetPaymentPayloadActionTypes => ({ type: SUBMIT_VIRTUAL_PAYMENT.SUCCESS, payload }),
}

export const submitInsurancePayment = {
  error: (payload: any): BasicRedux => ({ type: SUBMIT_INSURANCE_PAYMENT.FAILURE, payload }),
  request: (payload: any): BasicRedux => ({ type: SUBMIT_INSURANCE_PAYMENT.REQUEST, payload }),
  success: (payload: any): BasicRedux => ({ type: SUBMIT_INSURANCE_PAYMENT.SUCCESS, payload }),
}

export const submitPendingOrderCardPayment = {
  error: (payload: any): BasicRedux => ({ type: SUBMIT_PENDING_ORDER_CARD_PAYMENT.FAILURE, payload }),
  request: (payload: any): BasicRedux => ({ type: SUBMIT_PENDING_ORDER_CARD_PAYMENT.REQUEST, payload }),
  success: (payload: any): BasicRedux => ({ type: SUBMIT_PENDING_ORDER_CARD_PAYMENT.SUCCESS, payload }),
}

export const submitACHAccountDetails = {
  error: (payload: any): BasicRedux => ({ type: SUBMIT_ACH_ACCOUNT_DETAILS.FAILURE, payload }),
  request: (payload: any): BasicRedux => ({ type: SUBMIT_ACH_ACCOUNT_DETAILS.REQUEST, payload }),
  success: (payload: any): BasicRedux => ({ type: SUBMIT_ACH_ACCOUNT_DETAILS.SUCCESS, payload }),
}
export const getACHInstance = {
  error: (payload: any): BasicRedux => ({ type: GET_ACH_INSTANCE.FAILURE, payload }),
  request: (payload: any): BasicRedux => ({ type: GET_ACH_INSTANCE.REQUEST, payload }),
  success: (payload: any): BasicRedux => ({ type: GET_ACH_INSTANCE.SUCCESS, payload }),
}
export const saveACHAccountDetails = {
  error: (payload: any): BasicRedux => ({ type: SAVE_ACH_ACCOUNT_DETAILS.FAILURE, payload }),
  request: (payload: any): BasicRedux => ({ type: SAVE_ACH_ACCOUNT_DETAILS.REQUEST, payload }),
  success: (payload: any): BasicRedux => ({ type: SAVE_ACH_ACCOUNT_DETAILS.SUCCESS, payload }),
}
export const submitACHMicroTransfer = {
  error: (payload: any): BasicRedux => ({ type: SUBMIT_ACH_MICRO_TRANSFER.FAILURE, payload }),
  request: (payload: any): BasicRedux => ({ type: SUBMIT_ACH_MICRO_TRANSFER.REQUEST, payload }),
  success: (payload: any): BasicRedux => ({ type: SUBMIT_ACH_MICRO_TRANSFER.SUCCESS, payload }),
}
export const getPaymentMethods = {
  error: (payload: any): BasicRedux => ({ type: GET_PAYMENT_METHODS.FAILURE, payload }),
  request: (payload: any): BasicRedux => ({ type: GET_PAYMENT_METHODS.REQUEST, payload }),
  success: (payload: any): BasicRedux => ({ type: GET_PAYMENT_METHODS.SUCCESS, payload }),
}
export const getPaymentMethodByToken = {
  error: (payload: any): BasicRedux => ({ type: GET_PAYMENT_METHOD_BY_TOKEN.FAILURE, payload }),
  request: (payload: any): BasicRedux => ({ type: GET_PAYMENT_METHOD_BY_TOKEN.REQUEST, payload }),
  success: (payload: any): BasicRedux => ({ type: GET_PAYMENT_METHOD_BY_TOKEN.SUCCESS, payload }),
}
export const deletePaymentMethod = {
  error: (payload: any): BasicRedux => ({ type: DELETE_PAYMENT_METHOD.FAILURE, payload }),
  request: (payload: any): BasicRedux => ({ type: DELETE_PAYMENT_METHOD.REQUEST, payload }),
  success: (payload: any): BasicRedux => ({ type: DELETE_PAYMENT_METHOD.SUCCESS, payload }),
}
export const createPaymentMethod = {
  error: (payload: any): BasicRedux => ({ type: CREATE_PAYMENT_METHOD.FAILURE, payload }),
  request: (payload: any): BasicRedux => ({ type: CREATE_PAYMENT_METHOD.REQUEST, payload }),
  success: (payload: any): BasicRedux => ({ type: CREATE_PAYMENT_METHOD.SUCCESS, payload }),
}
export const updatePaymentMethod = {
  error: (payload: any): BasicRedux => ({ type: UPDATE_PAYMENT_METHOD.FAILURE, payload }),
  request: (payload: any): BasicRedux => ({ type: UPDATE_PAYMENT_METHOD.REQUEST, payload }),
  success: (payload: any): BasicRedux => ({ type: UPDATE_PAYMENT_METHOD.SUCCESS, payload }),
}
export const getCurrencyRate = {
  error: (payload: any): BasicRedux => ({ type: GET_CURRENCY_RATE.FAILURE, payload }),
  request: (payload: any): BasicRedux => ({ type: GET_CURRENCY_RATE.REQUEST, payload }),
  success: (payload: any): BasicRedux => ({ type: GET_CURRENCY_RATE.SUCCESS, payload }),
}

export const resetPaymentsError = {
  success: (payload: any): BasicRedux => ({ type: RESET_PAYMENTS_ERROR, payload }),
}

