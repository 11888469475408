// @flow

import { call, fork, put, take } from 'redux-saga/effects'
import { GET_EXEMPTION_CERTIFICATE_ATTACHMENT, getExemptionCertificateAttachment } from 'src/redux/actions/exemptionCertificates/exemptionCertificates'
import { fetchExemptionCertificateAttachment } from 'src/services/exemptionCertificates'
// types
import type { IOEffect } from 'redux-saga/effects'

function * watchGetExemptionCertificateAttachment(): Generator<IOEffect, void, any> {
  while (true) {
    const { payload } = yield take(GET_EXEMPTION_CERTIFICATE_ATTACHMENT.REQUEST)

    try {
      const data = yield call(fetchExemptionCertificateAttachment, payload)

      const a = document.createElement('a')
      a.href = URL.createObjectURL(data.file)
      a.setAttribute('download', 'certificate.pdf')
      a.click()

      yield put(getExemptionCertificateAttachment.success())
    } catch (e) {
      yield put(getExemptionCertificateAttachment.error(e.message))
    }
  }
}

export default [fork(watchGetExemptionCertificateAttachment)]
