// @flow

import { call, fork, put, take } from 'redux-saga/effects'
import { GET_LEAGUE, getLeague } from 'src/redux/actions/myLeagues/myLeagues'
import { getAddresses } from 'src/redux/actions/addresses/addresses'
import { getDivisions } from 'src/redux/actions/divisions/divisions'
import { getSeasons } from 'src/redux/actions/seasons/seasons'
import http from 'src/services/http'
import { get } from 'lodash'
import { history } from 'src/services/history'
// types
import type { IOEffect } from 'redux-saga/effects'

function * watchGetLeague(): Generator<IOEffect, void, any> {
  while (true) {
    const action = yield take(GET_LEAGUE.REQUEST)
    try {
      const { leagueId, redirectURL } = action.payload
      const { data } = yield call(http, `api/league/initial-data/${leagueId}`, {
        method: 'GET',
      })

      yield put(getLeague.success(data.league))
      // TODO: why this call if this data save higher yield put(getLeague.success(data.league)) ?
      // yield put(setLeague.success(data.league))
      yield put(getAddresses.success(data.addresses))
      yield put(getDivisions.success(data.divisions))
      yield put(getSeasons.success(data.seasons))
      const isApproved = get(data, 'league.approved')
      if (redirectURL && isApproved && isApproved !== 'APPROVED') {
        yield call(history.push, redirectURL)
      }
    } catch (e) {
      yield put(getLeague.error(e.message))
    }
  }
}

export default [fork(watchGetLeague)]
