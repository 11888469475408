import styled from 'styled-components'

export const StyledSizeInput = styled.div`
    align-items: center;
    display: flex;
    
    input {
        background-color: transparent;
        border: 1px solid ${props => props.theme.main.colors.inputBorderColor};
        font-size: 14px;
        font-weight: 300;
        margin: 0 0 0 10px;
        padding: 7px 12px;
        width: 70px;
        
        :focus {
            border-color: ${props => props.theme.main.colors.primaryColor};
        }
    }
    
    .label {
        text-align: right;
        width: 55px;
        &.description {
            width: 200px;
            text-align: left;
        }
    }
    
    .available-number {
        border-radius: 3px;
        display: inline-flex;
        font-size: 14px;
        padding: 8px;
        
        &.success {
            color: #45bb7e;
        }
        
        &.warning {
            color: #f3af5f;
        }
        
        &.error {
            color: #db7478;
        }
    }
    
    .input-wrapper {
        align-items: center;
        display: flex;
    }
`
export const StyledError = styled.div`
    font-size: 13px;
    color: ${props => props.theme.main.colors.errorColor};
    padding-top: 7px;
`
