// @flow
import React, { Component } from 'react'
import { CloseIcon } from 'src/assets/icons'
// styles
import { StyledAlert } from './alertBLockStyles'

// types
import type { AlertPropTypes, AlertStateTypes } from './AlertBLockTypes'

class AlertBlock extends Component<AlertPropTypes, AlertStateTypes> {
  state = {
    showAlert: true,
  }

  closeAlert = () => {
    this.setState({
      showAlert: false,
    })
  }

  render() {
    return (
      <StyledAlert showAlert={this.state.showAlert} alertType={this.props.alertType}>
        <div className="alert-header">
          <h4 className="title">{this.props.title}</h4>
          <button type="button" className="btn-close" onClick={this.closeAlert}>
            <CloseIcon />
          </button>
        </div>
        <div className="alert-body">
          {this.props.description}
        </div>
      </StyledAlert>
    )
  }
}

export default AlertBlock
