// @flow
import systemRoles from 'src/config/systemRoles'

const LEAGUE_OWNER = 'LEAGUE_OWNER'
const CO_OWNER = 'CO_OWNER'

const matrixRoles = [LEAGUE_OWNER, CO_OWNER]

export const managingRoles = [LEAGUE_OWNER, CO_OWNER, 'ADMIN']

export const canUserManageLeague = (roleName: string) => {
  if (!roleName) return
  return managingRoles.find(managingRole => managingRole === roleName)
}

export const getSystemRoleName = (roleName: string) => {
  if (!roleName) return
  return systemRoles.filter(item => roleName.split(',').includes(item.name)).map(role => role.title).join(', ')
}

export const isUserLeagueOwner = (roles: string[]) => {
  if (!roles || !roles.length) return false
  return roles.includes(LEAGUE_OWNER)
}

export const canUserUseMatrix = (userData: any, leaguesData: any) => {
  if (!leaguesData || !leaguesData.length || !userData || !userData.roles || !userData.roles.length) return false
  const hasAllowedRole = userData.roles.some(role => matrixRoles.includes(role))
  const hasApprovedLeague = leaguesData.some(league => league.approved === 'APPROVED')
  if (!hasAllowedRole || !hasApprovedLeague) return false
  if (!userData.matrix_user) {
    return leaguesData.some(league => league.active_seasons.length)
  }
  return true
}
