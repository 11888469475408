// @flow
import { requestTypeCreator } from 'src/helpers'
import type { BasicRedux } from './teamOrderTypes'

export const DOWNLOAD_MATRIX_TEAM_ORDER = requestTypeCreator('DOWNLOAD_MATRIX_TEAM_ORDER')

export const downloadMatrixTeamOrder = {
  request: (payload: any): BasicRedux => ({ type: DOWNLOAD_MATRIX_TEAM_ORDER.REQUEST, payload }),
  success: (payload: any): BasicRedux => ({ type: DOWNLOAD_MATRIX_TEAM_ORDER.SUCCESS, payload }),
  error: (payload: any): BasicRedux => ({ type: DOWNLOAD_MATRIX_TEAM_ORDER.FAILURE, payload }),
}
