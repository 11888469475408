// @flow

import React, { useEffect, useState } from 'react'
import CardInsurance from 'src/common/components/cardInsurance'
import FileSaver from 'file-saver'

// types
import type { MyInsurancesDataItem } from 'src/redux/reducers/myInsurances'

// styles
import { StyledList } from './cardInsuranceListStyles'

const List = ({ items, checkFile }: any) => {
  return items.map((item: MyInsurancesDataItem) => {
    return (
      <li className="list-item" key={item.id}>
        <CardInsurance
          id={item.id}
          title={item.title}
          type_insurance={item.type_insurance}
          status={item.status}
          expiration_status={item.expiration_status}
          start_date={item.start_date}
          end_date={item.end_date}
          approve_date={item.approve_date}
          policy_number={item.policy_number}
          bound_at={item.bound_at}
          payment_status={item.payment_status}
          file_name={item.file_name}
          file={item.file}
          order_field={item.order_field}
          status_reason={item.status_reason}
          vendor={item.vendor}
          checkFile={checkFile}
          files={item.files}
          meta={item.meta}
          players={item.players}
        />
      </li>
    )
  })
}

const CardInsuranceList = ({ items, checkFile, fileOpen }: any) => {
  const [isOpenFile, setIsOpenFile] = useState(false)

  useEffect(() => {
    if (fileOpen && isOpenFile) {
      setIsOpenFile(false)

      if (fileOpen?.file?.data) {
        const arr = new Uint8Array(fileOpen.file.data)
        const blob = new Blob([arr])
        FileSaver.saveAs(blob, fileOpen.name)
      }
    }
  }, [fileOpen])

  return (
    <StyledList className="list list-my-leagues">
      <List items={items} checkFile={data => {
        checkFile(data)
        setIsOpenFile(true)
      }}/>
    </StyledList>
  )
}

export default CardInsuranceList
