// @flow
import React from 'react'
import { Link } from 'react-router-dom'
// styles
import { StyledLink } from './sidebarLinkStyles'

// types
import type { SidebarLinkTypes } from './SidebarLinkTypes'

const SidebarLink = (props: SidebarLinkTypes) => {
  const currentPath = window.location.pathname
  return (
    <StyledLink isCurrent={props.path === currentPath && true}>
      <Link to={{ pathname: props.path }} className="sidebar-link">
        <span className="link d-flex">
          <span className="icon-wrapper">{props.icon}</span>
          <span className="link-text">{props.title}</span>
        </span>
      </Link>
    </StyledLink>
  )
}

export default SidebarLink
