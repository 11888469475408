// @flow
import React, { lazy, Suspense, useEffect } from 'react'
import { Switch, Redirect } from 'react-router-dom'
import AppBrowserRouter, { history } from 'src/services/history'
import { Loader, LoaderWrapper, PrivateRoute, PublicRoute, NotFoundRoute } from 'src/common/components'
import RegisterRoutes from 'src/modules/register/routes'
import MyLeaguesRoutes from 'src/modules/myLeagues/routes'
import MyInsurancesRoutes from 'src/modules/myInsurances/routes'
import LeagueFinderRoutes from 'src/modules/leagueFinder/routes'
import DivisionsRoutes from 'src/modules/divisions/routes'
import SeasonsRoutes from 'src/modules/seasons/routers'
import PaymentRoutes from 'src/modules/payment/routes'
import OrderRoutes from 'src/modules/order/routes'
import ResourcesRoutes from 'src/modules/resources/routes'
import MembersRoutes from 'src/modules/users/routes'
import OrdersListRoutes from 'src/modules/ordersList/routes'
import PublicRoutes from 'src/modules/public/routes'
import insuranceRoutes from 'src/modules/insurancePurchase/routes'
import PaymentSettingsRoutes from 'src/modules/paymentSettings/routes'
import { useLocation } from 'react-router'
import { checkServiceWorkerForUpdate } from '../serviceWorkerRegistration'
import ExemptionCertificatesRoutes from 'src/modules/exemptionCertificates/routes'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
const { REACT_APP_SENTRY_URL = '' } = process.env

Sentry.init({
  dsn: REACT_APP_SENTRY_URL,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),
  ],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
})

const Login = lazy(() => import('src/modules/login'))
const Dashboard = lazy(() => import('src/modules/dashboard'))
const PurchaseInsurance = lazy(() => import('src/modules/purchase-insurance'))
const EditProfile = lazy(() => import('src/modules/editProfile'))
const changeEmail = lazy(() => import('src/modules/changeEmail'))
const OtherPayments = lazy(() => import('src/modules/otherPayments'))
const BackgroundCheckPage = lazy(() => import('src/modules/otherPayments/backgroundCheckPage'))
const NotFound = lazy(() => import('src/common/components/notFound'))

function usePageViews() {
  const location = useLocation()

  useEffect(
    () => {
      checkServiceWorkerForUpdate()
    },
    [location.pathname],
  )
}

const AppSwitch = () => {
  usePageViews()
  return (
    <Switch>
      <PrivateRoute exact={true} path="/" component={Dashboard} />
      <PublicRoute exact={true} path="/session-login" component={Login} />
      <PublicRoute exact={true} path="/login" component={Login} />
      <PrivateRoute exact={true} path="/purchase-insurance" component={PurchaseInsurance} />
      <PrivateRoute exact={true} path="/edit-profile" component={EditProfile} />
      <PrivateRoute exact={true} path="/payments" component={OtherPayments} />
      <PrivateRoute exact={true} path="/background-checks/:id" component={BackgroundCheckPage} />
      <PrivateRoute exact={true} path="/change-email" component={changeEmail} />
      {RegisterRoutes}
      {MyLeaguesRoutes}
      {MyInsurancesRoutes}
      {LeagueFinderRoutes}
      {DivisionsRoutes}
      {SeasonsRoutes}
      {PaymentRoutes}
      {OrderRoutes}
      {ResourcesRoutes}
      {MembersRoutes}
      {OrdersListRoutes}
      {insuranceRoutes}
      {PublicRoutes}
      {PaymentSettingsRoutes}
      {ExemptionCertificatesRoutes}
      <NotFoundRoute path="/404" component={NotFound} />
      <Redirect to="/404" />
    </Switch>
  )
}

const AppRouter = () => {
  return (
    <AppBrowserRouter history={history}>
      <Suspense fallback={<LoaderWrapper fullHeight><Loader show={true} /></LoaderWrapper>}>
        <AppSwitch />
      </Suspense>
    </AppBrowserRouter>
  )
}

export default AppRouter
