// @flow

import { fork, put, take, call } from 'redux-saga/effects'
import { SUBMIT_VIRTUAL_PAYMENT, submitVirtualPayment, getPaymentToken } from 'src/redux/actions/payments/payments'
import http from 'src/services/http'
import { history } from 'src/services/history'
import { resetBulkOrder } from 'src/redux/actions/bulkOrder/bulkOrder'
import { stopSubmit } from 'redux-form'
import config from 'src/config'

// types
import type { IOEffect } from 'redux-saga/effects'

function * watchSubmitVirtualPayment(): Generator<IOEffect, void, any> {
  while (true) {
    const {
      payload: { paymentInfo, ...values },
    } = yield take(SUBMIT_VIRTUAL_PAYMENT.REQUEST)
    const formId = config.virtualTerminalForm.id

    try {
      const { nonce } = paymentInfo
      values.amount = +values.amount
      values.terminal_id = +values.terminal_id
      values.league_id = +values.league_id
      yield http('api/payment/virtual-terminal', {
        body: JSON.stringify({
          nonceFromTheClient: nonce,
          ...values,
        }),
        method: 'POST',
      })

      yield put(submitVirtualPayment.success())
      yield call(history.push, 'thank-you')
      yield put(resetBulkOrder.success())
    } catch (e) {
      yield put(getPaymentToken.request())
      yield put(submitVirtualPayment.error(e.message))
      yield put(stopSubmit(formId, e.errors ? e.errors : {}))
    }
  }
}

export default [fork(watchSubmitVirtualPayment)]
