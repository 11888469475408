// @flow

export default {
  APPROVED_ACTIVE: 0,
  APPROVED_NOT_ACTIVE: 1,
  PENDING_ACTIVE: 2,
  PENDING_NOT_ACTIVE: 3,
  APPROVED_EXPIRED: 4,
  PENDING_EXPIRED: 5,
  DECLINED_ACTIVE: 6,
  DECLINED_NOT_ACTIVE: 7,
  DECLINED_EXPIRED: 8,
  OTHER: 9,
}
