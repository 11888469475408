// @flow

import { fork, put, take, call } from 'redux-saga/effects'
import { GET_ACH_INSTANCE, getACHInstance } from 'src/redux/actions/payments/payments'

import Braintree from 'src/services/braintree'
// types
import type { IOEffect } from 'redux-saga/effects'

function * watchGetACHInstance(): Generator<IOEffect, void, any> {
  while (true) {
    const {
      payload: { paymentToken },
    } = yield take(GET_ACH_INSTANCE.REQUEST)

    try {
      const braintreeService = new Braintree()
      const ACHInstance = yield call(braintreeService.getInstance, paymentToken)

      yield put(getACHInstance.success(ACHInstance))
    } catch (e) {
      yield put(getACHInstance.error(e.message))
    }
  }
}

export default [fork(watchGetACHInstance)]
