import http from 'src/services/http'
export const fetchValidateAddress = async data => {
  const response = await http('avalara/address/validate', {
    method: 'POST',
    body: JSON.stringify(data),
  }, { disableToastr: true })
  return response?.data
}

export const fetchLeaguesPersonal = async() => {
  const response = await http('api/leagues-personal', {
    method: 'GET',
  })
  return response?.data
}

