// @flow

import React from 'react'

import { Modal } from 'src/common/components'
import { CloseIcon } from 'src/assets/icons'

// types
import type { ConfirmPropsType, ConfirmStateType } from './ConfirmTypes'

export default class ConfirmStatusChange extends React.Component<ConfirmPropsType, ConfirmStateType> {
  state = {
    open: false,
    callback: () => {},
    cancelCallback: () => {},
  }

  show = ({ valid, handleSubmit, handleCancel, getDescriptionVariable }: {
      valid: boolean,
      handleSubmit: () => void,
      handleCancel: () => void,
    getDescriptionVariable: () => void
  }) => (event: SyntheticInputEvent<HTMLInputElement>) => {
    if (event) {
      event.preventDefault()
    }
    getDescriptionVariable && getDescriptionVariable()

    !valid && handleSubmit()

    valid && this.setState({
      open: true,
      callback: () => handleSubmit(),
      cancelCallback: () => typeof handleCancel === 'function' ? handleCancel() : undefined,
    })
  }

  cancel = () => {
    this.state.cancelCallback()
    this.hide()
  }

  hide = () => {
    this.setState({ open: false, callback: () => {}, cancelCallback: () => {} })
  }

  confirm = () => {
    this.state.callback()
    this.hide()
  }

  render() {
    const confirmBtnText = this.props.confirmText || (this.props.closedQuestion ? 'Yes' : 'Ok')
    const cancelBtnText = this.props.cancelText || (this.props.closedQuestion ? 'No' : 'Cancel')

    return (
      <React.Fragment>
        {this.props.children(this.show)}

        {this.state.open && (
          <Modal
            title={this.props.title}
            subtitle={this.props.description}
            onCancel={this.cancel}
            onConfirm={this.confirm}
            onHide={this.hide}
            showHideBtn={this.props.showHideBtn}
            confirmBtnText={confirmBtnText}
            showCancelBtn={this.props.showCancelBtn}
            cancelBtnText={cancelBtnText}
            cancelBtnPrependContent={this.props.isDeleting ? <CloseIcon/> : null}
            isCancelInPlainStyle={this.props.isDeleting}
            bodyContent={this.props.bodyContent}
          />
        )}
      </React.Fragment>
    )
  }
}
