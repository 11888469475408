// @flow
import { ButtonPrimary, TagsField } from 'src/common/components'
import { Field } from 'redux-form'
import { StyledPayWithCheck } from './PayWithCheckStyles'

import React from 'react'

const PayWithCheck = ({
  checkMessage,
  handleCheckSubmit,
  handleSubmit,
}: {
  handleCheckSubmit: () => void,
  checkMessage: ?string,
  handleSubmit: (data: any) => void
}) => {
  return (
    <StyledPayWithCheck>
      <h4>{checkMessage}</h4>
      <div>
        <div className="field">
          <Field name="emails" component={TagsField} />
        </div>
        <h5>Pay With Check</h5>
        <h5>
          Orders paid by check will not be submitted for processing until the
          payment is received. Approved nonprofit and Parks & Recreation
          programs are eligible to provide a purchase order to submit orders for
          processing prior to payment being received. Please contact the NFL
          FLAG customer service team at 844-940-1005 or <a href = "mailto:info@nflflag.com">info@nflflag.com</a>
        </h5>
      </div>
      <ButtonPrimary
        size="medium"
        buttonText="Confirm"
        action={handleSubmit(handleCheckSubmit)}
      />
    </StyledPayWithCheck>
  )
}

export default PayWithCheck
