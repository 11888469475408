// @flow
import styled from 'styled-components'
import { device } from 'src/common/theme/mediaQueries'
import insuranceOrders from 'src/config/insuranceOrders'

export const StyledCard = styled.div`
    border: 1px solid ${props => props.theme.main.colors.borderColor};
    border-left: 5px solid ${props => getColorStatus(props)};
    border-radius: 3px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    cursor: ${props => props.pointer ? 'pointer' : 'auto'}
    
    :hover {
        background-color: rgba(31,46,81,0.03);
    }
    
    .card-left,
    .card-center,
    .card-right {
        padding: 10px 15px;
    }
    
    .card-left {
        padding-right: 20px;
        flex: 1 1 55%;
    }
    
    .card-center {
        flex: 1 1 25%;
    }
    
    .card-right {
        align-items: center;
        display: flex;
        justify-content: flex-end;
        min-width: 200px;
        flex: 1 1 20%;
        @media (max-width: 1400px) and (min-width: 500px) {
            position: absolute;
            right: 0;
            bottom: 0;
        }
    }
    
    .card-title {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        text-transform: uppercase;
    }
    
    .card-status {
        display: flex;
        align-items: center;
        align-content: center;
        font-size: 14px;
        font-family: ${props => props.theme.main.fonts};
        text-transform: none;
        color: #d43540;
        margin-top: 5px;
        
        svg {
          margin-right: 5px;
          path {
            fill: red;
            }
          }
       }
    
    .card-subtitle {
        display: block;
        margin-bottom: 10px;
        span {
          text-transform: capitalize;
        }
        .group {
            padding-right: 15px;
        }
    }
    
    .list-block {
        flex-wrap: wrap;
    }
    
    @media ${device.tablet} {
        .card-left {
            padding-bottom: 20px;
            flex-basis: 100%;
        }

        .card-center {
            flex-basis: 100%;
        }
        
        .card-right {
            justify-content: center;
            padding-bottom: 20px;
            margin: 0 auto;
            max-width: 240px;
            flex-basis: 100%;
        }
    }
    
    @media ${device.mobileL} {
        .card-title {
            font-size: 14px;
        }
    }
`

const getColorStatus = (props: any) => {
  switch (props.order_field) {
    case insuranceOrders.APPROVED_ACTIVE:
      return props.theme.main.colors.greenColor
    case insuranceOrders.APPROVED_NOT_ACTIVE:
    case insuranceOrders.PENDING_ACTIVE:
    case insuranceOrders.PENDING_NOT_ACTIVE:
      return props.theme.main.colors.accentColor
    default:
      return props.theme.main.colors.errorColor
  }
}
