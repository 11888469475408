// @flow

export const gradeMapper = {
  // $FlowFixMe
  '-2': 'Pre-K', // $FlowFixMe
  '-1': 'Kindergarten',
}
const getgradeValues = (min: number = -2, max: number = 11) => {
  const gradeValues = []
  for (let i = min; i <= max; i++) {
    if (i === 0) {
      continue
    }
    if (gradeMapper[i]) {
      gradeValues.push({
        name: `${gradeMapper[i]}`,
        value: i,
      })
    } else {
      gradeValues.push({
        name: `${i}`,
        value: i,
      })
    }
  }
  return gradeValues
}

export default getgradeValues
