// @flow
import React, { useCallback, useRef } from 'react'
import ReactTags from 'react-tag-autocomplete'
import { StyledTagsField } from './TagsFieldStyles'
import type { FieldProps } from 'redux-form'

const TagAutocomplete = ({ input: { value, onChange } }: FieldProps) => {
  const reactTags = useRef()

  const newValue = !value
    ? []
    : value

  const handleDelete = i => {
    const tags = [...value]
    tags.splice(i, 1)
    onChange(tags)
  }

  const onAddition = useCallback(newTag => {
    onChange([...value, newTag])
  }, [value])

  const onValidate = tag => {
    return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(tag?.name)
  }

  return (
    <StyledTagsField>
      <ReactTags
        placeholderText="Add another email"
        ref={reactTags}
        tags={newValue}
        allowNew
        newTagText="Send invoice to:"
        suggestions={[]}
        onDelete={handleDelete}
        onAddition={onAddition}
        onValidate={onValidate}
      />
    </StyledTagsField>
  )
}

export default TagAutocomplete
