// @flow

// import { stopSubmit } from "redux-form"
import { call, fork, put, take } from 'redux-saga/effects'
// import config from "src/config"
import { SUBMIT_USER_AGREEMENT, submitUserAgreement } from 'src/redux/actions/user/user'
import { toggleUserForm } from 'src/redux/actions/resources/resources'
import http from 'src/services/http'
// types
import type { IOEffect } from 'redux-saga/effects'

function * watchRegister(): Generator<IOEffect, void, any> {
  while (true) {
    const {
      payload: { ...values },
    } = yield take(SUBMIT_USER_AGREEMENT.REQUEST)
    // const formId = config.leagueAgreementForm.id

    try {
      const { data } = yield call(http, 'api/account/agreement', {
        body: JSON.stringify({
          ...values,
        }),
        method: 'POST',
      })

      yield put(submitUserAgreement.success(data))
      yield put(toggleUserForm.success())
    } catch (e) {
      // yield put(submitUserAgreement.error(e.message))
      // yield put(stopSubmit(formId, e.errors ? e.errors : {}))
      // yield put(submitUserAgreement.success())
      yield put(toggleUserForm.success()) // TODO change when BACKEND is READY
    }
  }
}

export default [fork(watchRegister)]
