// @flow
import React from 'react'
import { Field } from 'redux-form'
import { siteUrl, siteStartUrl, validateLength, email } from 'src/helpers/formValidation'
import { normalizeWebsite } from 'src/helpers/formNormalizers'
import { FormInput } from 'src/common/components'

const SocialBlock = () => (
  <>
    <div className="form-row">
      <div className="form-group">
        <label>Public Email</label>
        <div>
          <Field
            name="email"
            id="email"
            placeholder="email@gmail.com"
            component={FormInput}
            validate={[email]}
          />
        </div>
      </div>
    </div>
    <div className="form-row">
      <div className="form-group">
        <label>Facebook</label>
        <div>
          <Field
            name="facebook"
            id="facebook"
            placeholder="https://www.facebook.com"
            component={FormInput}
            validate={[siteStartUrl, siteUrl, validateLength('website')]}
            normalize={normalizeWebsite}
          />
        </div>
      </div>
    </div>
    <div className="form-row">
      <div className="form-group">
        <label>Instagram</label>
        <div>
          <Field
            name="instagram"
            id="instagram"
            placeholder="https://www.instagram.com"
            component={FormInput}
            validate={[siteStartUrl, siteUrl, validateLength('website')]}
            normalize={normalizeWebsite}
          />
        </div>
      </div>
    </div>
    <div className="form-row">
      <div className="form-group">
        <label>Twitter</label>
        <div>
          <Field
            name="twitter"
            id="twitter"
            placeholder="https://twitter.com"
            component={FormInput}
            validate={[siteStartUrl, siteUrl, validateLength('website')]}
            normalize={normalizeWebsite}
          />
        </div>
      </div>
    </div>
  </>
)

export default SocialBlock
