// @flow
import { store } from 'src/redux/store'
import { logout, setUserBySession } from 'src/redux/actions/user/user'
import { resetBulkOrder } from 'src/redux/actions/bulkOrder/bulkOrder'
import { resetMyLeagues } from 'src/redux/actions/myLeagues/myLeagues'
const { localStorage } = window

export const setToken = (token: string) => localStorage.setItem('access_token', token)

export const getToken = () => localStorage.getItem('access_token')

export const resetToken = () => localStorage.removeItem('access_token')

export const resetUser = () => clearLocalStorage()

export const resetPreviousLocation = () => localStorage.removeItem('previous_location')

export const setPreviousLocation = (location: string, userId?: number) => {
  if (!(location && userId && location !== '/login' && !location.includes('register'))) {
    return
  }

  const locationData = getPreviousLocation()
  if (locationData && locationData.userId !== userId) {
    resetPreviousLocation()
  } else {
    localStorage.setItem('previous_location', `${userId},${location}`)
  }
}

export const getPreviousLocation = (): ?{ userId?: number, locationPath?: string } => {
  const previousLocation = localStorage.getItem('previous_location')

  if (!previousLocation) return undefined

  const [userId, locationPath] = previousLocation.split(',')

  return { userId: (userId && +userId) || undefined, locationPath }
}

export const signOutUser = async(resetUserState: (() => void)) => {
  resetToken()
  resetUser()
  if (typeof resetUserState === 'function') {
    resetUserState()
  }
  store.dispatch(resetBulkOrder.success())
  store.dispatch(resetMyLeagues.success())
}

export const logoutUser = async(resetUserState: any) => {
  await signOutUser(resetUserState)
  store.dispatch(logout.request())
}

export const loginBySession = () => {
  store.dispatch(setUserBySession.request())
}

const clearLocalStorage = () => {
  const previousLocation = localStorage.getItem('previous_location')
  localStorage.clear()
  if (previousLocation) localStorage.setItem('previous_location', previousLocation)
}
