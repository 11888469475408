// @flow
import type { SeasonsActionTypes } from 'src/redux/actions/seasons/seasonsTypes'
import type { VendorType, OrderModeType } from 'src/common/constants.types'
import { CREATE_SEASON, GET_SEASONS, EDIT_SEASON, DELETE_SEASON } from 'src/redux/actions/seasons/seasons'

import moment from 'moment-timezone'
import getPastSeasons from 'src/helpers/getPastSeasons'
import getFutureSeasons from 'src/helpers/getFutureSeasons'

type UnpaidOrdersType = {
    id: number,
    vendor: VendorType,
    mode: OrderModeType
};

export type SeasonData = {
  id: number,
  league_id: number,
  name: string,
  start_date: string,
  end_date: string,
  registration_start_date: string,
  registration_end_date: string,
  min_age: number,
  max_age: number,
  max_grade: number,
  min_grade: number,
  age_grade_range: boolean,
  has_boys: boolean,
  has_coed: boolean,
  has_girls: boolean,
  unpaid_orders: UnpaidOrdersType[]
};

export type SeasonState = {
  isLoading: boolean,
  seasonData?: SeasonData,
  seasonErrors?: ?{ [key: string]: any } | ?string,
  seasonsData: SeasonData[],
  currentSeasons: SeasonData[],
  pastSeasons: SeasonData[],
  upcomingSeasons: SeasonData[]
};

const initialState = {
  isLoading: false,
  seasonData: {},
  seasonErrors: null,
  seasonsData: [],
  currentSeasons: [],
  pastSeasons: [],
  upcomingSeasons: [],
}

const season = (state: SeasonState = initialState, action: SeasonsActionTypes): SeasonState => {
  switch (action.type) {
    case CREATE_SEASON.REQUEST:
    case EDIT_SEASON.REQUEST:
    case DELETE_SEASON.REQUEST:
    {
      return {
        ...state,
        isLoading: true,
        seasonErrors: null,
      }
    }
    case CREATE_SEASON.SUCCESS:
    case EDIT_SEASON.SUCCESS:
    {
      return {
        ...state,
        isLoading: false,
        seasonData: { ...action.payload },
        seasonErrors: null,
      }
    }

    case GET_SEASONS.SUCCESS:
    {
      const seasonsData = action.payload
      return {
        ...state,
        isLoading: false,
        seasonsData: [...seasonsData],
        currentSeasons: seasonsData.filter(season => ((moment(season.end_date).format() >= moment().format()) &&
                (moment().format() > moment(season.start_date).format()))) || [],
        pastSeasons: getPastSeasons(seasonsData) || [],
        upcomingSeasons: getFutureSeasons(seasonsData) || [],
        seasonErrors: null,
      }
    }
    case DELETE_SEASON.SUCCESS:
    {
      return {
        ...state,
        isLoading: false,
        seasonErrors: null,
      }
    }

    case CREATE_SEASON.FAILURE:
    case GET_SEASONS.FAILURE:
    case EDIT_SEASON.FAILURE:
    case DELETE_SEASON.FAILURE:
    {
      return {
        ...state,
        isLoading: false,
        seasonErrors: action.payload,
      }
    }

    default: {
      return state
    }
  }
}

export default season
