// @flow
import React, { useState, useEffect, memo } from 'react'
import { RenderIf } from 'src/common/components'
// types
import type { PaginationPropsType } from './PaginationTypes'
import { StyledPagination } from 'src/modules/ordersList/ordersListStyles'

const Pagination = (props: PaginationPropsType) => {
  const {
    onChangePage,
    limit,
    page,
    total,
  } = props
  const [data, setData] = useState({
    page: 1,
    totalPages: 0,
  })

  useEffect(() => {
    setContent()
  }, [total, page, limit])

  const setPage = (page: number) => {
    onChangePage(page)
  }

  const setContent = () => {
    setData({ ...calculateContent() })
  }

  const calculateContent = () => {
    let {
      total,
      page,
      limit,
    } = props

    // default to first page
    page = +page || 1

    // default page size is 10
    limit = limit || 10

    // calculate total pages
    const totalPages = Math.ceil(total / limit)

    return {
      page,
      totalPages,
    }
  }

  const { page: currentPage, totalPages } = data

  const showPagination = currentPage && totalPages
  return (
    <RenderIf condition={showPagination}>
      <StyledPagination
        count={totalPages}
        page={currentPage}
        onChange={(e, page) => {
          setPage(page)
        }}
        showFirstButton
        showLastButton
      />
    </RenderIf>
  )
}

export default memo<PaginationPropsType>(Pagination)
