// @flow
import { call, fork, put, takeEvery } from 'redux-saga/effects'
import { GET_FEATURE, getFeature } from 'src/redux/actions/featureManagement/featureManagement'
import { getFeature as fetchGetFeature } from 'src/services/featureManagement'

// types
import type { IOEffect } from 'redux-saga/effects'

function * watchGetFeatureStart(): Generator<IOEffect, void, any> {
  yield takeEvery(
    GET_FEATURE.REQUEST,
    getFeatureRequest,
  )
}

function * getFeatureRequest({ payload }): Generator<IOEffect, void, any> {
  try {
    const data = yield call(fetchGetFeature, payload)

    yield put(getFeature.success(data))
  } catch (e) {
    yield put(getFeature.error(e.message))
  }
}

export default [fork(watchGetFeatureStart)]
