// @flow
import styled from 'styled-components'
import { device } from 'src/common/theme/mediaQueries'

export const StyledOrderSummary = styled.div`
    .block-heading {
        background-color: rgb(233, 240, 255);
        font-size: 18px;
        padding: 18px;
        text-align: center;
    }
    
    .summary-action {
        display: flex;
        justify-content: center;
        margin-bottom: 30px;
    }
    
    .title {
        font-size: 24px;
    }
    
    .summary-block {
        margin-bottom: 50px;
    }
    
    .label {
        color: #6b6b6b;
    }
    
    .total-info {
        font-size: 18px;
    }
    
    .total-row {
        align-items: center;
        display: flex;
        margin-bottom: 10px;
        
        span {
            padding-right: 8px;
            width: 200px;
        }
        
        strong {
            font-size: 22px;
            text-align: right;
            min-width: 130px;
        }
    }

    .control-btn-row {
        padding: 12px 0;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        button {
            width: 140px;
         }

         .label {
            align-self: center;
            margin-right: auto;
         }
    }

    @media ${device.tablet} {
        .mobile-label {
            display: inline-flex;
            opacity: 0.6;
            padding-right: 6px;
        }
        
        .table-heading {
            display: none;
        }
        
        .table-cell {
            align-items: center;
            border-bottom: 0;
            display: flex;
            justify-content: center;
            text-align: center;
            width: 100%;
            
            &:last-child {
                border-bottom: 1px solid ${props => props.theme.main.colors.borderColor};
            }
            
            &.description {
                padding-bottom: 0;
                padding-top: 0;
            }
        }
    }
`

export const StyledItemInfo = styled.div`
    .mobile-label {
        display: none;
    }
    
    .item-info-table {
        display: table;
        width: 100%;
    }
    
    .table-row {
        display: table-row;
    }
    
    .table-cell {
        border-bottom: 1px solid ${props => props.theme.main.colors.borderColor};
        display: table-cell;
        padding: 12px;
    }
    
    .table-heading {
        display: table;
        font-weight: bold;
        width: 100%;
    }
    
    .table-body {
        display: table;
        width: 100%;
    }
    
    .sku {
        width:25%;
    }
    
    .quantity {
        text-align: right;
        width: 100px;
    }
    
    @media ${device.tablet} {
        .mobile-label {
            display: inline-flex;
            opacity: 0.6;
            padding-right: 6px;
        }
        
        .table-heading {
            display: none;
        }
        
        .table-cell {
            align-items: center;
            border-bottom: 0;
            display: flex;
            justify-content: center;
            text-align: center;
            width: 100%;
            
            &:last-child {
                border-bottom: 1px solid ${props => props.theme.main.colors.borderColor};
            }
            
            &.description {
                padding-bottom: 0;
                padding-top: 0;
            }
        }
    }
`

export const StyledShippingInformation = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
    
    .col {
        padding: 0 15px;
        width: 50%;
    }
    
    .shipping-row {
        align-items: center;
        border-bottom: 1px solid ${props => props.theme.main.colors.borderColor};
        display: flex;
        justify-content: space-between;
        padding: 12px 0;
        width: 100%;
        
        .value {
            text-align: right;
            word-break: break-word;
        }

        .list {
            margin: 0px;
        }
    }
    
    .summary-block {
        margin-bottom: 50px;
    }
    
    .label {
        flex: 0 0 auto;
        color: #6b6b6b;
        padding-right: 8px;
    }
    
    @media ${device.tablet} {
        .shipping-row {
            margin: 0; 
        }
        
        .col {
            width: 100%;
        }
    }
`

export const StyledPopover = styled.div`
    padding: 20px;
`

export const StyledButtons = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
   
   .team-tooltip {
       margin-bottom: 30px
   }
`
