import styled from 'styled-components'
import { device } from 'src/common/theme/mediaQueries'

export const StyledLayout = styled.div`
    display: flex;
    
    .layout-item-left {
        max-width: 295px;
        width: 25%;
    }
    
    .layout-item-right {
        flex: 1;
        background-color: #fff;
    }
    
    .agreement-link {
        
        .link {
            color: #3751a2;
            text-decoration: underline;
        }
    }
    
    .toggle-sidebar {
        align-items: center;
        border: 1px solid ${props => props.showMenu ? props.theme.main.colors.inverseColor : props.theme.main.colors.primaryColor};
        border-radius: 50%;
        cursor: pointer;
        display: none;
        height: 45px;
        justify-content: center;
        margin: 17px;
        padding-right: 3px;
        position: relative;
        width: 45px;
        z-index: 9999;
        transform: rotate(${props => props.showMenu ? '180deg' : '0deg'});
        transition: transform 0.4s ease-in-out;
        
        svg {
            
            path {
                fill: ${props => props.showMenu ? props.theme.main.colors.inverseColor : props.theme.main.colors.primaryColor};
            }
        }
    }
    
    @media ${device.tablet} {
        .toggle-sidebar {
            display: flex;
            position: absolute;
        }
        
        .layout-item-left {
            position: absolute;
            max-width: unset;
            width: auto;
        }
    }
    
    @media ${device.mobileL} {
        .toggle-sidebar {
            margin: 10px 15px;
        }
    }
`

export const StyledContent = styled.div`
    padding: 30px;
    position: relative;
    
    @media ${device.tablet} {
        padding: 15px;
    }
`
