import styled from 'styled-components'

export const StyledDatePicker = styled.div`
    input {
        border-radius: 2px;
        margin: 6px 0 4px;
        border: 1px solid ${props => props.theme.main.colors.inputBorderColor};
        background: ${props => props.disabled ? props.theme.main.colors.backgroundLight : props.theme.main.colors.inputBackgroundColor};
        color: ${props => props.theme.main.colors.inputColor};
        font-size: 14px;
        padding: 12px;
        width: 100%;
    }
`
export const StyledError = styled.span`
    font-size: 13px;
    color: ${props => props.theme.main.colors.errorColor};
`
