// @flow
import styled from 'styled-components'

export const StyledTeamTooltip = styled.div`
    display: flex;
    background: ${props => props.full_name ? 'none' : props.theme.main.colors.adminMessageBackground};
    padding: ${props => props.full_name ? 0 : '20px'};
    border: 1px solid ${props => props.full_name
  ? 'transparent'
  : props.theme.main.colors.inputColor
};
    
    .inner-text {
        padding-left: 20px;
    }

    .link {
        color: ${props => props.theme.main.colors.linkColor};
        text-decoration: underline;
    }
    
`
