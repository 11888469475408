// @flow

import React from 'react'
import { FormInput, Select, InputZipByCountry, AutoSelect } from 'src/common/components'
import { Field } from 'redux-form'
import { required, validateLength } from 'src/helpers/formValidation'
import { normalizeText } from 'src/helpers/formNormalizers'
import * as countriesConfig from 'src/config/countries'
import { COUNTRY_CODES } from 'src/common/constants'

import type { AddressBlockPropsType } from './AddressBlockTypes'

const AddressBlock = ({
  prefix,
  title,
  selectData,
  validateCounty = () => {},
  isAddressDisabled = false,
  isSelectDisabled = false,
  validateZip = true,
  withCountyRequired = false,
  isCountyDisabled = false,
  change,
  country,
  availableCountries = countriesConfig.countries,
  isCountryDisabled = false,
}: AddressBlockPropsType) => {
  return (
    <div>
      <h4>{title}</h4>
      {Array.isArray(selectData) && (
        <div className="form-row">
          <div className="form-group">
            <label>Address</label>
            <div>
              <Field
                name={`${prefix}address_id`}
                id={`${prefix}address_id`}
                component={AutoSelect}
                placeholder="Add New Address"
                selectData={[...selectData]}
                disabled={isSelectDisabled}
              />
            </div>
          </div>
        </div>
      )}
      <div className="form-row">
        <div className="form-group">
          <label className={!isAddressDisabled ? 'required' : ''}>
            Street Address
          </label>
          <div>
            <Field
              name={`${prefix}address_1`}
              id={`${prefix}address_1`}
              component={FormInput}
              placeholder="Street building"
              disabled={isAddressDisabled}
              normalize={normalizeText}
              validate={[required, validateLength('address')]}
            />
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="form-group">
          <div>
            <Field
              name={`${prefix}address_2`}
              id={`${prefix}address_2`}
              component={FormInput}
              placeholder={
                isAddressDisabled ? null : 'Apt Suite etc (optional)'
              }
              disabled={isAddressDisabled}
              normalize={normalizeText}
              validate={[validateLength('address')]}
            />
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="form-group">
          <label className={!isAddressDisabled ? 'required' : ''}>City</label>
          <div>
            <Field
              name={`${prefix}city`}
              id={`${prefix}city`}
              component={FormInput}
              disabled={isAddressDisabled}
              normalize={normalizeText}
              validate={[required, validateLength('city')]}
            />
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="form-group">
          <label className={(!isCountyDisabled && withCountyRequired) ? 'required' : ''}>County, NOT Country</label>
          <div>
            <Field
              name={`${prefix}county`}
              id={`${prefix}county`}
              component={FormInput}
              normalize={normalizeText}
              validate={withCountyRequired ? [required, validateLength('county'), validateCounty] : [validateLength('county'), validateCounty]}
              disabled={isAddressDisabled && isCountyDisabled}
            />
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="form-group">
          <div className="form-row without-margin">
            <div className="form-group">
              <label className={!isAddressDisabled ? 'required' : ''}>
                { country === COUNTRY_CODES.CA ? 'Province' : 'State' }
              </label>
              <div>
                <Field
                  id={`${prefix}state`}
                  name={`${prefix}state`}
                  component={Select}
                  selectData={country === COUNTRY_CODES.CA ? countriesConfig.provinces : countriesConfig.states}
                  validate={[required]}
                  disabled={isAddressDisabled}
                />
              </div>
            </div>
            <div className="form-group">
              <label className={!isAddressDisabled ? 'required' : ''}>Zip Code</label>
              <div>
                <InputZipByCountry
                  name={`${prefix}zip`}
                  id={`${prefix}zip`}
                  validateZip={validateZip}
                  country={country}
                  disabled={isAddressDisabled}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="form-group">
          <label>Country</label>
          <div>
            <Field
              id={`${prefix}country`}
              name={`${prefix}country`}
              component={Select} selectData={availableCountries}
              disabled={true}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddressBlock
