// @flow
import type { MyLeaguesDataItem } from 'src/redux/reducers/myLeagues'

export const sortLeaguesByActiveSeasons = (leagues: MyLeaguesDataItem[]) => {
  const distantFuture = new Date(8640000000000000)
  // $FlowFixMe
  const sortedLeagues = [...leagues].sort(function(a, b) {
    const aDate = a.active_seasons.length ? new Date(Date.parse(a.active_seasons[0].end_date)) : distantFuture
    const bDate = b.active_seasons.length ? new Date(Date.parse(b.active_seasons[0].end_date)) : distantFuture
    return aDate.getTime() - bDate.getTime()
  })
  return sortedLeagues
}
