// @flow
import * as React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import {
  getNFLTeams,
} from 'src/redux/actions/NFLTeams/NFLTeams'

const withNFLTeams = <P>(WrappedComponent: React.ComponentType<P>) => {
  class WithNFLTeamsComponent extends React.Component<P> {
    render() {
      return <WrappedComponent {...this.props} />
    }
  }
  const mapStateToProps = ({ NFLTeams }) => ({ NFLTeams })

  const mapDispatchToProps = (dispatch, ownProps) => ({
    getNFLTeams: () => dispatch(getNFLTeams.request()),
  })

  return connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withRouter(WithNFLTeamsComponent))
}

export default withNFLTeams

