// @flow
import styled from 'styled-components'
import { device } from 'src/common/theme/mediaQueries'
import { INSURANCE_STATUSES, LEAGUE_STATUSES } from 'src/common/constants'

const handleStatusColor = (status: string) => {
  switch (status) {
    case LEAGUE_STATUSES.PENDING:
      return '#ffb74d'
    case LEAGUE_STATUSES.DECLINED:
    case LEAGUE_STATUSES.INACTIVE:
    case INSURANCE_STATUSES.EXPIRED:
      return '#f44336'
    case LEAGUE_STATUSES.APPROVED:
      return '#4caf50'
    default:
      return '#4caf50'
  }
}

export const StyledCard = styled.div`
    border: 1px solid ${props => props.theme.main.colors.borderColor};
    border-left: 5px solid ${props => props.theme.main.colors.secondaryColor};
    border-radius: 3px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    cursor: ${props => props.pointer ? 'pointer' : 'auto'}
    line-height: 30px;  
  
    :hover {
        background-color: rgba(31,46,81,0.03);
    }
    
    .card-left,
    .card-center,
    .card-right {
        padding: 10px 15px;
    }
    
    .card-left {
        padding-right: 20px;
        flex: 1 1 55%;
    }
  
    .status {
      color: ${({ status }) => handleStatusColor(status)};
    }

    .insuranceStatus {
      color: ${({ insuranceStatus }) => handleStatusColor(insuranceStatus)};
    }
    
    .card-center {
        flex: 1 1 25%;
      
        svg {
          path {
            fill: #d43540;
          }
        }
    }
    
    .card-right {
        align-items: flex-end;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        min-width: 200px;
        flex: 1 1 20%;
    }
    
    .contact {
        align-items: center;
        display: flex;
        line-height: 1.2;
    }
    
    .phone {
      a {
        :hover {
          text-decoration: none;
        }
      }
    }
    
    .card-title {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        text-transform: uppercase;
    }
    
    .distance {
      margin-top: 10px;
      display: block;
    }
    
    .card-subtitle {
        display: block;
        margin-bottom: 10px;
    }
  
    .error {
      color: #f44336;
    }
    @media ${device.tablet} {
        width: 100%;
      
        .card-left {
            padding-bottom: 20px;
            flex-basis: 100%;
        }
        
        .card-right {
            justify-content: space-between;
            align-items: center;
            padding-bottom: 20px;
            flex-basis: 100%;
          
          
        }
        
        .list-contacts {
        
            .list-item {
                border-right: 0;
                padding-left: 0;
            }
        }
    }
    
    @media ${device.mobileL} {
        .card-title {
            font-size: 14px;
        }
    }
`
