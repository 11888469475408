// @flow

import { call, fork, put, take } from 'redux-saga/effects'
import { getMembersListByLeagueId, REMOVE_MEMBER, removeMember } from 'src/redux/actions/members/members'
import http from 'src/services/http'

// types
import type { IOEffect } from 'redux-saga/effects'

function * watchRemoveMember(): Generator<IOEffect, void, any> {
  while (true) {
    const {
      payload: { email, league_id },
    } = yield take(REMOVE_MEMBER.REQUEST)

    try {
      yield call(http, `api/league/member/${league_id}/${email}`, {
        method: 'DELETE',
      })

      yield put(removeMember.success())
      yield put(getMembersListByLeagueId.request({ league_id }))
    } catch (e) {
      yield put(removeMember.error(e.message))
    }
  }
}

export default [fork(watchRemoveMember)]
