// @flow
import { requestTypeCreator } from 'src/helpers'
import type { BasicRedux } from './messageCenterTypes'

export const GET_MESSAGE_CENTER = requestTypeCreator('GET_MESSAGE_CENTER')

export const getMessageCenter = {
  error: (payload: any): BasicRedux => ({ type: GET_MESSAGE_CENTER.FAILURE, payload }),
  request: (payload: any): BasicRedux => ({ type: GET_MESSAGE_CENTER.REQUEST, payload }),
  success: (payload: any): BasicRedux => ({ type: GET_MESSAGE_CENTER.SUCCESS, payload }),
}
