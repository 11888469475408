// @flow
import type { BasicRedux } from 'src/redux/actions/messageCenter/messageCenterTypes'
import { GET_RESOURCES_DASHBOARD } from 'src/redux/actions/resourcesDashboard/resourcesDashboard'

export type ResourceDashboard = {
  id: number,
  title: string,
  link: string,
  order_resource: number
};

export type ResourcesDashboardState = {
  isLoading: boolean,
  data: ResourceDashboard[],
  resourcesDashboardErrors?: ?{ [key: string]: any } | ?string
};

const initialState = {
  isLoading: false,
  data: [],
  resourcesDashboardErrors: null,
}

const messageCenter = (state: ResourcesDashboardState = initialState, action: BasicRedux): ResourcesDashboardState => {
  switch (action.type) {
    case GET_RESOURCES_DASHBOARD.REQUEST:
      return {
        ...state,
        isLoading: true,
        resourcesDashboardErrors: null,
      }
    case GET_RESOURCES_DASHBOARD.SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      }
    case GET_RESOURCES_DASHBOARD.FAILURE:
      return {
        ...state,
        isLoading: false,
        resourcesDashboardErrors: action.payload,
      }
    default:
      return state
  }
}

export default messageCenter

