// @flow

import { requestTypeCreator } from 'src/helpers'

// types
import type { GetMembersListByLeagueIdActionTypes, InviteMemberActionTypes, RemoveMemberActionType } from './membersTypes'

export const GET_MEMBERS_BY_LEAGUE_ID = requestTypeCreator('GET_MEMBERS_BY_LEAGUE_ID')
export const INVITE_MEMBER = requestTypeCreator('INVITE_MEMBER')
export const REMOVE_MEMBER = requestTypeCreator('REMOVE_MEMBER')

export const getMembersListByLeagueId = {
  error: (payload: any): GetMembersListByLeagueIdActionTypes => ({ type: GET_MEMBERS_BY_LEAGUE_ID.FAILURE, payload }),
  request: (payload: any): GetMembersListByLeagueIdActionTypes => ({ type: GET_MEMBERS_BY_LEAGUE_ID.REQUEST, payload }),
  success: (payload: any): GetMembersListByLeagueIdActionTypes => ({ type: GET_MEMBERS_BY_LEAGUE_ID.SUCCESS, payload }),
}

export const inviteMember = {
  error: (payload: any): InviteMemberActionTypes => ({ type: INVITE_MEMBER.FAILURE, payload }),
  request: (payload: any): InviteMemberActionTypes => ({ type: INVITE_MEMBER.REQUEST, payload }),
  success: (payload: any): InviteMemberActionTypes => ({ type: INVITE_MEMBER.SUCCESS, payload }),
}

export const removeMember = {
  error: (payload: any): RemoveMemberActionType => ({ type: REMOVE_MEMBER.FAILURE, payload }),
  request: (payload: any): RemoveMemberActionType => ({ type: REMOVE_MEMBER.REQUEST, payload }),
  success: (payload: any): RemoveMemberActionType => ({ type: REMOVE_MEMBER.SUCCESS, payload }),
}
