import styled from 'styled-components'

export const StyledConfirm = styled.div`
  ul {
    li {
      text-align: left;
      a {
        color: #3751a2;
        text-decoration: underline;
      }
    }
  }
`
