import styled from 'styled-components'

export const StyledError = styled.span`
    font-size: 13px;
    color: ${props => props.theme.main.colors.errorColor};
`

export const StyledDatePicker = styled.input`
    appearance: none;
    border: 1px solid ${props => props.theme.main.colors.inputBorderColor};
    background: ${props => props.theme.main.colors.inputBackgroundColor};
    font-family: ${props => props.theme.main.fonts.baseFontFamily};
    border-radius: 0;
    color: ${props => props.theme.main.colors.inputColor};
    text-transform: uppercase;
    font-size: 14px;
    margin: 6px 0 4px;
    max-height: 43px;
    padding: 12px;
`
