// @flow
import { requestTypeCreator } from 'src/helpers'

// types
import type { ShowToastActionTypes } from './uiTypes'

export const SHOW_TOAST = requestTypeCreator('SHOW_TOAST')
export const HIDE_TOAST = requestTypeCreator('HIDE_TOAST')
export const TOGGLE_CONFIRM = 'TOGGLE_CONFIRM'

export const showToast = {
  success: (payload: any): ShowToastActionTypes => ({ type: SHOW_TOAST.SUCCESS, payload }),
  hide: (payload: any): ShowToastActionTypes => ({ type: HIDE_TOAST.SUCCESS, payload }),
}

export const toggleConfirm = {
  success: (payload: any): ShowToastActionTypes => ({ type: TOGGLE_CONFIRM, payload }),
}
