// @flow
import { call, fork, put, take } from 'redux-saga/effects'
import {
  GET_ORDER,
  getOrder,
  updateOrder,
  GET_ORDER_TRACKING_NUMBER,
  getOrderTrackingNumber,
  downloadOrderPDF,
  DOWNLOAD_ORDER_PDF,
} from 'src/redux/actions/bulkOrder/bulkOrder'
import http from 'src/services/http'
import { history } from 'src/services/history'
import { showToast } from 'src/redux/actions/ui/ui'

// types
import type { IOEffect } from 'redux-saga/effects'
import { DOWNLOAD_MATRIX_TEAM_ORDER, downloadMatrixTeamOrder } from 'src/redux/actions/teamOrder'

function * watchGetOrder(): Generator<IOEffect, void, any> {
  while (true) {
    const action = yield take(GET_ORDER.REQUEST)
    const { order_id, leagueId } = action.payload

    try {
      const { data } = yield call(http, `api/order/${leagueId}/${order_id}`, {
        method: 'GET',
      })

      yield put(getOrder.success(data))
      if (!data.order.tracking_number) {
        yield put(getOrderTrackingNumber.request({ order_id }))
      }
    } catch (e) {
      yield call(history.push, '/404')
      yield put(getOrder.error(e.message))
    }
  }
}

function * watchGetOrderTrackingNumber(): Generator<IOEffect, void, any> {
  while (true) {
    const action = yield take(GET_ORDER_TRACKING_NUMBER.REQUEST)
    const { order_id } = action.payload

    try {
      const { data } = yield call(http, `api/order/${order_id}/shipping-status`, {
        method: 'GET',
      }, {
        disableToastr: true,
      })

      // yield put(getOrderTrackingNumber.success())
      yield put(updateOrder.success(data))
    } catch (e) {
      // yield put(getOrderTrackingNumber.error(e.message))
    }
  }
}

function * watchOrderPdfDownload(): Generator<IOEffect, void, any> {
  while (true) {
    const action = yield take(DOWNLOAD_ORDER_PDF.REQUEST)
    const order_id = action.payload

    try {
      const { file, filename } = yield call(http, `api/order/${order_id}/pdf-summary`, {
        method: 'GET',
      }, {
        responseIsFile: true,
      })

      const a = document.createElement('a')
      a.href = URL.createObjectURL(file)
      a.setAttribute('download', filename)
      a.click()

      yield put(downloadOrderPDF.success())
    } catch (e) {
      yield put(downloadOrderPDF.error())
    }
  }
}

function * watchMatrixTeamOrderDownload(): Generator<IOEffect, void, any> {
  while (true) {
    const action = yield take(DOWNLOAD_MATRIX_TEAM_ORDER.REQUEST)
    const order_id = action.payload

    try {
      const { data: { link } } = yield call(http, `api/order/${order_id}/team-summary`, {
        method: 'GET',
      })

      if (!link) {
        throw new Error('Link for download not found!')
      }

      window.open(link, '_target')

      yield put(downloadMatrixTeamOrder.success())
    } catch (e) {
      yield put(showToast.success({ title: e.message, messageType: 'error' }))
      yield put(downloadMatrixTeamOrder.error())
    }
  }
}

export default [
  fork(watchGetOrder),
  fork(watchGetOrderTrackingNumber),
  fork(watchOrderPdfDownload),
  fork(watchMatrixTeamOrderDownload),
]
