// @flow
import type { BulkOrderActionTypes } from 'src/redux/actions/bulkOrder/bulkOrderTypes'
import {
  RESET_BULK_ORDER,
  GET_ORDER,
  UPDATE_ORDER,
  DOWNLOAD_ORDER_PDF,
  CANCEL_PENDING_ORDER,
  DOWNLOAD_ORDER_INVOICE_PDF,
} from 'src/redux/actions/bulkOrder/bulkOrder'
import type { OrderType, OrderedItemType } from 'src/common/constants.types'
import type { ItemsSummaryType } from 'src/redux/reducers/makeOrder'

export type RefundItemType = {
  source_id: string,
  order_id: number,
  type: string,
  amount: number,
  currency: string,
  card_type: string,
  card_number: string,
  status: string,
  source: string,
  created_at: string,
  updated_at: string
};
export type RefundOrderType = {
  refund_amount: string,
  refundsData: RefundItemType[]
};

export type PaymentOrderType = {
  card: ?string,
  card_type: ?string,
  type: ?string,
  status: ?string,
  amount: ?string,
  description: ?string,
  descriptor: ?string,
  type_description: ?string,
  invoice_number: ?string,
  created_at: string
};

export type BulkOrderState = {
  isLoading: boolean,
  order: ?OrderType,
  payments: PaymentOrderType[],
  refunds: ?RefundOrderType,
  items: OrderedItemType[],
  summary: ItemsSummaryType
};

const initialState = {
  isLoading: false,
  order: null,
  payments: [],
  refunds: {},
  items: [],
  summary: {
    total_amount: {},
    total_cost: {},
    additional: {
      brown_balls: null,
      blue_balls: null,
      mini_balls: null,
      belts: null,
      junior_premium_balls: null,
      pee_wee_premium_balls: null,
      cost: 0,
    },
    custom_info: {
      sublimated_jersey_amount: 0,
      core_jersey_amount: 0,
      core_shorts_amount: 0,
      sublimated_shorts_amount: 0,
    },
  },
  balance: null,
}

const bulkOrder = (state: BulkOrderState = initialState, action: BulkOrderActionTypes): BulkOrderState => {
  switch (action.type) {
    case CANCEL_PENDING_ORDER.REQUEST:
    case GET_ORDER.REQUEST:
    case DOWNLOAD_ORDER_PDF.REQUEST:
    case DOWNLOAD_ORDER_INVOICE_PDF.REQUEST:
    {
      return {
        ...state,
        isLoading: true,
      }
    }

    case GET_ORDER.SUCCESS:
    {
      return ({
        ...state,
        isLoading: false,
        order: { ...action.payload.order },
        payments: [...action.payload.payments],
        refunds: { ...action.payload.refunds },
        items: [...action.payload.items],
        summary: { ...action.payload.summary },
      })
    }

    case UPDATE_ORDER.SUCCESS:
    {
      return ({
        ...state,
        order: {
          ...state.order,
          ...action.payload,
        },
      })
    }

    case GET_ORDER.FAILURE:
    {
      return { ...state, isLoading: false, bulkOrderErrors: action.payload }
    }

    case RESET_BULK_ORDER: {
      return { ...initialState, ...action.payload }
    }

    case CANCEL_PENDING_ORDER.SUCCESS:
    case CANCEL_PENDING_ORDER.FAILURE:
    case DOWNLOAD_ORDER_PDF.SUCCESS:
    case DOWNLOAD_ORDER_PDF.FAILURE:
    case DOWNLOAD_ORDER_INVOICE_PDF.SUCCESS:
    case DOWNLOAD_ORDER_INVOICE_PDF.FAILURE:
    {
      return {
        ...state,
        isLoading: false,
      }
    }

    default: {
      return state
    }
  }
}

export default bulkOrder
