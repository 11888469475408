// @flow
import * as React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import {
  resetBulkOrder,
  getOrder,
  downloadOrderPDF,
  cancelPendingOrder,
  downloadOrderInvoicePDF,
} from 'src/redux/actions/bulkOrder/bulkOrder'
import { downloadMatrixTeamOrder } from 'src/redux/actions/teamOrder'

const withBulkOrder = <P>(WrappedComponent: React.ComponentType<P>) => {
  class WithBulkOrderComponent extends React.Component<P> {
    render() {
      return <WrappedComponent {...this.props} />
    }
  }
  const mapStateToProps = ({ bulkOrder }) => ({ bulkOrder })

  const mapDispatchToProps = (dispatch, ownProps) => ({
    resetBulkOrder: values => {
      dispatch(resetBulkOrder.success())
    },
    getOrder: () => dispatch(getOrder.request({ order_id: +ownProps.match.params.orderId, leagueId: +ownProps.match.params.leagueId })),
    downloadOrderPDF: orderId => dispatch(downloadOrderPDF.request(orderId)),
    cancelPendingOrder: ({ orderId, leagueId }) => dispatch(cancelPendingOrder.request({ orderId, leagueId })),
    downloadMatrixTeamOrder: orderId => dispatch(downloadMatrixTeamOrder.request(orderId)),
    downloadOrderInvoicePDF: id => dispatch(downloadOrderInvoicePDF.request(id)),
  })

  return connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withRouter(WithBulkOrderComponent))
}

export default withBulkOrder

