// @flow

import React from 'react'
import { StyledError } from './autoSelectStyles'
import type { SelectPropsType } from './AutoSelectTypes'
import Select from 'react-select'

const AutoSelect = ({
  label,
  // $FlowFixMe
  input,
  selectData,
  placeholder,
  name,
  id,
  meta: { touched, error },
  withEmptyValue = true,
  ...rest
}: SelectPropsType) => {
  let labelBlock = null
  let errorBlock = null

  if (label) {
    labelBlock = (
      <label>
        {label}
      </label>
    )
  }

  if (touched && error) {
    errorBlock = (
      <div>
        <StyledError className="error-message">{error}</StyledError>
      </div>
    )
  }

  const prepareOptions = selectData => {
    const newAddress = {
      value: '',
      label: 'Add new address',
    }
    return [newAddress, ...selectData.map(({ name, address_1, id, value, ...rest }) => {
      return {
        value: id || value,
        label: name || address_1,
        rest,
      }
    })]
  }

  const showPlaceholder = (selectData, value) => {
    if (!value) return placeholder || 'Select...'

    return selectData.find(el => el.id === value)?.address_1
  }

  return (
    <div>
      {labelBlock}
      <Select
        {...input}
        {...rest}
        id={id}
        name={name}
        placeholder={showPlaceholder(selectData, input.value)}
        value={input.value}
        options={prepareOptions(selectData)}
        onChange={({ value }) => input.onChange(value)}
        onBlur={() => input.onBlur(input.value)}
        styles={{
          control: styles => ({ ...styles, borderRadius: 0, margin: '5px 0 0 0' }),
        }}
      />
      {errorBlock}
    </div>
  )
}

export default AutoSelect
