import styled from 'styled-components'
import { device } from 'src/common/theme/mediaQueries'

export const StyledSizesBlock = styled.div`
    display: flex;
    
    > *:first-child {
        width: 33.33%;
    }
    
    > *:last-child {
        width: 66.66%;
    }

    .items-wrapper {
        display: flex;
        width: 100%;
        @media ${device.tabletL} {
            flex-direction: column;
        }
        .left-items, .right-items {
            width: 50%;
            margin-left: 10px;
            margin-right: 10px;
            @media ${device.tabletL} {
                width: 100%;
                margin: 0;
            }
        }
    }

    .type-wrapper {
        display: flex;
        flex-wrap: wrap;
    }

    .type-title {
        width: 100%;
        margin-bottom: 0;
    }

    .new-design {
        background: ${props => props.theme.main.colors.adminMessageBackground};
    }

    .sizes {
        display: flex;
        flex-direction: row;
    }

    .sizes-row {
        flex: 1;
        margin: 10px 0 20px;
        max-width: 500px;
        padding: 0 20px;
        
        .title {
            font-size: 16px;
        }
        
        .sizes-list {
            display: flex;
            flex-wrap: wrap;

            .list-item {
                width: 100%;
                margin-bottom: 15px;
            }
        }
    }
    
    @media ${device.tabletL} {
        align-items: center;
        flex-direction: column;
        
        .sizes-row {
            width: 100%;
            
            &:first-child {
                border-bottom: 1px solid ${props => props.theme.main.colors.borderColor};
                border-right: 0;
            }
            
            .list-item {
                display: flex;
                flex-direction: column;
                align-items: center;
            }
            
            .title {
                text-align: center;
            }
        }
    }
    
    @media ${device.mobileL} {
        .sizes-list {
            align-items: center;
            flex-direction: column;
        }
        
        .sizes-row {
            margin-bottom: 20px;
            
             .sizes-list {
                
                .list-item {
                    width: 100%;
                }
            }
        }
    }
`

export const StyledAction = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    
    .btn-delete {
        appearance: none;
        background-color: transparent;
        border: 0;
        color: ${props => props.theme.main.colors.secondaryColor};
        display: flex;
        font-size: 14px;
        font-weight: 300;
        margin-right: 20px;
        padding: 0;
        
        :hover {
            filter: brightness(1.2);
        }
        
        svg {
            margin-right: 4px;
        }
        
        span {
            border-bottom: 1px solid ${props => props.theme.main.colors.secondaryColor};
        }
    }
    
    @media ${device.mobileL} {
        flex-direction: column;
        
        .btn-delete {
            margin-bottom: 20px;
        }
    }
`
