// @flow

import { stopSubmit } from 'redux-form'
import { call, fork, put, take } from 'redux-saga/effects'
import config from 'src/config'
import { SET_USER, SET_USER_BY_SESSION, setUser, resetUser } from 'src/redux/actions/user/user'
import { toggleConfirm } from 'src/redux/actions/ui/ui'
import { fetchLeaguesPersonal } from 'src/services/myLeagues'
import http from 'src/services/http'
import { signOutUser, setToken, getPreviousLocation } from 'src/services/user'
import { history } from 'src/services/history'
import { LEAGUE_STATUSES } from 'src/common/constants'
// types
import type { IOEffect } from 'redux-saga/effects'

function * notifyMissingWebsiteLeagues(): Generator<IOEffect, void, any> {
  const leaguesPersonal = yield call(fetchLeaguesPersonal)
  const missingWebsiteLeagues = leaguesPersonal.filter(({ website, approved }) => !website && LEAGUE_STATUSES.APPROVED === approved)

  if (missingWebsiteLeagues.length) {
    yield put(toggleConfirm.success({
      show: true,
      data: missingWebsiteLeagues,
    }))
  }
}

function * watchLogin(): Generator<IOEffect, void, any> {
  while (true) {
    const {
      payload: { email, password, keepLoggedIn = false, redirectCb },
    } = yield take(SET_USER.REQUEST)
    const formId = config.loginForm.id

    try {
      const { data: { token, user } } = yield http('login', {
        body: JSON.stringify({
          password,
          email,
          keepLoggedIn,
        }),
        method: 'POST',
      })

      const resetUserState = yield put(resetUser.success())
      yield signOutUser(resetUserState)
      setToken(token)

      yield put(setUser.success(user))

      yield call(notifyMissingWebsiteLeagues)

      const redirectPath = getPreviousLocation()?.locationPath || '/'
      yield call(redirectCb, redirectPath)
    } catch (e) {
      yield put(setUser.error(e.message))
      yield put(stopSubmit(formId))
      yield call(redirectCb, '/login')
    }
  }
}

function * watchLoginBySession(): Generator<IOEffect, void, any> {
  while (true) {
    yield take(SET_USER_BY_SESSION.REQUEST)
    try {
      const { data: { token, user, connectedByAdmin } } = yield http('api/session-login', {
        method: 'POST',
      })

      yield call(history.push, '/login')

      const resetUserState = yield put(resetUser.success())
      yield signOutUser(resetUserState)
      setToken(token)

      yield put(setUser.success({ ...user, connectedByAdmin }))
      const redirectPath = '/'
      yield call(history.push, redirectPath)
    } catch (e) {
      yield put(setUser.error(e.message))
    }
  }
}

export default [
  fork(watchLogin),
  fork(watchLoginBySession),
]
