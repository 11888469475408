// @flow
import * as React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import {
  getMyLeagues,
  createLeague,
  getLeaguesOrganizations,
  getLeague,
  editLeague,
  // setLeague, // TODO: not found where used maybe need remove
  getSurveyQuestions,
  getLeagueInsurance,
  validateAddress,
} from 'src/redux/actions/myLeagues/myLeagues'

const withMyLeagues = <P>(WrappedComponent: React.ComponentType<P>) => {
  class WithMyLeaguesComponent extends React.Component<P> {
    render() {
      return <WrappedComponent {...this.props} />
    }
  }
  const mapStateToProps = ({ myLeagues }) => ({ myLeagues })

  const mapDispatchToProps = (dispatch, ownProps) => ({
    // setLeague: value => dispatch(setLeague.request({ ...value, redirectCb: ownProps.history.push })), //TODO: never used ?
    getMyLeagues: () => dispatch(getMyLeagues.request()),
    createLeague: values => dispatch(createLeague.request({ ...values, redirectCb: ownProps.history.push })),
    getLeaguesOrganizations: () => dispatch(getLeaguesOrganizations.request()),
    getLeague: data => dispatch(getLeague.request({ leagueId: ownProps.match.params.leagueId, ...data })),
    editLeague: values => dispatch(editLeague.request({ ...values, league_id: ownProps.match.params.leagueId })),
    getSurveyQuestions: () => dispatch(getSurveyQuestions.request()),
    getLeagueInsurance: () => {
      dispatch(getLeagueInsurance.request({ leagueId: ownProps.match.params.leagueId }))
    },
    validateAddress: data => dispatch(validateAddress.request(data)),
  })

  return connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withRouter(WithMyLeaguesComponent))
}

export default withMyLeagues

