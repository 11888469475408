// @flow
import { call, fork, put, take } from 'redux-saga/effects'
import { GET_MESSAGE_CENTER, getMessageCenter } from 'src/redux/actions/messageCenter/messageCenter'
import http from 'src/services/http'
import { showToast } from 'src/redux/actions/ui/ui'
// types
import type { IOEffect } from 'redux-saga/effects'

function * watchGetMessageCenter(): Generator<IOEffect, void, any> {
  while (true) {
    yield take(GET_MESSAGE_CENTER.REQUEST)

    try {
      const { data } = yield call(http, 'api/message-center', {
        method: 'GET',
      })

      yield put(getMessageCenter.success(data?.body || ''))
    } catch (e) {
      yield put(getMessageCenter.error(e.message))
      yield put(showToast.success({ title: e.message, messageType: 'error' }))
    }
  }
}

export default [
  fork(watchGetMessageCenter),
]
