// @flow

import { requestTypeCreator } from 'src/helpers'

// types
import type { GetAddressesActionTypes } from './addressesTypes'

export const GET_ADDRESSES = requestTypeCreator('GET_ADDRESSES')

export const getAddresses = {
  error: (payload: any): GetAddressesActionTypes => ({ type: GET_ADDRESSES.FAILURE, payload }),
  success: (payload: any): GetAddressesActionTypes => ({ type: GET_ADDRESSES.SUCCESS, payload }),
}
