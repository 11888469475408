import React from 'react'

const MyInsurancesIcon = () => {
  return (
    <svg fill="#fff" width="20px" height="20px" viewBox="0 0 1000 1000">
      <g>
        <path d="M724.5,127.6h-59.6c-12.3-12.1-29.1-19.6-47.7-19.6h-10.8c-5.6-55-51.3-98-107.5-98c-56.2,0-101.9,43-107.5,98h-8.4c-18.6,0-35.4,7.5-47.7,19.6h-59.6c-53.9,0-117.1,43.9-117.1,98V892c0,54.1,63.2,98,117.1,98h448.9c53.9,0,117.1-43.9,117.1-98V225.6C841.6,171.5,778.4,127.6,724.5,127.6 M498.8,63.4c30.6,0,55.3,24.9,55.3,55.6c0,30.7-24.8,55.6-55.3,55.6c-30.6,0-55.4-24.9-55.4-55.6C443.4,88.3,468.2,63.4,498.8,63.4 M783,833.2c0,54.1-43.7,98-97.6,98H314.6c-53.9,0-97.6-43.9-97.6-98V264.8c0-54.1,43.7-98,97.6-98h1c-0.4,3.2-1,6.4-1,9.8c0,37.9,30.6,68.6,68.3,68.6h234.2c37.7,0,68.3-30.7,68.3-68.6c0-3.4-0.5-6.6-1-9.8h1c53.9,0,97.6,43.9,97.6,98V833.2z M695.2,617.6H304.8c-16.2,0-29.3,13.2-29.3,29.4c0,16.2,13.1,29.4,29.3,29.4h390.4c16.2,0,29.3-13.2,29.3-29.4C724.5,630.8,711.4,617.6,695.2,617.6 M695.2,323.6H304.8c-16.2,0-29.3,13.2-29.3,29.4c0,16.2,13.1,29.4,29.3,29.4h390.4c16.2,0,29.3-13.2,29.3-29.4C724.5,336.8,711.4,323.6,695.2,323.6 M695.2,421.6H304.8c-16.2,0-29.3,13.2-29.3,29.4c0,16.2,13.1,29.4,29.3,29.4h390.4c16.2,0,29.3-13.2,29.3-29.4C724.5,434.8,711.4,421.6,695.2,421.6 M695.2,519.6H304.8c-16.2,0-29.3,13.2-29.3,29.4c0,16.2,13.1,29.4,29.3,29.4h390.4c16.2,0,29.3-13.2,29.3-29.4C724.5,532.8,711.4,519.6,695.2,519.6 M461,718.9c-65.9,33.9-107.6,135.5-107.6,135.5l-25.7-50l-53,32.3c22.5,8.1,54.6,33.9,81.9,67.7c19.3-35.5,78.7-108.1,107.6-114.5C453,764.1,459.4,743.1,461,718.9"/>
      </g>
    </svg>
  )
}

export default MyInsurancesIcon
