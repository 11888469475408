// @flow

import React from 'react'
import type { TeamDataType } from 'src/common/components/teamTooltip/TeamTooltipTypes'
import { StyledPopoverContent } from './PopoverContentStyles'

const PopoverContent = ({ full_name }: TeamDataType) => {
  const renderTeamText = full_name => (
    <div>
      <div className="heading">
        Core Jersey are not available for the {full_name}. Only add on merchandise is only available for purchase.
      </div>
      <div>Performance Jerseys for the {full_name} are available for purchase in the bulk performance jersey order flow.</div>
    </div>
  )

  const renderText = () => {
    return (
      <div >
        {renderTeamText(full_name)}
      </div>
    )
  }

  return <StyledPopoverContent>{renderText()}</StyledPopoverContent>
}

export default PopoverContent
