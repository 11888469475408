// @flow
import styled from 'styled-components'
export const StyledEmptyBlock = styled.div`
    border: 1px solid rgba(212, 53, 64, 0.7);
    padding: 15px;
    
    .title {
        margin-bottom: 5px;
    }
    
    .subtitle {
        margin: 0 auto;
        max-width: 500px;
    }
    
    .button-wrapper {
        margin-top: 15px;
    }
`

export const StyledInfoBlock = styled.div`
    background-color: #fff;
    box-shadow: 3px 6px 40px 0 rgba(14,28,69,0.1);
    padding: 15px;
    
    .empty-insurance {
        background-color: rgba(250, 200, 61, 1);
        
        a {
          text-decoration: underline;
          
          :hover {
            text-decoration: none;
          }
        }
    }
`
