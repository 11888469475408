// @flow

import type { ShowToastActionTypes } from 'src/redux/actions/ui/uiTypes'
import type { MyLeaguesDataItem } from 'src/redux/reducers/myLeagues'
import { SHOW_TOAST, HIDE_TOAST, TOGGLE_CONFIRM } from 'src/redux/actions/ui/ui'

export type ToastData = {
    title: string,
    messageType: string
};

export type ConfirmData = {
    show: boolean,
    data: MyLeaguesDataItem[]
};

export type UiState = {
  toast?: ToastData,
  confirmData?: ConfirmData
};

const initialState = {
  toast: {},
  confirmData: undefined,
}

const ui = (state: UiState = initialState, action: ShowToastActionTypes): UiState => {
  switch (action.type) {
    case SHOW_TOAST.SUCCESS:
    {
      return {
        ...state,
        toast: { ...action.payload },
      }
    }
    case HIDE_TOAST.SUCCESS:
    {
      return {
        ...state,
        toast: {},
      }
    }
    case TOGGLE_CONFIRM:
    {
      return {
        ...state,
        confirmData: { ...action.payload },
      }
    }
    default:
      return state
  }
}

export default ui
