// @flow
import { requestTypeCreator } from 'src/helpers'
import type { BasicRedux } from './resourcesDashboardTypes'

export const GET_RESOURCES_DASHBOARD = requestTypeCreator('GET_RESOURCES_DASHBOARD')

export const getResourcesDashboard = {
  error: (payload: any): BasicRedux => ({ type: GET_RESOURCES_DASHBOARD.FAILURE, payload }),
  request: (payload: any): BasicRedux => ({ type: GET_RESOURCES_DASHBOARD.REQUEST, payload }),
  success: (payload: any): BasicRedux => ({ type: GET_RESOURCES_DASHBOARD.SUCCESS, payload }),
}
