// @flow

import { fork, put, take } from 'redux-saga/effects'
import {
  SUBMIT_ACH_ACCOUNT_DETAILS,
  submitACHAccountDetails,
  saveACHAccountDetails,
} from 'src/redux/actions/payments/payments'
import { stopSubmit } from 'redux-form'
import config from 'src/config'
import { PAYMENT_TEXT } from 'src/config/ACHConfig'
// types
import type { IOEffect } from 'redux-saga/effects'

const matchConfig = [
  {
    matchValue: 'zipCode',
    value: 'postalCode',
  },
  {
    matchValue: 'input',
    value: 'routingNumber',
  },
]

const shapePaymentError = e => {
  const { originalError } = e?.details?.originalError?.details || {}
  if (Array.isArray(originalError)) {
    // eslint-disable-next-line array-callback-return
    return originalError.reduce((acc, cur) => {
      const { message } = cur || {}
      if (message) {
        const [left, right] = message.split(':')
        if (left && right) {
          const field = left.match(/'([^']+)'/)[0].replace(/['"]+/g, '')
          // braintree returns errors for 'postalCode' as 'zipCode'
          const { value } = matchConfig.find(({ matchValue }) => matchValue === field) || {}
          return {
            ...acc,
            // $FlowFixMe
            ...(value && { [value]: right }),
          }
        } else {
          return {
            ...acc,
            globalError: message,
          }
        }
      }
    }, {})
  }
}

function * watchSubmitACHAccountDetails(): Generator<IOEffect, void, any> {
  while (true) {
    const {
      payload: {
        bankDetails,
        ACHInstance,
      },
    } = yield take(SUBMIT_ACH_ACCOUNT_DETAILS.REQUEST)
    const formId = config.ACHPaymentForm.id

    try {
      const tokenizedPayload = yield ACHInstance.tokenize({
        bankDetails, // or bankLogin: bankLogin
        mandateText: PAYMENT_TEXT,
      })

      yield put(submitACHAccountDetails.success())
      yield put(saveACHAccountDetails.request({ tokenizedPayload }))
      yield
    } catch (e) {
      const errors = shapePaymentError(e)
      yield put(submitACHAccountDetails.error(e.message))
      yield put(stopSubmit(formId, errors))
    }
  }
}

export default [fork(watchSubmitACHAccountDetails)]
