// @flow
import React from 'react'
// styles
import { StyledSizeInput, StyledError } from './sizeInputStyles'

// types
import type { SizeInputTypes } from './SizeInputTypes'

const SizeInput = (props: SizeInputTypes) => {
  const {
    input,
    meta: { error },
    data_meta,
    inventory_limited,
  } = props
  let errorBlock = null

  if (error) {
    errorBlock = (
      <div>
        <StyledError className="error-message">{error}</StyledError>
      </div>
    )
  }

  const isNotValidInput = (inventory_limited && input.value > props.availableNumber) || (inventory_limited && props.availableNumber === 0)

  return (
    <>
      <StyledSizeInput>
        {props.description && <span className="label description">{props.description}</span>}
        {props.size && <span className="label">{props.size}</span>}
        <div className="input-wrapper">
          <input type="text" min="0" max={inventory_limited ? props.availableNumber : undefined} data-meta={data_meta} placeholder="0" disabled={(+props.availableNumber === 0) && !(+input.value !== 0)} {...input}/>
          <span className={`available-number ${isNotValidInput ? 'error' : 'success'}`}>
            {inventory_limited && props.availableNumber !== 0 && props.availableNumber}
            {inventory_limited && props.availableNumber === 0 && 'Out of stock'}
          </span>
        </div>

      </StyledSizeInput>
      {errorBlock}
    </>
  )
}

export default SizeInput
