// @flow
import { VENDOR, ITEM_TYPES } from 'src/common/constants'

import { addToMappedArray } from 'src/helpers/shapeOrder'

type CheckFLowType = {
  default_vendor: string,
  vendor: string
};

export const FORM_TYPES = {
  CORE: 'CORE',
  SUBL: 'SUBL',
}

export const ORDER_TYPES = {
  BULK: 'BULK',
  TEAM: 'TEAM',
}

export const STEPS = {
  CHOOSE_TEAMS: 'choose-teams',
  UNIFORM: 'uniform',
  ADDITIONAL: 'additional',
  ADDRESS_INFO: 'address-info',
  ADDRESS_VALIDATION: 'address-validation',
  SHIPPING: 'shipping',
  ORDER_SUMMARY: 'order-summary',
  PAYMENT: 'payment',
}

export const STEPS_ORDER = [
  STEPS.CHOOSE_TEAMS,
  STEPS.UNIFORM,
  STEPS.ADDITIONAL,
  STEPS.ADDRESS_INFO,
  STEPS.ADDRESS_VALIDATION,
  STEPS.SHIPPING,
  STEPS.ORDER_SUMMARY,
  STEPS.PAYMENT,
]

export const STEP_VALUE = {
  [STEPS.CHOOSE_TEAMS]: 1,
  [STEPS.UNIFORM]: 1.5,
  [STEPS.ADDITIONAL]: 2,
  [STEPS.ADDRESS_INFO]: 3,
  [STEPS.ADDRESS_VALIDATION]: 3,
  [STEPS.SHIPPING]: 4,
  [STEPS.ORDER_SUMMARY]: 5,
  [STEPS.PAYMENT]: 6,
}

export const isMatrixTeamOrder = (order: any = {}) => order.order_mode === ORDER_TYPES.TEAM

export const TEAMS_WITHOUT_CORE_JERSEYS = {
  WAS: 'WAS',
}

export const hasAccessToPage = (cur_step: string, makeOrder: any) => {
  const { items: { forms, [ITEM_TYPES.BELT]: belts, [ITEM_TYPES.FOOTBALL]: balls } } = makeOrder
  switch (cur_step) {
    case STEPS.UNIFORM:
      return !!forms.find(f => f.form_type === FORM_TYPES.CORE || f.form_type === FORM_TYPES.SUBL)
    case STEPS.ADDITIONAL:
      return belts.length || balls.length
    default:
      return true
  }
}

export const getNextStep = (cur_step: string, makeOrder: any, stepOrders: string[] = STEPS_ORDER) => {
  for (let index = stepOrders.indexOf(cur_step) + 1; index < stepOrders.length; index++) {
    const step = stepOrders[index]
    if (hasAccessToPage(step, makeOrder)) {
      return step
    }
  }
  return stepOrders[0]
}

export function canUserEditOrder(order: any): boolean {
  const notEditableStatuses = ['PAID', 'REFUNDED', 'PENDING']

  if (order.order_mode === 'VIRTUAL' || notEditableStatuses.includes(order.payment_status)) {
    return false
  }

  return true
}

export const prepareDataForms = (items: any, type: string) => {
  const data = items.reduce((acc, cur) => addToMappedArray(acc, cur.form_type, cur), {})
  return data[type]
}

export const ORDER_OPTIONS = {
  [VENDOR.BDA]: [{
    value: VENDOR.BDA,
    title: 'Order Core Jerseys',
  }, {
    value: VENDOR.AUGUSTA,
    title: 'Order Performance Jerseys Or Shorts',
  }],
  [VENDOR.AUGUSTA]: [{
    value: VENDOR.AUGUSTA,
    title: 'Create Order',
  }],
  [VENDOR.MATRIX]: [{
    value: VENDOR.MATRIX,
    title: 'Create Order',
  }, {
    value: VENDOR.AUGUSTA,
    title: 'Order Performance Jerseys Or Shorts',
  }],
}

export const hasNewDesign = ({ orderedItems, NFLTeamsMapped, vendor, default_vendor, order_mode }: any) => {
  // TODO: temporary not using not design for orders created by Matrix add another flag on API later
  if (order_mode === ORDER_TYPES.TEAM) {
    return false
  }

  let res = false
  for (const item of orderedItems) {
    const currentTeam = NFLTeamsMapped[item.team_id]
    if (currentTeam?.new_design && isMatrixFlow({ vendor, default_vendor })) {
      res = true
      break
    }
  }
  return res
}

export const isMatrixFlow = ({ default_vendor, vendor }: CheckFLowType) => default_vendor === VENDOR.MATRIX && vendor === VENDOR.MATRIX
export const isAugustaFlow = ({ default_vendor, vendor }: CheckFLowType) => default_vendor === VENDOR.AUGUSTA && vendor === VENDOR.AUGUSTA

export const showTeamTooltip = ({ show_popup, default_vendor, vendor }: { show_popup: boolean, default_vendor: string, vendor: string }) => {
  return show_popup && (isMatrixFlow({ default_vendor, vendor }) || isAugustaFlow({ default_vendor, vendor }))
}
