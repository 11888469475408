// @flow
import * as React from 'react'
import { connect } from 'react-redux'

import withUser from '../withUser'

type UserIncompleteProfilePageBlockProps = {
  userHasCompletedProfile: boolean,
  history: {
    replace: (path: string) => void
  }
};

const withUserIncompleteProfilePageBlock = <P: UserIncompleteProfilePageBlockProps>(WrappedComponent: React.ComponentType<P>) => {
  class WithUserComponent extends React.Component<P> {
    componentDidMount() {
      if (!this.props.userHasCompletedProfile) {
        this.props.history.replace('/edit-profile')
      }
    }

    render() {
      return <WrappedComponent {...this.props} />
    }
  }
  const mapStateToProps = () => ({})

  const mapDispatchToProps = (dispatch, ownProps) => ({})

  return connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withUser(WithUserComponent))
}

export default withUserIncompleteProfilePageBlock
