// @flow
import React, { Component } from 'react'
import { ArrowDown, SquareCheckIcon, EmptySquareIcon } from 'src/assets/icons'
// styles
import { StyledAccordion } from './accordionStyles'

// types
import type { AccordionPropsTypes, AccordionStateTypes } from './AccordionTypes'

class Accordion extends Component<AccordionPropsTypes, AccordionStateTypes> {
  state = {
    openAccordion: false,
    hasError: true,
  }

  toggleAccordion = (flag: ?boolean) => {
    let newState = !this.state.openAccordion
    if (typeof flag === 'boolean') {
      newState = flag
    }
    this.setState({
      openAccordion: newState,
    })
  }

  toggleStatus = (value: boolean) => {
    this.setState({
      hasError: value,
    })
  }

  render() {
    const childrenWithProps = React.Children.map(this.props.children, child =>
      React.cloneElement(child, { toggleAccordion: this.toggleAccordion, toggleStatus: this.toggleStatus, hasError: this.state.hasError }),
    )
    const { sizesList, amount, warning } = this.props
    return (
      <StyledAccordion openAccordion={this.state.openAccordion}>
        <div className={this.state.hasError ? 'accordion-panel has-error' : 'accordion-panel'} onClick={this.toggleAccordion} data-accordion-opened={this.state.openAccordion}>
          <div className="panel-left">
            <h5 className="accordion-title">
              { this.state.hasError ? <EmptySquareIcon/> : <SquareCheckIcon/> }
              <span>
                {this.props.title}
              </span>
            </h5>
            <div className="list-sizes">{sizesList}</div>
          </div>
          <div className="panel-right">
            {warning && <span>{warning}</span>}
            <span className="amount">{amount}</span>
            <span className="toggle">
              <ArrowDown />
            </span>
          </div>
        </div>
        <div className="accordion-body">
          {childrenWithProps}
        </div>
      </StyledAccordion>
    )
  }
}

export default Accordion
