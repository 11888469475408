// @flow

import React, { lazy } from 'react'
import { PrivateRoute } from 'src/common/components'

const Resources = lazy(() => import('src/modules/resources'))
const ResourcePage = lazy(() => import('src/modules/resources/fragments/resourcePage'))

export default [
  <PrivateRoute exact={true} path="/my-leagues/:leagueId/resources" component={Resources} key="my-leagues/resources"/>,
  <PrivateRoute exact={true} path="/my-leagues/resources/:resourceId" component={ResourcePage} key="my-leagues/resources/resourcePage"/>,
]
