import React from 'react'

const DashboardIcon = () => {
  return (
    <svg fill="#fff" x="0px" y="0px" width="20px" height="20px" viewBox="0 0 1792 1792">
      <g>
        <g>
          <path d="M762.747,761.079H46.514V71.492h716.232V761.079z M146.455,661.14h516.354V171.43H146.455V661.14z"></path>
          <path d="M1745.486,761.079h-716.232V71.492h716.232V761.079z M1132.51,661.14h516.354V171.43H1132.51V661.14z"></path>
          <path d="M762.747,1720.508H46.514v-689.59h716.232V1720.508z M146.455,1620.57h516.354v-489.711H146.455V1620.57z"></path>
          <path d="M1745.486,1720.508h-716.232v-689.59h716.232V1720.508z M1132.51,1620.57h516.354v-489.711H1132.51V1620.57z"></path>
        </g>
      </g>
    </svg>
  )
}

export default DashboardIcon
