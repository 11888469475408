// @flow
import React from 'react'
import { compose } from 'redux'
import { withUser, withPayments } from 'src/decorators'
import { StyledItemInfo } from './ItemInformationStyles'
import type { ItemInformationPropsType } from './ItemInformationTypes'
import { ADDITIONAL_ITEM_TYPES } from 'src/common/constants'
import { getPriceString } from 'src/helpers/moneyHelpers'

const ItemInformation = ({
  items,
  payments: { currencyRate },
  user: { userData },
  order = {},
}: ItemInformationPropsType) => {
  const { currency } = userData || {}
  return (
    <StyledItemInfo>
      <div className="item-info-table">
        <div className="table-heading">
          <div className="table-row">
            <div className="table-cell sku">SKU</div>
            <div className="table-cell description">Description</div>
            <div className="table-cell quantity">Price</div>
            <div className="table-cell quantity">Quantity</div>
          </div>
        </div>
        <div className="table-body">
          {Array.isArray(items) && items.length > 0 && items.map(item => (
            <div className="table-row" key={`${item.id}-${item.price}`}>
              <div className="table-cell sku"><span className="mobile-label">SKU:</span>{item.vendor_item_sku || item.sku}</div>
              <div className="table-cell description">{item.sale_item_description || item.description} {
                ADDITIONAL_ITEM_TYPES.includes(item.type) && (
                  item.price ? '(additional)' : '(free)'
                )}</div>
              <div className="table-cell quantity"><span className="mobile-label">price:</span>{
                item.price ? getPriceString({ price: item.initial_item_price, currency, currencyRate: order?.currency_cad_rate || currencyRate }) : 0
              }</div>
              <div className="table-cell quantity"><span className="mobile-label">qty:</span>{item.quantity}</div>
            </div>
          ))}
        </div>
      </div>
    </StyledItemInfo>
  )
}

export default compose(
  withUser,
  withPayments,
)(ItemInformation)
