// @flow

import { call, fork, put, take } from 'redux-saga/effects'
import { GET_RESOURCE_PAGE, getResourcePage } from 'src/redux/actions/resources/resources'
import http from 'src/services/http'

// types
import type { IOEffect } from 'redux-saga/effects'

function * watchGetResourcePage(): Generator<IOEffect, void, any> {
  while (true) {
    const action = yield take(GET_RESOURCE_PAGE.REQUEST)
    const pageId: string = action.payload

    try {
      const { data } = yield call(http, `api/public-site/resources/${pageId}`, {
        method: 'GET',
      })

      yield put(getResourcePage.success(data))
    } catch (e) {
      yield put(getResourcePage.error(e.message))
    }
  }
}

export default [fork(watchGetResourcePage)]
