import styled from 'styled-components'

export const StyledMenuWrapper = styled.div`
    background-color: ${props => props.theme.main.colors.primaryColor};
    color: ${props => props.theme.main.colors.inverseColor};
    min-height: auto;
    width: 100%;
    
    a {
        align-items: center;
        color: ${props => props.theme.main.colors.inverseColor};
        display: flex;
        font-weight: 300;
        padding: 12px 24px;
    }
    
    .nav-link {
        
        .icon-wrapper {
            margin-right: 15px;
        }
    }
    
    .league-name {
        font-weight: 400;
        margin-top: 40px;
        padding: 0 24px;
        
        :hover {
          text-decoration: none;
        }
    }
`

export const StyledMenu = styled.div`
    background-color: #202f5d;
    
    .nav-link {
      a {
        :hover {
          text-decoration: none;
        }
       }
      }

    .subnav-menu {
        margin: 0;
        padding-left: 0;
        
        a {
            opacity: 0.5;
            padding-left: 20%;
            
            :hover {
                opacity: 0.8;
                text-decoration: none;
            }
        }
    }
`
