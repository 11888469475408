// @flow
import React, { lazy } from 'react'
import { PrivateRoute } from 'src/common/components'
const CreateSeason = lazy(() => import('src/modules/seasons/createSeason'))
const EditSeason = lazy(() => import('src/modules/seasons/editSeason'))

export default [
  <PrivateRoute exact={true} path="/my-leagues/:leagueId/seasons/create" component={CreateSeason} key="create-season" />,
  <PrivateRoute exact={true} path="/my-leagues/:leagueId/seasons/:seasonId/edit" component={EditSeason} key="create-season" />,
]
