// @flow

import { stopSubmit } from 'redux-form'
import { call, fork, put, take } from 'redux-saga/effects'
import { cloneDeep } from 'lodash'
import config from 'src/config'
import { trimObjectStringValues } from 'src/helpers/formatHelpers'
import { CREATE_DIVISION, createDivision } from 'src/redux/actions/divisions/divisions'
import http from 'src/services/http'
import { history } from 'src/services/history'
// types
import type { IOEffect } from 'redux-saga/effects'

const shapeToNumber = (val: string) => {
  return +val
}

const toNumberValues = ['season_id', 'min', 'max', 'league_id']

function * watchCreateDivision(): Generator<IOEffect, void, any> {
  while (true) {
    const {
      payload: { redirectCb, ...values },
    } = yield take(CREATE_DIVISION.REQUEST)
    const formId = config.createDivisionForm.id

    const formValues = cloneDeep(values)

    trimObjectStringValues(formValues)

    toNumberValues.forEach(item => {
      formValues[item] = shapeToNumber(formValues[item])
    })

    delete formValues.has_boys
    delete formValues.has_coed
    delete formValues.has_girls

    try {
      yield call(http, 'api/division', {
        body: JSON.stringify({
          ...formValues,
        }),
        method: 'POST',
      })

      yield put(createDivision.success(values))
      yield call(history.push, `/my-leagues/${values.league_id}/league-management`)
    } catch (e) {
      yield put(createDivision.error(e.message))
      yield put(stopSubmit(formId, e.errors ? e.errors : {}))
    }
  }
}

export default [fork(watchCreateDivision)]
