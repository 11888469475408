// @flow

import { requestTypeCreator } from 'src/helpers'

// types
import type {
  GetOrdersActionTypes,
} from './ordersTypes'

export const GET_ORDERS = requestTypeCreator('GET_ORDERS')

export const getOrders = {
  error: (payload: any): GetOrdersActionTypes => ({ type: GET_ORDERS.FAILURE, payload }),
  request: (payload: any): GetOrdersActionTypes => ({ type: GET_ORDERS.REQUEST, payload }),
  success: (payload: any): GetOrdersActionTypes => ({ type: GET_ORDERS.SUCCESS, payload }),
}
