// @flow

import { stopSubmit } from 'redux-form'
import { call, fork, put, take } from 'redux-saga/effects'
import config from 'src/config'
import { EDIT_USER_PASSWORD, editUserPassword } from 'src/redux/actions/user/user'
import http from 'src/services/http'
import { history } from 'src/services/history'

// types
import type { IOEffect } from 'redux-saga/effects'

function * watchEditPassword(): Generator<IOEffect, void, any> {
  while (true) {
    const {
      payload: { password, old_password },
    } = yield take(EDIT_USER_PASSWORD.REQUEST)
    const formId = config.activatePasswordForm.id
    try {
      const { data: { user } } = yield http('api/account/change-password', {
        body: JSON.stringify({
          old_password,
          new_password: password,
        }),
        method: 'POST',
      })

      if (user && old_password) {
        yield put(editUserPassword.success(user))
        yield call(history.push, '/password/thank-you')
      } else {
        throw new Error('something went wrong')
      }
    } catch (e) {
      yield put(editUserPassword.error(e.message))
      yield put(stopSubmit(formId, e.errors ? e.errors : {}))
    }
  }
}

export default [fork(watchEditPassword)]
