// @flow
import styled from 'styled-components'

export const StyledPrimaryButtonContainer = styled.div`
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: ${props => props.theme.main.colors.buttonPrimaryColor};
    border: 1px solid ${props => props.theme.main.colors.buttonPrimaryColor};
    border-radius: 3px;
    color: ${props => props.theme.main.colors.primaryColor};
    content: "${props => props.buttonText}";
    cursor: pointer;
    display: inline-flex;
    font-family: ${props => props.theme.main.fonts.secondaryFontFamily};
    font-size: 16px;
    height: 40px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    letter-spacing: 0.6px;
    padding: 14px 24px 12px;
    text-decoration: none;
    width: 100%;
    max-width: 300px;
    white-space: nowrap;
    -webkit-user-select: none;
    position: relative;
    cursor: pointer;
`

export const StyledPrimaryButtonText = styled.span`
    
`

export const StyledPrimaryButton = styled.input`
    opacity: 0;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    cursor: pointer;
    bottom: 0;

    &:focus {
        outline: none;
    }
    
    ::-webkit-file-upload-button {
      visibility: hidden;
    }
    
    ::before {
        
    }
`
