// @flow
import styled from 'styled-components'
import { device } from 'src/common/theme/mediaQueries'

export const StyledInfoBlock = styled.div`
    background-color: #fff;
    box-shadow: 3px 6px 40px 0 rgba(14,28,69,0.1);
    margin-bottom: 30px;
    padding: 15px;
    
    .empty-insurance {
        background-color: rgba(212,53,64,0.1);
        
        a {
          text-decoration: underline;
          
          :hover {
            text-decoration: none;
          }
        }
    }
    .expiring-insurance {
        background-color: rgba(255,255,102,0.4);
        
        a {
          text-decoration: underline;
          
          :hover {
            text-decoration: none;
          }
        }
    }
`

export const StyledPageHeader = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;

    .btn-wrapper {
        display: flex;
        justify-content: center;
        margin-bottom: 30px;
    }
    
    @media ${device.mobileL} {
        align-items: center;
        flex-direction: column;
        margin-bottom: 30px;
        text-align: center;
    }
    
    .link-wrapper {
        a {
            align-items: center;
            background-color: ${props => props.theme.main.colors.buttonPrimaryColor};
            border: 1px solid ${props => props.theme.main.colors.buttonPrimaryColor};
            color: ${props => props.theme.main.colors.primaryColor};
            border-radius: 3px;
            cursor: pointer;
            display: inline-flex;
            font-family: ${props => props.theme.main.fonts.secondaryFontFamily};
            font-size: 16px;
            height: 40px;
            justify-content: center;
            letter-spacing: 0.6px;
            max-width: 200px;
            padding: 12px 24px;
            text-decoration: none;
            width: 200px;
        
            &:hover {
                opacity: 0.8;
                text-decoration: none;
            }
            
            &:focus {
                outline: none;
            }
            &.custom {
                width: auto;
                max-width: none;
            }
        }

    }
`

export const StyledEmptyBlock = styled.div`
    border: 1px solid rgba(212, 53, 64, 0.7);
    padding: 15px;
    
    .title {
        margin-bottom: 5px;
    }
    
    .subtitle {
        margin: 0 auto;
        max-width: 500px;
    }
    
    .button-wrapper {
        margin-top: 15px;
    }
  
    .empty-block {
      margin: 0 20px;
    }
`

// TODO USE src/common/components/bannerBlockStyle
// FOR ALL THIS TYPES
export const StyledApprovedBlock = styled.div`
    border: 1px solid ${props => props.theme.main.colors.greenColor};
    padding: 15px;
    background-color: rgba(144,238,144, 0.2);
    
    .title {
        margin-bottom: 5px;
    }
    
    .subtitle {
        margin: 0 auto;
        max-width: 500px;
    }
    
    .button-wrapper {
        margin-top: 15px;
    }

  .empty-block {
    margin: 0 20px;
  }
`

export const StyledInsuranceBlock = styled.div`
    padding-top: 15px;
    
    
    .button-wrapper {
        margin-top: 15px;
    }

    .link-wrapper {
        padding: 0 15px;
        width: 100%;
        a {
            text-decoration: none;
        }
        line-height: 1.5;
    }
`

export const StyledSeason = styled.div`
    padding: 15px 0 40px;
    
    .season-description {
        background-color: #e9f0ff;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 20px 10px 10px;
        margin-bottom: 15px;
    }
    
    h6.title {
        font-size: 18px;
        font-style: italic;
        font-weight: 300;
        margin-bottom: 0;
        text-transform: uppercase;
    }
    
    .season-title {
        margin-bottom: 5px;
        
        a {
            text-decoration: underline;
            
            :hover {
                text-decoration: none;
            }
        }
    }
    
    .info-row {
        display: flex;
        flex-wrap: wrap;
        
        @media ${device.mobileL} {
            flex-direction: column;
        }
    }
    
    .info-col {
        border-right: 1px solid #ebedef;
        line-height: 1.2;
        margin-bottom: 8px;
        padding-left: 8px;
        padding-right: 20px;
        
        &:first-child {
            padding-left: 0;
        }
        
        &:last-child {
            border-right: 0;
        }
        
        > * {
            align-items: center;
            display: flex;
        }
        
        .icon-wrapper {
            margin-right: 6px;
            margin-top: 2px;
            min-width: 20px;
        }
        
        @media ${device.mobileL} {
            border-right: 0;
            padding-left: 0;
            padding-right: 20px;
        }
    }

    .guide-link {
        text-align: end;
        padding-top: 12px;
    }

    .btn-delete {
        align-items: center;
        appearance: none;
        background-color: transparent;
        border: 0;
        color: ${props => props.theme.main.colors.secondaryColor};
        display: flex;
        margin-left: auto;
        
        svg {
            height: 12px;
            margin-right: 4px;
            min-width: 12px;
            width: 12px;
            
            path {
                fill: ${props => props.theme.main.colors.secondaryColor};
            }
        }
        
        :hover {
            text-decoration: underline;
        }
    }
    
    .table-action {
        margin-top: 20px;
    }
`

export const StyledButton = styled.button`
    appearance: none;
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid;
    color: ${props => props.theme.main.colors.secondaryColor};
    font-weight: bold;
    padding-left: 0;
    padding-right: 4px;
    
    svg {
        margin-right: 4px;
        
        path {
            fill: ${props => props.theme.main.colors.secondaryColor};
            margin-right: 5px;
        }
    }
    
    :hover {
        filter: brightness(1.3);
    }
    
    a {
      color: ${props => props.theme.main.colors.secondaryColor};
    
      &:hover {
        text-decoration: none;
      }
    }
`

export const StyledTable = styled.div`
    .table-body {
        border-bottom: 1px solid ${props => props.theme.main.colors.borderColor};
    }
    
    .table-row {
        border-left: 1px solid ${props => props.theme.main.colors.borderColor};
        border-right: 1px solid ${props => props.theme.main.colors.borderColor};
        display: table;
        width: 100%;
    }
    
    .table-cell {
        display: table-cell;
        padding: 12px 15px;
        border-top: 1px solid ${props => props.theme.main.colors.borderColor};
        width: 20%;
        
        :first-child {
            width: 40%;
        }
        
        @media ${device.mobileL} {
            font-size: 14px;
            padding: 8px 12px;
        }
        
        .btn-delete {
        align-items: center;
        appearance: none;
        background-color: transparent;
        border: 0;
        color: ${props => props.theme.main.colors.secondaryColor};
        display: flex;
        margin-left: auto;
        padding: 8px 0;
        
          svg {
              height: 12px;
              margin-right: 4px;
              min-width: 12px;
              width: 12px;
              
              path {
                  fill: ${props => props.theme.main.colors.secondaryColor};
              }
          }
          
          :hover {
              text-decoration: underline;
          }
        }
    }
    
    .table-header {
        font-weight: bold;
    }
    
    .table-action {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        
        span {
            opacity: 0.7;
            font-size: 17px;
            padding-bottom: 15px;
        }
        
        &.button-group {
            flex-direction: row;
            justify-content: space-between;
        }
    }
    
    .edit-division-link {
        text-decoration: underline;
        
        :hover {
            text-decoration: none;
        }
    }
`

export const StyleOrderBtnWrapper = styled.div`
    flex: 0 0 160px;
    button + button {
        margin-top: 10px;
    }
`
