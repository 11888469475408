// @flow
import React from 'react'
import { Link } from 'react-router-dom'
import { LocationIcon, PhoneIcon } from 'src/assets/icons/index'
import { ButtonPrimary, LeagueActiveSeason } from 'src/common/components/index'
import WarningIcon from 'src/assets/icons/Warning'
import { INSURANCE_STATUSES } from 'src/common/constants'

import { formatAbsoluteDate } from 'src/helpers/dateHelpers'

// styles
import { StyledCard } from './cardLeagueStyles'

// types
import type { CardLeagueTypes } from './CardLeagueTypes'
const CardLeague = (props: CardLeagueTypes) => {
  const { showButton, status, insurance, onClickHandler, title, division, activeSeasons, buttonLink, buttonText, location, phone, participants = 0 } = props

  return (
    <StyledCard pointer={!showButton} status={status} insuranceStatus={insurance && insurance.status}>
      <div className="card-left" onClick={showButton ? () => {} : onClickHandler}>
        <h5 className="card-title">
          {title}
        </h5>
        {division &&
          <span className="card-role"><strong>Division:</strong> {division}</span>
        }
        <div className="card-contacts d-flex align-items-center">
          <ul className="list-contacts d-flex list">
            {location &&
              <li className="list-item">
                <div className="contact location">
                  <span className="icon-wrapper">
                    <LocationIcon />
                  </span>
                  {location}
                </div>
              </li>
            }
            {phone &&
              <li className="list-item">
                <div className="contact phone">
                  <span className="icon-wrapper">
                    <PhoneIcon />
                  </span>
                  <a href={`tel: ${phone}`}>{phone}</a>
                </div>
              </li>
            }
          </ul>
        </div>
        {status &&
          <div className=""><strong>Approval Status:</strong> <span className="status">{status}</span></div>
        }
        {insurance &&
          <div className="">
            <strong>Insurance Status: </strong>
            <span className="insuranceStatus">{insurance.status}</span>
            {insurance.status === INSURANCE_STATUSES.APPROVED && !insurance.active && <span style={{ color: '#ffb74d' }}>, NOT ACTIVE</span>}
          </div>
        }
        {
          insurance && insurance.status === INSURANCE_STATUSES.APPROVED &&
          <div className="">
            From {formatAbsoluteDate(insurance.start_date)} to {formatAbsoluteDate(insurance.end_date)}
          </div>
        }
        <div className=""><strong>Participant accident insurance: </strong> {participants === 'unknown' ? <span className="error">unknown</span> : `${participants} players covered`}</div>
        <span className="following_text">NFL FLAG offers free secondary participant accident insurance, covering all players uploaded to an active roster in Matrix. Leagues can review this upload process <a className="link-underline"
          target="_blank"
          href="https://nflflag.mediavalet.com/portals/Insurance" rel="noreferrer">here</a></span>
      </div>
      <div className="card-center">
        {!activeSeasons.length && <WarningIcon />}
        <LeagueActiveSeason
          seasons={activeSeasons}
        />
      </div>
      {showButton && (
        <div className="card-right">
          <Link to={buttonLink}>
            <ButtonPrimary buttonText={buttonText} />
          </Link>
        </div>
      )}
    </StyledCard>
  )
}

export default CardLeague
