// @flow
import * as React from 'react'
import { compose } from 'redux'
import { withUser, withPayments } from 'src/decorators'
import { StyledShippingInformation, StyledPopover } from '../../orderSummaryStyles'
import { formatFullDate } from 'src/helpers/dateHelpers'
import { getPriceString } from 'src/helpers/moneyHelpers'
import { Popover } from '@material-ui/core'
import { PaymentType, humanize } from 'src/helpers/valuesHumanizer'
import { PAYMENT_TYPES } from 'src/config/ACHConfig'

// types
import type { PaymentInformationPropsType } from './PaymentInformationType'

const PaymentInformation = ({
  paymentsArray,
  payments: { currencyRate },
  user: { userData },
  order = {},
}: PaymentInformationPropsType) => {
  const { currency } = userData || {}
  const [checkNumberPopoverAnchor, setCheckNumberPopoverAnchor] = React.useState(null)

  const handleCheckNumberClick = (element, index) => {
    setCheckNumberPopoverAnchor({ element, index })
  }

  const handleCheckNumberClose = () => {
    setCheckNumberPopoverAnchor(null)
  }

  const formatMoney = price => getPriceString({ price, currency, currencyRate: order?.currency_cad_rate || currencyRate })

  return paymentsArray.map<React.Node>((payment, index) => {
    const isCheck = payment.type === PAYMENT_TYPES.CHECK
    const isACH = payment.type === PAYMENT_TYPES.ACH
    return (
      <StyledShippingInformation key={index}>
        <div className="col">
          {payment && payment.status && (
            <div className="shipping-row">
              <span className="label"><strong>Payment Status</strong></span>
              <span className="value">{humanize(payment.status)}</span>
            </div>
          )}
          {payment && payment.type && (
            <div className="shipping-row">
              <span className="label">Payment Type</span>
              <span className="value">{PaymentType(payment.type)}</span>
            </div>
          )}
          {payment && Boolean(payment.amount) && (
            <div className="shipping-row">
              <span className="label">Amount</span>
              <span className="value">{formatMoney(payment.amount)}</span>
            </div>
          )}
        </div>
        <div className="col">
          {payment && payment.card_type && (
            <div className="shipping-row">
              <span className="label">{isACH ? 'Account type' : 'Card Type'}</span>
              <span className="value">{payment.card_type}</span>
            </div>
          )}
          {payment && payment.card && (
            <div className="shipping-row">
              <span className="label">{isACH ? 'ACH Account' : 'Credit Card'}</span>
              {/* $FlowFixMe */}
              <span className="value">*{payment?.card?.slice?.(-4) || ''}</span>
            </div>
          )}
          {payment && payment.invoice_number && isCheck && (
            <div className="shipping-row">
              <span className="label">Invoice Paid</span>
              <span className="value">#{payment.invoice_number}</span>
            </div>
          )}
          {payment && payment.type_description && isCheck && (
            <div className="shipping-row">
              <span className="label">Check Number</span>
              <span className="value text-overflow" onClick={e => handleCheckNumberClick(e.currentTarget, index)}>{payment.type_description}</span>
              <Popover
                open={!!checkNumberPopoverAnchor && checkNumberPopoverAnchor.index === index}
                anchorEl={!!checkNumberPopoverAnchor && checkNumberPopoverAnchor.element}
                onClose={handleCheckNumberClose}
                anchorOrigin={{
                  vertical: 'center',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'center',
                  horizontal: 'right',
                }}
              >
                <StyledPopover>
                  {payment.type_description}
                </StyledPopover>
              </Popover>
            </div>
          )}
          {payment && payment.created_at && (
            <div className="shipping-row">
              <span className="label">Submitted at</span>
              <span className="value">{ formatFullDate(payment.created_at) }</span>
            </div>
          )}
        </div>
      </StyledShippingInformation>
    )
  })
}

export default compose(
  withUser,
  withPayments,
)(PaymentInformation)
