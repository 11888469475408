// @flow

import React from 'react'
import { DashboardCardLeague } from 'src/common/components'
import { canUserManageLeague } from 'src/helpers/rolesHelpers'
import { withPublicLeagues } from 'src/decorators'
import getLocation from 'src/helpers/getLocation'
import { LEAGUE_STATUSES } from 'src/common/constants'

// types
import type { MyLeaguesDataItem } from 'src/redux/reducers/myLeagues'

// styles
import { StyledList } from './cardLeagueListStyles'

const getButtonData = ({ canManageLeague, isApproved, id }) => {
  const buttonData = {
    text: 'View details',
    link: `/my-leagues/${id}/league-management`,
  }
  if (canManageLeague && isApproved) {
    buttonData.text = 'MANAGE LEAGUE'
  } else if (canManageLeague && !isApproved) {
    buttonData.text = 'Preview League'
    buttonData.link = `/my-leagues/${id}/edit`
  }

  return buttonData
}

const LeaguesList = ({ myLeaguesSortByActiveSeasons, showButton, updatePublicLeague }: any) => {
  // eslint-disable-next-line
  return myLeaguesSortByActiveSeasons.map((item: MyLeaguesDataItem, index: number) => {
    // eslint-disable-next-line
    if (index >= 3) return
    const canManageLeague = canUserManageLeague(item.roles)
    const isApproved = item.approved === LEAGUE_STATUSES.APPROVED
    const buttonData = getButtonData({ canManageLeague, isApproved, id: item.id })

    return (
      <li className="list-item" key={`ist-item${item.id}`}>
        <DashboardCardLeague
          status={item.approved}
          updated_at={item.updated_at}
          showButton={showButton}
          id={item.id}
          title={item.name}
          location={getLocation(item)}
          phone={item.phone}
          activeSeasons={item.active_seasons}
          buttonText={buttonData.text}
          buttonLink={buttonData.link}
          insurance={item.insurance}
          participants={item.participants}
          onClickHandler={() => updatePublicLeague({ myLeaguesSortByActiveSeasons, id: item.id })}
        />
      </li>
    )
  })
}

const CardLeagueList = ({ myLeaguesSortByActiveSeasons, showButton = true, updatePublicLeague }: any) => {
  return (
    <StyledList className="list list-my-leagues half-width">
      <LeaguesList
        myLeaguesSortByActiveSeasons={myLeaguesSortByActiveSeasons}
        showButton={showButton}
        updatePublicLeague={updatePublicLeague}
      />
    </StyledList>
  )
}

export default withPublicLeagues(CardLeagueList)
