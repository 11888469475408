import styled from 'styled-components'

const BaseCheckbox = styled.div`
  .checkbox-container {
    cursor: pointer;
    display: block;
    padding-bottom: 0;
    padding-left: 26px;
    position: relative;
    user-select: none;

    input {
      cursor: pointer;
      height: 0;
      opacity: 0;
      position: absolute;
      width: 0;
    }
  }

  .checkmark {
    border: 2px solid ${props => props.theme.main.colors.primaryColor};
    height: 19px;
    left: 0;
    position: absolute;
    top: 0;
    width: 19px;
  }

  .checkbox-container input:checked ~ .checkmark {
    background-color: ${props => props.theme.main.colors.primaryColor};
  }

  .checkmark:after {
    content: "";
    display: none;
    position: absolute;
  }

  .checkbox-container {
    input:checked ~ .checkmark:after {
      display: block;
    }

    .checkmark:after {
      border: solid white;
      border-width: 0 2px 2px 0;
      height: 10px;
      left: 5px;
      top: 1px;
      transform: rotate(45deg);
      width: 5px;
    }
  }

  input:disabled + .checkmark {
    opacity: 0.2;
  }

  .checkbox-group.checkbox-container {
    margin-bottom: 5px;
    margin-top: 16px;
  }
`

export const StyledCheckbox = styled(BaseCheckbox)`
  padding-right: 20px;

  .checkbox-container {
    margin-bottom: 16px;
  }
`
export const StyledError = styled.span`
    font-size: 13px;
    color: ${props => props.theme.main.colors.errorColor}`

export const StyledCommonCheckbox = styled(BaseCheckbox)`
  padding-right: 5px;
`
