// @flow

import React, { useEffect, useRef } from 'react'
import { StyledTextarea } from './textAreaStyles'
import type { TextAreaProps } from './TextAreaTypes'

const CommonTextarea = ({
  value,
  onChange,
  onBlur,
  autofocus,

}: TextAreaProps) => {
  const textarea = useRef(null)

  useEffect(() => {
    if (autofocus && textarea.current) {
      textarea.current.focus()
    }
  }, [])

  return <StyledTextarea value={value} onChange={onChange} onBlur={onBlur} ref={textarea}/>
}

export default CommonTextarea
