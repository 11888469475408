// @flow
import React from 'react'
import WarningIcon from 'src/assets/icons/Warning'
import { formatAbsoluteDate } from 'src/helpers/dateHelpers'
import insuranceStatuses from 'src/config/insuranceStatuses'
import insuranceTypes from 'src/config/insuranceTypes'
import FilesList from './fragments/filesList'
import RenderIf from '../renderIf'

// styles
import { StyledCard } from './cardInsuranceStyles'

// types
import type { CardInsuranceTypes } from './CardInsuranceTypes'

const CardInsurance = (props: CardInsuranceTypes) => {
  const { files, checkFile, meta, players } = props
  return (
    <StyledCard order_field={props.order_field}>
      <div className="card-left">
        <h5 className="card-title">
          {props.title}
          {props.status === insuranceStatuses.PENDING &&
          <span className="card-status">
            <WarningIcon/>
            <span>
              {props.type_insurance === insuranceTypes.PURCHASE ? 'Pending bind certificate' : 'Pending NFL Admin Approval'}
            </span>
          </span>
          }
          {props.status === 'DECLINED' &&
          <span className="card-status">
            <WarningIcon/>
            <span>{props.status_reason}</span>
          </span>
          }
        </h5>
        <FilesList
          insuranceId={props.id}
          files={files}
          checkFile={checkFile}
        />
        <div className="card-subtitle d-flex list">
          <span><strong>Type:</strong> {props.type_insurance && props.type_insurance.toLowerCase()}</span>
        </div>
        <div className="list-block d-flex list">
          <div className="card-subtitle">
            From <strong>{formatAbsoluteDate(props.start_date)}</strong> to <strong>{formatAbsoluteDate(props.end_date)}</strong>
          </div>
        </div>
        { props.type_insurance === insuranceTypes.PURCHASE &&
          <>
            <div className="list-block d-flex list">
              <div className="card-subtitle">
                <span><strong>Policy Number:</strong> {props.policy_number}</span>
              </div>
            </div>
            <div className="list-block d-flex list">
              <div className="card-subtitle">
                <span><strong>Bound at:</strong> {formatAbsoluteDate(props.bound_at)}</span>
              </div>
            </div>
            <div className="list-block d-flex list">
              <div className="card-subtitle">
                <span><strong>Coverage:</strong> ${meta?.coverage}</span>
              </div>
            </div>
            <RenderIf condition={players}>
              <div className="list-block d-flex list">
                <div className="card-subtitle">
                  <span><strong>Number of players in age groups: </strong>
                    {/* $FlowFixMe */}
                    {players?.map(group =>
                      <span className="group" key={`${group.minAge}-${group.maxAge}`}>
                        <span>{`${group.minAge}-${group.maxAge}:`}</span>
                        <span><strong>{group.numberOfPlayers}</strong></span>
                      </span>,
                    )}
                  </span>
                </div>
              </div>
            </RenderIf>
          </>
        }
      </div>
      <div className="card-center">
        <div className="card-subtitle">
          <span>{props.status && props.status.toLowerCase()}</span>
        </div>
        <div className="card-subtitle">
          <span>{props.expiration_status && props.expiration_status.toLowerCase().replace('_', ' ')}</span>
        </div>
      </div>
    </StyledCard>
  )
}

export default CardInsurance
