// @flow

import React from 'react'

// types

import type { FormFileInputPropsType } from './FormFileInputTypes'

import { StyledPrimaryButton, StyledPrimaryButtonContainer, StyledPrimaryButtonText } from './formInputStyles'

const FormFileInput = (props: FormFileInputPropsType) => {
  const { buttonText = 'submit', handleSubmit, action, params, size, input: { name }, ...rest } = props

  const handleChange = e => {
    const { input: { onChange } } = props
    onChange(e.target.files[0])
    handleSubmit && action && handleSubmit(action({ [name]: e.target.files[0], ...params }))
  }

  return (
    <StyledPrimaryButtonContainer {...rest}>
      <StyledPrimaryButtonText>IMPORT SIZES FROM XLS</StyledPrimaryButtonText>
      <StyledPrimaryButton
        buttonText={buttonText}
        size={size}
        type="file"
        onChange={handleChange}
      />
    </StyledPrimaryButtonContainer>
  )
}

export default FormFileInput
