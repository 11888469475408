// @flow

import { call, fork, put, take } from 'redux-saga/effects'
import { GET_MY_LEAGUES, getMyLeagues } from 'src/redux/actions/myLeagues/myLeagues'
import { fetchLeaguesPersonal } from 'src/services/myLeagues'
// types
import type { IOEffect } from 'redux-saga/effects'
import { sortLeaguesByActiveSeasons } from 'src/modules/myLeagues/utils'

function * watchGetMyLeagues(): Generator<IOEffect, void, any> {
  while (true) {
    yield take(GET_MY_LEAGUES.REQUEST)

    try {
      const data = yield call(fetchLeaguesPersonal)

      yield put(getMyLeagues.success({
        leagues: data,
        leaguesSortByActiveSeasons: sortLeaguesByActiveSeasons(data),
      }))
    } catch (e) {
      yield put(getMyLeagues.error(e.message))
    }
  }
}

export default [fork(watchGetMyLeagues)]
