import styled from 'styled-components'
import { device } from 'src/common/theme/mediaQueries'
export const StyledHeading = styled.div`
    align-items: center;
    border-bottom: 1px solid ${props => props.theme.main.colors.inputBorderColor};
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    flex-direction: column;
    
    h3 {
        margin-bottom: 5px;
        margin-top: 0;
    }
    
    p {
        max-width: 500px;
        display: flex;
        justify-content: center;
        margin-top: 0;
        margin-bottom: 30px
    }
    
    @media ${device.tabletL} {
        flex-direction: column;
        padding-bottom: 20px;
    }
    
    @media ${device.mobileL} {
        h3 {
            text-align: center;
        }
    }
`
