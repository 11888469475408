// @flow

import { call, fork, put, take } from 'redux-saga/effects'
import { CANCEL_PENDING_ORDER, cancelPendingOrder } from '../actions/bulkOrder/bulkOrder'
import http from 'src/services/http'
import { history } from 'src/services/history'

// types
import type { IOEffect } from 'redux-saga/effects'

function * watchCancelPendingOrder(): Generator<IOEffect, void, any> {
  while (true) {
    const {
      payload: { orderId, leagueId },
    } = yield take(CANCEL_PENDING_ORDER.REQUEST)
    try {
      yield call(http, `api/order/${orderId}`, {
        method: 'DELETE',
      })

      yield put(cancelPendingOrder.success())
      yield call(history.push, `/my-leagues/${leagueId}/orders`)
    } catch (e) {
      yield put(cancelPendingOrder.error(e.message))
    }
  }
}

export default [fork(watchCancelPendingOrder)]
