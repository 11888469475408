import React from 'react'

const MyLeaguesIcon = () => {
  return (
    <svg fill="#fff" width="20px" height="20px" viewBox="0 0 1792 1792">
      <g>
        <g>
          <path d="M1413.121,1654.434l-63.452-262.002c-35.85-22.059-162.702-93.78-394.377-93.78 c-11.016,0-24.805-5.521-33.101-13.789c-19.297-19.311-38.595-60.681-33.085-223.381v-16.564l179.276-173.743 c16.537-57.905,107.542-383.347,0-548.821c-2.773-5.507-77.243-107.556-303.385-107.556 c-226.14,0-300.609,104.795-303.369,110.317c-110.316,165.474-19.298,488.154,0,548.82l179.264,173.758v16.535 c5.522,162.729-13.789,204.098-33.086,223.409c-8.282,8.268-22.072,13.789-33.102,13.789c-228.9,0-358.527,68.947-394.374,93.752 L116.89,1657.18l-79.977-19.312l68.947-292.326l11.029-8.269c5.521-5.521,143.417-113.092,432.997-118.585 c2.762-16.565,8.269-55.159,8.269-135.148L389.921,918.063l-2.747-8.268c-5.522-16.549-135.149-416.446,8.267-628.812 c2.762-5.507,99.29-146.163,372.32-146.163c273.043,0,369.569,140.656,372.316,146.163 c143.418,215.127,13.79,612.264,8.269,628.812l-2.747,8.268L977.351,1083.54c-2.747,79.989,2.772,118.583,8.295,135.148 c289.581,5.493,427.476,113.064,432.971,118.585l11.042,8.269l68.949,292.326L1413.121,1654.434z"></path>
          <rect x="1313.819" y="634.006" width="441.268" height="82.737"></rect>
          <rect x="1415.869" y="901.514" width="336.472" height="82.751"></rect>
          <rect x="1534.454" y="1171.798" width="220.633" height="82.738"></rect>
        </g>
      </g>
    </svg>
  )
}

export default MyLeaguesIcon
