// @flow
import React from 'react'
import { Link } from 'react-router-dom'
import { AdjustmentIcon, ParentIcon } from 'src/assets/icons'
// styles
import { StyledMenuWrapper, StyledMenu } from './myLeagueMenuStyles'

// types
import type { MyLeagueMenuTypes } from './MyLeagueMenuTypes'

const MyLeagueMenu = (props: MyLeagueMenuTypes) => {
  // const { REACT_APP_SHOW_ORDERS_MODULE = "" } = process.env
  return (
    <StyledMenuWrapper>
      { props.role === 'organizer' && props.leagueData.league_id &&
      <Link to={`/my-leagues/${props.leagueData.league_id}/league-management`} className="league-name">
        <h4>{props.leagueData.name}</h4>
      </Link>
      }
      <StyledMenu>
        {props.leagueData.league_id && (<><div className="nav-link">
          { props.role === 'parent' &&
          <Link to="#">
            <span className="icon-wrapper">
              <ParentIcon />
            </span>
            <span>Parent</span>
          </Link>
          }
          { props.role === 'organizer' &&
          <Link to={`/my-leagues/${props.leagueData.league_id}/league-management`}>
            <span className="icon-wrapper">
              <AdjustmentIcon />
            </span>
            <span>League Organizer</span>
          </Link>
          }
        </div>
        </>)}
        { props.role === 'organizer' &&
        <ul className="subnav-menu">
          {props.leagueData.league_id && (
            <>
              <li className="menu-item">
                <Link to={`/my-leagues/${props.leagueData.league_id}/edit`}>Edit League Info</Link>
              </li>

              <li className="menu-item">
                <Link to={`/my-leagues/${props.leagueData.league_id}/members`}>Members</Link>
              </li>

              {/* {REACT_APP_SHOW_ORDERS_MODULE === "true" && (
                                <li className="menu-item">
                                    <Link to="#">Equipment</Link>
                                </li>
                            )} */}

              <li className="menu-item">
                <Link to={`/my-leagues/${props.leagueData.league_id}/resources`}>Resources</Link>
              </li>

              <li className="menu-item">
                <Link to={`/my-leagues/${props.leagueData.league_id}/orders`}>Orders</Link>
              </li>
            </>)}
        </ul>
        }
      </StyledMenu>
    </StyledMenuWrapper>
  )
}

export default MyLeagueMenu
