// @flow

import { call, fork, put, select, take } from 'redux-saga/effects'
import { reset } from 'redux-form'
import {
  MAKE_INSURANCE_GET_QUOTE,
  getPlayerQuote,
  cleanInsuranceData,
  MAKE_INSURANCE_CREATE_INSURANCE,
  createInsurance,
} from 'src/redux/actions/insurances/insurances'
import { getPaymentToken, setPaymentPayload, SUBMIT_INSURANCE_PAYMENT } from 'src/redux/actions/payments/payments'
import http from 'src/services/http'
import { history } from 'src/services/history'
import { showToast } from 'src/redux/actions/ui/ui'
import { linkGeneratorByName } from 'src/modules/insurancePurchase/utils'
import config from 'src/config'

// types
import type { IOEffect } from 'redux-saga/effects'

function * watchGetQuote(): Generator<IOEffect, void, any> {
  while (true) {
    const { payload: { nextStep, leagueId } } = yield take(MAKE_INSURANCE_GET_QUOTE.REQUEST)

    const { makeInsurance: { coverage, insuranceData } } = yield select()

    try {
      if (!coverage) {
        throw new Error('Coverage is empty')
      }
      const { data } = yield call(http, 'api/insurance/purchase/cost', {
        body: JSON.stringify({ coverage, insuranceData }),
        method: 'POST',
      })
      yield put(getPlayerQuote.success({ ...data }))
      if (nextStep) yield call(history.push, linkGeneratorByName(nextStep, leagueId))
    } catch (e) {
      yield put(getPlayerQuote.error(e.message))
      yield put(showToast.success({ title: e.message, messageType: 'error' }))
    }
  }
}

function * watchCreateInsurance(): Generator<IOEffect, void, any> {
  while (true) {
    const { payload: { nextStep, leagueId } } = yield take(MAKE_INSURANCE_CREATE_INSURANCE.REQUEST)

    const { makeInsurance: { coverage, insuranceData, additionalInsureds } } = yield select()

    try {
      if (!coverage) {
        throw new Error('Coverage is empty')
      }
      const { data } = yield call(http, 'api/insurance/purchase/create', {
        body: JSON.stringify({ coverage, insuranceData, additionalInsureds }),
        method: 'POST',
      })
      yield put(createInsurance.success({ ...data }))
      if (nextStep) yield call(history.push, linkGeneratorByName(nextStep, leagueId))
    } catch (e) {
      yield put(createInsurance.error(e.message))
      yield put(showToast.success({ title: e.message, messageType: 'error' }))
    }
  }
}

function * watchSubmitInsurancePayment(): Generator<IOEffect, void, any> {
  while (true) {
    const {
      payload,
    } = yield take(SUBMIT_INSURANCE_PAYMENT.REQUEST)

    const { makeInsurance: { quoteData: { totalCost, vendorData } } } = yield select()

    try {
      const { nonce, token, storeInVaultOnSuccess, paymentType } = payload
      yield http('api/insurance/purchase/payment', {
        body: JSON.stringify({
          totalCost,
          vendorData,
          paymentType,
          ...(nonce ? { nonceFromTheClient: nonce } : { paymentMethodToken: token }),
          storeInVaultOnSuccess,
        }),
        method: 'POST',
      })

      yield put(setPaymentPayload.success())
      yield put(cleanInsuranceData.success())
      yield put(reset(config.insuranceCostForm.id))
      yield call(history.replace, '/my-insurances/purchase/thank-you')
    } catch (e) {
      yield put(setPaymentPayload.error(e.message || e))
      yield put(getPaymentToken.request())
    }
  }
}

export default [
  fork(watchGetQuote),
  fork(watchCreateInsurance),
  fork(watchSubmitInsurancePayment),
]
