// @flow

import { stopSubmit } from 'redux-form'
import moment from 'moment'
import { call, fork, put, take } from 'redux-saga/effects'
import { CREATE_SEASON, createSeason } from 'src/redux/actions/seasons/seasons'
import config from 'src/config'
import { trimObjectStringValues } from 'src/helpers/formatHelpers'
import { cloneDeep } from 'lodash'
import http from 'src/services/http'
import { history } from 'src/services/history'
// types
import type { IOEffect } from 'redux-saga/effects'

const shapeToNumber = (val: string) => {
  return +val
}

const toNumberValues = ['min', 'max']

function * watchCreateSeason(): Generator<IOEffect, void, any> {
  while (true) {
    const {
      payload: { redirectCb, leagueId, ...values },
    } = yield take(CREATE_SEASON.REQUEST)
    const formId = config.createSeasonForm.id

    const formValues = cloneDeep(values)

    trimObjectStringValues(formValues)

    toNumberValues.forEach(item => {
      formValues[item] = shapeToNumber(formValues[item])
    })

    if (formValues.age_grade_range === 'age') {
      formValues.min_age = formValues.min
      formValues.max_age = formValues.max
      delete formValues.min
      delete formValues.max
    } else {
      formValues.min_grade = formValues.min
      formValues.max_grade = formValues.max
      delete formValues.min
      delete formValues.max
    }

    formValues.start_date = moment.utc(formValues.start_date).startOf('day')
    formValues.end_date = moment.utc(formValues.end_date).endOf('day')
    formValues.registration_start_date = moment.utc(formValues.registration_start_date).startOf('day')
    formValues.registration_end_date = moment.utc(formValues.registration_end_date).endOf('day')

    try {
      yield call(http, 'api/season', {
        body: JSON.stringify({
          ...formValues,
          league_id: shapeToNumber(leagueId),
        }),
        method: 'POST',
      })

      yield put(createSeason.success(values))
      yield call(history.push, `/my-leagues/${leagueId}/league-management`)
    } catch (e) {
      yield put(createSeason.error(e.message))
      yield put(stopSubmit(formId, e.errors ? e.errors : {}))
    }
  }
}

export default [fork(watchCreateSeason)]
