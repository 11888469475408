// @flow
import React from 'react'
import { withRouter } from 'react-router-dom'
// styles
import { ButtonPrimary } from 'src/common/components'
const InsuranceButton = ({ linkTo, history }) => {
  const PURCHASE_BUTTON_TEXT = 'PURCHASE INSURANCE'

  return (
    <>
      <span className="empty-block" />
      <ButtonPrimary
        buttonText={PURCHASE_BUTTON_TEXT}
        size="hi-small"
        action={() => history.push(linkTo)}
      />
    </>
  )
}

export default withRouter(InsuranceButton)
