// @flow
import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import SidebarLink from './fragments/sidebarLink'
import SidebarExternalLink from './fragments/sidebarExternalLink'
import MyLeagueMenu from './fragments/myLeagueMenu'
import { DashboardIcon, MyLeaguesIcon, EditIcon, SearchIcon, StartLeagueIcon, BlankIcon, MyInsurancesIcon, CalendarIcon } from 'src/assets/icons'
import { LogoInverse } from 'src/assets/images'
import { getMainAppOriginUrl } from 'src/helpers/urlHelpers'
import { REACT_APP_CREATE_LEAGUE_FEATURE_FLAG, ROLES, REACT_APP_MATRIX_SITE } from 'src/common/constants'
import { FEATURES_LIST } from 'src/config/featureManagement'
import { Payment, Assessment } from '@material-ui/icons'
import { RenderIf, ButtonPrimary } from 'src/common/components'

import { compose } from 'redux'

// styles
import { StyledSidebar, StyledHeader } from './sidebarStyles'
import type { UserState } from 'src/redux/reducers/user'
import { withMyLeagues, withUser, withFeatureManagement } from 'src/decorators'
import type { MyLeaguesState } from 'src/redux/reducers/myLeagues'
import type { FeatureManagementState } from 'src/redux/reducers/featureManagement'
import { canUserUseMatrix } from 'src/helpers/rolesHelpers'

interface SidebarStateTypes {
    showMenu?: boolean,
    user: UserState,
    myLeagues: MyLeaguesState,
    getMyLeagues: () => void,
    featureManagement: FeatureManagementState,
    getFeature: (data: string) => void
}

// only for dev
const itIsDev = id => {
  return window.location.hostname.includes('football') && id === 1545
}

const policyLink = `${getMainAppOriginUrl()}/policy`

class Sidebar extends React.Component<SidebarStateTypes> {
  componentDidMount() {
    this.props.getMyLeagues()
    this.props.getFeature(FEATURES_LIST.AVALARA_TAXES)
  }

  render() {
    const {
      showMenu,
      user: {
        userData,
      },
      myLeagues: {
        leagueData,
        myLeaguesData,
      },
      featureManagement,
    } = this.props
    const isUserLeagueOwner = userData && userData.roles && userData.roles.find(role => role === 'LEAGUE_OWNER')

    const ownLeagues = myLeaguesData.filter(({ roles }) => ROLES.LEAGUE_OWNER === roles)
    const taxExemptionCondition = featureManagement?.features?.[FEATURES_LIST.AVALARA_TAXES]?.status && ownLeagues?.length
    return (
      <StyledSidebar showMenu={showMenu}>
        <StyledHeader className="sidebar-header">
          <img src={LogoInverse} alt="logo"/>
        </StyledHeader>
        {
          userData && (
            <>
              {
                userData.first_name
                  ? (
                    <div className="user-name">Hello, <span>{userData.first_name} {userData.last_name}</span></div>
                  )
                  : (
                    <Link to="/edit-profile" className="user-name empty">
                      <span className="empty">Please edit your profile</span>
                    </Link>
                  )
              }
            </>

          )
        }
        {canUserUseMatrix(userData, myLeaguesData) && !itIsDev(userData?.id) && (
          <div className="matrix-button link-wrapper d-flex justify-content-center">
            <ButtonPrimary
              size="small"
              buttonText="GO TO MATRIX"
              action={() => {
                window.location.href = REACT_APP_MATRIX_SITE
              }}
            />
          </div>
        )}
        <MyLeagueMenu
          role="organizer"
          leagueData={leagueData}
        />
        <h4>User Menu</h4>
        <div className="sidebar-menu">
          <SidebarLink title="Dashboard" icon={<DashboardIcon/>} path="/" isCurrent />
          <SidebarLink title="My Leagues" icon={<MyLeaguesIcon/>} path="/my-leagues"/>
          <SidebarLink title="Insurances" icon={<MyInsurancesIcon/>} path="/my-insurances"/>
          {isUserLeagueOwner && REACT_APP_CREATE_LEAGUE_FEATURE_FLAG === 'true' && <SidebarLink path="/my-leagues/create" title="Start a League" icon={<StartLeagueIcon/>} />}
          <SidebarLink path="/league-finder" title="League Finder" icon={<SearchIcon/>} />
          <SidebarLink path="/edit-profile" icon={<EditIcon/>} title="Edit Profile" />
          <SidebarLink path="/payment-settings" icon={<Payment />} title="Payment Settings" />
          <SidebarLink path="/payments" icon={<CalendarIcon/>} title="Payment History" />
          <RenderIf condition={taxExemptionCondition}>
            <SidebarLink path="/exemption-certificates" icon={<Assessment/>} title="Exemption Certificates" />
          </RenderIf>
        </div>
        <div className="link-menu">
          <SidebarExternalLink path={policyLink} icon={<BlankIcon/>} title="Privacy Policy" />
        </div>
      </StyledSidebar>
    )
  }
}

export default compose(
  withMyLeagues,
  withUser,
  withRouter,
  withFeatureManagement,
)(Sidebar)
