// @flow

import { call, fork, put, take, select, all } from 'redux-saga/effects'
import { VALIDATE_ADDRESS, validateAddress, getLeague, GET_LEAGUE } from 'src/redux/actions/myLeagues/myLeagues'
import { getFeature } from 'src/redux/actions/featureManagement/featureManagement'
import { fetchValidateAddress } from 'src/services/myLeagues'
import { showToast } from 'src/redux/actions/ui/ui'
import { FEATURES_LIST } from 'src/config/featureManagement'
// types
import type { IOEffect } from 'redux-saga/effects'

function * watchValidateAddress(): Generator<IOEffect, void, any> {
  while (true) {
    const {
      payload: { ...values },
    } = yield take(VALIDATE_ADDRESS.REQUEST)

    try {
      let stateData = {}

      yield put(getFeature.request(FEATURES_LIST.AVALARA_TAXES))
      const { featureManagement } = yield select()
      const taxExemptionCondition = featureManagement?.features?.[FEATURES_LIST.AVALARA_TAXES]?.status
      if (taxExemptionCondition) {
        const { leagueId } = values
        yield put(getLeague.request({ leagueId }))
        yield take(GET_LEAGUE.SUCCESS)
        const { myLeagues: { leagueData } } = yield select()
        const shippingData = {
          state: leagueData?.shipping_state,
          address: leagueData?.shipping_address_1,
          city: leagueData?.shipping_city,
          zipCode: leagueData?.shipping_zip,
          type: 'shipping',
        }

        const correspondenceData = {
          state: leagueData?.billing_state,
          address: leagueData?.billing_address_1,
          city: leagueData?.billing_city,
          zipCode: leagueData?.billing_zip,
          type: 'correspondence',
        }

        const fetchData = yield all([
          call(fetchValidateAddress, shippingData),
          call(fetchValidateAddress, correspondenceData),
        ],
        )
        stateData = fetchData.reduce((acc, cur) => {
          if (!cur.isValid) {
            return {
              ...acc,
              [cur.type]: { ...cur, message: `We were unable to validate your address. Please double check your ${cur.type} address` },
            }
          } else {
            return acc
          }
        }, {})
      }
      yield put(validateAddress.success(stateData))
    } catch (e) {
      yield put(validateAddress.error(e.message))
      yield put(showToast.success({ title: e.message, messageType: 'error' }))
    }
  }
}

export default [fork(watchValidateAddress)]
