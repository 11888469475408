// @flow

const roles = [
  {
    name: 'PARENT',
    labelText: 'Parent',
    title: 'Parent',
    registrationAvailable: true,
  },
  {
    name: 'COACH',
    labelText: 'Coach',
    title: 'Coach',
    registrationAvailable: true,
  },
  {
    name: 'OFFICIAL',
    labelText: 'Official',
    title: 'Official',
    registrationAvailable: true,
  },
  {
    name: 'LEAGUE_OWNER',
    labelText: 'I am interested in starting a league',
    title: 'League Organizer',
    registrationAvailable: false,
  },
  {
    name: 'CO_OWNER',
    labelText: 'Co-Organizer',
    title: 'CO League Organizer',
    registrationAvailable: false,
  },
]

export default roles
