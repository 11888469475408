// @flow
import React, { lazy } from 'react'
import { PrivateRoute } from 'src/common/components'

const ThankYouPage = lazy(() => import('src/modules/insurancePurchase/thankYouPage'))
const ChildLayout = lazy(() => import('src/modules/insurancePurchase/childLayout'))

export default [
  <PrivateRoute exact={true} path="/my-insurances/purchase/step/:step" component={ChildLayout} key="insurance-stepper"/>,
  <PrivateRoute exact={true} path="/my-insurances/purchase/step/:step/:leagueId" component={ChildLayout} key="insurance-stepper-for-league"/>,

  <PrivateRoute exact={true} path="/my-insurances/purchase/thank-you" component={ThankYouPage} key="insurance-thank-you"/>,
]
