// @flow

import React, { lazy } from 'react'
import { PrivateRoute } from 'src/common/components'

const OrdersList = lazy(() => import('src/modules/ordersList'))
const PaidOrderContainer = lazy(() => import('src/modules/ordersList/paidOrder/PaidOrderContainer'))

export default [
  <PrivateRoute exact={true} path="/my-leagues/:leagueId/orders" component={OrdersList} key="/my-leagues/leagueId/orders"/>,
  <PrivateRoute exact={true} path="/my-leagues/:leagueId/orders/:orderId" component={PaidOrderContainer} key="/my-leagues/leagueId/orders"/>,
]
