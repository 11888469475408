// @flow
import client from 'braintree-web/client'
import usBankAccount from 'braintree-web/us-bank-account'

class Braintree {
  getInstance = async(paymentToken: string) => {
    const clientInstance = await client.create({
      authorization: paymentToken,
    })

    const ACHInstance = await usBankAccount.create({
      client: clientInstance,
    })

    return ACHInstance
  }
}

export default Braintree
