// @flow
import React from 'react'
import { Link } from 'react-router-dom'
import { EditIcon, LocationIcon, ArrowNextIcon, PhoneIcon } from 'src/assets/icons'
// styles
import { StyledDashboardHeader } from './dashboardHeaderStyles'

// types
import type { DashboardHeaderTypes } from './DashboardHeaderTypes'

const DashboardHeader = (props: DashboardHeaderTypes) => {
  const link = props.websiteLink && !(/^http:|^https:\/\//.test(props.websiteLink)) ? 'http://' + props.websiteLink : props.websiteLink
  return (
    <StyledDashboardHeader>
      <div className="user-info">
        <h2 className="title">{props.title}</h2>
        <div className="info-row">
          {props.leagueInfo &&
          <div className="info-col">
            <span>{props.leagueInfo}</span>
          </div>
          }
          {props.location &&
          <div className="info-col">
            <div className="location">
              <span className="icon-wrapper">
                <LocationIcon/>
              </span>
              <span>{props.location}</span>
            </div>
          </div>
          }
          {props.editProfileLink &&
          <div className="info-col">
            {props.leagueId
              ? (
                <Link to={`/my-leagues/${props.leagueId}/edit`} className="edit-link">
                  <span className="icon-wrapper">
                    <EditIcon/>
                  </span>
                  <span>Edit League</span>
                </Link>
              )
              : (
                <Link to="/edit-profile" className="edit-link">
                  <span className="icon-wrapper">
                    <EditIcon/>
                  </span>
                  <span>Edit Profile</span>
                </Link>
              )
            }
          </div>
          }
          {props.phone &&
          <div className="info-col">
            <div className="contact phone">
              <span className="icon-wrapper">
                <PhoneIcon />
              </span>
              {props.phone}
            </div>
          </div>
          }
          {link &&
          <div className="info-col">
            <div className="contact website">
              <span className="icon-wrapper">
                <ArrowNextIcon />
              </span>
              <a href={link} rel="noopener noreferrer" target="_blank" >Visit Website</a>
            </div>
          </div>
          }
        </div>
      </div>
    </StyledDashboardHeader>
  )
}

export default DashboardHeader
