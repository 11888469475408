// @flow
import React, { lazy } from 'react'
import { PrivateRoute } from 'src/common/components'

const CertificatesList = lazy(() => import('src/modules/exemptionCertificates/certificatesList'))

const url = '/exemption-certificates'

export default [
  <PrivateRoute exact={true} path={url} component={CertificatesList} key="exemption-certificates"/>,
]
