// @flow
import { COUNTRY_CODES } from 'src/common/constants'
import { normalizeZipCA } from 'src/helpers/formNormalizers'

export default (item: any) => {
  const zip = item.league_country === COUNTRY_CODES.CA ? normalizeZipCA(item.league_zip) : item.league_zip
  return item && item.league_zip && item.league_address_1 && item.league_city && item.league_state
    ? `${item.league_address_1} ${item.league_city}, ${item.league_state}, ${zip}, ${item.league_country}`
    : null
}
