// @flow
import { COUNTRY_CODES } from 'src/common/constants'

const isCanada = (country: ?string) => country === COUNTRY_CODES.CA

const isUSA = (country: ?string) => country === COUNTRY_CODES.US

export {
  isCanada,
  isUSA,
}
