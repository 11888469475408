// @flow
import { combineReducers } from 'redux'
import storage from 'redux-persist/lib/storage'
import { reducer as formReducer } from 'redux-form'
import { persistReducer } from 'redux-persist'
import user from './user'
import myLeagues from './myLeagues'
import publicLeagues from './publicLeagues'
import divisions from './divisions'
import addresses from './addresses'
import season from './season'
import payments from './payments'
import filesState from './filesState'
import ui from './ui'
import NFLTeams from './NFLTeams'
import bulkOrder from './bulkOrder'
import makeOrder from './makeOrder'
import makeInsurance from './makeInsurance'
import resources from './resources'
import members from './members'
import serviceWorkers from './serviceWorkers'
import orders from './orders'
import myInsurances from './myInsurances'
import messageCenter from './messageCenter'
import resourcesDashboard from './resourcesDashboard'
import featureManagement from './featureManagement'
import exemptionCertificates from './exemptionCertificates'

const userPersistConfig = {
  key: 'user',
  storage,
  blacklist: ['userErrors', 'loginErrors', 'passwordErrors', 'isLoading'],
}

const leaguePersistConfig = {
  key: 'myLeagues',
  storage,
  blacklist: ['organizationsData', 'cuurentLeagueData', 'myLeaguesErrors', 'myLeaguesData', 'isLoadingLeague', 'isLoading', 'surveyQuestions'],
}

const makeInsurancePersistConfig = {
  key: 'makeInsurance',
  storage,
  blacklist: ['isLoading', 'quoteError'],
}

const makeOrderPersistConfig = {
  key: 'makeOrder',
  storage,
  blacklist: ['isLoading', 'makeOrderErrors', 'lastTouchedTeamForm', 'lastTouchedTeamFormField', 'hasOrderSubmitFailed'],
}

const reducers = {
  form: formReducer,
  user: persistReducer(userPersistConfig, user),
  myLeagues: persistReducer(leaguePersistConfig, myLeagues),
  publicLeagues,
  divisions,
  addresses,
  season,
  payments,
  filesState,
  ui,
  NFLTeams,
  resources,
  makeInsurance: persistReducer(makeInsurancePersistConfig, makeInsurance),
  orders,
  bulkOrder,
  makeOrder: persistReducer(makeOrderPersistConfig, makeOrder),
  members,
  serviceWorkers,
  myInsurances,
  messageCenter,
  resourcesDashboard,
  featureManagement,
  exemptionCertificates,
}

export default combineReducers(reducers)
