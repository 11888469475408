// @flow
import styled from 'styled-components'
import Pagination from '@material-ui/lab/Pagination'

export const StyledTable = styled.div`
    th {
      font-weight: bold;
    }
    
    tr {
    
      td {
      
        a {
            text-decoration: underline;
            
            :hover {
              text-decoration: none;
            }
          }
      }
    }
`

export const StyledPagination = styled(Pagination)`
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
`
