// @flow
import * as React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import {
  getExemptionCertificates,
  submitExemptionCertificate,
  getExposureZones,
  getExemptionCertificateAttachment,
} from 'src/redux/actions/exemptionCertificates/exemptionCertificates'

const withExemptionCertificates = <P>(WrappedComponent: React.ComponentType<P>) => {
  class WithFeatureManagementComponent extends React.Component<P> {
    render() {
      return <WrappedComponent {...this.props} />
    }
  }
  const mapStateToProps = ({ exemptionCertificates }) => ({ exemptionCertificates })

  const mapDispatchToProps = (dispatch, ownProps) => ({
    getExemptionCertificates: data => dispatch(getExemptionCertificates.request(data)),
    submitExemptionCertificate: data => dispatch(submitExemptionCertificate.request(data)),
    getExposureZones: data => dispatch(getExposureZones.request(data)),
    getExemptionCertificateAttachment: data => dispatch(getExemptionCertificateAttachment.request(data)),
  })

  return connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withRouter(WithFeatureManagementComponent))
}

export default withExemptionCertificates

