// @flow

import React, { useState, useEffect } from 'react'
import { PAYMENT_SELECT_OPTIONS, PAYMENT_TYPES, NO_PAYMENT_METHOD_AVAILABLE } from 'src/config/ACHConfig'
import {
  ButtonPrimary,
  PaymentSelect,
  ACHMethods,
  CardPayment,
  RenderIf,
  Loader,
} from 'src/common/components'
import { getDefaultPaymentMethod } from 'src/helpers/paymentHelper'
import { FEATURES } from 'src/helpers/featureManagementHelper'
import { getFeature } from 'src/services/featureManagement'
import { withUser } from 'src/decorators'
import { isUSA } from 'src/helpers/countryHelper'
import PayWithCheck from './fragments/payWithCHeck'
import type { ACHMethod, CreditCardType } from 'src/redux/reducers/payments'
import type { UserState } from 'src/redux/reducers/user'

export type SubmitPaymentType = {
    paymentType?: string,
    token?: ?string,
    nonce?: ?string,
    storeInVaultOnSuccess?: ?boolean
};

export type PaymentType = {
    onSubmit: (data: SubmitPaymentType) => void,
    paymentMethods: ?{
      cards: CreditCardType[],
      ACH: ACHMethod[]
    },
    paymentToken: string,
    canAddCard: ?boolean,
    addButtonText: ?string,
    buttonText: ?string,
    can_pay_by_check: ?boolean,
    handleCheckSubmit: () => void,
    checkMessage: ?string,
    deletePaymentMethod: (token: string) => void,
    editable: ?boolean,
    verifiedOnly: ?boolean,
    user: UserState
};

const Payment = ({
  onSubmit,
  paymentMethods,
  paymentToken,
  canAddCard = true,
  addButtonText = 'Add a payment method',
  buttonText = 'Submit payment',
  can_pay_by_check = false,
  handleCheckSubmit,
  checkMessage,
  deletePaymentMethod,
  editable,
  verifiedOnly = true,
  user: { userData },
}: PaymentType) => {
  const [paymentType, setPaymentType] = useState(PAYMENT_SELECT_OPTIONS[0].value)
  const [ACHMethod, setACHMethod] = useState(null)
  const [paymentCard, setPaymentCard] = useState(null)
  const [loading, setLoading] = useState(null)
  const [selectOptions, setSelectOptions] = useState(PAYMENT_SELECT_OPTIONS.filter(item => item.value !== FEATURES.ACH.toUpperCase()))
  const currentToken = paymentType === PAYMENT_TYPES.ACH ? ACHMethod?.token : paymentCard?.token

  const loadFeature = async name => {
    try {
      setLoading(true)
      const { status } = await getFeature(name)
      if (status && isUSA(userData?.country)) {
        setSelectOptions(PAYMENT_SELECT_OPTIONS)
      }
      setLoading(false)
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setACHMethod(getDefaultPaymentMethod(paymentMethods?.ACH) || paymentMethods?.ACH?.[0])
  }, [paymentMethods?.ACH?.length])

  useEffect(() => {
    loadFeature(FEATURES.ACH)
  }, [])

  const onSubmitPayment = data => {
    onSubmit({
      paymentType,
      token: currentToken,
      nonce: data?.nonce,
      storeInVaultOnSuccess: data?.storeInVaultOnSuccess,
    })
  }

  const handleDeletePaymentMethod = () => {
    currentToken && deletePaymentMethod(currentToken)
  }
  return (
    <div>
      <Loader show={loading} />
      <PaymentSelect
        label="Available payment types:"
        value={paymentType}
        handleChange={e => {
          setPaymentType(e.target.value)
        }}
        selectOptions={selectOptions}
        can_pay_by_check={can_pay_by_check}
      />
      <RenderIf condition={paymentType === PAYMENT_TYPES.ACH}>
        <RenderIf condition={Array.isArray(paymentMethods?.ACH) && paymentMethods?.ACH?.length}>
          <ACHMethods
            paymentMethods={paymentMethods}
            checked={ACHMethod}
            setChecked={value => () => {
              setACHMethod(value)
            }}
            verifiedOnly={verifiedOnly}
            deletePaymentMethod={handleDeletePaymentMethod}
            editable={editable}
          />
          <div className="d-flex justify-content-center">
            <ButtonPrimary
              size="medium"
              buttonText="Submit payment"
              action={onSubmitPayment}
            />
          </div>
        </RenderIf>
        <RenderIf condition={!Array.isArray(paymentMethods?.ACH) || paymentMethods?.ACH?.length === 0}>
          <h4>
            {NO_PAYMENT_METHOD_AVAILABLE}
          </h4>
        </RenderIf>

      </RenderIf>
      <RenderIf condition={paymentType === PAYMENT_TYPES.CARD} >
        <CardPayment
          payments={{ paymentMethods, paymentToken }}
          paymentCard={paymentCard}
          setPaymentCardMethod={setPaymentCard}
          onSubmitCardPayment={onSubmitPayment}
          canAddCard={canAddCard}
          addButtonText={'Add a payment method'}
          buttonText={'Submit payment'}
          deletePaymentMethod={handleDeletePaymentMethod}
          editable={editable}
        />
      </RenderIf>
      <RenderIf condition={(paymentType === PAYMENT_TYPES.CHECK) && can_pay_by_check && handleCheckSubmit && checkMessage} >
        <PayWithCheck
          checkMessage={checkMessage}
          handleCheckSubmit={handleCheckSubmit}
        />
      </RenderIf>
    </div>
  )
}

export default withUser(Payment)
