// @flow
import React from 'react'
import { createPortal } from 'react-dom'
import { ButtonPrimary } from 'src/common/components'
import { CloseIcon } from 'src/assets/icons'

// styles
import { StyledWrapper, StyledModal, StyledCancelButton } from './ModalStyles'

// types
import type { ModalTypes } from './ModalTypes'

const Modal = (props: ModalTypes) => {
  const {
    title,
    subtitle,
    confirmBtnText,
    cancelBtnText,
    cancelBtnPrependContent,
    isCancelInPlainStyle,
    showHideBtn = false,
    showCancelBtn = true,
    onConfirm,
    onCancel,
    onHide,
    bodyContent: BodyContent = null,
  } = props

  const cancelContent = (
    <>
      {cancelBtnPrependContent}
      {cancelBtnText}
    </>
  )

  const el = document.getElementById('modal-root')
  return createPortal(
    <StyledWrapper style={{ zIndex: 1500 }}>
      <StyledModal>
        {showHideBtn && <div className="modal-close" onClick={onHide}><CloseIcon/></div>}
        <div className="modal-title">{title}</div>
        <div className="modal-subtitle">{subtitle}</div>
        {BodyContent && <div className="modal-subtitle"><BodyContent /></div>}
        <div className="modal-action">
          <ButtonPrimary buttonText={confirmBtnText} size="small" action={onConfirm}/>
          {showCancelBtn && (
            <>
              {isCancelInPlainStyle
                ? <StyledCancelButton onClick={onCancel}>
                  {cancelContent}
                </StyledCancelButton>
                : <ButtonPrimary buttonText={cancelContent} size="small" action={onCancel}/>
              }
            </>
          )}
        </div>
      </StyledModal>
    </StyledWrapper>,
    // $FlowFixMe
    el,
  )
}

export default Modal
