// @flow
import type { OrderSummaryInfoType } from './OrderSummaryInfoType'
import React, { useEffect, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { compose } from 'redux'

import { FEE_ITEMS, ITEM_TYPES, ORDER_MODE, PAYMENT_STATUS, COUNTRY_CODES } from 'src/common/constants'
import { getPriceString, formatMoney as format } from 'src/helpers/moneyHelpers'
import { withFeatureManagement, withUser, withPayments } from 'src/decorators'
import { FEATURES_LIST } from 'src/config/featureManagement'

import { StyledOrderSummaryInfo } from './orderSummaryInfoStyles'
import { SummaryRow } from './SummaryRow'

const SalesHint = ({ leagueId }) => {
  return (
    <>
      RCX Sports LLC is obligated to collect sales tax from leagues that have not provided a sales tax exemption certificate.
      Leagues may be exempt from sales tax if they have registered as a reseller or if they are a registered nonprofit and
      are exempt from tax. If you are exempt from sales tax and have not yet uploaded your certificate of exemption,
      please upload your form at this{' '}
      <Link className="link-underline" to={`/my-leagues/${leagueId}/exemption-certificate`}>
        link
      </Link>.
      Please note that all submissions require review and approval
      from RCX Sports before orders can be placed as sales tax exempt.
    </>
  )
}

const concatAmountDescription = (coreAmount, subAmount) => {
  const amountDescription = []
  if (coreAmount) {
    amountDescription.push(`Core: ${coreAmount}`)
  }
  if (subAmount) {
    amountDescription.push(`Sub: ${subAmount}`)
  }
  return amountDescription.join(', ')
}

const OrderSummaryInfo = ({
  order,
  totalOrdered,
  totalCost,
  additional,
  refunds,
  customInfo,
  featureManagement,
  getFeature,
  payments: { currencyRate },
  user: { userData },
}: OrderSummaryInfoType) => {
  const { currency, email } = userData || {}
  useEffect(() => {
    getFeature(FEATURES_LIST.AVALARA_TAXES)
  }, [])

  const formatMoney = price => getPriceString({
    price,
    currency,
    currencyRate: order?.currency_cad_rate || currencyRate,
  })
  const jerseyAmountDescription = concatAmountDescription(
    customInfo?.core_jersey_amount,
    customInfo?.sublimated_jersey_amount,
  )
  const shortsAmountDescription = concatAmountDescription(
    customInfo?.core_shorts_amount,
    customInfo?.sublimated_shorts_amount,
  )

  const taxExemptionCondition = featureManagement?.features?.[FEATURES_LIST.AVALARA_TAXES]?.status ||
                                (order.tax_amount && order.tax_amount !== '0.00')

  const isLeagueOwner = order?.league_owner_email === email

  const ITEM_TYPES_LIST = [{
    teamLabel: 'Jerseys Ordered',
    label: 'Jerseys Total',
    type: ITEM_TYPES.UNIFORM,
    amountDescription: jerseyAmountDescription,
  }, {
    teamLabel: 'Premium Jerseys Ordered',
    label: 'Premium Jerseys Total',
    type: ITEM_TYPES.PREMIUM_JERSEY,
  }, {
    teamLabel: 'Shorts Ordered',
    label: 'Shorts Total',
    type: ITEM_TYPES.SHORTS,
    amountDescription: shortsAmountDescription,
  }, {
    teamLabel: 'Socks Ordered',
    label: 'Socks Total',
    type: ITEM_TYPES.SOCKS,
  }, {
    teamLabel: 'Sleeves Ordered',
    label: 'Sleeves Total',
    type: ITEM_TYPES.SLEEVES,
  }, {
    teamLabel: 'Gloves Ordered',
    label: 'Gloves Total',
    type: ITEM_TYPES.GLOVES,
  }, {
    teamLabel: 'Headbands Ordered',
    label: 'Headbands Total',
    type: ITEM_TYPES.HEADBAND,
  }, {
    teamLabel: 'Coach Shirts Ordered',
    label: 'Coach Shirts Total',
    type: ITEM_TYPES.COACH_SHIRTS,
  }, {
    teamLabel: 'Mouthguards Ordered',
    label: 'Mouthguards Total',
    type: ITEM_TYPES.MOUTHGUARD,
  }, {
    teamLabel: 'Premium Mouthguards Ordered',
    label: 'Premium Mouthguards Total',
    type: ITEM_TYPES.PREMIUM_MOUTHGUARD,
  }, {
    teamLabel: 'Pylon Sets Ordered',
    label: 'Pylon Sets Total',
    type: ITEM_TYPES.PYLON_SETS,
  }, {
    teamLabel: 'Paid Flag Belts Ordered',
    label: 'Flag Belts Total',
    type: ITEM_TYPES.BELT,
    customTeamCondition: additional.belts?.paid,
    customTeamValue: additional.belts?.paid || '0',
  }, {
    teamLabel: 'Team Belts Ordered',
    label: 'Team Belts Total',
    type: ITEM_TYPES.PREMIUM_BELT,
    customTeamCondition: additional.premiumBelts?.paid,
    customTeamValue: additional.premiumBelts?.paid || '0',
  }, {
    teamLabel: 'Paid Standard Footballs Ordered',
    label: 'Standard Footballs Total',
    type: ITEM_TYPES.FOOTBALL,
    customTeamValue: (additional.blue_balls?.paid || 0) + (additional.brown_balls?.paid || 0) + (additional.mini_balls?.paid || 0),
  }, {
    teamLabel: 'Paid Premium Footballs Ordered',
    label: 'Premium Footballs Total',
    type: ITEM_TYPES.PREMIUM_FOOTBALL,
    customTeamValue: (additional?.pee_wee_premium_balls?.paid || 0) + (additional?.junior_premium_balls?.paid || 0),
  }, {
    teamLabel: 'Lettering Ordered',
    label: 'Lettering Total',
    type: FEE_ITEMS.LETTERING,
  }, {
    label: 'Bundle By Team Total',
    type: FEE_ITEMS.BUNDLING,
    customCondition: totalCost[FEE_ITEMS.BUNDLING] >= 0,
    customValue: totalCost[FEE_ITEMS.BUNDLING] === 0 ? 'Free' : `${formatMoney(totalCost[FEE_ITEMS.BUNDLING])}`,
  }]
  const dutiesCondition = order.order_mode !== ORDER_MODE.VIRTUAL && order.shipping_country === COUNTRY_CODES.CA
  return (
    <StyledOrderSummaryInfo className="col">
      {ITEM_TYPES_LIST.map((itemType: any) => {
        const amountDescription = itemType?.amountDescription ? `(${itemType?.amountDescription})` : ''
        return (
          <Fragment key={itemType.type}>
            <SummaryRow
              label={itemType.teamLabel}
              value={itemType.customTeamValue || `${totalOrdered[itemType.type]} ${amountDescription}`}
              condition={
                order.order_mode === ORDER_MODE.TEAM &&
              itemType.teamLabel &&
              (
                typeof itemType.customTeamCondition !== 'undefined'
                  ? itemType.customTeamCondition
                  : totalOrdered[itemType.type]
              )}
            />

            <SummaryRow
              label={itemType.label}
              value={itemType.customValue || `${formatMoney(totalCost[itemType.type])}`}
              condition={totalCost[itemType.type] || itemType.customCondition}
            />
          </Fragment>
        )
      })}
      <SummaryRow
        label="Additional Merch Ordered"
        value={(totalOrdered[ITEM_TYPES.APPAREL_WHITE_TEE] || 0) +
               (totalOrdered[ITEM_TYPES.APPAREL_SLEEVE_TEE] || 0) +
               (totalOrdered[ITEM_TYPES.APPAREL_HOODIE] || 0)}
        condition={
          order.order_mode === ORDER_MODE.TEAM && (
            totalOrdered[ITEM_TYPES.APPAREL_WHITE_TEE] ||
               totalOrdered[ITEM_TYPES.APPAREL_SLEEVE_TEE] ||
               totalOrdered[ITEM_TYPES.APPAREL_HOODIE]
          )
        }
      />

      <SummaryRow
        label="Additional Merch Total"
        value={`${formatMoney((totalCost[ITEM_TYPES.APPAREL_WHITE_TEE] || 0) +
               (totalCost[ITEM_TYPES.APPAREL_SLEEVE_TEE] || 0) +
               (totalCost[ITEM_TYPES.APPAREL_HOODIE] || 0))}`}
        condition={totalCost[ITEM_TYPES.APPAREL_WHITE_TEE] ||
               totalCost[ITEM_TYPES.APPAREL_SLEEVE_TEE] ||
               totalCost[ITEM_TYPES.APPAREL_HOODIE]}
      />

      <SummaryRow
        label="Order Amount"
        value={`${formatMoney(order.vt_amount)}`}
        condition={typeof order.vt_amount === 'number'}
      />

      <SummaryRow
        label="Shipping Cost"
        value={order.shipping_cost_is_free ? 'Free' : `${formatMoney(order.shipping_amount)}`}
        condition={true}
      />

      <SummaryRow
        label="Sales Tax"
        value={order.exempt && order.tax_amount <= 0 ? `${formatMoney(0)} – exempt` : `${formatMoney(order.tax_amount)}`}
        hint={isLeagueOwner && <SalesHint leagueId={order.league_id} />}
        condition={taxExemptionCondition}
      />

      <SummaryRow
        label="Duties"
        value={`${formatMoney(order.duties)}`}
        condition={dutiesCondition}
      />

      <SummaryRow
        label="Fee"
        value={`${formatMoney(order.fees)}`}
        condition={dutiesCondition}
      />

      <SummaryRow
        label="Order Total"
        value={`${formatMoney(order.total_amount)}`}
        condition={order.total_amount}
      />

      <SummaryRow
        label="Charge for failed ACH"
        value={`${formatMoney(order.penalty_amount)}`}
        condition={+order.penalty_amount > 0}
      />

      <SummaryRow
        label="Discount"
        value={`-${formatMoney(order.discount_amount)}`}
        condition={+order.discount_amount > 0}
      />

      <SummaryRow
        label={'Credit Applied'}
        value={`-${formatMoney(order.credit_paid_amount)}`}
        condition={order.credit_paid_amount > 0}
      />

      <SummaryRow
        label={'Total Paid'}
        value={`${formatMoney(order.total_paid)}`}
        condition={order.payment_status === PAYMENT_STATUS.PAID && format(order.total_amount) !== format(order.total_paid)}
      />

      <SummaryRow
        label={'Total to Pay'}
        value={`${formatMoney(order.amount_to_pay)}`}
        condition={order.payment_status !== PAYMENT_STATUS.PAID && order.amount_to_pay > 0 && order.total_amount !== order.amount_to_pay}
      />

      <SummaryRow
        label="Total Refunded"
        value={`(${formatMoney(order.refund_amount)})`}
        condition={order.refund_amount > 0}
      />

      <SummaryRow
        label="Shipping Method"
        value={order.shipment_method}
        condition={order.order_mode === ORDER_MODE.VIRTUAL}
      />

      <SummaryRow
        label="Payment Description"
        value={order.payment_description}
        condition={order.payment_description}
      />
    </StyledOrderSummaryInfo>
  )
}

export default compose(
  withFeatureManagement,
  withPayments,
  withUser,
)(OrderSummaryInfo)
