// @flow

import { stopSubmit } from 'redux-form'
import moment from 'moment-timezone'
import { call, fork, put, take } from 'redux-saga/effects'
import config from 'src/config'
import { SUBMIT_EXEMPTION_CERTIFICATES, submitExemptionCertificate } from 'src/redux/actions/exemptionCertificates/exemptionCertificates'
import http from 'src/services/http'
import { history } from 'src/services/history'
import { showToast } from 'src/redux/actions/ui/ui'
// types
import type { IOEffect } from 'redux-saga/effects'

function * watchSubmitExemptionCertificate(): Generator<IOEffect, void, any> {
  while (true) {
    const {
      payload: { ...values },
    } = yield take(SUBMIT_EXEMPTION_CERTIFICATES.REQUEST)
    const formId = config.uploadCertificate.id

    const doesNotExpireValue = '12/31/9999'

    try {
      const {
        file,
        leagueId,
        expirationDate,
        exemptionReason,
        exposureZone,
        doesNotExpire,
      } = values
      const data = new FormData()
      data.append('file', file)
      data.append('leagueId', leagueId)
      data.append('expirationDate', doesNotExpire
        ? moment.utc(doesNotExpireValue).endOf('day').format()
        : moment.utc(expirationDate).endOf('day').format(),
      )
      data.append('exemptionReason', exemptionReason)
      data.append('exposureZone', exposureZone)
      yield call(http, 'api/exemptionCertificates', {
        body: data,
        method: 'POST',
        headers: {
          'Content-Disposition': `attachment; filename=${file.name}`,
          'Content-Length': file.length,
        },
        withoutContentType: true,
      })

      yield put(submitExemptionCertificate.success())
      yield call(history.push, '/exemption-certificates')
    } catch (e) {
      yield put(submitExemptionCertificate.error(e.message))
      yield put(stopSubmit(formId))
      yield put(showToast.success({ title: e.message, messageType: 'error' }))
    }
  }
}

export default [fork(watchSubmitExemptionCertificate)]
