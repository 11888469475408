import styled from 'styled-components'
import { device } from 'src/common/theme/mediaQueries'

export const StyledDashboardHeader = styled.div`
    background-color: color: ${props => props.theme.main.colors.inverseColor};
    border-left: 7px solid ${props => props.theme.main.colors.blockHeaderBackground};
    box-shadow: ${props => props.theme.main.secondary.blockBoxShadow};
    padding: 15px;
    margin-bottom: 30px;
    
    .info-row {
        display: flex;
        flex-wrap: wrap;
    }
    
    .info-col {
        border-right: 1px solid ${props => props.theme.main.colors.borderColor};
        line-height: 1.2;
        margin-bottom: 8px;
        padding-left: 8px;
        padding-right: 20px;
        
        :first-child {
            padding-left: 0;
        }
        
        :last-child {
            border-right: 0;
        }
        
        > * {
            align-items: center;
            display: flex;
        }
    }
    
    .title {
        margin-bottom: 15px;
    }
    
    .icon-wrapper {
        margin-right: 4px;
        min-width: 20px;
    }
    
    @media ${device.tablet} {
        .info-row {
            flex-direction: column;
        }
        
        .info-col {
            padding-left: 0;
        }
    }
`
