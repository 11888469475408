// @flow

import { stopSubmit } from 'redux-form'
import { call, fork, put, take } from 'redux-saga/effects'
import { cloneDeep } from 'lodash'
import config from 'src/config'
import { trimObjectStringValues } from 'src/helpers/formatHelpers'
import { EDIT_LEAGUE, editLeague } from 'src/redux/actions/myLeagues/myLeagues'
import http from 'src/services/http'
import { history } from 'src/services/history'
// types
import type { IOEffect } from 'redux-saga/effects'

const shapeToNumber = (val: string) => {
  return val ? +val : null
}

const toNumberValues = ['org_type_id', 'min', 'max', 'league_id', 'shipping_address_id', 'billing_address_id']

function * watchEditLeague(): Generator<IOEffect, void, any> {
  while (true) {
    const {
      payload: { ...values },
    } = yield take(EDIT_LEAGUE.REQUEST)
    const formId = config.editLeagueForm.id

    const formValues = cloneDeep(values)

    trimObjectStringValues(formValues)

    toNumberValues.forEach(item => {
      formValues[item] = shapeToNumber(formValues[item])
    })
    const { league_id } = formValues

    try {
      yield call(http, `api/league/${league_id}`, {
        body: JSON.stringify({
          ...formValues,
        }),
        method: 'PUT',
      })

      yield put(editLeague.success(values))
      yield call(history.push, `/my-leagues/${values.league_id}/league-management`)
    } catch (e) {
      yield put(editLeague.error(e.message))
      yield put(stopSubmit(formId, e.errors ? e.errors : {}))
    }
  }
}

export default [fork(watchEditLeague)]
