// @flow
import { requestTypeCreator } from 'src/helpers'
import type { CreateSeasonActionTypes, GetSeasonsActionTypes, EditSeasonActionsTypes, DeleteSeasonActionsTypes } from './seasonsTypes'

export const CREATE_SEASON = requestTypeCreator('CREATE_SEASON')
export const GET_SEASONS = requestTypeCreator('GET_SEASONS')
export const EDIT_SEASON = requestTypeCreator('EDIT_SEASON')
export const DELETE_SEASON = requestTypeCreator('DELETE_SEASON')

export const createSeason = {
  error: (payload: any): CreateSeasonActionTypes => ({ type: CREATE_SEASON.FAILURE, payload }),
  request: (payload: any): CreateSeasonActionTypes => ({ type: CREATE_SEASON.REQUEST, payload }),
  success: (payload: any): CreateSeasonActionTypes => ({ type: CREATE_SEASON.SUCCESS, payload }),
}

export const getSeasons = {
  error: (payload: any): GetSeasonsActionTypes => ({ type: GET_SEASONS.FAILURE, payload }),
  success: (payload: any): GetSeasonsActionTypes => ({ type: GET_SEASONS.SUCCESS, payload }),
}

export const editSeason = {
  error: (payload: any): EditSeasonActionsTypes => ({ type: EDIT_SEASON.FAILURE, payload }),
  request: (payload: any): EditSeasonActionsTypes => ({ type: EDIT_SEASON.REQUEST, payload }),
  success: (payload: any): EditSeasonActionsTypes => ({ type: EDIT_SEASON.SUCCESS, payload }),
}

export const deleteSeason = {
  error: (payload: any): DeleteSeasonActionsTypes => ({ type: DELETE_SEASON.FAILURE, payload }),
  request: (payload: any): DeleteSeasonActionsTypes => ({ type: DELETE_SEASON.REQUEST, payload }),
  success: (payload: any): DeleteSeasonActionsTypes => ({ type: DELETE_SEASON.SUCCESS, payload }),
}
