// @flow
import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import createSagaMiddleware from 'redux-saga'
import rootReducer from './reducers'
import rootSaga from './sagas'
import { persistStore, persistReducer } from 'redux-persist'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import storage from 'redux-persist/lib/storage'
import * as Sentry from '@sentry/react'

const sagaMiddleware = createSagaMiddleware()
const sentryReduxEnhancer = Sentry.createReduxEnhancer({})

const persistConfig = {
  key: 'root',
  storage,
  blacklist: [
    'user',
    'form',
    'myLeagues',
    'myInsurances',
    'publicLeagues',
    'divisions',
    'addresses',
    'season',
    'payments',
    'ui',
    'filesState',
    'NFLTeams',
    'bulkOrder',
    'makeOrder',
    'members',
    'resources',
    'serviceWorkers',
    'orders',
  ],
  stateReconciler: autoMergeLevel2,
  timeout: null,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)
export const store = createStore(
  persistedReducer,
  composeWithDevTools(
    applyMiddleware(sagaMiddleware),
    sentryReduxEnhancer,
  ),
)

export const persistor = persistStore(store)

sagaMiddleware.run(rootSaga)
