// @flow
import React from 'react'
import { Route } from 'react-router-dom'

// types
import type { NotFoundRoutePropsType } from './NotFoundRouteTypes'

const NotFoundRoute = ({
  component: Component,
  ...rest

}: NotFoundRoutePropsType) => {
  return (
    <Route
      {...rest}
      render={props =>
        <>
          <div>
            <Component {...props} />
          </div>
        </>
      }
    />
  )
}

export default NotFoundRoute
