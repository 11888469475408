// @flow
import styled from 'styled-components'
import { device } from 'src/common/theme/mediaQueries'

export const SummaryTooltipStyles = styled.div`
  max-width: 500px;
  padding: 10px;
  line-height: 1.5;
  font-size: 14px;
`
export const StyledOrderSummaryInfo = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 0 15px;
    .question-mark {
      font-size: 14px;
      font-weight: bold;
      display: inline-flex;
      width: 20px;
      height: 20px;
      margin-left: 7px;
      align-items: center;
      justify-content: center;
      background-color: #81c8e1;
      color: #fff;
      border-radius: 50%;
    }
    .summary-row {
      align-items: center;
      border-bottom: 1px solid ${props => props.theme.main.colors.borderColor};
      display: flex;
      justify-content: space-between;
      padding: 12px 0;
      width: 100%;
    }

    .summary-row-2 {
      align-items: center;
      display: flex;
      justify-content: space-between;
      padding: 12px 0;
      width: 100%;
    }

    .label {
      flex: 0 0 auto;
      color: #6b6b6b;
      padding-right: 8px;
    }

    .value {
      text-align: right;
      word-break: break-word;
    }
  
    .summary-col {
        padding: 0 15px;
        width: 50%;
    }

  @media ${device.mobileL} {
    flex-direction: column;
    .summary-row {
      margin: 0;
    }

    .summary-col {
      width: 100%;
    }
  }
`
