
// @flow
import { call, put, take, fork } from 'redux-saga/effects'
import { downloadOrderInvoicePDF, DOWNLOAD_ORDER_INVOICE_PDF } from 'src/redux/actions/bulkOrder/bulkOrder'
import { fetchDownloadOrderInvoicePDF } from 'src/services/bulkOrder'
import { showToast } from 'src/redux/actions/ui/ui'

// types
import type { IOEffect } from 'redux-saga/effects'

function * watchDownloadOrderInvoicePDF(): Generator<IOEffect, void, any> {
  while (true) {
    const { payload } = yield take(DOWNLOAD_ORDER_INVOICE_PDF.REQUEST)

    try {
      const { file, filename } = yield call(fetchDownloadOrderInvoicePDF, payload)

      const a = document.createElement('a')
      a.href = URL.createObjectURL(file)
      a.setAttribute('download', filename)
      a.click()

      yield put(downloadOrderInvoicePDF.success())
    } catch (e) {
      yield put(downloadOrderInvoicePDF.error(e.message))
      yield put(showToast.success({ title: e.message, messageType: 'error' }))
    }
  }
}

export default [fork(watchDownloadOrderInvoicePDF)]
