// @flow
import React, { memo, useState, useEffect } from 'react'

import { withTheme } from 'styled-components'
// styles
import { StyledLoader } from './loaderStyles'

interface LoaderProps {
    show?: boolean,
    duration?: number
}

const Loader = (props: LoaderProps) => {
  const { show = false, duration } = props
  const [timeLeft, setTimeLeft] = useState(0)

  useEffect(() => {
    if (show && duration) {
      setTimeLeft(duration)
    } else {
      setTimeLeft(0)
    }
  }, [show, duration])

  useEffect(() => {
    if (!timeLeft) return

    const timer = setTimeout(() => {
      setTimeLeft(timeLeft - 1)
    }, 1000)
    // Clear timeout if the component is unmounted
    return () => clearTimeout(timer)
  }, [timeLeft])

  const message = timeLeft ? `Expected request time: ${timeLeft} sec. Please wait...` : ''

  const LoaderComponent = show
    ? (
      <>
        <StyledLoader>
          <div className="loader">Loading</div>
        </StyledLoader>
        <StyledLoader>
          <div className="message">{message}</div>
        </StyledLoader>
      </>
    )
    : null
  return LoaderComponent
}

export default withTheme(memo<{}>(Loader))
