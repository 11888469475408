
// @flow

export default {
  loginForm: {
    id: 'loginForm',
  },
  registerForm: {
    id: 'registerForm',
  },
  registerLeagueOwnerForm: {
    id: 'registerLeagueOwnerForm',
  },
  editUserForm: {
    id: 'editUserForm',
  },
  changeUserEmail: {
    id: 'changeUserEmail',
  },
  activatePasswordForm: {
    id: 'activatePasswordForm',
  },
  createLeagueForm: {
    id: 'createLeagueForm',
  },
  leaguesFinderForm: {
    id: 'leaguesFinderForm',
  },
  createDivisionForm: {
    id: 'createDivisionForm',
  },
  createSeasonForm: {
    id: 'createSeasonForm',
  },
  editLeagueForm: {
    id: 'editLeagueForm',
  },
  editDivisionForm: {
    id: 'editDivisionForm',
  },
  editSeasonForm: {
    id: 'editSeasonForm',
  },
  forgotPasswordForm: {
    id: 'forgotPasswordForm',
  },
  leagueInsuranceForm: {
    id: 'leagueInsuranceForm',
  },
  chooseTeamsForm: {
    id: 'chooseTeamsForm',
  },
  insuranceCostForm: {
    id: 'insuranceCostForm',
  },
  importSizesForm: {
    id: 'importSizesForm',
  },
  teamOrderItems: {
    dynamic: (id: string) => `teamOrderItems-${id}`,
    isTeamForm: (name: string) => name.indexOf('teamOrderItems-') === 0,
  },
  freeBallsItems: {
    id: 'freeBallsItems',
  },
  freeBeltsItems: {
    id: 'freeBeltsItems',
  },
  paidBallsItems: {
    id: 'paidBallsItems',
  },
  paidBeltsItems: {
    id: 'paidBeltsItems',
  },
  paidItems: {
    dynamic: (id: string) => `paidItems-${id}`,
  },
  mainOrderForm: { // to remove?
    id: 'mainOrderForm',
  },
  addressesInfoForm: {
    id: 'addressesInfoForm',
  },
  inviteUserForm: {
    id: 'inviteUserForm',
  },
  shippingForm: {
    id: 'shippingForm',
  },

  leagueAgreementForm: {
    id: 'leagueAgreementForm',
  },
  virtualTerminalForm: {
    id: 'virtualTerminalForm',
  },
  addressValidationForm: {
    id: 'addressValidationForm',
  },
  leagueSurveyForm: {
    id: 'leagueSurveyForm',
  },
  additionalInsuredForm: {
    id: 'additionalInsuredForm',
  },
  ACHPaymentForm: {
    id: 'ACHPaymentForm',
  },
  microTransferForm: {
    id: 'microTransferForm',
  },
  uploadCertificate: {
    id: 'uploadCertificate',
  },
  searchCertificateForm: {
    id: 'searchCertificateForm',
  },
  payWithCheckForm: {
    id: 'payWithCheckForm',
  },
  ballsForm: {
    id: 'ballsForm',
  },
  additionalItemsForm: {
    id: 'additionalItemsForm',
  },
}
