// @flow
import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { Layout } from 'src/common/components'
import { withUser } from 'src/decorators'
import { getToken, setPreviousLocation } from 'src/services/user'
import * as Sentry from '@sentry/react'
// types
import type { PrivateRouteProps } from './PrivateRouteTypes'

const SentryRoute = Sentry.withSentryRouting(Route)

const PrivateRoute = ({
  component: Component,
  user,
  ...rest
}: PrivateRouteProps) => {
  return (
    <SentryRoute
      {...rest}
      render={props => {
        if (user.userData && getToken()) {
          setPreviousLocation(props.location.pathname, user?.userData?.id)
          return (
            <Layout>
              <Component {...props} />
            </Layout>
          )
        }
        return (
          <Redirect to="/login" />
        )
      }
      }
    />
  )
}

export default withUser(PrivateRoute)
