// @flow

import { stopSubmit } from 'redux-form'
import { call, fork, put, take } from 'redux-saga/effects'
import { cloneDeep, mapKeys } from 'lodash'
import config from 'src/config'
import { trimObjectStringValues } from 'src/helpers/formatHelpers'
import { CREATE_LEAGUE, createLeague } from 'src/redux/actions/myLeagues/myLeagues'
import http from 'src/services/http'
import { history } from 'src/services/history'
// types
import type { IOEffect } from 'redux-saga/effects'

const shapeToNumber = (val: string) => {
  return +val
}

const toNumberValues = ['org_type_id', 'min', 'max']

function * watchCreateLeague(): Generator<IOEffect, void, any> {
  while (true) {
    const {
      payload: { redirectCb, ...values },
    } = yield take(CREATE_LEAGUE.REQUEST)
    const formId = config.createLeagueForm.id

    const formValues = cloneDeep(values)

    trimObjectStringValues(formValues)

    toNumberValues.forEach(item => {
      formValues[item] = shapeToNumber(formValues[item])
    })

    const surveyAnswers = mapKeys(formValues.survey_answers, (value, key) => key.split('-').pop())

    try {
      yield call(http, 'api/league', {
        body: JSON.stringify({
          ...formValues,
          survey_answers: surveyAnswers,

        }),
        method: 'POST',
      })

      yield put(createLeague.success(values))
      yield call(history.push, '/my-leagues/league-survey')
      yield call(redirectCb, '/my-leagues/thank-you')
    } catch (e) {
      yield put(createLeague.error(e.message))
      yield put(stopSubmit(formId, e.errors ? e.errors : {}))
    }
  }
}

export default [fork(watchCreateLeague)]
