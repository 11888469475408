// @flow
import React from 'react'
import type { leagueActiveSeasonTypes } from './LeagueActiveSeasonTypes'

// types
import { SeasonRowStyle } from './leagueActiveSeasonStyles'
import { formatAbsoluteShortDate, isActiveDate } from 'src/helpers/dateHelpers'

const CardLeague = (props: leagueActiveSeasonTypes): any => {
  return (props.seasons && props.seasons.length
    ? (
      props.seasons.map(season => {
        const isActiveSeason = isActiveDate(season.start_date, season.end_date)
        return (
          <SeasonRowStyle key={season.id}>
            <div>{isActiveSeason
              ? 'Active'
              : ''} Season {formatAbsoluteShortDate(season.start_date)} - {formatAbsoluteShortDate(season.end_date)}
            </div>
            {!isActiveSeason && (
              <div>{isActiveDate(season.registration_start_date, season.registration_end_date)
                ? 'Active'
                : ''} Registration {formatAbsoluteShortDate(season.registration_start_date)} - {formatAbsoluteShortDate(season.registration_end_date)}
              </div>
            )}
          </SeasonRowStyle>
        )
      })
    )
    : (
      <SeasonRowStyle>
        <div>No active season</div>
        <div>No active registration</div>
      </SeasonRowStyle>
    )
  )
}

export default CardLeague
