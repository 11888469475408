// @flow
import * as React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { createSeason, editSeason, deleteSeason } from 'src/redux/actions/seasons/seasons'

const withSeasons = <P>(WrappedComponent: React.ComponentType<P>) => {
  class WithSeasonsComponent extends React.Component<P> {
    render() {
      return <WrappedComponent {...this.props} />
    }
  }
  const mapStateToProps = ({ season }) => ({ season })

  const mapDispatchToProps = (dispatch, ownProps) => ({
    createSeason: values => dispatch(createSeason.request({ ...values, leagueId: ownProps.match.params.leagueId, redirectCb: ownProps.history.push })),
    editSeason: values => dispatch(editSeason.request({ ...values, league_id: ownProps.match.params.leagueId, season_id: ownProps.match.params.seasonId })),
    deleteSeason: values => dispatch(deleteSeason.request({ ...values })),
  })

  return connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withRouter(WithSeasonsComponent))
}

export default withSeasons
