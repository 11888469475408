// @flow
import type { AddressesActionTypes } from 'src/redux/actions/addresses/addressesTypes'
import { GET_ADDRESSES } from 'src/redux/actions/addresses/addresses'

export type AddressesDataItem = {
  id: number,
  account_id: number,
  address_1: string,
  address_2: ?string,
  city: string,
  state: string,
  zip: string,
  county: string,
  country: string,
  name?: ?string
};
export type AddressesData = AddressesDataItem[];

export type AddressesState = {
  isLoading: boolean,
  addressesData: AddressesData,
  addressesErrors?: ?{ [key: string]: any } | ?string
};

const initialState = {
  isLoading: false,
  addressesData: [],
  addressesErrors: null,
}

const addresses = (state: AddressesState = initialState, action: AddressesActionTypes): AddressesState => {
  switch (action.type) {
    case GET_ADDRESSES.REQUEST:
    {
      return {
        ...state,
        isLoading: true,
      }
    }

    case GET_ADDRESSES.SUCCESS:
    {
      return ({
        ...state,
        isLoading: false,

        addressesData: [...action.payload],
        addressesErrors: null,
      })
    }

    case GET_ADDRESSES.FAILURE:
    {
      return { ...state, isLoading: false, addressesErrors: action.payload }
    }

    default: {
      return state
    }
  }
}

export default addresses
