// @flow

import React from 'react'

const SimpleCheckIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="14" height="14" viewBox="0 0 448.8 448.8">
      <g id="check">
        <polygon points="142.8,323.85 35.7,216.75 0,252.45 142.8,395.25 448.8,89.25 413.1,53.55   "
          fill="#45BB7E"/>
      </g>
    </svg>
  )
}

export default SimpleCheckIcon
