import { omit, flatten, find, isEmpty, each, cloneDeep } from 'lodash'
import { ITEM_TYPES, ADDITIONAL_ITEM_TYPES, VENDOR } from 'src/common/constants'
import { FORM_TYPES, STEPS } from 'src/modules/order/utils'

import { bTreeTraveller } from 'src/helpers/objectHelpers'

export const idToInputName = id => `${id}-item`

export const getIdFromInputName = fieldName => parseInt(fieldName) ? parseInt(fieldName) : null

export const formToIdsObject = formVals => {
  const result = {}
  for (const prop in formVals) {
    result[getIdFromInputName(prop)] = +formVals[prop]
  }
  return result
}

export const ITEMS_WITH_META_DATA = [
  ITEM_TYPES.SHORTS, ITEM_TYPES.SLEEVES,
]

export const DEFAULT_RULE_FOR_AMOUNT = 5

const sizesOrderingValue = {
  YXS: 5,
  'YXS/YS': 10,
  YS: 15,
  YM: 20,
  'YM/YL': 25,
  YL: 30,
  YXL: 40,
  AS: 50, // breakpoint
  S: 51,
  'AS/AM': 55,
  AM: 60,
  L: 69,
  AL: 70,
  AXL: 80,
  AXXL: 90,
}

const SIZE_TYPES_MAPPINGS = size => {
  const val = sizesOrderingValue[size]
  if (!val) return null
  return val >= sizesOrderingValue.AS ? 'adult' : 'youth'
}

const ballsOrderingValue = {
  WHITE: 5,
  BLACK: 4,
  BROWN: 3,
  BLUE: 2,
  RED: 1,
}

const beltsOrderingValue = {
  YELLOW: 1,
  GREEN: 2,
}

function reorderItems(items, prop, map) {
  items = items || []
  items.sort((a, b) => map[a[prop]] - map[b[prop]])
  return items
}

function getUpdatedFreeItemForms(state, newItems) {
  const { freeRules } = state
  const typeToRemove = []
  Object.keys(state.freeFormsState).forEach(type => {
    if (!freeRules[type]) {
      typeToRemove.push(type)
    } else {
      const neededItems = newItems[freeRules[type].FOR_TYPE] || []
      if (!neededItems.length) {
        typeToRemove.push(type)
      }
    }
  })

  return {
    freeFormsState: omit(state.freeFormsState, typeToRemove),
    freeRules: omit(freeRules, typeToRemove),
  }
}

export function addToMappedArray(mapped, key, item) {
  const array = mapped[key] || []
  array.push(item)
  mapped[key] = array
  return mapped
}

function reorderJrsPosition(mappedItems) {
  mappedItems = Object.entries(mappedItems)
  mappedItems.sort(([team_id1], [team_id2]) => (team_id1 > team_id2) - 0.5)
  return mappedItems.map(([team_id, items]) => ({ team_id, items: reorderItems(items, 'size', sizesOrderingValue) }))
}

const addMetaItemsToJerseys = (jerseys, finder, state) => {
  // see https://itrdev.atlassian.net/browse/NFLFLAG-1678
  const config = {
    NYJ: {
      color: 'BLACK',
      vendor: VENDOR.AUGUSTA,
    },
  }
  const result = cloneDeep(jerseys)
  for (const key in jerseys) {
    const [jrs] = result[key]
    for (const condition of jrs.jerseys_match_condition) {
      if (config[key] && config[key]?.vendor === state?.order?.vendor) {
        result[key].push(...finder({ ...condition, color: config[key].color }))
      } else {
        result[key].push(...finder(condition))
      }
    }
  }
  return result
}

export function groupItemsByType(items, state) {
  const mapped = {}
  const typed = {}

  const jerseys = {}

  const jrsMatchingPropsSet = new Set()
  const metaItems = []

  items.forEach(item => {
    mapped[item.id] = item
    addToMappedArray(typed, item.type, item)

    if (item.size) {
      item.size_type = SIZE_TYPES_MAPPINGS(item.size)
    }

    if ([ITEM_TYPES.UNIFORM, ITEM_TYPES.GLOVES, ITEM_TYPES.SOCKS, ITEM_TYPES.HEADBAND].includes(item.type)) {
      if (item.team_id) {
        addToMappedArray(jerseys, item.team_id, item)
        each(item.jerseys_match_condition, condition => Object.keys(condition).forEach(key => jrsMatchingPropsSet.add(key)))
      }
    } else if (ITEMS_WITH_META_DATA.includes(item.type)) {
      metaItems.push(item)
    }
  })

  const traveller = bTreeTraveller(Array.from(jrsMatchingPropsSet))

  metaItems.forEach(item => traveller.add(item))

  const shapedJerseys = addMetaItemsToJerseys(jerseys, traveller.get, state)
  return {
    all: items,
    forms: [
      ...reorderJrsPosition(shapedJerseys).map(data => ({
        ...data,
        form_type: FORM_TYPES.CORE,
        form_name: `${data.team_id}-${FORM_TYPES.CORE}`,
      })),
    ],
    [ITEM_TYPES.FOOTBALL]: reorderItems(typed[ITEM_TYPES.FOOTBALL], 'color', ballsOrderingValue),
    [ITEM_TYPES.BELT]: reorderItems(typed[ITEM_TYPES.BELT], 'color', beltsOrderingValue),
    [ITEM_TYPES.PREMIUM_BELT]: typed[ITEM_TYPES.PREMIUM_BELT],
    [ITEM_TYPES.UNIFORM]: typed[ITEM_TYPES.UNIFORM] || [],
    [ITEM_TYPES.SHORTS]: typed[ITEM_TYPES.SHORTS] || [],
    [ITEM_TYPES.SOCKS]: typed[ITEM_TYPES.SOCKS] || [],
    [ITEM_TYPES.SLEEVES]: typed[ITEM_TYPES.SLEEVES] || [],
    [ITEM_TYPES.MOUTHGUARD]: typed[ITEM_TYPES.MOUTHGUARD] || [],
    [ITEM_TYPES.GLOVES]: typed[ITEM_TYPES.GLOVES] || [],
    [ITEM_TYPES.HEADBAND]: typed[ITEM_TYPES.HEADBAND] || [],
    [ITEM_TYPES.COACH_SHIRTS]: typed[ITEM_TYPES.COACH_SHIRTS] || [],
    [ITEM_TYPES.PYLON_SETS]: typed[ITEM_TYPES.PYLON_SETS] || [],
    [ITEM_TYPES.PREMIUM_FOOTBALL]: reorderItems(typed[ITEM_TYPES.PREMIUM_FOOTBALL], 'color', ballsOrderingValue) || [],
    mapped,
  }
}

export function removeForm(state, form_name) {
  const form = state.items.forms.find(form => form.form_name === form_name)
  const idsToRemove = form && form.items.reduce((acc, cur) => {
    if ([ITEM_TYPES.UNIFORM, ITEM_TYPES.GLOVES, ITEM_TYPES.SOCKS, ITEM_TYPES.HEADBAND].includes(cur.type)) {
      acc[cur.id] = true
    }
    return acc
  }, {})

  const items = groupItemsByType(
    form
      ? state.items.all.filter(item => !idsToRemove[item.id])
      : state.items.all,
    state,
  )

  return {
    paidFormsState: omit(state.paidFormsState, form_name),
    ...getUpdatedFreeItemForms(state, items),
    items,
  }
}

export function teamsChange(state, { freeRules, saleItems }) {
  const items = groupItemsByType(saleItems, state)
  const newTeams = flatten(Object.values(items.forms)).map(({ team_id }) => team_id)
  const oldTeams = state.items.forms
  const formsToRemove = oldTeams.filter(form => newTeams.indexOf(form.team_id) === -1)

  const newState = {
    ...state,
    freeRules: { ...freeRules },
    items,
  }
  return formsToRemove.reduce((acc, cur) => ({
    ...acc,
    ...removeForm(acc, cur.form_name),
  }), newState)
}

export function countPaidTotal(state) {
  const paidForms = state.paidFormsState
  const { mapped } = state.items
  const output = {}

  for (const formName in paidForms) {
    const form = paidForms[formName]
    for (let id in form) {
      id = +id
      if (id && +form[id] > 0 && mapped[id]) {
        const { type } = mapped[id]
        output[type] = (output[type] || 0) + +form[id]
      }
    }
  }
  state.paidTotalCounter = output
  return state
}

export function countJerseyByTeamTotal(state) {
  const paidForms = state?.makeOrder?.paidFormsState
  const mapped = state?.makeOrder?.items?.mapped
  const output = {}

  for (const formName in paidForms) {
    const form = paidForms[formName]
    for (let id in form) {
      id = +id
      const quantity = +form[id]
      if (id && quantity > 0 && mapped[id]) {
        const { type, team_id } = mapped[id]
        if (type === ITEM_TYPES.UNIFORM) {
          output[team_id] = (output[team_id] || 0) + quantity
        }
      }
    }
  }
  return output
}

export function assingItemsToForms(state, items) {
  const paidFormsState = {}
  const freeFormsState = {}
  items.forEach(item => {
    const isPaid = !!item.price
    if (item.type === ITEM_TYPES.FOOTBALL || item.type === ITEM_TYPES.PREMIUM_FOOTBALL) {
      assingItemToForm(
        item,
        paidFormsState,
        ITEM_TYPES.FOOTBALL,
      )
    } else if (item.type === ITEM_TYPES.BELT) {
      assingItemToForm(
        item,
        isPaid ? paidFormsState : freeFormsState,
        item.type,
      )
    } else if (item.type === ITEM_TYPES.MOUTHGUARD) {
      assingItemToForm(
        item,
        paidFormsState,
        ITEM_TYPES.MOUTHGUARD,
      )
    } else if (item.type === ITEM_TYPES.PYLON_SETS) {
      assingItemToForm(
        item,
        paidFormsState,
        ITEM_TYPES.PYLON_SETS,
      )
    } else if (item.type === ITEM_TYPES.COACH_SHIRTS) {
      assingItemToForm(
        item,
        paidFormsState,
        ITEM_TYPES.COACH_SHIRTS,
      )
    } else if ([ITEM_TYPES.UNIFORM, ITEM_TYPES.GLOVES, ITEM_TYPES.SOCKS, ITEM_TYPES.HEADBAND].includes(item.type)) {
      const form_type = FORM_TYPES.CORE
      assingItemToForm(
        item,
        paidFormsState,
        `${item.team_id}-${form_type}`,
      )
    } else if (ITEMS_WITH_META_DATA.includes(item.type)) {
      const forms = prepareFormsOrder(item, state.order.isMatrixWithFlowAugusta)
      Object.entries(forms).forEach(([form_name, quantity]) => {
        assingItemToForm(
          {
            id: item.id,
            quantity,
          },
          paidFormsState,
          form_name,
        )
      })
    }
  })
  state.paidFormsState = paidFormsState
  state.freeFormsState = freeFormsState

  const credit = getJerseysCredit(state)
  let jerseysCreditChanged = false
  if (state.jerseysCredit && state.jerseysCredit !== credit) {
    jerseysCreditChanged = true
  }
  state.jerseysCredit = credit
  state.jerseysCreditChanged = jerseysCreditChanged

  return state
}

const prepareFormsOrder = (item, isMatrixWithFlowAugusta) => {
  let forms = (item.user_meta && item.user_meta.forms) || {}

  if (isMatrixWithFlowAugusta) {
    forms = Object.keys(forms).reduce((res, curr) => {
      const arr = curr.split('-')
      if (arr.slice(-1).pop() === FORM_TYPES.CORE) {
        arr[arr.length - 1] = FORM_TYPES.SUBL
        const formName = arr.join('-')
        res[formName] = (res[formName] || 0) + forms[curr]
      } else {
        res[curr] = (res[curr] || 0) + forms[curr]
      }
      return res
    }, {})
  }

  return forms
}

export function assingItemsToFormsFromExcel(state, foundItems, isMatrixWithFlowAugusta) {
  const paidFormsState = {}
  const freeFormsState = {}
  const { items: { forms } } = state

  foundItems.forEach(item => {
    const form = find(forms, {
      team_id: item.team_id,
      form_type: FORM_TYPES.CORE,
    })
    if (!form) return
    const foundConditions = {
      type: item.type,
      size: item.size,
      is_sublimated: item.is_sublimated,
    }
    if (item.is_sublimated) {
      foundConditions.team_id = item.team_id
    }
    const foundItem = find(form.items, foundConditions)
    if (!foundItem || foundItem.is_forbidden) return
    assingItemToForm(
      {
        id: foundItem.id,
        quantity: item.quantity,
      },
      paidFormsState,
      form.form_name,
    )
  })

  state.paidFormsState = paidFormsState
  state.freeFormsState = freeFormsState

  return state
}

export function findOnWhichStepsItemIs(item) {
  if (item.type === ITEM_TYPES.UNIFORM) {
    return STEPS.UNIFORM
  } else if (ADDITIONAL_ITEM_TYPES.includes(item.type)) {
    return [STEPS.ADDITIONAL]
  } else if (ITEMS_WITH_META_DATA.includes(item.type)) {
    const teams = (item.user_meta && item.user_meta.teams) || []
    const forms = (item.user_meta && item.user_meta.forms) || {}

    const formsStep = {}
    teams.forEach(team_id => {
      [FORM_TYPES.CORE, FORM_TYPES.SUBL].forEach(form_type => {
        if (forms[`${team_id}-${form_type}`]) {
          formsStep[STEPS.UNIFORM] = true
        }
      })
    })
    if (!isEmpty(formsStep)) {
      return Object.keys(formsStep)
    }
  }

  return [STEPS.CHOOSE_TEAMS]
}

function assingItemToForm(item, forms, name) {
  const form = forms[name] || {
    isValid: true,
  }
  form[item.id] = (form[item.id] || 0) + item.quantity
  forms[name] = form
}

export const getJerseysCredit = state => {
  const mergedState = { ...countPaidTotal(state) }
  const { freeRules, paidTotalCounter } = mergedState || {}
  const rules = freeRules?.[ITEM_TYPES.FOOTBALL]
  const amountPaid = paidTotalCounter?.[rules?.FOR_TYPE] || 0
  const credit = Math.floor(amountPaid / (rules?.FOR_EVERY_AMOUNT / DEFAULT_RULE_FOR_AMOUNT))

  return credit
}
