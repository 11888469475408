// @flow

import type {
  GetMyInsurancesActionTypes,
  CheckFileInsuranceActionTypes,
} from 'src/redux/actions/myInsurances/myInsurancesTypes'
import { requestTypeCreator } from 'src/helpers'

export const GET_MY_INSURANCES = requestTypeCreator('GET_MY_INSURANCES')
export const CHECK_FILE_INSURANCE = requestTypeCreator('CHECK_FILE_INSURANCE')

export const getMyInsurances = {
  error: (payload: any): GetMyInsurancesActionTypes => ({ type: GET_MY_INSURANCES.FAILURE, payload }),
  request: (payload: any): GetMyInsurancesActionTypes => ({ type: GET_MY_INSURANCES.REQUEST, payload }),
  success: (payload: any): GetMyInsurancesActionTypes => ({ type: GET_MY_INSURANCES.SUCCESS, payload }),
}

export const checkFileInsurance = {
  error: (payload: any): CheckFileInsuranceActionTypes => ({ type: CHECK_FILE_INSURANCE.FAILURE, payload }),
  request: (payload: any): CheckFileInsuranceActionTypes => ({ type: CHECK_FILE_INSURANCE.REQUEST, payload }),
  success: (payload: any): CheckFileInsuranceActionTypes => ({ type: CHECK_FILE_INSURANCE.SUCCESS, payload }),
}
