// @flow
import React from 'react'
// styles
import { StyledBlock } from './dashobardBlockStyles'
import { InsuranceButton } from 'src/common/components'

// types
import type { DashboardBlockTypes } from './DashboardBlockTypes'

const DashboardBlock = (props: DashboardBlockTypes) => {
  const { title, children, btnEntity, ButtonComponent = null } = props
  return (
    <StyledBlock halfWidth={props.halfWidth}>
      <div className="block-header">
        <h4 className={btnEntity ? 'title' : 'title full-width'}>{title}</h4>
        {btnEntity === 'insurance' &&
        <>
          <InsuranceButton linkTo="/purchase-insurance"/>
        </>
        }
        {ButtonComponent && (
          <ButtonComponent />
        )}
      </div>
      <div className="block-body">
        {children}
      </div>
    </StyledBlock>
  )
}

export default DashboardBlock
