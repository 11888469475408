// @flow

import { stopSubmit } from 'redux-form'
import { call, fork, put, take } from 'redux-saga/effects'
import config from 'src/config'
import { REGISTER_USER, registerUser } from 'src/redux/actions/user/user'
import http from 'src/services/http'
import { shapeRolesOnFormSubmit } from 'src/helpers/shapeRoles'
// types
import type { IOEffect } from 'redux-saga/effects'

function * watchRegister(): Generator<IOEffect, void, any> {
  while (true) {
    const {
      payload: { redirectCb, ...values },
    } = yield take(REGISTER_USER.REQUEST)
    const formId = config.registerForm.id
    const formValues = shapeRolesOnFormSubmit(values)

    try {
      yield call(http, 'register', {
        body: JSON.stringify({
          ...formValues,
        }),
        method: 'POST',
      })

      yield put(registerUser.success(formValues.email))
      redirectCb('/register/email-confirmation')
    } catch (e) {
      yield put(registerUser.error(e.message))
      yield put(stopSubmit(formId, e.errors ? e.errors : {}))
    }
  }
}

export default [fork(watchRegister)]
