import styled from 'styled-components'
import { device } from 'src/common/theme/mediaQueries'

export const StyledHeader = styled.div`
    align-items: center;
    background-color: ${props => props.theme.main.colors.backgroundLight};
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    padding: 20px;
    
    .header-menu {
        align-items: center;
        display: flex;
        
        .menu-item {
            padding-left: 30px;
        }
    }
    
    .header-link {
        color: ${props => props.theme.main.colors.primaryColor};
        font-family: ${props => props.theme.main.fonts.headerFontFamily};
        font-size: 14px;
        text-transform: uppercase;
    }
    
    .toggle-nav {
        cursor: pointer;
        display: none;
        height: 26px;
        margin-left: auto;
        position: relative;
        transform: rotate(0deg);
        transition: .5s ease-in-out;
        width: 35px;
        z-index: 99;
        
        span {
            background: ${props => props.theme.main.colors.secondaryColor};
            display: block;
            height: 3px;
            opacity: 1;
            left: 0;
            position: absolute;
            transform: rotate(0deg);
            transition: .25s ease-in-out;
            width: 100%;
            
            &:first-child {
                left: ${props => (props.showMenu ? '2px' : 'auto')};
                top: ${props => (props.showMenu ? '10px' : '0')};
                transform-origin: ${props => (props.showMenu ? 'default' : 'left center')};
                transform: ${props => (props.showMenu ? 'rotate(45deg)' : 'rotate(0)')};
            }
            
            &:nth-child(2) {
                opacity: ${props => (props.showMenu ? '0' : '1')};
                top: 10px;
                transform-origin: left center;
            }
            
            &:nth-child(3) {
                left: ${props => (props.showMenu ? '8px' : 'auto')};
                top: ${props => (props.showMenu ? '22px' : '20px')};
                transform-origin: left center;
                transform: ${props => (props.showMenu ? 'rotate(-45deg)' : 'rotate(0)')};
            }
        }
        
       
    }
    
    @media ${device.mobileL} {
        .toggle-nav {
            display: block;
        }
        
        .header-menu {
            background-color: #fff;
            box-shadow: ${props => props.theme.main.secondary.blockBoxShadow};
            display: ${props => (props.showMenu ? 'flex' : 'none')};
            flex-direction: column;
            padding: 12px 0;
            position: absolute;
            right: 0;
            top: 66px;
            z-index: 9;
            
            .menu-item {
                padding: 12px 30px;
                width: 100%;
                
                :last-child {
                    border-bottom: 0;
                }
            }
        }
    }
`
