// @flow

import { call, fork, put, take } from 'redux-saga/effects'
import { GET_ORDERS, getOrders } from 'src/redux/actions/orders/orders'
import { resetBulkOrder } from 'src/redux/actions/bulkOrder/bulkOrder'
import http from 'src/services/http'

// types
import type { IOEffect } from 'redux-saga/effects'

function * watchGetOrders(): Generator<IOEffect, void, any> {
  while (true) {
    const action = yield take(GET_ORDERS.REQUEST)
    const { leagueId } = action.payload

    try {
      const data = yield call(http, `api/order-list/${leagueId}`, {
        method: 'GET',
      })

      yield put(getOrders.success(data.data))
      yield put(resetBulkOrder.success())
    } catch (e) {
      yield put(getOrders.error(e.message))
    }
  }
}

export default [fork(watchGetOrders)]
