// @flow
import React from 'react'
import { CheckIcon, ErrorIcon, InfoIcon, WarningIcon } from 'src/assets/icons'
// styles
import { StyledMessage } from './toastMessageStyles'

// types
import type { ToastMessageTypes } from './ToastMessageTypes'

const handleIconType = (messageType: string) => {
  switch (messageType) {
    case 'success':
      return <CheckIcon />
    case 'warning':
      return <WarningIcon />
    case 'info':
      return <InfoIcon />
    case 'error':
      return <ErrorIcon />
    default:
      return <InfoIcon />
  }
}

const ToastMessage = ({
  title,
  messageType,
}: ToastMessageTypes) => {
  return (
    <StyledMessage messageType={messageType}>
      <span className="icon-wrapper">{messageType && handleIconType(messageType)}</span>
      <div className="text-wrapper">
        <span className="title">{title}</span>
      </div>
    </StyledMessage>
  )
}

export default ToastMessage
