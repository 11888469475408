import styled from 'styled-components'

export const StyledFile = styled.div`
    display: inline-flex;
    border-radius: 16px;
    border: 1px solid #ccc;
    align-items: center;
    padding: 2px
    .file-icon {
        margin-right: 5px;
    }
    .delete-icon {
        margin-left: 5px;
        cursor: pointer;
    }
    margin-top: 5px;
`

export const StyledFileWrapper = styled.div`
   margin-top: 10px;
`
