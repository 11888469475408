// @flow
import React from 'react'
import { Link } from 'react-router-dom'
import { LocationIcon, PhoneIcon, ArrowNextIcon } from 'src/assets/icons'
import { ButtonPrimary, LeagueActiveSeason, RenderIf } from 'src/common/components'
import WarningIcon from 'src/assets/icons/Warning'
import { config, getProps } from 'src/config/social'
// styles
import { StyledCard } from './cardLeagueStyles'

// types
import type { CardLeagueTypes } from './CardLeagueTypes'

const CardLeague = (props: CardLeagueTypes) => {
  const link = props.link && !(/^http:|^https:\/\//.test(props.link)) ? 'http://' + props.link : props.link

  const renderSocial = () => {
    return config.map(({ name, Component, title, type }) => {
      const data = props[name]
      const attributes = getProps({ data, type })
      return (
        <RenderIf key={name} condition={!!data}>
          <li className="list-item">
            <div className="contact website">
              <a {...attributes}>
                <span className="icon-wrapper">
                  {Component}
                </span>
                {title}</a>
            </div>
          </li>
        </RenderIf>
      )
    },
    )
  }
  return (
    <StyledCard pointer={!props.showButton}>
      <div className="card-left" onClick={props.showButton ? () => {} : props.onClickHandler}>
        <h5 className="card-title">
          <div>
            <div>{props.title}</div>
          </div>
          {props.status === 'PENDING' &&
          <span className="card-status">
            <WarningIcon/>
            <span>Pending NFL Admin Approval</span>
          </span>
          }
          {props.status === 'INACTIVE' &&
          <span className="card-status">
            <WarningIcon/>
            <span>Inactive</span>
          </span>
          }
          {props.status === 'DECLINED' &&
          <span className="card-status">
            <WarningIcon/>
            <span>Declined</span>
          </span>
          }
          {props.status === 'INACTIVE' &&
          <span className="card-status">
            <WarningIcon/>
            <span>This league is inactive and is not visible in the public League Finder. Please contact the NFL FLAG team to discuss the steps needed to activate this league at info@nflflag.com.</span>
          </span>
          }
        </h5>
        {props.role &&
        <span className="card-subtitle"><strong>Roles:</strong> {props.role}</span>
        }
        {props.division &&
        <span className="card-role"><strong>Division:</strong> {props.division}</span>
        }
        <div className="card-contacts d-flex align-items-center">
          <ul className="list-contacts d-flex list">
            {props.location &&
            <li className="list-item">
              <div className="contact location">
                <span className="icon-wrapper">
                  <LocationIcon />
                </span>
                {props.location}
              </div>
            </li>
            }
            {props.phone &&
            <li className="list-item">
              <div className="contact phone">
                <span className="icon-wrapper">
                  <PhoneIcon />
                </span>
                <a href={`tel: ${props.phone}`}>{props.phone}</a>
              </div>
            </li>
            }
            {link &&
            <li className="list-item">
              <div className="contact website">
                <span className="icon-wrapper">
                  <ArrowNextIcon />
                </span>
                <a href={link} rel="noopener noreferrer" target="_blank">Visit Website</a>
              </div>
            </li>
            }
          </ul>
        </div>
        <div className="card-contacts d-flex align-items-center">
          <ul className="list-contacts d-flex list">
            {renderSocial()}
          </ul>
        </div>
        {props.distance &&
        <span className="distance"><strong>Distance:</strong> {props.distance}</span>
        }
        {
          (props.has_boys || props.has_girls || props.has_coed) && (
            <ul className="list-contacts d-flex list">
              <li><strong>Team Genders: &nbsp;</strong></li>
              {props.has_boys && 'boys'}{props.has_boys && (props.has_girls || props.has_coed) ? ', ' : ''}
              {props.has_girls && 'girls'}{props.has_girls && props.has_coed ? ', ' : ''}
              {props.has_coed && 'co-ed'}
            </ul>
          )
        }
      </div>
      <div className="card-center">
        <div>
          <LeagueActiveSeason
            seasons={props.activeSeasons}
          />
        </div>
        <RenderIf condition={props.program_logo}>
          <div className="logo">
            <img src={props.program_logo} />
          </div>
        </RenderIf>

      </div>
      {props.showButton && (
        <div className="card-right">
          <Link to={props.buttonLink}>
            <ButtonPrimary buttonText={props.buttonText} />
          </Link>
        </div>
      )}

    </StyledCard>
  )
}

export default CardLeague
