// @flow

import React from 'react'
import { Field } from 'redux-form'
import { FormFileInput } from 'src/common/components'

// styles
import { StyledForm, StyledBlock } from './importSizesFormStyles'

// types
import type { ImportSizeFormPropsType } from './ImportSizesFormTypes'
const {
  REACT_APP_API_URL = '',
  REACT_APP_BULK_ORDER_TEMPLATE_LINK = '',
} = process.env

const getFileName = vendor => REACT_APP_BULK_ORDER_TEMPLATE_LINK

const ImportSizesForm = ({ makeOrder: { order }, sendMakeOrderFile, handleSubmit }: ImportSizeFormPropsType) => {
  return (
    <StyledBlock>
      <StyledForm>
        <div className="form-body">
          <div className="form-column">
            <h4 className="title">Choose the NFL Teams you want to add to the order <br /> Or click Proceed with order flag belts and footballs only</h4>
          </div>
          <div className="form-column input-file-wrapper link-wrapper column-direction">
            <Field
              name="file"
              id="file"
              component={FormFileInput}
              handleSubmit={handleSubmit}
              action={({ file }) => sendMakeOrderFile({ file, order })}
              params={{ leagueId: order.league_id }}
              size="meidum"
              buttonText="Import Sizes from XLS"
            />

            <a className="import-button download-link" href={`${REACT_APP_API_URL}/${getFileName(order.vendor)}`} download>
              <span>Download Sample File</span>
            </a>

          </div>
        </div>
      </StyledForm>
    </StyledBlock>
  )
}

export default ImportSizesForm
