// @flow
import type { BasicRedux } from 'src/redux/actions/insurances/insurancesTypes'
import {
  MAKE_INSURANCE_GET_QUOTE,
  MAKE_INSURANCE_SET_PLAYER_DATA,
  MAKE_INSURANCE_CLEAN_DATA,
  MAKE_INSURANCE_CLEAN_PLAYER_DATA,
  MAKE_INSURANCE_SET_COVERAGE,
  MAKE_INSURANCE_SET_ADDITIONAL_INSUREDS,
  MAKE_INSURANCE_CLEAN_ADDITIONAL_INSUREDS,
  MAKE_INSURANCE_CREATE_INSURANCE,
} from 'src/redux/actions/insurances/insurances'

type CostDataItem = {
  numberOfPlayers: number,
  costOnePlayer: number,
  groupName: string,
  totalCost: number
};

type AdditionalInsuredItem = {
  name: string,
  location: {
    address: string,
    city: string,
    state: string,
    zipCode: string
  }
};

const initialState = {
  coverage: 0,
  insuranceData: {},
  additionalInsureds: [],
  quoteData: {
    costs: {},
    startDate: '',
    endDate: '',
    vendorData: {},
    totalCost: 0,
  },
  isLoading: false,
  quoteError: null,
}

export type MakeInsuranceState = {
  coverage: number,
  insuranceData: any,
  additionalInsureds: AdditionalInsuredItem[],
  quoteData: {
    costs: ?{ [key: string]: CostDataItem },
    startDate?: string | number,
    endDate?: string | number,
    vendorData: {
      qNum?: number,
      qSuffix?: string
    } | null,
    totalCost: number
  } | null,
  isLoading: boolean,
  quoteError?: ?string
};

const makeInsurance = (state: MakeInsuranceState = initialState, action: BasicRedux): MakeInsuranceState => {
  switch (action.type) {
    case MAKE_INSURANCE_GET_QUOTE.REQUEST:
    case MAKE_INSURANCE_CREATE_INSURANCE.REQUEST:
    {
      return {
        ...state,
        isLoading: true,
        quoteError: null,
        quoteData: null,
      }
    }

    case MAKE_INSURANCE_GET_QUOTE.SUCCESS:
    case MAKE_INSURANCE_CREATE_INSURANCE.SUCCESS:
    {
      return {
        ...state,
        isLoading: false,
        quoteError: null,
        quoteData: action.payload,
      }
    }

    case MAKE_INSURANCE_GET_QUOTE.FAILURE:
    case MAKE_INSURANCE_CREATE_INSURANCE.FAILURE:
    {
      return {
        ...state,
        isLoading: false,
        quoteError: action.payload,
        quoteData: null,
      }
    }

    case MAKE_INSURANCE_SET_PLAYER_DATA.SUCCESS:
    {
      return { ...state, isLoading: false, insuranceData: action.payload }
    }

    case MAKE_INSURANCE_CLEAN_DATA.SUCCESS:
    {
      return { ...state, ...initialState }
    }

    case MAKE_INSURANCE_CLEAN_PLAYER_DATA.SUCCESS:
    {
      return { ...state, insuranceData: {} }
    }

    case MAKE_INSURANCE_SET_COVERAGE.SUCCESS:
    {
      return { ...state, isLoading: false, coverage: action.payload }
    }

    case MAKE_INSURANCE_SET_ADDITIONAL_INSUREDS.SUCCESS:
    {
      return { ...state, isLoading: false, additionalInsureds: action.payload }
    }

    case MAKE_INSURANCE_CLEAN_ADDITIONAL_INSUREDS.SUCCESS:
    {
      return { ...state, additionalInsureds: [] }
    }
    default: {
      return state
    }
  }
}

export default makeInsurance
