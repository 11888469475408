// @flow

import React from 'react'
import { CardLeague } from 'src/common/components'
import { canUserManageLeague, getSystemRoleName } from 'src/helpers/rolesHelpers'
import { withPublicLeagues } from 'src/decorators'
import getLocation from 'src/helpers/getLocation'

// types
import type { MyLeaguesDataItem } from 'src/redux/reducers/myLeagues'

// styles
import { StyledList } from './cardLeagueListStyles'

const getButtonData = ({ canManageLeague, isApproved, id }) => {
  const buttonData = {
    text: 'View details',
    link: `/my-leagues/${id}/league-management`,
  }
  if (canManageLeague && isApproved) {
    buttonData.text = 'MANAGE LEAGUE'
  } else if (canManageLeague && !isApproved) {
    buttonData.text = 'Preview League'
    buttonData.link = `/my-leagues/${id}/edit`
  }

  return buttonData
}

const sortArray = arr => {
  return arr.sort((a, b) => a.name.localeCompare(b.name))
}

const sortLeaguesByStatus = items => {
  const leagues = {
    APPROVED: [],
    INACTIVE: [],
    PENDING: [],
    DECLINED: [],
    OTHERS: [],
  }
  items.forEach(el => {
    if (leagues[el.approved]) {
      leagues[el.approved].push(el)
    } else {
      leagues.OTHERS.push(el)
    }
  })

  return [...sortArray(leagues.APPROVED), ...sortArray(leagues.INACTIVE), ...sortArray(leagues.PENDING), ...sortArray(leagues.DECLINED), ...sortArray(leagues.OTHERS)]
}

const List = ({ items, showButton, updatePublicLeague, notSortByStatus }: any) => {
  if (!notSortByStatus) {
    items = sortLeaguesByStatus(items)
  }

  return items.map((item: MyLeaguesDataItem) => {
    const itemRoleTitle = getSystemRoleName(item.roles)
    const canManageLeague = canUserManageLeague(item.roles)
    const isApproved = item.approved === 'APPROVED'
    const buttonData = getButtonData({ canManageLeague, isApproved, id: item.id })
    return (
      <li className="list-item" key={`ist-item${item.id}`}>
        <CardLeague
          status={item.approved}
          updated_at={item.updated_at}
          showButton={showButton}
          id={item.id}
          title={item.name}
          role={itemRoleTitle}
          location={getLocation(item)}
          phone={item.phone}
          link={item.website}
          has_boys={item.has_boys}
          has_girls={item.has_girls}
          has_coed={item.has_coed}
          distance={item.distance || null}
          activeSeasons={item.active_seasons}
          buttonText={buttonData.text}
          buttonLink={buttonData.link}
          facebook={item.facebook}
          instagram={item.instagram}
          twitter={item.twitter}
          email={item.email}
          program_logo={item.program_logo}
          onClickHandler={() => updatePublicLeague({ items, id: item.id })}
        />
      </li>
    )
  })
}

const CardLeagueList = ({ items, showButton = true, updatePublicLeague, notSortByStatus = false }: any) => {
  return (
    <StyledList className="list list-my-leagues"><List items={items} showButton={showButton} updatePublicLeague={updatePublicLeague} notSortByStatus={notSortByStatus}/></StyledList>
  )
}

export default withPublicLeagues(CardLeagueList)
