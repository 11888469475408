// @flow
import React from 'react'
import DescriptionIcon from '@material-ui/icons/Description'

// types
import type { FileType } from '../../CardInsuranceTypes'

// styles
import { StyledFilesList } from './StyledFilesList'

type FilesListPropsType = {
  insuranceId: number,
  files: FileType[],
  checkFile: (data: {insuranceId: number, fileId: number}) => void
};

const FilesList = ({ files, checkFile, insuranceId }: FilesListPropsType) => {
  return (
    <StyledFilesList>
      {/* $FlowFixMe */}
      {files?.map(({ id, name }) => (
        <li key={id} className="file-card" onClick={() => {
          checkFile({ insuranceId, fileId: id, name })
        }}
        >
          <DescriptionIcon />
          {name}
        </li>
      ),
      )}
    </StyledFilesList>
  )
}

export default FilesList
