// @flow
import React from 'react'
// styles
import { StyledLink } from './sidebarExternalLinkStyles'

// types
import type { SidebarExternalLinkTypes } from './SidebarExternalLinkTypes'

const SidebarExternalLink = (props: SidebarExternalLinkTypes) => {
  return (
    <StyledLink>
      <a
        href={props.path}
        className="sidebar-link"
        target="_blank"
        rel="noopener noreferrer"
      >
        <span className="link d-flex">
          <span className="icon-wrapper">{props.icon}</span>
          <span className="link-text">{props.title}</span>
        </span>
      </a>
    </StyledLink>
  )
}

export default SidebarExternalLink
