// @flow
import React, { lazy } from 'react'
import { PrivateRoute } from 'src/common/components'
const LeaguesList = lazy(() => import('src/modules/myLeagues/leaguesList'))
const CreateLeague = lazy(() => import('src/modules/myLeagues/createLeague'))
const EditLeague = lazy(() => import('src/modules/myLeagues/editLeague'))
const ThankYouPage = lazy(() => import('src/modules/myLeagues/thankYouPage'))
const LeagueManagement = lazy(() => import('src/modules/myLeagues/leagueManagement'))
const UploadCertificate = lazy(() => import('src/modules/myLeagues/uploadCertificate'))

export default [
  <PrivateRoute exact={true} path="/my-leagues" component={LeaguesList} key="my-leagues"/>,
  <PrivateRoute exact={true} path="/my-leagues/create" component={CreateLeague} key="my-leagues/create"/>,
  <PrivateRoute exact={true} path="/my-leagues/:leagueId/edit" component={EditLeague} key="my-leagues/edit"/>,
  <PrivateRoute exact={true} path="/my-leagues/thank-you" component={ThankYouPage} key="thank-you"/>,
  <PrivateRoute exact={true} path="/my-leagues/:leagueId/league-management" component={LeagueManagement} key="my-leagues/league-management"/>,
  <PrivateRoute exact={true} path="/my-leagues/:leagueId/exemption-certificate" component={UploadCertificate} key="my-leagues/exemption-certificate"/>,
  <PrivateRoute exact={true} path="/my-leagues/exemption-certificate" component={UploadCertificate} key="my-leagues/exemption-certificate"/>,
]
