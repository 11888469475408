// @flow
import * as React from 'react'
// import { destroy } from "redux-form"
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
// import config from "src/config"
import { ORDER_OPTIONS } from 'src/modules/order/utils'
import queryString from 'query-string'

import {
  makeOrderTeamsChange,
  checkoutLatestItemsQty,
  sendMakeOrderFile,
  submitMainMakeOrder,
  removeTeamFromMakeOrder,
  updateOrderFormState,
  sendOrderShippingAddress,
  setAvailableAddresses,
  changeShippingAddressFields,
  sendShippingMethod,
  getShippingMethods,
  restoreOrderProgress,
  resetMakeOrder,
  resetConfirmWarning,
  updateMakeOrder,
  verificationCheckBeforePayment,
  setMakeOrderAdjusted,
  submitOrderCheckPayment,
  updateDiscount,
  makeDiscountPayment,
  updateNote,
  setDiscount,
  updateCreditAmount,
  setFootballsState,
} from 'src/redux/actions/makeOrder/makeOrder'
import { downloadMatrixTeamOrder } from 'src/redux/actions/teamOrder'

const withMakeOrder = <P>(WrappedComponent: React.ComponentType<P>) => {
  class WithMakeOrderComponent extends React.Component<P> {
    render() {
      return <WrappedComponent {...this.props} />
    }
  }

  const mapStateToProps = ({ makeOrder, user }) => ({ makeOrder, isAdmin: user.userData.connectedByAdmin })

  const mapDispatchToProps = (dispatch, ownProps) => ({
    makeOrderTeamsChange: ({ formValues, order, default_vendor }) => dispatch(makeOrderTeamsChange.request({
      teams: Object.entries(formValues).filter(([id, isChecked]) => isChecked).map(([id]) => id),
      order,
      default_vendor,
    })),
    initNewOrder: ({ league, matchParams, location, order }) => {
      const { default_vendor } = league || {}
      if (!default_vendor) {
        return
      }
      const availible_vendors = ORDER_OPTIONS[default_vendor]

      let { vendor } = queryString.parse(location.search)
      vendor = vendor || order.vendor
      if (!availible_vendors.find(({ value }) => value === vendor)) {
        vendor = default_vendor
      }
      const { leagueId, seasonId } = matchParams

      const updateOrderWith = {
        league_id: +leagueId,
        season_id: +seasonId,
        vendor,
      }

      if (order.id || order.league_id !== updateOrderWith.league_id ||
        order.season_id !== updateOrderWith.season_id ||
        order.vendor !== updateOrderWith.vendor
      ) {
        dispatch(resetMakeOrder.success())
      }

      dispatch(updateMakeOrder.success(updateOrderWith))
      dispatch(checkoutLatestItemsQty.request())
    },
    checkoutLatestItemsQty: () => dispatch(checkoutLatestItemsQty.request()),
    submitMainMakeOrder: data => {
      window.previousStep = ownProps.match?.params?.step
      dispatch(submitMainMakeOrder.request({
        ...data,
        match: ownProps.match,
      }))
    },
    removeTeamFromMakeOrder: data => dispatch(removeTeamFromMakeOrder.success(data)),
    updatePaidItemsForm: data => dispatch(updateOrderFormState.paid(data)),
    updateFreeItemsForm: data => dispatch(updateOrderFormState.free(data)),
    resetMakeOrder: values => {
      dispatch(resetMakeOrder.success())
    },
    resetConfirmWarning: () => dispatch(resetConfirmWarning.success()),
    restoreOrderProgress: () => dispatch(restoreOrderProgress.request({ matchParams: ownProps.match.params })),
    sendOrderShippingAddress: data => {
      return (
        dispatch(sendOrderShippingAddress.request({
          values: { ...data.values },
          match: ownProps.match,
          order_id: +ownProps.match.params.orderId,
          dirty: data.dirty,
        }))
      )
    },
    changeShippingAddressFields: (newVal, oldVal) => {
      dispatch(changeShippingAddressFields({ newVal, oldVal }))
    },

    sendValidShippingAddress: data => {
      if (!data?.values) return

      let addressObject = {
        ...data.lastSubmittedShippingAddress,
        shipping_address_1: data.values.address_1,
        shipping_address_2: data.values.address_2,
        shipping_city: data.values.city,
        shipping_state: data.values.state,
        shipping_zip: data.values.zip,
        shipping_address_id: null,
        address_validated: true,
      }

      if (data.values.shipment_address_data && data.values.shipment_address_data !== 'MANUAL') {
        const parsedValue = JSON.parse(data.values.shipment_address_data)
        addressObject = {
          ...addressObject,
          shipping_address_1: parsedValue.address_1,
          shipping_address_2: parsedValue.address_2,
          shipping_city: parsedValue.city,
          shipping_state: parsedValue.state,
          shipping_zip: parsedValue.zip,
          shipping_county: parsedValue.county,
        }
      }
      dispatch(sendOrderShippingAddress.request({
        values: addressObject,
        match: ownProps.match,
        order_id: data.order_id,
      }))
    },
    sendShippingMethod: data => (
      dispatch(sendShippingMethod.request(data))
    ),
    getShippingMethods: () => dispatch(getShippingMethods.request({
      order_id: +ownProps.match.params.orderId,
      season_id: +ownProps.match.params.seasonId,
    })),
    verificationCheckBeforePayment: order => dispatch(verificationCheckBeforePayment.request(order)),
    sendMakeOrderFile: data => dispatch(sendMakeOrderFile.request(data)),
    handleCheckSubmit: value => dispatch(submitOrderCheckPayment.request(value)),

    // UNUSED
    setMakeOrderAdjusted: values => {
      dispatch(setMakeOrderAdjusted.success(values))
    },
    downloadMatrixTeamOrder: orderId => dispatch(downloadMatrixTeamOrder.request(orderId)),
    resetAlternativeShippingAddresses: () => dispatch(setAvailableAddresses.success([])),
    makeDiscountPayment: value => dispatch(makeDiscountPayment.request(value)),
    updateDiscount: value => dispatch(updateDiscount.request(value)),
    updateNote: value => dispatch(updateNote.request(value)),
    setDiscount: value => dispatch(setDiscount.request(value)),
    updateCreditAmount: value => dispatch(updateCreditAmount.request(value)),
    setFootballsState: data => dispatch(setFootballsState.success(data)),
  })

  return connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withRouter(WithMakeOrderComponent))
}

export default withMakeOrder

