import styled from 'styled-components'

export const StyledCardForm = styled.section`
    border-radius: 5px;
    border: 2px solid #219653;

    .top-text-wrapper {
      align-items: center;
      border-bottom: 1px solid #B5B5B5;
      display: flex;
      flex-wrap: wrap;
      padding: 12px 15px 12px 12px;
      justify-content: space-between;
    }

    .wrapper {
        display: flex;
        flex-direction: column;
        padding: 10px 15px 10px 10px;
        min-height: auto;
    }

    .bottom-wrapper {
      text-align: center;
    }

    .checkbox-wrapper {
      display: flex;
      align-items: center;
    }

    .checkbox {
      margin-right: 10px;
      width: auto;
    }

    .title-wrapper {
      display: flex;
      align-items: center;
    }
    
    .title {
      color: #000000;
      font-size: 16px;
    }

    .text-label {
      color: #000000;
      display: block;
      font-size: 15px;
      font-weight: bold;
      line-height: 1.4;
      margin: 0;
      padding: 0;
      text-align: left;
    }

  .braintree-hosted-field {
   
    display: flex;
    background-color: transparent;
    color: rgba(0, 0, 0, .87);
    outline: 0;
    font-size: 16px;
    padding: 0;
    box-shadow: none;
    border-radius: 0;
    position: relative;
    flex: 1;
    border: 1px solid #bfbfbf;
    height: 44px;
    margin: 4px 10px 0 0;
    padding: 0 4px;
  }

  .error-wrapper {
    margin-top: 5px;
  }

  .braintree-hosted-fields-invalid {
    border: 1px solid red;
  }

  .invalid {
    border: 1px solid red;
  }

  .bottom-container {
    display: flex;
  }

  .top-wrapper: {
    display: block;
    
  }
  
  .form-input {
    display: block;
    width: 100%;
    margin-top: 3px;
  }
`

export const StyledButton = styled.div`
  margin-top: 10px;
   
   &.hide {
     display: none;
   }
`
