// @flow
import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { ServiceWorkerNotifier } from 'src/common/components'
import { withUser } from 'src/decorators'
import { getToken, loginBySession, setPreviousLocation, getPreviousLocation } from 'src/services/user'
import * as Sentry from '@sentry/react'

// types
import type { PublicRouteProps } from './PublicRouteTypes'

const SentryRoute = Sentry.withSentryRouting(Route)

const PublicRoute = ({
  component: Component,
  user,
  no_redirects,
  ...rest
}: PublicRouteProps) => {
  return (
    <SentryRoute
      {...rest}
      render={props => {
        const { location } = props

        if (location.pathname === '/session-login') loginBySession()
        if (user && getToken() && !no_redirects) {
          setPreviousLocation(location.pathname, user?.userData?.id)
          return (
            <Redirect to={getPreviousLocation()?.locationPath || '/'} />
          )
        }
        return (
          <div>
            <ServiceWorkerNotifier />
            <Component {...props} />
          </div>
        )
      }
      }
    />
  )
}

export default withUser(PublicRoute)
