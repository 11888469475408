import styled from 'styled-components'

export const StyledSearch = styled.form`
   margin-top: 15px;
   
   .btn-search {
       align-items: center;
       appearance: none;
       background-color: ${props => props.theme.main.colors.primaryColor};
       border-bottom-right-radius: 3px;
       border-top-right-radius: 3px;
       border: 0;
       display: flex;
       justify-content: center;
       padding: 10px 14px;
       
       :hover {
           filter: brightness(1.3);
       }
   }
   
   .form-row {
       display: flex;
   }
   
   .form-label {
       display: block;
       font-weight: bold;
       margin-bottom: 10px;
   }
   
   input {
       appearance: none;
       border: 1px solid ${props => props.theme.main.colors.inputBorderColor};
       border-bottom-left-radius: 3px;
       border-top-left-radius: 3px;
       flex: 1;
       padding: 0 14px;
       height: 40px;
       
       :focus {
           border-color: ${props => props.theme.main.colors.primaryColor};
       }
   }
   
   .form-body {
       max-width: 600px;
       width: 100%;
   }
`
