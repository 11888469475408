// @flow
import React from 'react'
import { StyledPrimaryButton } from './buttoPrimaryStyles'
import type { ButtonPrimaryPropsType } from './ButtonPrimaryTypes'

const ButtonPrimary = ({
  buttonText,
  center,
  disabled,
  size,
  type = 'button',
  action = () => {},
  className,
}: ButtonPrimaryPropsType) => {
  return (
    <StyledPrimaryButton type={type} onClick={action} size={size} disabled={disabled} center={center} className={className}>
      {buttonText}
    </StyledPrimaryButton>
  )
}

export default ButtonPrimary
