// @flow

import { call, fork, put, take } from 'redux-saga/effects'
import { GET_PAYMENT_METHOD_BY_TOKEN, getPaymentMethodByToken } from 'src/redux/actions/payments/payments'

import http from 'src/services/http'
// types
import type { IOEffect } from 'redux-saga/effects'

const getVerificationId = data => {
  const { verifications } = data
  return verifications?.[0]?.id
}

function * watchGetPaymentMethodByToken(): Generator<IOEffect, void, any> {
  while (true) {
    const action = yield take(GET_PAYMENT_METHOD_BY_TOKEN.REQUEST)
    const { token } = action.payload
    try {
      const { data } = yield call(http, `api/payment/payment-method/${token}`, {
        method: 'GET',
      })
      const verificationId = getVerificationId(data)

      yield put(getPaymentMethodByToken.success({
        selectedPaymentMethod: data,
        verificationId,
      }))
    } catch (e) {
      yield put(getPaymentMethodByToken.error(e.message))
    }
  }
}

export default [fork(watchGetPaymentMethodByToken)]
