import React from 'react'
import { RenderIf } from 'src/common/components'
import Popover from '@material-ui/core/Popover'
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state'
import { SummaryTooltipStyles } from './orderSummaryInfoStyles'

const Hint = ({ children }) => (
  <PopupState variant="popover" popupId="demo-popup-popover">
    {popupState => (
      <span>
        <span className="question-mark" {...bindTrigger(popupState)}>?</span>
        <Popover
          {...bindPopover(popupState)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <SummaryTooltipStyles>{children}</SummaryTooltipStyles>
        </Popover>
      </span>
    )}
  </PopupState>
)

export const SummaryRow = ({ label, value, hint, condition }) => (
  <RenderIf condition={condition}>
    <div className="summary-row">
      <span className="label">
        {label}:
        <RenderIf condition={hint}>
          <Hint>
            {hint}
          </Hint>
        </RenderIf>
      </span>
      <span className="value">{value}</span>
    </div>
  </RenderIf>
)
