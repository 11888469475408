// @flow
import * as React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import {
  setUser,
  getUser,
  registerUser,
  sendActivationEmail,
  resetUser,
  editUser,
  setUserPassword,
  submitUserAgreement,
  editUserPassword,
  editUserEmail,
  editUserGeoLocationZip,
} from 'src/redux/actions/user/user'

const requiredUserProfileProps = [
  'first_name',
  'last_name',
  'email',
]

const withUser = <P>(WrappedComponent: React.ComponentType<P>) => {
  class WithUserComponent extends React.Component<P> {
    render() {
      return <WrappedComponent {...this.props} />
    }
  }
  const mapStateToProps = ({ user }) => {
    const userHasCompletedProfile = !user.userData || requiredUserProfileProps.every(prop => !!user.userData[prop])

    return {
      user,
      userHasCompletedProfile,
    }
  }

  const mapDispatchToProps = (dispatch, ownProps) => ({
    setUser: values =>
      dispatch(setUser.request({ ...values, redirectCb: ownProps.history.push })),
    getUser: () => dispatch(getUser.request()),
    registerUser: values =>
      dispatch(registerUser.request({ ...values, redirectCb: ownProps.history.push })),
    sendActivationEmail: data =>
      dispatch(sendActivationEmail.request({ ...data, redirectCb: ownProps.history.push })),
    resetUserState: () => dispatch(resetUser.success()),
    editUser: values => dispatch(editUser.request({ ...values })),
    setUserPassword: values =>
      dispatch(setUserPassword.request({ ...values, token: ownProps.match.params.token, redirectCb: ownProps.history.push })),
    editUserPassword: values =>
      dispatch(editUserPassword.request({ ...values })),
    editUserEmail: values => dispatch(editUserEmail.request(values)),
    submitUserAgreement: values => dispatch(submitUserAgreement.request({ ...values })),
    editUserGeoLocationZip: values =>
      dispatch(editUserGeoLocationZip.success({ ...values })),

  })

  return connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withRouter(WithUserComponent))
}

export default withUser

