// @flow

import { call, fork, put, take } from 'redux-saga/effects'
import { GET_LEAGUE_INSURANCE, getLeagueInsurance } from 'src/redux/actions/myLeagues/myLeagues'

import http from 'src/services/http'
// types
import type { IOEffect } from 'redux-saga/effects'

function * watchGetLeagueInsurnace(): Generator<IOEffect, void, any> {
  while (true) {
    const action = yield take(GET_LEAGUE_INSURANCE.REQUEST)
    try {
      const { leagueId } = action.payload
      const { data } = yield call(http, `api/league/${leagueId}/insurances`, {
        method: 'GET',
      })

      yield put(getLeagueInsurance.success(data))
    } catch (e) {
      yield put(getLeagueInsurance.error(e.message))
    }
  }
}

export default [fork(watchGetLeagueInsurnace)]
