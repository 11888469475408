import http from 'src/services/http'

export const fetchExemptionZones = async() => {
  const response = await http('api/exposureZones', {
    method: 'GET',
  })
  return response?.data
}

export const fetchExemptionCertificateAttachment = async certificateId => {
  const response = await http(`api/exemptionCertificates/${certificateId}/attachment`, {
    method: 'GET',
  }, {
    responseIsFile: true,
  })
  return response
}

export const fetchExemptionCertificates = async params => {
  const response = await http('api/exemptionCertificates', {
    method: 'GET',
    params,
  })
  return response?.data
}
