// @flow
import * as React from 'react'
import { Link } from 'react-router-dom'
import { Confirm, DashboardBlock } from 'src/common/components'

import type { ACHMethod } from 'src/redux/reducers/payments'

import { StyledPrimaryText } from '../../ACHMethodsStyles'

type OptionsPropsType = {
    checked: ?ACHMethod,
    editable: ?boolean,
    deletePaymentMethod: (data: {token: string}) => void,
    children?: React.Node,
    updatePaymentMethod: (data: any) => void
};

const Options = ({ checked, editable, deletePaymentMethod, children = null, updatePaymentMethod }: OptionsPropsType) => {
  return (
    <div className="heading">
      <DashboardBlock title="ACH Account">
        <div className="warning">
          <StyledPrimaryText>Declined or failed ACH payments are subject to a $5 fee</StyledPrimaryText>
        </div>
        <div className="top-container">
          {editable && (
            <div className="crud-container">
              <div className="crud">
                {checked && (
                  <>
                    <Link
                      to={{
                        pathname: `/payment-settings/ach/${checked.token}`,
                      }}
                      className="edit-link"
                    >
                      {checked.verified ? 'View' : 'Edit'}
                    </Link>
                    {deletePaymentMethod && checked && (
                      <Confirm
                        title="Confirm"
                        description={'Are you sure you wish to delete this Payment Method?'}
                        confirmText="yes"
                        cancelText="no"
                      >
                        {confirm => (
                          <div className="text-center">
                            <span
                              className="edit-link"
                              onClick={confirm({
                                handleSubmit: () => deletePaymentMethod({ token: checked.token }),
                                valid: true,
                              })}
                            >
                              Delete
                            </span>
                          </div>
                        )}
                      </Confirm>
                    )}

                  </>
                )}
              </div>
              {updatePaymentMethod && <div className="sub-crud">Default payment method</div> }
            </div>
          )}
        </div>
        {children}
        {editable && (
          <Link
            to={{ pathname: '/payment-settings/ach' }}
            className="edit-link button-wrapper"
          >
            <span>Add ACH account</span>
          </Link>
        )}
      </DashboardBlock>
    </div>
  )
}

export default Options
