// @flow
import type { BasicRedux } from 'src/redux/actions/featureManagement/featureManagementTypes'
import {
  GET_EXEMPTION_CERTIFICATES,
  SUBMIT_EXEMPTION_CERTIFICATES,
  GET_EXPOSURE_ZONES,
  GET_EXEMPTION_CERTIFICATE_ATTACHMENT,
} from 'src/redux/actions/exemptionCertificates/exemptionCertificates'

export type ExposureZone = {
  country: string,
  createdAt: string,
  deletedAt: null,
  description: string,
  id: number,
  name: string,
  state: string,
  updatedAt: string,
  value: string
};

export type ExemptionCertificatesState = {
  isLoading: boolean,
  exemptionCertificatesData: any[],
  exposureZones: ExposureZone[],
  exemptionCertificatesErrors?: ?{ [key: string]: any } | ?string
};

const initialState = {
  isLoading: false,
  exemptionCertificatesData: [],
  exposureZones: [],
  exemptionCertificatesErrors: null,
}

const exemptionCertificates = (state: ExemptionCertificatesState = initialState, action: BasicRedux): ExemptionCertificatesState => {
  switch (action.type) {
    case GET_EXPOSURE_ZONES.REQUEST:
    case SUBMIT_EXEMPTION_CERTIFICATES.REQUEST:
    case GET_EXEMPTION_CERTIFICATES.REQUEST:
    case GET_EXEMPTION_CERTIFICATE_ATTACHMENT.REQUEST:
    {
      return {
        ...state,
        isLoading: true,
        exemptionCertificatesErrors: null,
      }
    }

    case GET_EXPOSURE_ZONES.FAILURE:
    case SUBMIT_EXEMPTION_CERTIFICATES.FAILURE:
    case GET_EXEMPTION_CERTIFICATE_ATTACHMENT.FAILURE:
    {
      return {
        ...state,
        isLoading: false,
        exemptionCertificatesErrors: action.payload,
      }
    }

    case GET_EXEMPTION_CERTIFICATES.FAILURE:
    {
      return {
        ...state,
        isLoading: false,
        exemptionCertificatesErrors: action.payload,
        exemptionCertificatesData: [],
      }
    }

    case GET_EXEMPTION_CERTIFICATES.SUCCESS:
    {
      return {
        ...state,
        isLoading: false,
        exemptionCertificatesData: action.payload,
        exemptionCertificatesErrors: null,
      }
    }

    case SUBMIT_EXEMPTION_CERTIFICATES.SUCCESS:
    case GET_EXEMPTION_CERTIFICATE_ATTACHMENT.SUCCESS:
    {
      return {
        ...state,
        isLoading: false,
        exemptionCertificatesErrors: null,
      }
    }

    case GET_EXPOSURE_ZONES.SUCCESS:
    {
      return {
        ...state,
        isLoading: false,
        exposureZones: action.payload,
        exemptionCertificatesErrors: null,
      }
    }

    default: {
      return state
    }
  }
}

export default exemptionCertificates
