// @flow

import moment from 'moment-timezone'

import type { SeasonData } from 'src/redux/reducers/season'

export default (seasons: SeasonData[]): SeasonData[] => {
  return seasons
    .sort((left, right) => {
      return moment.utc(right.end_date).diff(moment.utc(left.end_date))
    })
    .filter((season: SeasonData) => (moment(season.end_date).format() < moment().format()))
}
