import React from 'react'

const BlankIcon = (props = {}) => {
  const { width = '20', height = '20' } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px" y="0px"
      width={width} height={height}>
    </svg>
  )
}

export default BlankIcon
