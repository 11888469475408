import { STEPS } from 'src/modules/order/utils'
// STEPS_ORDER
export const hasRightForThisStep = (data, step) => {
  const { order } = data
  switch (step) {
    case STEPS.CHOOSE_TEAMS:
    case STEPS.UNIFORM:
    case STEPS.ADDITIONAL:
    case STEPS.ADDRESS_INFO:
      return true
    case STEPS.ADDRESS_VALIDATION:
      return false
    case STEPS.SHIPPING:
      return true
    case STEPS.ORDER_SUMMARY:
      if (order.wasShippingSelected) {
        return true
      }
      return false
    case STEPS.PAYMENT:
      return false
    default:
      return false
  }
}
