// @flow
import type { BasicRedux } from 'src/redux/actions/messageCenter/messageCenterTypes'
import { GET_MESSAGE_CENTER } from 'src/redux/actions/messageCenter/messageCenter'

export type MessageCenterState = {
  isLoading: boolean,
  body: string,
  messageCenterErrors?: ?{ [key: string]: any } | ?string
};

const initialState = {
  isLoading: false,
  body: '',
  messageCenterErrors: null,
}

const messageCenter = (state: MessageCenterState = initialState, action: BasicRedux): MessageCenterState => {
  switch (action.type) {
    case GET_MESSAGE_CENTER.REQUEST:
      return {
        ...state,
        isLoading: true,
        messageCenterErrors: null,
      }
    case GET_MESSAGE_CENTER.SUCCESS:
      return {
        ...state,
        isLoading: false,
        body: action.payload,
      }
    case GET_MESSAGE_CENTER.FAILURE:
      return {
        ...state,
        isLoading: false,
        messageCenterErrors: action.payload,
      }
    default:
      return state
  }
}

export default messageCenter

