// @flow

import { stopSubmit } from 'redux-form'
import moment from 'moment'
import { call, fork, put, take } from 'redux-saga/effects'
import { cloneDeep } from 'lodash'
import config from 'src/config'
import { trimObjectStringValues } from 'src/helpers/formatHelpers'
import { EDIT_SEASON, editSeason } from 'src/redux/actions/seasons/seasons'
import http from 'src/services/http'
import { history } from 'src/services/history'
// types
import type { IOEffect } from 'redux-saga/effects'

const shapeToNumber = (val: string) => {
  return +val
}

const toNumberValues = ['min', 'max', 'league_id']

function * watchEditSeason(): Generator<IOEffect, void, any> {
  while (true) {
    const {
      payload: { ...values },
    } = yield take(EDIT_SEASON.REQUEST)
    const formId = config.createSeasonForm.id

    const formValues = cloneDeep(values)

    trimObjectStringValues(formValues)

    toNumberValues.forEach(item => {
      formValues[item] = shapeToNumber(formValues[item])
    })
    const { season_id } = formValues

    if (formValues.age_grade_range === 'age') {
      if (formValues.min && formValues.max) {
        formValues.min_age = formValues.min
        formValues.max_age = formValues.max
        delete formValues.min
        delete formValues.max
        delete formValues.min_grade
        delete formValues.max_grade
      } else {
        delete formValues.min_grade
        delete formValues.max_grade
      }
    } else if (formValues.min && formValues.max) {
      formValues.min_grade = formValues.min
      formValues.max_grade = formValues.max
      delete formValues.min
      delete formValues.max
      delete formValues.min_age
      delete formValues.max_age
    } else {
      delete formValues.min_age
      delete formValues.max_age
    }

    formValues.start_date = moment.utc(formValues.start_date).startOf('day')
    formValues.end_date = moment.utc(formValues.end_date).endOf('day')
    formValues.registration_start_date = moment.utc(formValues.registration_start_date).startOf('day')
    formValues.registration_end_date = moment.utc(formValues.registration_end_date).endOf('day')

    try {
      yield call(http, `api/season/${season_id}`, {
        body: JSON.stringify({
          ...formValues,
        }),
        method: 'PUT',
      })

      yield put(editSeason.success(values))
      yield call(history.push, `/my-leagues/${values.league_id}/league-management`)
    } catch (e) {
      yield put(editSeason.error(e.message))
      yield put(stopSubmit(formId, e.errors ? e.errors : {}))
    }
  }
}

export default [fork(watchEditSeason)]
