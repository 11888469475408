export const { REACT_APP_CREATE_LEAGUE_FEATURE_FLAG = '', REACT_APP_MATRIX_SITE = '' } = process.env

export const ITEM_TYPES = {
  BELT: 'BELT',
  PREMIUM_BELT: 'PREMIUM_BELT',
  FOOTBALL: 'FOOTBALL',
  UNIFORM: 'UNIFORM',
  SHORTS: 'SHORTS',
  SOCKS: 'SOCKS',
  SLEEVES: 'SLEEVES',
  MOUTHGUARD: 'MOUTHGUARD',
  // FEE: 'FEE',
  GLOVES: 'GLOVES',
  COACH_SHIRTS: 'COACH_SHIRTS',
  APPAREL_WHITE_TEE: 'APPAREL_WHITE_TEE',
  APPAREL_SLEEVE_TEE: 'APPAREL_SLEEVE_TEE',
  APPAREL_HOODIE: 'APPAREL_HOODIE',
  PREMIUM_JERSEY: 'PREMIUM_JERSEY',
  PREMIUM_MOUTHGUARD: 'PREMIUM_MOUTHGUARD',
  PYLON_SETS: 'PYLON_SETS',
  PREMIUM_FOOTBALL: 'PREMIUM_FOOTBALL',
  HEADBAND: 'HEADBAND',
}

export const ITEM_SIZES = {
  MINI: 'MINI',
}

export const ADDITIONAL_ITEM_TYPES = [
  ITEM_TYPES.BELT,
  ITEM_TYPES.FOOTBALL,
  ITEM_TYPES.PREMIUM_FOOTBALL,
]

export const VENDOR = {
  BDA: 'BDA',
  AUGUSTA: 'AUGUSTA',
  MATRIX: 'MATRIX',
}

export const PAYMENT_STATUS = {
  PAID: 'PAID',
  NOT_PAID: 'NOT_PAID',
  PENDING: 'PENDING',
  REFUNDED: 'REFUNDED',
}

export const VENDOR_INSURANCE_NAMES = {
  JETFILE: 'JETFILE',
  MANUAL: 'MANUAL',
}

export const ORDER_MODE = {
  BULK: 'BULK',
  TEAM: 'TEAM',
  VIRTUAL: 'VIRTUAL',
}

export const FEE_ITEMS = {
  LETTERING: 'LETTERING',
  BUNDLING: 'BUNDLING',
}

export const INSURANCE_PAYMENT_STATUS = {
  PAID: 'PAID',
  NOT_PAID: 'NOT_PAID',
}

const BASE_STATUSES = {
  APPROVED: 'APPROVED',
  PENDING: 'PENDING',
  DECLINED: 'DECLINED',
}

export const INSURANCE_STATUSES = {
  ...BASE_STATUSES,
  EXPIRED: 'EXPIRED',
}

export const LEAGUE_STATUSES = {
  ...BASE_STATUSES,
  INACTIVE: 'INACTIVE',
}

export const ROLES = {
  LEAGUE_OWNER: 'LEAGUE_OWNER',
  CO_OWNER: 'CO_OWNER',
  OFFICIAL: 'OFFICIAL',
  COACH: 'COACH',
  PARENT: 'PARENT',
}

export const TYPE_ROLES = {
  ...ROLES,
  LO_CO: 'LO_CO',
}

export const ROLES_GUARD = new Map([
  [TYPE_ROLES.LO_CO, [ROLES.LEAGUE_OWNER, ROLES.CO_OWNER]],
  [TYPE_ROLES.LEAGUE_OWNER, [ROLES.LEAGUE_OWNER]],
  [TYPE_ROLES.CO_OWNER, [ROLES.CO_OWNER]],
  [TYPE_ROLES.OFFICIAL, [ROLES.OFFICIAL]],
  [TYPE_ROLES.COACH, [ROLES.COACH]],
  [TYPE_ROLES.PARENT, [ROLES.PARENT]],
])

export const REGISTER_AGREEMENT_TYPES = {
  APP: 'registration-app',
  LEAGUE: 'registration-league',
}

export const COUNTRY_CODES = {
  US: 'US',
  CA: 'CA',
}
export const CURRENCIES = {
  USD: 'USD',
  CAD: 'CAD',
}

export const ADDRESS_PREFIXES = {
  SHIPPING: 'shipping_',
  BILLING: 'billing_',
  LEAGUE: 'league_',
}

export const DEFAULT_PAYMENT_OPTIONS = {
  translations: {
    payingWith: '',
  },
  card: {
    vault: {
      allowVaultCardOverride: true,
    },
  },
}

export const SETTINGS_PAGE_PAYMENT_OPTIONS = {
  translations: {
    chooseAnotherWayToPay: 'Add credit card',
    payWithCard: 'Card details',
    ...DEFAULT_PAYMENT_OPTIONS.translations,
  },
  card: {
    vault: {
      allowVaultCardOverride: false,
    },
  },
}

export const ALLOWED_FILE_TYPES = [
  'application/pdf',
  'image/jpeg',
  'image/png',
  'image/tiff',
]
export const MESSAGE_TYPES = {
  SUCCESS: 'success',
  WARNING: 'warning',
  INFO: 'info',
  ERROR: 'error',
}

export const NFL_DEFAULT_PROGRAM_ID = 1
