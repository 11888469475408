// @flow

const PERSONAL_OWNERSHIP_TYPE = {
  name: 'personal',
  value: 'personal',
}

const OWNERSHIP_TYPES = [
  PERSONAL_OWNERSHIP_TYPE,
  { name: 'business', value: 'business' },
]

const ACCOUNT_TYPES = [
  { name: 'checking', value: 'checking' },
  { name: 'savings', value: 'savings' },
]

const PAYMENT_TYPES = {
  CARD: 'CARD',
  CHECK: 'CHECK',
  ACH: 'ACH',
}

const PAYMENT_SELECT_OPTIONS = [
  {
    name: 'Credit card',
    value: PAYMENT_TYPES.CARD,
  },
  {
    name: 'ACH',
    value: PAYMENT_TYPES.ACH,
  },
  {
    name: 'Check',
    value: PAYMENT_TYPES.CHECK,
  },
]

const PAYMENT_TEXT = 'By clicking "Save Details", I authorize Braintree, a service of PayPal, on behalf of RCX Sports LLC (i) to verify my bank account information using bank information and consumer reports and (ii) to debit my bank account.'

const NO_PAYMENT_METHOD_AVAILABLE = 'You can now complete payment directly from your bank account. To pay via ACH, please configure your account details using the Payment Settings tab on the left hand navigation bar.'

export {
  PERSONAL_OWNERSHIP_TYPE,
  OWNERSHIP_TYPES,
  ACCOUNT_TYPES,
  PAYMENT_TEXT,
  PAYMENT_TYPES,
  PAYMENT_SELECT_OPTIONS,
  NO_PAYMENT_METHOD_AVAILABLE,
}
