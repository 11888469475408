/* eslint-disable multiline-ternary */
// @flow
import React, { useEffect, useState } from 'react'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { ACHIcon } from 'src/assets/icons'
import Checkbox from '@material-ui/core/Checkbox'
import Options from './fragments/options'
import { Link } from 'react-router-dom'
import { FEATURES } from 'src/helpers/featureManagementHelper'
import { getFeature } from 'src/services/featureManagement'
import { withUser } from 'src/decorators'
import { isUSA } from 'src/helpers/countryHelper'

import {
  RenderIf,
  Loader,
} from 'src/common/components'

// styles
import {
  StyledACHMethods,
  StyledPrimaryText,
  StyledSecondaryText,
  StyledWarning,
  StyledCircleCheckedFilled,
  StyledCircleChecked,
} from './ACHMethodsStyles'

// types
import type { ACHMethodsPropsType } from './ACHMethodsTypes'

function ACHMethods({
  paymentMethods,
  checked,
  setChecked,
  deletePaymentMethod,
  editable,
  updatePaymentMethod,
  user: { userData },
}: ACHMethodsPropsType) {
  const [loading, setLoading] = useState(null)
  const [feature, setFeature] = useState(false)

  const loadFeature = async(name: string) => {
    try {
      setLoading(true)
      const { status } = await getFeature(name)
      if (status && isUSA(userData?.country)) {
        setFeature(status)
      }
      setLoading(false)
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    loadFeature(FEATURES.ACH)
  }, [])
  return (
    <><Loader show={loading} />
      <RenderIf condition={feature}>
        <StyledACHMethods>
          {Array.isArray(paymentMethods?.ACH) && paymentMethods?.ACH?.length ? (
            <List>
              <Options
                checked={checked}
                editable={editable}
                deletePaymentMethod={deletePaymentMethod}
                updatePaymentMethod={updatePaymentMethod}
              >
                <div className="list">
                  {/* $FlowFixMe */}
                  {paymentMethods?.ACH.map(item => {
                    const { globalId, last4, accountHolderName, verified, token } = item
                    const labelId = `checkbox-list-label-${globalId}`
                    const isChecked = checked?.globalId === globalId
                    const canClick = editable || verified
                    return (
                      <div key={labelId} className="list-item-wrapper">
                        <ListItem
                          className={
                            isChecked ? 'left-wrapper braintree-method active' : ' left-wrapper braintree-method'
                          }
                          key={globalId}
                          role={undefined}
                          dense
                          button
                          onClick={canClick ? setChecked(item) : undefined}
                          disableRipple
                        >
                          <ACHIcon />
                          <ListItemText
                            id={labelId}
                            primary={
                              <StyledPrimaryText>Ending in {last4}</StyledPrimaryText>
                            }
                            secondary={
                              <StyledSecondaryText>
                                {accountHolderName}
                              </StyledSecondaryText>
                            }
                          />
                          {!verified && (
                            <ListItemText
                              id={labelId}
                              primary={<Link to={`/payment-settings/ach/${token}`}><StyledWarning>Not verified - Click here to complete verification</StyledWarning></Link>}
                            />
                          )}
                          {isChecked && (
                            <ListItemIcon>
                              <Checkbox
                                style={{
                                  backgroundColor: 'transparent',
                                  transition:
                            'transform 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275)',
                                }}
                                checked={isChecked}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{ 'aria-labelledby': labelId }}
                                icon={<StyledCircleChecked />}
                                checkedIcon={<StyledCircleCheckedFilled />}
                              />
                            </ListItemIcon>
                          )}
                        </ListItem>
                        {editable && updatePaymentMethod && (
                          <div className="right-wrapper">
                            <Checkbox
                              style={{
                                backgroundColor: 'transparent',
                                transition:
                            'transform 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275)',
                              }}
                              disabled={!verified}
                              checked={item.default}
                              tabIndex={-1}
                              disableRipple
                              inputProps={{ 'aria-labelledby': labelId }}
                              icon={<StyledCircleChecked verified={verified.toString()}/>}
                              checkedIcon={<StyledCircleCheckedFilled />}
                              onClick={() => verified && updatePaymentMethod({
                                token,
                                data: {
                                  options: {
                                    makeDefault: true,
                                  },
                                },
                              })}
                            />
                          </div>)}
                      </div>
                    )
                  })}
                </div>
              </Options>
            </List>
          ) : (
            <>
              <h4>
                You can now complete payment directly from your bank account. To pay
                via ACH, please configure your account details.
              </h4>
              <Options
                checked={checked}
                editable={editable}
                deletePaymentMethod={deletePaymentMethod}
                updatePaymentMethod={updatePaymentMethod}
              />
            </>
          )}
        </StyledACHMethods>
      </RenderIf>
    </>
  )
}

export default withUser(ACHMethods)
