// @flow

import { requestTypeCreator } from 'src/helpers'

// types
import type { GetPublicLeaguesActionTypes, UpdatePublicLeagueActionTypes } from './publicLeaguesTypes'

export const GET_PUBLIC_LEAGUES = requestTypeCreator('GET_PUBLIC_LEAGUES')
export const UDATE_PUBLIC_LEAGUE = ('UDATE_PUBLIC_LEAGUE')

export const getPublicLeagues = {
  error: (payload: any): GetPublicLeaguesActionTypes => ({ type: GET_PUBLIC_LEAGUES.FAILURE, payload }),
  request: (payload: any): GetPublicLeaguesActionTypes => ({ type: GET_PUBLIC_LEAGUES.REQUEST, payload }),
  success: (payload: any): GetPublicLeaguesActionTypes => ({ type: GET_PUBLIC_LEAGUES.SUCCESS, payload }),
}

export const updatePublicLeague = {
  success: (payload: any): UpdatePublicLeagueActionTypes => ({ type: UDATE_PUBLIC_LEAGUE, payload }),
}

