// @flow

import React from 'react'

const SliderIcon = () => {
  return (
    <svg height="20" viewBox="-33 -181 1065.0001 1065" width="20">
      <path
        d="m829.347656 65.886719c-12.75-44.542969-53.796875-77.238281-102.371094-77.238281-48.574218 0-89.625 32.695312-102.382812 77.238281h-626.613281v58.5h626.621093c12.75 44.535156 53.800782 77.242187 102.375 77.242187 48.574219 0 89.621094-32.707031 102.375-77.242187h167.066407v-58.5zm0 0"/>
      <path
        d="m185.488281 233.34375c-48.574219 0-89.628906 32.703125-102.371093 77.238281h-85.136719v58.503907h85.136719c12.742187 44.542968 53.800781 77.242187 102.375 77.242187 48.574218 0 89.628906-32.699219 102.375-77.242187h708.550781v-58.503907h-708.550781c-12.746094-44.535156-53.804688-77.238281-102.378907-77.238281zm0 0"/>
      <path
        d="m513.601562 478.046875c-48.574218 0-89.636718 32.699219-102.382812 77.242187h-413.238281v58.5h413.238281c12.746094 44.527344 53.808594 77.242188 102.382812 77.242188 48.570313 0 89.628907-32.714844 102.371094-77.242188h380.445313v-58.5h-380.445313c-12.742187-44.542968-53.800781-77.242187-102.371094-77.242187zm0 0"/>
    </svg>
  )
}

export default SliderIcon
