// @flow
import React from 'react'
import { StyledShippingInformation } from '../../orderSummaryStyles'
import { getExistingPropsAndValues } from 'src/helpers/objectHelpers'
import { humanize } from 'src/helpers/valuesHumanizer'
import { RenderIf } from 'src/common/components'
import { ORDER_MODE } from 'src/common/constants'
// types
import type { ShippingInformationPropsType } from './ShippingInformationType'

const ShippingInformation = ({ order, userData, leagueData }: ShippingInformationPropsType) => {
  const { props, values } = getExistingPropsAndValues({
    City: order.shipping_city,
    County: order.shipping_county,
    State: order.shipping_state,
  })

  const isVirtual = ORDER_MODE.VIRTUAL === order?.order_mode

  return (
    <StyledShippingInformation>
      <div className="col">
        <div className="shipping-row">
          <span className="label">League Name</span>
          <span className="value">{order.league_name}</span>
        </div>
        <div className="shipping-row">
          <span className="label">First Name</span>
          <span className="value">{order.contact_first_name }</span>
        </div>
        <div className="shipping-row">
          <span className="label">Last Name</span>
          <span className="value">{order.contact_last_name}</span>
        </div>
        <RenderIf condition={order.contact_phone}>
          <div className="shipping-row">
            <span className="label">Phone</span>
            <span className="value">{order.contact_phone}</span>
          </div>
        </RenderIf>
        <RenderIf condition={order.league_owner_phone && isVirtual}>
          <div className="shipping-row">
            <span className="label">Phone</span>
            <span className="value">{order.league_owner_phone}</span>
          </div>
        </RenderIf>
        <div className="shipping-row">
          <span className="label">Email</span>
          <span className="value">{order.contact_email}</span>
        </div>
      </div>
      <div className="col">
        {order.shipping_address_1 && (
          <div className="shipping-row">
            <span className="label">Address</span>
            <span className="value">{order.shipping_address_1}</span>
          </div>
        )}
        {order.shipping_address_2 && (
          <div className="shipping-row">
            <span className="label">Suite, Unit, Floor #</span>
            <span className="value">{order.shipping_address_2}</span>
          </div>
        )}
        {values.length > 0 && (
          <div className="shipping-row">
            <span className="label">{props.join(', ')}</span>
            <span className="value">{values.join(', ')}</span>
          </div>
        )}
        {order.shipping_zip && (
          <div className="shipping-row">
            <span className="label">Postal code</span>
            <span className="value">{order.shipping_zip}</span>
          </div>
        )}
        <div className="shipping-row">
          <span className="label">Country</span>
          <span className="value">{order.shipping_country}</span>
        </div>
        <RenderIf condition={order.shipment_method && !isVirtual}>
          <div className="shipping-row">
            <span className="label">Shipping Method</span>
            <span className="value">{order.shipment_method}</span>
          </div>
        </RenderIf>
        {order.high_priority && false && (
          <div className="shipping-row">
            <span className="label">High Priority</span>
            <span className="value">
              {order.high_priority}
            </span>
          </div>
        )}
        {order.shipping_status && (
          <div className="shipping-row">
            <span className="label">Shipping Status</span>
            <span className="value">{humanize(order.shipping_status)}</span>
          </div>
        )}
        {order.vendor_shipping_info?.length && (
          <div className="shipping-row">
            <span className="label">Tracking #</span>
            <ul className="list">
              {order.vendor_shipping_info.map((i, k) => <dt key={k}>{i.tracking_number}</dt>)}
            </ul>
          </div>
        )}
      </div>
    </StyledShippingInformation>
  )
}

export default ShippingInformation
