// @flow

import type { ResourcesActionTypes } from 'src/redux/actions/resources/resourcesTypes'
import { GET_RESOURCES, GET_RESOURCE_PAGE, GET_REGISTER_AGREEMENT, TOGGLE_AGREEMENT_FORM } from 'src/redux/actions/resources/resources'

export type ResourceItem = any; // ToDo types

export type ResourcesData = ResourceItem[];

export type ResourcesState = {
  isLoading: boolean,
  isLoadingResource: boolean,
  resourcesData?: ResourcesData,
  resourcePage: ?ResourceItem,
  resourcePageErrors?: ?{ [key: string]: any } | ?string,
  resourcesErrors?: ?{ [key: string]: any } | ?string,
  agreementData: any,
  showAgrementForm: any
};

const initialState = {
  isLoading: false,
  isLoadingResource: false,
  resourcesData: [],
  resourcePage: null,
  resourcePageErrors: null,
  resourcesErrors: null,
  agreementData: null,
  showAgrementForm: false,
}

const resources = (state: ResourcesState = initialState, action: ResourcesActionTypes): ResourcesState => {
  switch (action.type) {
    case GET_RESOURCES.REQUEST:
    case GET_REGISTER_AGREEMENT.REQUEST:
    {
      return {
        ...state,
        isLoading: true,
      }
    }
    case GET_RESOURCES.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        resourcesData: [...action.payload],
      }
    }
    case GET_REGISTER_AGREEMENT.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        resourcesErrors: null,
        agreementData: action.payload ? { ...action.payload } : null,
      }
    }
    case TOGGLE_AGREEMENT_FORM: {
      return {
        ...state,
        isLoading: false,
        resourcesErrors: null,
        agreementData: null,
      }
    }

    case GET_RESOURCES.FAILURE:
    case GET_REGISTER_AGREEMENT.FAILURE:
    {
      return {
        ...state,
        isLoading: false,
        resourcesErrors: action.payload,
      }
    }
    case GET_RESOURCE_PAGE.REQUEST:
    {
      return {
        ...state,
        isLoadingResource: true,
      }
    }
    case GET_RESOURCE_PAGE.SUCCESS:
    {
      return {
        ...state,
        isLoadingResource: false,
        resourcePage: action.payload,
      }
    }
    case GET_RESOURCE_PAGE.FAILURE:
    {
      return {
        ...state,
        isLoadingResource: false,
        resourcePageErrors: action.payload,
      }
    }
    default: {
      return state
    }
  }
}

export default resources
