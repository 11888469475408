import React from 'react'

import {
  EmailIcon,
  TwitterIcon,
  FacebookIcon,
  InstagramIcon,
} from 'src/assets/icons'
export const types = {
  link: 'link',
  mail: 'mail',
}

export const config = [
  {
    name: 'email',
    title: 'Public email',
    type: types.mail,
    Component: <EmailIcon width={48} height={48} />,
  },
  {
    name: 'twitter',
    title: 'twitter',
    type: types.link,
    Component: <TwitterIcon />,
  },
  {
    name: 'facebook',
    title: 'facebook',
    type: types.link,
    Component: <FacebookIcon />,
  },
  {
    name: 'instagram',
    title: 'instagram',
    type: types.link,
    Component: <InstagramIcon />,
  },
]

export const getProps = ({ type, data }) => {
  if (types.mail === type) {
    return {
      // $FlowFixMe
      href: `mailto:${data}`,
    }
  }
  return {
    rel: 'noopener noreferrer',
    target: '_blank',
    href: data,
  }
}

