// @flow
import { all } from 'redux-saga/effects'
import login from './login'
import logout from './logout'
import register from './register'
import sendActivationEmail from './sendActivationEmail'
import getUser from './getUser'
import editUser from './editUser'
import setPassword from './setPassword'
import getMyLeagues from './getMyLeagues'
import getPublicLeagues from './getPublicLeagues'
import getLeague from './getLeague'
import createLeague from './createLeague'
import getLeaguesOrganizations from './getLeaguesOrganizations'
import createDivision from './createDivision'
import createSeason from './createSeason'
import editLeague from './editLeague'
import editDivision from './editDivision'
import getPaymentToken from './getPaymentToken'
import editSeason from './editSeason'
import uploadInsurance from './uploadInsurance'
import toastMessage from './toastMessage'
import getNFLTeams from './getNFLTeams'
import getResources from './getResources'
import getResourcePage from './getResourcePage'
import makeOrder from './makeOrder'
import makeInsurance from './makeInsurance'
import getMembersListByLeagueId from './getMembersListByLeagueId'
import inviteMember from './inviteMember'
import getRegisterAgreement from './getRegisterAgreement'
import submitUserAgreement from './submitUserAgreement'
import submitVirtualPayment from './submitVirtualPayment'
import submitPendingOrderCardPayment from './submitPendingOrderCardPayment'
import changePassword from './changePassword'
import changeEmail from './changeEmail'
import getOrders from './getOrders'
import getOrder from './getOrder'
import cancelOrder from './cancelOrder'
import deleteSeason from './deleteSeason'
import removeMembers from './removeMembers'
import removeDivision from './removeDivision'
import getSurveyQuestions from './getSurveyQuestions'
import getLeagueInsurance from './getLeagueInsurance'
import getMyInsurances from './getMyInsurances'
import messageCenter from './messageCenter'
import resourcesDashboard from './resourcesDashboard'
import discount from './discount'
import submitACHAccountDetails from './submitACHAccountDetails'
import getACHInstance from './getACHInstance'
import saveACHAccountDetails from './saveACHAccountDetails'
import submitACHMicroTransfer from './submitACHMicroTransfer'
import getPaymentMethods from './getPaymentMethods'
import getPaymentMethodByToken from './getPaymentMethodByToken'
import deletePaymentMethod from './deletePaymentMethod'
import createPaymentMethod from './createPaymentMethod'
import paymentSagas from './payments'
import featureManagementSagas from './featureManagement'
import exemptionCertificatesSagas from './exemptionCertificates'
import myLeaguesSagas from './myLeagues'
import bulkOrderSagas from './bulkOrder'

// types
import type { IOEffect } from 'redux-saga/effects'

export default function * rootSaga(): Generator<IOEffect, void, any> {
  const sagas = [
    ...login,
    ...logout,
    ...register,
    ...sendActivationEmail,
    ...getUser,
    ...editUser,
    ...getMyLeagues,
    ...getPublicLeagues,
    ...createLeague,
    ...setPassword,
    ...getLeaguesOrganizations,
    ...createDivision,
    ...getLeague,
    ...createSeason,
    ...editLeague,
    ...editSeason,
    ...editDivision,
    ...getPaymentToken,
    ...uploadInsurance,
    ...toastMessage,
    ...getNFLTeams,
    ...getResources,
    ...getResourcePage,
    ...makeOrder,
    ...makeInsurance,
    ...getMembersListByLeagueId,
    ...inviteMember,
    ...getRegisterAgreement,
    ...submitUserAgreement,
    ...submitVirtualPayment,
    ...submitPendingOrderCardPayment,
    ...changePassword,
    ...changeEmail,
    ...getOrders,
    ...deleteSeason,
    ...removeMembers,
    ...getOrder,
    ...cancelOrder,
    ...removeDivision,
    ...getSurveyQuestions,
    ...getLeagueInsurance,
    ...getMyInsurances,
    ...messageCenter,
    ...resourcesDashboard,
    ...discount,
    ...submitACHAccountDetails,
    ...getACHInstance,
    ...saveACHAccountDetails,
    ...submitACHMicroTransfer,
    ...getPaymentMethods,
    ...getPaymentMethodByToken,
    ...deletePaymentMethod,
    ...createPaymentMethod,
    ...paymentSagas,
    ...featureManagementSagas,
    ...exemptionCertificatesSagas,
    ...myLeaguesSagas,
    ...bulkOrderSagas,
  ]

  yield all(sagas)
}
