import styled from 'styled-components'
import { device } from 'src/common/theme/mediaQueries'

export const StyledAccordion = styled.div`
    background-color: #fff;
    margin-bottom: 20px;
    
    .accordion-body {
        border: 1px solid ${props => props.theme.main.colors.borderColor};
        border-top: 0;
        height: ${props => props.openAccordion ? 'auto' : '0'};
        overflow: hidden;
        padding: ${props => props.openAccordion ? '14px' : '0'};
    }
    
    .accordion-panel {
        align-items: center;
        background-color: #e9f0ff;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        padding: 14px;
    }
    
    .panel-left {
        align-items: center;
        display: flex;
        flex: 1;
    }
    
    .panel-right {
        align-items: center;
        display: flex;
        
        .amount {
            font-weight: 300;
            padding-right: 20px;
        }

        svg {
            margin-right: 15px;
            font-size: 45px;
        }
    }
    
    .accordion-title {
        align-items: center;
        display: flex;
        font-size: 14px;
        font-weight: 300;
        margin-bottom: 0;
        text-transform: uppercase;
        width: 220px;
        
        svg {
            margin-right: 5px;
        }
        
        span {
            font-weight: 400;
            margin-right: 14px;
        }
    }
    
    .toggle {
        display: flex;
        margin-bottom: ${props => props.openAccordion ? '4px' : '0'};
        transform: rotate(${props => props.openAccordion ? '180' : '0'}deg);
    }
    
    .list-sizes {
        font-size: 15px;
        font-weight: 300;
    }
    .has-error {
        border: 1px solid #f00
    }
    
    @media ${device.tabletL} {
        .panel-left {
            align-items: flex-start;
            flex-direction: column;
            padding-right: 20px;
        }
        
        .accordion-title {
            margin-bottom: 8px;
        }
    }
    
    @media ${device.mobileL} {
        .accordion-action {
            justify-content: space-between;
            margin-top: 0;
        }
        
        .list-sizes {
            line-height: 1.2;
        }
    }
`
