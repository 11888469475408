// @flow
import * as React from 'react'
import { connect } from 'react-redux'

import {
  getPlayerQuote,
  setPlayerData,
  setCoverage,
  cleanInsuranceData,
  cleanPlayerData,
  setAdditionalInsuredsData,
  cleanAdditionalInsuredsData,
} from 'src/redux/actions/insurances/insurances'

// const AGE_CATEGORIES = {
//   1: { lowerBoundary: 1, upperBoundary: 12, name: '12 & under' },
//   2: { lowerBoundary: 13, upperBoundary: 15, name: '13-15' },
//   3: { lowerBoundary: 16, upperBoundary: 18, name: '16-18' },
// }
const withMakeInsurance = <P>(WrappedComponent: React.ComponentType<P>) => {
  class WithMakeInsuranceComponent extends React.Component<P> {
    render() {
      return <WrappedComponent {...this.props} />
    }
  }
  const mapStateToProps = ({ makeInsurance }) => ({ makeInsurance })

  const mapDispatchToProps = (dispatch, ownProps) => ({
    getPlayerQuote: values => dispatch(getPlayerQuote.request({ ...values })),
    setCoverage: num => dispatch(setCoverage.success(num)),
    cleanPlayerData: () => dispatch(cleanPlayerData.success()),
    cleanInsuranceData: () => {
      dispatch(cleanInsuranceData.success())
    },
    setPlayerData: formValues => {
      const playerNumbers = Object.entries(formValues).reduce((acc, el) => {
        const [key = '', value = 0] = el
        const [league_id, group_id] = key.split('-')
        if (!acc[league_id]) acc[league_id] = []
        acc[league_id].push({ ageGroup: +group_id, numberOfPlayers: +value })
        return acc
      }, {})
      dispatch(setPlayerData.success({ ...playerNumbers }))
    },
    setAdditionalInsured: insureds => dispatch(setAdditionalInsuredsData.success(insureds)),
    cleanAdditionalInsured: () => dispatch(cleanAdditionalInsuredsData.success()),
  })

  return connect(
    mapStateToProps,
    mapDispatchToProps,
  )(WithMakeInsuranceComponent)
}

export default withMakeInsurance

