// @flow
import * as React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import {
  getMyInsurances,
  checkFileInsurance,
} from 'src/redux/actions/myInsurances/myInsurances'

const withMyInsurances = <P>(WrappedComponent: React.ComponentType<P>) => {
  class WithMyInsurancesComponent extends React.Component<P> {
    render() {
      return <WrappedComponent {...this.props} />
    }
  }
  const mapStateToProps = ({ myInsurances }) => ({ myInsurances })

  const mapDispatchToProps = (dispatch, ownProps) => ({
    getMyInsurances: () => dispatch(getMyInsurances.request()),
    checkFileInsurance: async values => dispatch(checkFileInsurance.request(values)),
  })

  return connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withRouter(WithMyInsurancesComponent))
}

export default withMyInsurances
