// @flow

import React from 'react'
import { StyledFormInput, StyledError } from './formInputStyles'
import type { FormInputPropsType } from './FormInputTypes'

const FormInput = ({
  input,
  meta: { touched, error },
  placeholder,
  validationErrors,
  id,
  accept, // form field id equals to validationErrors.errors[key]
  // $FlowFixMe
  ...rest
}: FormInputPropsType) => {
  let errorBlock = null

  if (touched && (error || (validationErrors && validationErrors.errors[id]))) {
    errorBlock = (
      <div>
        <StyledError className="error-message">{error || validationErrors.message}</StyledError>
      </div>
    )
  }
  const handleChange = e => {
    const { onChange } = input
    onChange(e.target.files[0])
  }

  return (
    <>
      <StyledFormInput accept={accept} type="file" {...rest} placeholder={placeholder} onChange={handleChange} />
      {errorBlock}
    </>
  )
}

export default FormInput
