// @flow

import React from 'react'
import { StyledRadioInput, StyledError, StyledFormInput } from './radioStyles'
import type { RadioPropsType } from './RadioTypes'

const FormInput = ({
  input,
  meta: { touched, error },
  validationErrors,
  labelText,
  defaultChecked,
  id, // form field id equals to validationErrors.errors[key]
  ...rest
}: RadioPropsType) => {
  let errorBlock = null

  if (touched && (error || (validationErrors && validationErrors.errors[id]))) {
    errorBlock = (
      <div>
        <StyledError className="error-message">
          {error || validationErrors.message}
        </StyledError>
      </div>
    )
  }

  return (
    <>
      <StyledRadioInput>
        <StyledFormInput {...rest} {...input} />
        <span>{labelText}</span>
      </StyledRadioInput>
      {errorBlock}
    </>
  )
}

export default FormInput
