// @flow
import React from 'react'
import { Link } from 'react-router-dom'
import { Logo } from 'src/assets/images'
// styles
import { StyledNotFound } from './notFoundStyles'

const NotFound = () => (
  <StyledNotFound>
    <div className="container">
      <div className="content">
        <div className="left">
          <img src={Logo} alt="logo"/>
        </div>
        <div className="right">
          <h1 className="title">Error 404</h1>
          <h3 className="subtitle">We couldn&apos;t find the page you were looking for.</h3>
          <p className="description">The link you followed probably broken, or the page has been removed.</p>
          <p className="description">Return to the <Link to="/">Dashboard</Link></p>
        </div>
      </div>
    </div>
  </StyledNotFound>
)

export default NotFound
