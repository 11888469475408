// @flow

import { requestTypeCreator } from 'src/helpers'

import type {
  BasicRedux,
} from './exemptionCertificatesTypes'

export const GET_EXEMPTION_CERTIFICATES = requestTypeCreator('GET_EXEMPTION_CERTIFICATES')
export const SUBMIT_EXEMPTION_CERTIFICATES = requestTypeCreator('SUBMIT_EXEMPTION_CERTIFICATES')
export const GET_EXPOSURE_ZONES = requestTypeCreator('GET_EXPOSURE_ZONES')
export const GET_EXEMPTION_CERTIFICATE_ATTACHMENT = requestTypeCreator('GET_EXEMPTION_CERTIFICATE_ATTACHMENT')

export const getExemptionCertificates = {
  error: (payload: any): BasicRedux => ({ type: GET_EXEMPTION_CERTIFICATES.FAILURE, payload }),
  request: (payload: any): BasicRedux => ({ type: GET_EXEMPTION_CERTIFICATES.REQUEST, payload }),
  success: (payload: any): BasicRedux => ({ type: GET_EXEMPTION_CERTIFICATES.SUCCESS, payload }),
}

export const submitExemptionCertificate = {
  error: (payload: any): BasicRedux => ({ type: SUBMIT_EXEMPTION_CERTIFICATES.FAILURE, payload }),
  request: (payload: any): BasicRedux => ({ type: SUBMIT_EXEMPTION_CERTIFICATES.REQUEST, payload }),
  success: (payload: any): BasicRedux => ({ type: SUBMIT_EXEMPTION_CERTIFICATES.SUCCESS, payload }),
}

export const getExposureZones = {
  error: (payload: any): BasicRedux => ({ type: GET_EXPOSURE_ZONES.FAILURE, payload }),
  request: (payload: any): BasicRedux => ({ type: GET_EXPOSURE_ZONES.REQUEST, payload }),
  success: (payload: any): BasicRedux => ({ type: GET_EXPOSURE_ZONES.SUCCESS, payload }),
}

export const getExemptionCertificateAttachment = {
  error: (payload: any): BasicRedux => ({ type: GET_EXEMPTION_CERTIFICATE_ATTACHMENT.FAILURE, payload }),
  request: (payload: any): BasicRedux => ({ type: GET_EXEMPTION_CERTIFICATE_ATTACHMENT.REQUEST, payload }),
  success: (payload: any): BasicRedux => ({ type: GET_EXEMPTION_CERTIFICATE_ATTACHMENT.SUCCESS, payload }),
}

