import http from 'src/services/http'

export const fetchDownloadOrderInvoicePDF = async id => {
  const response = await http(`api/invoice/order/${id}/pdf`, {
    method: 'GET',
  }, {
    responseIsFile: true,
  })
  return response
}

export const fetchAmounts = async id => {
  const response = await http(`api/order/${id}/calculate-amounts`, {
    method: 'GET',
  })
  return response?.data
}
