// @flow
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { ButtonPrimary, Confirm, ItemInformation, RenderIf } from 'src/common/components'
import { PAYMENT_STATUS, ORDER_MODE } from 'src/common/constants'
import OrderSummaryInfo from 'src/common/components/orderSummaryInfo'
import { StyledOrderSummary, StyledShippingInformation, StyledButtons } from './orderSummaryStyles'
import ShippingInformation from './fragments/shippingInformation'
import PaymentInformation from './fragments/paymentInformation'
import RefundInformation from './fragments/refundInformation'

// types
import type { OrderSummaryPropsType } from './OrderSummaryTypes'

const OrderSummaryContent = ({
  cancelPendingOrder,
  bulkOrder: {
    order,
    payments,
    refunds,
    items,
    summary: { total_cost, total_amount, additional, custom_info },
  },
  user: { userData },
  myLeagues: { leagueData },
  downloadMatrixTeamOrder = () => {},
  getNFLTeams,
}: OrderSummaryPropsType) => {
  let filtered_payments = payments && payments.filter(p => p.status === 'PAID')
  if (payments && payments.length && !filtered_payments.length) {
    filtered_payments = payments.slice(0, 1)
  }

  if (!order) {
    return (
      <p>No Order Found</p>
    )
  }

  useEffect(() => {
    getNFLTeams()
  }, [])

  const downloadPackingList = event => {
    event.preventDefault()
    downloadMatrixTeamOrder(order.id)
  }

  const isVirtual = ORDER_MODE.VIRTUAL === order?.order_mode

  const shippingBlockTitle = isVirtual
    ? 'League Information'
    : 'Shipping information'

  return (
    <StyledOrderSummary>
      <div className="summary-block">
        <div className="block-heading">Order Summary</div>
        <StyledShippingInformation>
          <OrderSummaryInfo
            order={order}
            totalOrdered={total_amount}
            totalCost={total_cost}
            additional={additional}
            customInfo={custom_info}
            refunds={refunds}
          />
          <div className="col">
            { order.order_mode === ORDER_MODE.TEAM &&
            <div className="control-btn-row">
              <ButtonPrimary buttonText="PACKING LIST" size="small" action={downloadPackingList} />
            </div>
            }
            {order.payment_status === PAYMENT_STATUS.PENDING && (
              <div className="control-btn-row">
                <Link to={`/my-leagues/${order.league_id}/orders/${order.id}/payment`}>
                  <ButtonPrimary size="medium" buttonText={'Pay with card'} />
                </Link>
              </div>
            )}
            {order.payment_status === PAYMENT_STATUS.PENDING && !order.submitted_at && (
              <div className="control-btn-row">
                <Confirm
                  title="Confirm"
                  description={'Are you sure you wish to cancel the order?'}
                  confirmText="yes"
                  cancelText="no"
                >
                  {confirm => (
                    <div className="text-center">
                      <ButtonPrimary classname size="medium" buttonText="Cancel order"
                        action={confirm({
                          handleSubmit: () => cancelPendingOrder({ orderId: order.id, leagueId: order.league_id }),
                          valid: true,
                        })}/>
                    </div>
                  )}
                </Confirm>
              </div>
            )}

            {/* ADD BTNS HERE */}
          </div>
        </StyledShippingInformation>
      </div>

      <div className="summary-body">
        {filtered_payments && filtered_payments.length > 0 && (
          <div className="summary-block">
            <div className="block-heading">Payment Information</div>

            {refunds && refunds.refundsData && (
              <RefundInformation
                refunds={refunds.refundsData}
                order={order}
              />
            )}
            <PaymentInformation
              paymentsArray={filtered_payments}
              order={order}
            />

          </div>
        )}
        <div className="summary-block">
          <div className="block-heading">{shippingBlockTitle}</div>
          <ShippingInformation
            order={order}
            userData={userData}
            leagueData={leagueData}
          />
        </div>
        <RenderIf condition={!isVirtual}>
          <div className="summary-block">
            <div className="block-heading">Items Ordered</div>
            <ItemInformation items={items} order={order} />
          </div>
        </RenderIf>

        {order.payment_status !== PAYMENT_STATUS.PAID && order.payment_status !== PAYMENT_STATUS.PENDING && +order.discount_amount > 0 && (
          <StyledButtons className="text-center">
            <Link to={`/order/league/${order.league_id}/season/${order.season_id}/order/${order.id}/only/payment`}>
              <ButtonPrimary size="medium" buttonText={'Go to payment'} />
            </Link>
          </StyledButtons>
        )}
      </div>
    </StyledOrderSummary>

  )
}

export default OrderSummaryContent
