// @flow

import { call, fork, put, take } from 'redux-saga/effects'
import { SHOW_TOAST, showToast } from 'src/redux/actions/ui/ui'
import { delay } from 'q'
import { MESSAGE_TYPES } from 'src/common/constants'

// types
import type { IOEffect } from 'redux-saga/effects'

function * watchToastMessages(): Generator<IOEffect, void, any> {
  while (true) {
    const { payload } = yield take(SHOW_TOAST.SUCCESS)
    yield call(delay, payload?.messageType === MESSAGE_TYPES.ERROR ? 6000 : 3000)
    yield put(showToast.hide())
  }
}

export default [fork(watchToastMessages)]
