// @flow
import React, { lazy } from 'react'
import { PrivateRoute } from 'src/common/components'

const ThankYouPage = lazy(() => import('src/modules/order/thankYouPage'))
const ChildLayout = lazy(() => import('src/modules/order/childLayout'))

export default [
  <PrivateRoute exact={true} path="/order/league/:leagueId/season/:seasonId/step/:step" component={ChildLayout} key="order-stepper"/>,
  <PrivateRoute exact={true} path="/order/league/:leagueId/season/:seasonId/order/:orderId/step/:step" component={ChildLayout} key="order-stepper-with-id"/>,

  <PrivateRoute exact={true} path="/order/league/:leagueId/season/:seasonId/order/:orderId/thank-you" component={ThankYouPage} key="order-thank-you"/>,
]
