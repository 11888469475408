import styled from 'styled-components'
import { device } from 'src/common/theme/mediaQueries'

export const StyledNotFound = styled.div`
    background-color: #ebedef91;

    .container {
        align-items: center;
        display: flex;
        justify-content: center;
        margin: 0 auto;
        max-width: 1100px;
        min-height: 100vh;
    }
    
    .content {
        display: flex;
        padding: 3% 3% 8%;
    }
    
    .left {
        margin: 10px 35px 0 0;
        margin-right: 35px;
        max-width: 240px;
    }
    
    a {
        color: #d43540;
        text-decoration: underline;
        
        :hover {
            text-decoration: none;
        }
    }
    
    .description {
        font-size: 17px;
        margin: 0;
    }
    
    @media ${device.tablet} {
        .content {
            flex-direction: column;
            text-align: center;
        }
        
        .left {
            margin: 0 auto 25px auto;
            max-width: 180px;
        }
        
        .title {
            font-size: 30px;
        }
    }
`
