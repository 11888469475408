import styled from 'styled-components'

export const StyledBlock = styled.div`
    box-shadow: ${props => props.theme.main.secondary.blockBoxShadow};
    margin-bottom: 30px;
  
    width: ${({ halfWidth }) => halfWidth ? '50%' : '100%'}

    .block-header {
        background-color: ${props => props.theme.main.colors.blockHeaderBackground};
        padding: 15px;
        display: flex;
        justify-content: space-between;
        
        .title {
            margin: auto 0;
        }
      
        .full-width {
            width: 100%;
        }
    }
    
    .block-body {
        padding: 15px;
    }
    
    .bottom-button {
        display: block;
        width: 200px;
        margin: 0 auto;
    }
    
    .summary-action {
      display: flex;
      justify-content: center;
    }
`
