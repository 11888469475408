// @flow
import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { ArrowBackIcon } from 'src/assets/icons'
import { compose } from 'redux'

import { Sidebar, Header, DashboardHeader, ToastMessage, ServiceWorkerNotifier } from 'src/common/components'
import LeagueWarning from './fragments/leagueWarning'
// styles
import { StyledLayout, StyledContent } from './layoutStyles'
// types
import type { LayoutPropTypes, LayoutStateTypes } from './LayoutTypes'
import { withMyLeagues, withUser, withUi } from 'src/decorators'
import getLocation from 'src/helpers/getLocation'
import { StyledEmptyBlock, StyledInfoBlock } from 'src/modules/myLeagues/leagueManagement/leagueManagementStyles'

class Layout extends Component<LayoutPropTypes, LayoutStateTypes> {
  state = {
    showMenu: false,
  }

  toggleMenuHandler = () => {
    this.setState({
      showMenu: !this.state.showMenu,
    })
  }

  render() {
    const {
      user: { userData },
      myLeagues: { leagueData },
      match: { params: { leagueId } },
      ui: { toast, confirmData },
      userHasCompletedProfile,
      toggleConfirm,
    } = this.props

    const { first_name, last_name } = userData || {}
    const { title, messageType } = toast || {}

    return (
      <StyledLayout showMenu={this.state.showMenu}>
        <div className="layout-item-left">
          <div className="toggle-sidebar" onClick={this.toggleMenuHandler}>
            <span className="icon-wrapper">
              <ArrowBackIcon />
            </span>
          </div>
          <Sidebar showMenu={this.state.showMenu}/>
        </div>
        <div className="layout-item-right">
          <ServiceWorkerNotifier />
          <Header />
          {title && messageType && (
            <ToastMessage
              title={title}
              messageType={messageType}
            />
          )}
          <StyledContent className="content">
            {!userHasCompletedProfile
              ? (
                <StyledInfoBlock>
                  <StyledEmptyBlock className="empty-insurance">
                    <span className="text-center title">Your account data is incomplete.
                      Please update your user profile to have access to all features. <Link to="/edit-profile">Click here to Edit Profile</Link></span>
                  </StyledEmptyBlock>
                </StyledInfoBlock>
              )
              : (
                <DashboardHeader
                  title={!leagueId && first_name && last_name ? `${first_name} ${last_name}` : leagueData.name}
                  location={leagueId ? getLocation(leagueData) : ''}
                  editProfileLink={true}
                  leagueId={leagueId || null}
                  websiteLink={leagueId ? leagueData.website : false}
                />
              )
            }
            {this.props.children}
          </StyledContent>
          <LeagueWarning
            confirmData={confirmData}
            toggleConfirm={toggleConfirm}
          />
        </div>
      </StyledLayout>
    )
  }
}

export default compose(
  withMyLeagues,
  withUser,
  withRouter,
  withUi,
)(Layout)

