import http from 'src/services/http'

export const getFeatures = async() => {
  const response = await http('api/features', {
    method: 'GET',
  })
  return response?.data
}

export const getFeature = async name => {
  const response = await http(`features/${name}`, {
    method: 'GET',
  })
  return response?.data
}

