// @flow
import type { DefaultRedux } from 'src/redux/actions/makeOrder/makeOrderTypes'
import {
  groupItemsByType,
  removeForm,
  teamsChange,
  countPaidTotal,
  assingItemsToForms,
  assingItemsToFormsFromExcel,
} from 'src/helpers/shapeOrder'
import { ITEM_TYPES } from 'src/common/constants'
import config from 'src/config'

import {
  MAKE_ORDER_TEAMS_CHANGE,
  CHECKOUT_LATEST_ITEMS_QTY,
  SEND_MAKE_ORDER_FILE,
  SUBMIT_MAIN_MAKE_ORDER,
  UPDATE_ORDER_FORM_STATE,
  REMOVE_TEAM_FROM_MAKE_ORDER,
  MAKE_ORDER_UPDATE_ORDER,
  RESET_MAKE_ORDER,
  RESET_CONFIRM_WARNING,
  SEND_SHIPPING_ADDRESS,
  SEND_SHIPPING_METHOD,
  GET_SHIPPING_METHODS,
  SET_AVAILABLE_SHIPPING_ADDRESSES,
  SET_MAKE_ORDER_ADJUSTED,
  RESTORE_ORDER_PROGRESS,
  VERIFICATION_CHECK_BEFORE_PAYMENT,
  SUBMIT_ORDER_CHECK_PAYMENT,
  UPDATE_DISCOUNT,
  MAKE_DISCOUNT_PAYMENT,
  UPDATE_NOTE,
  SET_DISCOUNT,
  UPDATE_CREDIT_AMOUNT,
  SET_FOOTBALLS_STATE,
} from 'src/redux/actions/makeOrder/makeOrder'

import type { OrderType, SaleItemType, OrderedItemType } from 'src/common/constants.types'

export type SaleItemData = {
  id: number,
  team_id: string,
  size: string,
  type: SaleItemType,
  size_type: ?string,
  price: string,
  color: ?string,
  shorts_color: ?string,
  is_forbidden: ?boolean,
  is_sublimated: boolean,
  max_quantity: number,
  max_paid_quantity: number,
  inventory_limited: boolean,
  jerseys_match_condition: ?$Shape<SaleItemData>[]
};

export type ShippingMethodDataItem ={
  shipping_service_id: string,
  service_id: string,
  estimated_delivery: string,
  cost: string,
  shipping_count: string,
  free: ?boolean,
  name: string
};

export type AdditionalItemSummary = {
  total: number,
  free: number,
  paid: number
};

export type AdditionalSummaryOrderType = {
  brown_balls: ?AdditionalItemSummary,
  blue_balls: ?AdditionalItemSummary,
  mini_balls: ?AdditionalItemSummary,
  belts: ?AdditionalItemSummary,
  pee_wee_premium_balls: ?AdditionalItemSummary,
  junior_premium_balls: ?AdditionalItemSummary,
  cost: number
};

export type AdditionalCustomInfoSummaryType = {
  sublimated_jersey_amount: number,
  core_jersey_amount: number,
  core_jersey_amount: number,
  core_shorts_amount: number,
  sublimated_shorts_amount: number
};

export type PaymentOrderType = {
  card: ?string,
  amount: number,
  card_type: ?string,
  type: ?string,
  status: ?string,
  description: ?string,
  descriptor: ?string,
  created_at: string
};

export type ShippingMethodsList = ShippingMethodDataItem[];

export type FreeRulesType = {
  FOR_TYPE: SaleItemType,
  FOR_EVERY_AMOUNT: number
};

export type FreeRulesObjType = {
  BELT: ?FreeRulesType,
  FOOTBALL: ?FreeRulesType
};

export type GroupedItemsByForm = {
  team_id: ?string,
  form_name: string,
  form_type: string,
  items: SaleItemData[]
};

export type ItemsDataType = {
  all: SaleItemData[],
  forms: GroupedItemsByForm[],
  [key: SaleItemType]: SaleItemData[],
  mapped: {
    [key: number]: SaleItemData
  }
};

export type ItemsSummaryType = {
  total_amount: {
    [key: SaleItemType]: number
  },
  total_cost: {
    [key: SaleItemType]: number
  },
  additional: AdditionalSummaryOrderType,
  custom_info: AdditionalCustomInfoSummaryType
};

export type MakeOrderState = {
  isLoading: boolean,
  hasOrderSubmitFailed: boolean,
  order: OrderType,
  freeRules: FreeRulesObjType,
  items: ItemsDataType,
  lastTouchedTeamForm: ?string,
  lastTouchedTeamFormField: ?string,
  paidFormsState: {
    [key: string]: { // form name
      isValid: boolean,
      [key: number]: number // field: value
    }
  },
  paidTotalCounter: {
    [key: SaleItemType]: number
  },
  freeFormsState: {
    [key: string]: { // form name
      isValid: boolean,
      [key: number]: number // field: value
    }
  },
  avilableShippingAddresses: any,
  lastSubmittedShippingAddress: any,
  isAddressValidationFailed: boolean,
  orderedItems: OrderedItemType[],
  summary: ItemsSummaryType,
  payments: PaymentOrderType[],
  warningMessage?: string | null,
  makeOrderErrors?: any,
  isShippingLoading: boolean,
  shippingMethodsList: ShippingMethodsList,

  isRestoringLoading: boolean,
  isVerificationBeforePaymentLoading: boolean,
  discount: {
    amount: string,
    active: boolean,
    isChanged: boolean
  },
  jerseysCredit?: number,
  jerseysCreditChanged?: boolean
};

const makeOrder = (state: MakeOrderState = getInitState(), action: DefaultRedux): MakeOrderState => {
  switch (action.type) {
    case MAKE_ORDER_TEAMS_CHANGE.REQUEST:
    case SEND_MAKE_ORDER_FILE.REQUEST:
    case SUBMIT_MAIN_MAKE_ORDER.REQUEST:
    case SEND_SHIPPING_ADDRESS.REQUEST:
    case CHECKOUT_LATEST_ITEMS_QTY.REQUEST:
    case SUBMIT_ORDER_CHECK_PAYMENT.REQUEST:
    case MAKE_DISCOUNT_PAYMENT.REQUEST:
    case UPDATE_NOTE.REQUEST:
    case UPDATE_CREDIT_AMOUNT.START:
    {
      return {
        ...state,
        isLoading: true,
      }
    }

    case UPDATE_DISCOUNT.REQUEST:
    {
      return {
        ...state,
        isLoading: true,
        discount: {
          ...state.discount,
          isChanged: false,
        },
      }
    }

    case SET_DISCOUNT.REQUEST:
    {
      return ({
        ...state,
        discount: { ...action.payload },
      })
    }

    case SEND_SHIPPING_METHOD.REQUEST:
    case GET_SHIPPING_METHODS.REQUEST:
    {
      return {
        ...state,
        isShippingLoading: true,
      }
    }

    case VERIFICATION_CHECK_BEFORE_PAYMENT.REQUEST:
    {
      return {
        ...state,
        isVerificationBeforePaymentLoading: true,
      }
    }

    case RESTORE_ORDER_PROGRESS.REQUEST:
    {
      return {
        ...state,
        isRestoringLoading: true,
      }
    }

    case GET_SHIPPING_METHODS.SUCCESS:
    {
      return {
        ...state,
        isShippingLoading: false,
        shippingMethodsList: [...action.payload],
      }
    }

    case SET_AVAILABLE_SHIPPING_ADDRESSES.SUCCESS:
    {
      return {
        ...state,
        isLoading: false,
        avilableShippingAddresses: [...action.payload],
      }
    }

    case REMOVE_TEAM_FROM_MAKE_ORDER.SUCCESS:
    {
      const { form_name } = action.payload
      return countPaidTotal({
        ...state,
        ...removeForm(state, form_name),
        makeOrderErrors: null,
      })
    }

    case UPDATE_ORDER_FORM_STATE.PAID: {
      const values = action.payload?.name
        ? { [action.payload.name]: action.payload.state }
        : Object.entries(action.payload)
          .reduce((res, [key: string, value: { isValid: boolean, [key: number]: number}]) => ({ ...res, [key]: value }), {})

      return countPaidTotal({
        ...state,
        // $FlowFixMe
        paidFormsState: {
          ...state.paidFormsState,
          ...values,
        },
      })
    }

    case UPDATE_ORDER_FORM_STATE.FREE: {
      const { name, state: formState } = action.payload
      return {
        ...state,
        freeFormsState: {
          ...state.freeFormsState,
          [name]: formState,
        },
      }
    }

    case MAKE_ORDER_TEAMS_CHANGE.SUCCESS:
    {
      return countPaidTotal({
        ...state,
        ...teamsChange(state, action.payload),
        isLoading: false,
        hasOrderSubmitFailed: false,
        makeOrderErrors: null,
      })
    }
    case CHECKOUT_LATEST_ITEMS_QTY.SUCCESS:
    {
      return countPaidTotal({
        ...state,
        ...teamsChange(state, action.payload),
        isLoading: false,
      })
    }

    case MAKE_ORDER_UPDATE_ORDER.SUCCESS:
    {
      return ({
        ...state,
        order: {
          ...state.order,
          ...action.payload,
        },
      })
    }

    case RESET_CONFIRM_WARNING.SUCCESS:
    {
      return ({
        ...state,
        warningMessage: null,
      })
    }

    case SEND_MAKE_ORDER_FILE.SUCCESS:
    {
      return countPaidTotal(assingItemsToFormsFromExcel({
        ...state,
        items: groupItemsByType(action.payload.saleItems, state),
        freeRules: { ...action.payload.freeRules },
        isLoading: false,
        warningMessage: action.payload.warnMessage,
        makeOrderErrors: null,
      }, action.payload.foundItems, action.payload.isMatrixWithFlowAugusta))
    }

    case SUBMIT_MAIN_MAKE_ORDER.SUCCESS:
    {
      return ({
        ...state,
        isLoading: false,
        hasOrderSubmitFailed: false,
        makeOrderErrors: null,
        jerseysCredit: action.payload?.jerseysCredit,
        jerseysCreditChanged: action.payload?.jerseysCreditChanged,
      })
    }

    case RESTORE_ORDER_PROGRESS.SUCCESS:
    {
      return countPaidTotal(assingItemsToForms({
        ...state,
        order: { ...action.payload.order },
        items: groupItemsByType(action.payload.saleItems, action.payload),
        freeRules: { ...action.payload.freeRules },
        summary: { ...action.payload.summary },
        payments: [...action.payload.payments],
        orderedItems: [...action.payload.items],
        isRestoringLoading: false,
      }, action.payload.items))
    }

    case VERIFICATION_CHECK_BEFORE_PAYMENT.SUCCESS:
    {
      return {
        ...state,
        isVerificationBeforePaymentLoading: false,
      }
    }

    case SUBMIT_MAIN_MAKE_ORDER.ADJUSTED: // ADJUSTED not used?
    case SUBMIT_ORDER_CHECK_PAYMENT.SUCCESS:
    case SUBMIT_ORDER_CHECK_PAYMENT.FAILURE:
    case MAKE_DISCOUNT_PAYMENT.SUCCESS:
    case MAKE_DISCOUNT_PAYMENT.FAILURE:
    case UPDATE_DISCOUNT.FAILURE:
    case UPDATE_NOTE.FAILURE:
    case UPDATE_NOTE.SUCCESS:
    {
      return ({
        ...state,
        isLoading: false,
      })
    }

    case UPDATE_CREDIT_AMOUNT.SUCCESS:
    {
      return ({
        ...state,
        order: { ...action.payload.order },
        isLoading: false,
      })
    }

    case UPDATE_DISCOUNT.SUCCESS:
    {
      return ({
        ...state,
        isLoading: false,
        order: {
          ...state.order,
          discount_amount: action.payload,
        },
      })
    }

    case SEND_SHIPPING_ADDRESS.SUCCESS:
    {
      return ({
        ...state,
        isLoading: false,
        makeOrderErrors: null,
        lastSubmittedShippingAddress: { ...action.payload },
      })
    }

    case SET_FOOTBALLS_STATE:
    {
      return ({
        ...state,
        footballsState: { ...action.payload },
      })
    }

    case SEND_SHIPPING_METHOD.SUCCESS:
    {
      return ({
        ...state,
        isShippingLoading: false,
        makeOrderErrors: null,
      })
    }

    case RESTORE_ORDER_PROGRESS.FAILURE: {
      return {
        ...state,
        isRestoringLoading: false,
      }
    }

    case MAKE_ORDER_TEAMS_CHANGE.FAILURE:
    case SEND_MAKE_ORDER_FILE.FAILURE:
    case UPDATE_CREDIT_AMOUNT.FAILURE:
    {
      return {
        ...state,
        isLoading: false,
        makeOrderErrors: action.payload,

      }
    }

    case SUBMIT_MAIN_MAKE_ORDER.FAILURE:
    {
      return {
        ...state,
        isLoading: false,
        hasOrderSubmitFailed: true,
        makeOrderErrors: action.payload,

      }
    }

    case SEND_SHIPPING_METHOD.FAILURE:
    {
      return {
        ...state,
        isShippingLoading: false,
        makeOrderErrors: action.payload,

      }
    }

    case GET_SHIPPING_METHODS.FAILURE:
    {
      return {
        ...state,
        isShippingLoading: false,
      }
    }

    case RESET_MAKE_ORDER: {
      return { ...getInitState(), ...action.payload }
    }
    case SET_MAKE_ORDER_ADJUSTED: {
      return {
        ...state,
        isLoading: false,
      }
    }

    case SEND_SHIPPING_ADDRESS.FAILURE:
    {
      return {
        ...state,
        isLoading: false,
        makeOrderErrors: action.payload,
        isAddressValidationFailed: true,
      }
    }

    case '@@redux-form/FOCUS': {
      if (action.meta && action.meta.form && config.teamOrderItems.isTeamForm(action.meta.form)) {
        return {
          ...state,
          lastTouchedTeamForm: action.meta.form,
          lastTouchedTeamFormField: action.meta.field,
        }
      }
      if (state.isAddressValidationFailed) {
        return {
          ...state,
          isAddressValidationFailed: false,
        }
      }
      return state
    }

    case '@@redux-form/DESTROY':
    case '@@redux-form/INITIALIZE': {
      return {
        ...state,
        lastTouchedTeamForm: null,
        lastTouchedTeamFormField: null,
      }
    }

    case 'persist/REHYDRATE': { // hack around to remove old version of makeOrder
      if (action.key === 'makeOrder') {
        const { payload } = action
        if (!(payload && payload.items && payload.items.forms)) {
          return getInitState()
        }
      }
      return state
    }

    default: {
      return state
    }
  }
}

export default makeOrder

function getInitState() {
  return {
    isLoading: false,
    hasOrderSubmitFailed: false,
    order: getEmptyOrder(),
    freeRules: {},
    items: {
      all: [],
      forms: [],
      [ITEM_TYPES.FOOTBALL]: [],
      [ITEM_TYPES.BELT]: [],
      [ITEM_TYPES.UNIFORM]: [],
      [ITEM_TYPES.SHORTS]: [],
      [ITEM_TYPES.SOCKS]: [],
      [ITEM_TYPES.SLEEVES]: [],
      [ITEM_TYPES.MOUTHGUARD]: [],
      [ITEM_TYPES.GLOVES]: [],
      [ITEM_TYPES.COACH_SHIRTS]: [],
      mapped: {},
    },
    paidFormsState: {},
    paidTotalCounter: {},
    freeFormsState: {},
    lastTouchedTeamForm: null,
    lastTouchedTeamFormField: null,
    avilableShippingAddresses: [],
    lastSubmittedShippingAddress: {},
    isAddressValidationFailed: false,
    orderedItems: [],
    summary: {
      total_amount: {},
      total_cost: {},
      additional: {
        brown_balls: null,
        blue_balls: null,
        mini_balls: null,
        belts: null,
        junior_premium_balls: null,
        pee_wee_premium_balls: null,
        cost: 0,
      },
      custom_info: {
        sublimated_jersey_amount: 0,
        core_jersey_amount: 0,
        core_shorts_amount: 0,
        sublimated_shorts_amount: 0,
      },
    },
    payments: [],
    warningMessage: null,
    makeOrderErrors: null,
    isShippingLoading: false,
    shippingMethodsList: [],

    isRestoringLoading: false,
    isVerificationBeforePaymentLoading: false,
    lo_credit_amount: 0,
    discount_amount: 0,
    note: '',
    credit_amount: '0',
    discount: {
      amount: '0',
      active: false,
      isChanged: false,
    },
    penalty_amount: '0',
    sum_total: '0',
    exempt: false,
  }
}

function getEmptyOrder() {
  return {
    id: 0,
    season_id: 0,
    league_id: 0,
    payment_status: 'NOT_PAID',
    shipping_service_id: null,
    shipping_service_name: null,
    shipment_method: null,
    wasShippingSelected: false,
    total_amount: '',
    tax_amount: 0,
    order_mode: 'BULK',
    vendor: 'BDA',
    vendor_shipping_info: [],
    contact_first_name: '',
    contact_last_name: '',
    contact_email: '',
    contact_phone: '',
    linked_order_id: null,
    shipping_amount: '',
    shipping_cost_is_free: false,

    billing_address_1: null,
    billing_address_2: null,
    billing_state: null,
    billing_city: null,
    billing_zip: null,
    billing_county: null,

    shipping_address_1: null,
    shipping_address_2: null,
    shipping_state: null,
    shipping_city: null,
    shipping_zip: null,
    shipping_county: null,
    shipping_country: 'US',
    shipping_is_residence: null,
    shipping_status: null,
    tracking_number: null,

    prices: null,
    high_priority: '',
    created_at: '',
    submitted_at: null,
    league_name: '',
    phone: '',
    website: '',
    free_shipping_count: 0,
    can_pay_by_check: false,
    lo_credit_amount: 0,
    discount_amount: 0,
    note: '',
    credit_amount: '0',
    discount: {
      amount: '0',
      active: false,
      isChanged: false,
    },
    isMatrixWithFlowAugusta: false,
    penalty_amount: '0',
    sum_total: '0',
    exempt: false,
    amount_to_pay: 0,
    league_owner_phone: '0',
    currency_cad_rate: 0,
    vt_amount: 0,
    total_paid: 0,
    refund_amount: 0,
    net_amount: 0,
    fees: 0,
    duties: 0,
    credit_paid_amount: 0,
    payment_description: '',
    league_owner_email: '',
  }
}
