import styled from 'styled-components'

export const StyledWrapper = styled.div`
    align-items: center;
    background-color: rgba(0, 0, 0, 0.4);
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 999;
`

export const StyledModal = styled.div`
    background-color: #fff;
    font-size: 17px;
    font-weight: 300;
    line-height: 1.5;
    margin: 0 auto;
    max-width: 800px;
    padding: 24px 24px 36px;
    text-align: center;
    width: 90%;
    
    .modal-action {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 30px;
    }
    
    .modal-title {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 15px;
    }
    
    .modal-body {
        height: 420px;
        overflow-y: auto;
        text-align: left;
    }
    
    .checkmark {
        margin-top: 4px;
    }
    
    .checkbox-container {
        margin-top: 4px;
    }
   
`

export const StyledCloseButton = styled.button`
    align-items: center;
    appearance: none;
    background-color: transparent;
    border: 0;
    color: ${props => props.theme.main.colors.secondaryColor};
    display: flex;
    font-size: 15px;
    justify-content: center;
    padding: 0;
    
    :hover {
        filter: brightness(1.2);
    }
    
    svg {
        height: 18px;
        width: 18px;
        
        path {
            fill: ${props => props.theme.main.colors.secondaryColor};
        }
    }
`
