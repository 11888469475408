// @flow
import { compose } from 'redux'
import { CURRENCIES } from 'src/common/constants'
import type { OrderType, MoneyFormatTypeOptions } from 'src/common/constants.types'

export const formatMoney = (value: any): string => {
  const num = +parseFloat((+value).toFixed(2))
  if (!num) {
    return '0'
  }
  return Math.floor(num) === num ? String(num) : num.toFixed(2).replace('-0', '0')
}

export const sumMoneyAndFormat = (...values: Array<any>): string => {
  let sum: number = 0
  values.forEach(value => { sum += +value })
  return formatMoney(sum)
}

export const subMoneyAndFormat = (from: any, ...values: Array<any>): string => {
  values.forEach(value => { from -= +value })
  return formatMoney(from)
}

export const isMoneyInputValid = (value: any) => {
  // eslint-disable-next-line
  const decimalRegExp = new RegExp('^[+]?([0-9]+([.][0-9]{0,2})?|[.][0-9]+)$')
  return decimalRegExp.test(value)
}

export const getRemainingToPay = ({
  order,
  discount,
  currency,
  currencyRate,
}: {
  order: OrderType,
  discount: {
    active: boolean,
    amount: string
  },
  currency: string,
  currencyRate: string
}) => {
  const getSum = x => {
    return sumMoneyAndFormatCurrency(+x, [+order.discount_amount], {
      currency,
      currencyRate,
      applyCurrencyValues: true,
      applyCurrencyFrom: false,
    })
  }
  const getDiff = () => {
    return subMoneyAndFormatCurrency(+order.amount_to_pay, [+discount.amount], {
      currency,
      currencyRate,
      applyCurrencyValues: false,
      applyCurrencyFrom: true,
    })
  }
  return compose(getSum, getDiff)()
}

export const getCurrencyString = ({ currency }: {
  currency: string
}) => {
  return CURRENCIES.CAD === currency
    ? 'C$ '
    : '$'
}

export const getPriceString = ({ currency, price, currencyRate }: {
  currency: string,
  price: ?string | number | null,
  currencyRate: string | number
}) => {
  const currencyString = getCurrencyString({ currency, price })
  if (!price) {
    return `${currencyString}0`
  }
  const amount = formatMoneyWithCurrency({ currency, price, currencyRate })
  return `${currencyString}${amount}`
}

export const formatMoneyWithCurrency = ({ currency, price, currencyRate }: {
  currency: string,
  price: string | number,
  currencyRate: string | number
}) => {
  if (!price) {
    return '0'
  }
  return CURRENCIES.CAD === currency
    ? formatMoney(+price * +currencyRate)
    : formatMoney(price)
}

export const subMoneyAndFormatCurrency = (
  from: string | number,
  values: Array<string | number>,
  {
    currency,
    currencyRate,
    applyCurrencyValues = false,
    applyCurrencyFrom = false,
  }: MoneyFormatTypeOptions,
): string => {
  const res = values.reduce((acc, cur) => {
    return +acc - (applyCurrencyValues ? +formatMoneyWithCurrency({ currency, price: cur, currencyRate }) : +cur)
  }, (applyCurrencyFrom ? +formatMoneyWithCurrency({ currency, price: from, currencyRate }) : from))

  return formatMoney(res)
}
export const subMoneyAndFormatCurrencyString = (
  from: string | number,
  values: Array<string | number>,
  {
    currency,
    currencyRate,
    applyCurrencyValues = false,
    applyCurrencyFrom = false,
  }: MoneyFormatTypeOptions,
): string => {
  const res = subMoneyAndFormatCurrency(from, values, {
    currency,
    currencyRate,
    applyCurrencyValues,
    applyCurrencyFrom,
  })

  return `${getCurrencyString({ currency })}${formatMoney(res)}`
}
export const sumMoneyAndFormatCurrencyString = (
  from: string | number,
  values: Array<string | number>,
  {
    currency,
    currencyRate,
    applyCurrencyValues = false,
    applyCurrencyFrom = false,
  }: MoneyFormatTypeOptions,
): string => {
  const res = sumMoneyAndFormatCurrency(from, values, {
    currency,
    currencyRate,
    applyCurrencyValues,
    applyCurrencyFrom,
  })

  return `${getCurrencyString({ currency })}${formatMoney(res)}`
}
export const sumMoneyAndFormatCurrency = (
  from: string | number,
  values: Array<string | number>,
  {
    currency,
    currencyRate,
    applyCurrencyValues = false,
    applyCurrencyFrom = false,
  }: MoneyFormatTypeOptions,
): string => {
  const res = values.reduce((acc, cur) => {
    return +acc + (applyCurrencyValues ? +formatMoneyWithCurrency({ currency, price: cur, currencyRate }) : +cur)
  }, (applyCurrencyFrom ? +formatMoneyWithCurrency({ currency, price: from, currencyRate }) : from))

  return formatMoney(res)
}

export const toDollarNoRound = ({ currency, price, currencyRate }: {
  currency: string,
  price: string | number,
  currencyRate: string
}) => {
  if (!price) {
    return 0
  }
  return CURRENCIES.CAD === currency
    ? (+price / +currencyRate)
    : price
}

export const toDollar = ({ currency, price, currencyRate }: {
  currency: string,
  price: string | number,
  currencyRate: string
}) => {
  if (!price) {
    return '0'
  }
  return CURRENCIES.CAD === currency
    ? (+price / +currencyRate).toFixed(2)
    : price
}
