// @flow
import type { FileActionsActionTypes } from 'src/redux/actions/fileActions/fileActionsTypes'
import { UPLOAD_INSURANCE } from 'src/redux/actions/fileActions/fileActions'

export type FileActionsState = {
    isLoading: boolean,
    fileActionsErrors?: ?{ [key: string]: any } | ?string
};

const initialState = {
  isLoading: false,
  fileActionsErrors: null,
}

const filesState = (state: FileActionsState = initialState, action: FileActionsActionTypes): FileActionsState => {
  switch (action.type) {
    case UPLOAD_INSURANCE.REQUEST:
    {
      return {
        ...state,
        isLoading: true,
      }
    }

    case UPLOAD_INSURANCE.SUCCESS:
    {
      return ({
        ...state,
        isLoading: false,
      })
    }

    case UPLOAD_INSURANCE.FAILURE:
    {
      return { ...state, isLoading: false, fileActionsErrors: action.payload }
    }

    default: {
      return state
    }
  }
}

export default filesState
