// @flow

import { call, fork, put, take } from 'redux-saga/effects'
import { GET_SURVEY_QUESTIONS, getSurveyQuestions } from 'src/redux/actions/myLeagues/myLeagues'
import http from 'src/services/http'
// types
import type { IOEffect } from 'redux-saga/effects'

function * watchGetSurveyQuestions(): Generator<IOEffect, void, any> {
  while (true) {
    yield take(GET_SURVEY_QUESTIONS.REQUEST)

    try {
      const { data } = yield call(http, 'api/league-survey', {
        method: 'GET',
      })

      yield put(getSurveyQuestions.success(data))
    } catch (e) {
      yield put(getSurveyQuestions.error(e.message))
    }
  }
}

export default [fork(watchGetSurveyQuestions)]
