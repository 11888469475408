// @flow

import { call, fork, put, take } from 'redux-saga/effects'
import { CREATE_PAYMENT_METHOD, createPaymentMethod, getPaymentMethods } from 'src/redux/actions/payments/payments'
import http from 'src/services/http'
// types
import type { IOEffect } from 'redux-saga/effects'

function * watchCreatePaymentMethod(): Generator<IOEffect, void, any> {
  while (true) {
    const {
      payload: { nonce },
    } = yield take(CREATE_PAYMENT_METHOD.REQUEST)

    try {
      yield call(http, 'api/payment/payment-method', {
        method: 'POST',
        body: JSON.stringify({
          nonce,
        }),
      })

      yield put(createPaymentMethod.success())
      yield put(getPaymentMethods.request())
    } catch (e) {
      yield put(createPaymentMethod.error(e.message))
    }
  }
}

export default [fork(watchCreatePaymentMethod)]
