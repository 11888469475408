// @flow
import React, { useEffect } from 'react'
import {
  ButtonPrimary,
  PaymentCardList,
} from 'src/common/components'

import { getDefaultPaymentMethod } from 'src/helpers/paymentHelper'

import type { PaymentsState, CreditCardType, ACHMethod } from 'src/redux/reducers/payments'

type CardPropsType = {
    setPaymentCardMethod: (data: ?CreditCardType | ACHMethod) => void,
    setPaymentPayload: (data: {
        payload: any,
        order_id: string
    }) => void,
    payments: PaymentsState,
    paymentCard: ?CreditCardType,
    buttonText: ?string,
    addButtonText: string,
    onSubmitCardPayment: () => void,
    deletePaymentMethod: () => void,
    canAddCard: boolean,
    editable: boolean
};

const CardPayment = (props: CardPropsType) => {
  const {
    payments: { paymentMethods, paymentToken },
    paymentCard,
    setPaymentCardMethod,
    onSubmitCardPayment,
    canAddCard,
    editable,
    deletePaymentMethod,
    buttonText,
    addButtonText,
  } = props

  useEffect(() => {
    setPaymentCardMethod(getDefaultPaymentMethod(paymentMethods?.cards) || paymentMethods?.cards?.[0])
  }, [paymentMethods?.cards?.length])

  return (
    <>
      <PaymentCardList
        paymentMethods={paymentMethods}
        checked={paymentCard}
        setChecked={setPaymentCardMethod}
        verifiedOnly={true}
        buttonText={buttonText}
        paymentToken={paymentToken}
        onSubmit={onSubmitCardPayment}
        canAddCard={canAddCard}
        editable={editable}
        deletePaymentMethod={deletePaymentMethod}
        addButtonText={addButtonText}
      />
      {paymentCard && onSubmitCardPayment && (
        <div className="d-flex justify-content-center">
          <ButtonPrimary
            disabled={!paymentCard}
            size="medium"
            buttonText="Submit payment"
            action={() => onSubmitCardPayment()}
          />
        </div>)}
    </>
  )
}

export default CardPayment
