// @flow

import React from 'react'

const CalendarIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512 512" width="20" height="20">
      <g>
        <g>
          <path
            d="M435.532,59.844V0h-39.896v59.844H355.74V0h-39.896v59.844h-39.896V0h-39.896v59.844h-39.896V0H156.26v59.844h-39.896V0    H76.468v59.844H9.974V512h492.052V59.844H435.532z M462.13,472.104H49.87V192.831h412.26V472.104z M462.13,152.935H49.87V99.74    h412.26V152.935z"
            fill="#6F6E6E"/>
        </g>
        <g>
          <rect x="116.364" y="232.727" width="39.896" height="39.896" fill="#6F6E6E"/>
        </g>
        <g>
          <rect x="196.156" y="232.727" width="39.896" height="39.896" fill="#6F6E6E"/>
        </g>
        <g>
          <rect x="116.364" y="312.519" width="39.896" height="39.896" fill="#6F6E6E"/>
        </g>
        <g>
          <rect x="196.156" y="312.519" width="39.896" height="39.896" fill="#6F6E6E"/>
        </g>
        <g>
          <rect x="116.364" y="392.312" width="39.896" height="39.896" fill="#6F6E6E"/>
        </g>
        <g>
          <rect x="196.156" y="392.312" width="39.896" height="39.896" fill="#6F6E6E"/>
        </g>
        <g>
          <rect x="275.948" y="232.727" width="39.896" height="39.896" fill="#6F6E6E"/>
        </g>
        <g>
          <rect x="275.948" y="312.519" width="39.896" height="39.896" fill="#6F6E6E"/>
        </g>
        <g>
          <rect x="275.948" y="392.312" width="39.896" height="39.896" fill="#6F6E6E"/>
        </g>
        <g>
          <rect x="355.74" y="232.727" width="39.896" height="39.896" data-original="#000000" fill="#6F6E6E"/>
        </g>
        <g>
          <rect x="355.74" y="312.519" width="39.896" height="39.896" data-original="#000000" fill="#6F6E6E"/>
        </g>
      </g>
    </svg>
  )
}

export default CalendarIcon
