// @flow

import { requestTypeCreator } from 'src/helpers'

// types
import type { BasicRedux } from './insurancesTypes'

export const MAKE_INSURANCE_GET_QUOTE = requestTypeCreator('MAKE_INSURANCE_GET_QUOTE')
export const MAKE_INSURANCE_SET_PLAYER_DATA = requestTypeCreator('MAKE_INSURANCE_SET_PLAYER_DATA')
export const MAKE_INSURANCE_CLEAN_DATA = requestTypeCreator('MAKE_INSURANCE_CLEAN_DATA')
export const MAKE_INSURANCE_CLEAN_PLAYER_DATA = requestTypeCreator('MAKE_INSURANCE_CLEAN_PLAYER_DATA')
export const MAKE_INSURANCE_SET_COVERAGE = requestTypeCreator('MAKE_INSURANCE_SET_COVERAGE')
export const MAKE_INSURANCE_SET_ADDITIONAL_INSUREDS = requestTypeCreator('MAKE_INSURANCE_SET_ADDITIONAL_INSUREDS')
export const MAKE_INSURANCE_CLEAN_ADDITIONAL_INSUREDS = requestTypeCreator('MAKE_INSURANCE_CLEAN_ADDITIONAL_INSUREDS')
export const MAKE_INSURANCE_CREATE_INSURANCE = requestTypeCreator('MAKE_INSURANCE_CREATE_INSURANCE')

export const getPlayerQuote = {
  error: (payload: any): BasicRedux => ({ type: MAKE_INSURANCE_GET_QUOTE.FAILURE, payload }),
  request: (payload: any): BasicRedux => ({ type: MAKE_INSURANCE_GET_QUOTE.REQUEST, payload }),
  success: (payload: any): BasicRedux => ({ type: MAKE_INSURANCE_GET_QUOTE.SUCCESS, payload }),
}

export const setPlayerData = {
  error: (payload: any): BasicRedux => ({ type: MAKE_INSURANCE_SET_PLAYER_DATA.FAILURE, payload }),
  request: (payload: any): BasicRedux => ({ type: MAKE_INSURANCE_SET_PLAYER_DATA.REQUEST, payload }),
  success: (payload: any): BasicRedux => ({ type: MAKE_INSURANCE_SET_PLAYER_DATA.SUCCESS, payload }),
}

export const setAdditionalInsuredsData = {
  error: (payload: any): BasicRedux => ({ type: MAKE_INSURANCE_SET_ADDITIONAL_INSUREDS.FAILURE, payload }),
  request: (payload: any): BasicRedux => ({ type: MAKE_INSURANCE_SET_ADDITIONAL_INSUREDS.REQUEST, payload }),
  success: (payload: any): BasicRedux => ({ type: MAKE_INSURANCE_SET_ADDITIONAL_INSUREDS.SUCCESS, payload }),
}

export const setCoverage = {
  success: (payload: any): BasicRedux => ({ type: MAKE_INSURANCE_SET_COVERAGE.SUCCESS, payload }),
}

export const cleanPlayerData = {
  success: (payload: any): BasicRedux => ({ type: MAKE_INSURANCE_CLEAN_PLAYER_DATA.SUCCESS, payload }),
}

export const cleanInsuranceData = {
  success: (payload: any): BasicRedux => ({ type: MAKE_INSURANCE_CLEAN_DATA.SUCCESS, payload }),
}

export const cleanAdditionalInsuredsData = {
  success: (payload: any): BasicRedux => ({ type: MAKE_INSURANCE_CLEAN_ADDITIONAL_INSUREDS.SUCCESS, payload }),
}

export const createInsurance = {
  error: (payload: any): BasicRedux => ({ type: MAKE_INSURANCE_CREATE_INSURANCE.FAILURE, payload }),
  request: (payload: any): BasicRedux => ({ type: MAKE_INSURANCE_CREATE_INSURANCE.REQUEST, payload }),
  success: (payload: any): BasicRedux => ({ type: MAKE_INSURANCE_CREATE_INSURANCE.SUCCESS, payload }),
}
