// @flow

import { call, fork, put, take } from 'redux-saga/effects'
import { GET_FEATURES, getFeatures } from 'src/redux/actions/featureManagement/featureManagement'
import { getFeatures as fetchGetFeatures } from 'src/services/featureManagement'
// types
import type { IOEffect } from 'redux-saga/effects'

function * watchGetFeatures(): Generator<IOEffect, void, any> {
  while (true) {
    yield take(GET_FEATURES.REQUEST)

    try {
      const data = yield call(fetchGetFeatures)

      yield put(getFeatures.success(data))
    } catch (e) {
      yield put(getFeatures.error(e.message))
    }
  }
}

export default [fork(watchGetFeatures)]
