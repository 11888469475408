// @flow

import { requestTypeCreator } from 'src/helpers'

// types
import type { CreateDivisionActionTypes, GetDivisionsActionTypes, RemoveDivisionActionTypes } from './divisionsTypes'

export const CREATE_DIVISION = requestTypeCreator('CREATE_DIVISION')
export const EDIT_DIVISION = requestTypeCreator('EDIT_DIVISION')
export const GET_ADIVISIONS = requestTypeCreator('GET_ADIVISIONS')
export const REMOVE_DIVISION = requestTypeCreator('REMOVE_DIVISION')

export const createDivision = {
  error: (payload: any): CreateDivisionActionTypes => ({ type: CREATE_DIVISION.FAILURE, payload }),
  request: (payload: any): CreateDivisionActionTypes => ({ type: CREATE_DIVISION.REQUEST, payload }),
  success: (payload: any): CreateDivisionActionTypes => ({ type: CREATE_DIVISION.SUCCESS, payload }),
}

export const getDivisions = {
  error: (payload: any): GetDivisionsActionTypes => ({ type: GET_ADIVISIONS.FAILURE, payload }),
  success: (payload: any): GetDivisionsActionTypes => ({ type: GET_ADIVISIONS.SUCCESS, payload }),
}

export const editDivision = {
  error: (payload: any): CreateDivisionActionTypes => ({ type: EDIT_DIVISION.FAILURE, payload }),
  request: (payload: any): CreateDivisionActionTypes => ({ type: EDIT_DIVISION.REQUEST, payload }),
  success: (payload: any): CreateDivisionActionTypes => ({ type: EDIT_DIVISION.SUCCESS, payload }),
}

export const removeDivision = {
  error: (payload: any): RemoveDivisionActionTypes => ({ type: REMOVE_DIVISION.FAILURE, payload }),
  request: (payload: any): RemoveDivisionActionTypes => ({ type: REMOVE_DIVISION.REQUEST, payload }),
  success: (payload: any): RemoveDivisionActionTypes => ({ type: REMOVE_DIVISION.SUCCESS, payload }),
}
